import Authenticate_app from "../common/authenticate";
import React, { useState } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion, useAnimation, useViewportScroll } from "framer-motion";
import { useEffect } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { IoCalendarClearOutline } from "react-icons/io5";

import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import ReactSelect from "react-select";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

const AnimatedBox = motion(Box);
const MotionBox = motion(Box);

const ImageN = () => {
  const [isOpen, setIsOpen] = useState({
    benefits: false,
    ingredients: false,
    usingProduct: false,
    standards: false,
    reviews: false,
    authenticate: false,
  });

  const openModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: true }));
  };

  const closeModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: false }));
  };

  const [selectedColor, setSelectedColor] = useState("white");

  const colors = ["yellow", "blue", "white", "red"];
  const displayColors = {
    yellow: "Yellow",
    blue: "Blue",
    white: "White",
    red: "Red",
  };

  const colorStyles = {
    yellow: {
      bg: "yellow.300",
      color: "black",
    },
    blue: {
      bg: "blue.300",
      color: "white",
    },
    white: {
      bg: "gray.100",
      color: "black",
      gray: "gray.100",
    },
    red: {
      bg: "red.300",
      color: "white",
    },
  };

  // for backgroud image fade

  const controls = useAnimation();
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    scrollY.onChange((y) => {
      const opacity = Math.max(1 - y / 500, 0.6); // Fades to 60% opacity
      controls.start({ opacity, transition: { duration: 0.1 } }); // Adds a transition duration for smoothness
    });
  }, [scrollY, controls]);

  return (
    <>
      <AnimatedBox
        // bgGradient="linear(to-tr, red.300, blue.500)"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3 }}
        // minH={"100vh"}
        color="white"
        bg="#FFF"
        maxW="md"

        display={"block"}
      >
        <Container
          mt="2"
          w="100%"
          p="0"
          border="0px"
          borderColor="gray.700"
          borderRadius={"lg"}
          bg="#FFF"
          p={0}
          color={colorStyles[selectedColor].color}
          textAlign="center"
          fontWeight="bold"
        >
          <Image
            src="/image.png"
            alt="Description of your image"
            borderTop="0px"
            mt="-9"
            borderLeft="0px"
            borderRight="0px"
            borderTopLeftRadius="20px"
            borderTopRightRadius="20px"
            borderBottomLeftRadius="0px"
            borderBottomRightRadius="0px"
            w="100%"
          />

          {/* <Image src="/certified.png" mt="-490" ml="150" w="70%" position={"relative"} alt="Description of your image" /> */}

          <Container mt="5">
            <Center border="0px" p="0" m="0">
              <Button
                width="100%"
                m="2"
                h="50px"
                mr="5"
                bg="#000"
                color="gray.100"
                borderRadius={"sm"}
                border="0.01em solid #000"
                fontSize={{ base: "19px", md: "28px", lg: "22px" }}
                fontWeight={"medium"}
                p="7"
                onClick={() => openModal("authenticate")}
              >
                Authenticate Product
              </Button>
            </Center>
          </Container>

          <Container lineHeight={{ base: "7", md: "9", lg: "9" }} mt="5">
            <Center border="0px" p="0" m="0">
              <Heading
                fontSize={{ base: "25px", md: "28px", lg: "22px" }}
                fontWeight={"black"}
                color="#000"
              >
                VITAL C hydrating anti-aging serum{" "}
              </Heading>
            </Center>

            <Center border="0px" p="0" mt="2">
              <Heading
                fontSize={{ base: "21px", md: "28px", lg: "22px" }}
                textTransform={"uppercase"}
                fontWeight={"medium"}
              >
                Size: 1.7 oz / 50 mL
              </Heading>
            </Center>

            <Text
              mt="2"
              textAlign={"center"}
              fontSize={{ base: "17px", md: "28px", lg: "22px" }}
              fontWeight={"medium"}
              color="gray.500"
            >
             Our bestselling facial serum with multiple forms of vitamin C helps to brighten, hydrate and improve the appearance of lines.
            </Text>
          </Container>

          <Container mt="4">
            <SimpleGrid
              columns={{ base: "1", md: "2", lg: "2" }}
              spacing="1"
              p="2"
            >
              <Box textAlign={"left"} color="gray.500">
                {" "}
                Produced{" "}
              </Box>
              <Box textAlign={"left"} fontWeight={"medium"}>
                {" "}
                United states of America{" "}
              </Box>

              <Box textAlign={"left"} color="gray.500">
                {" "}
                Date of Production{" "}
              </Box>
              <Box textAlign={"left"} fontWeight={"medium"}>
                {" "}
                May 2024{" "}
              </Box>

              <Box textAlign={"left"} color="gray.500">
                {" "}
                Expiry{" "}
              </Box>
              <Box textAlign={"left"} fontWeight={"medium"}>
                {" "}
                May 2026{" "}
              </Box>
            </SimpleGrid>

            <Box mr="15" textAlign={"right"}>
              {" "}
              <Button variant="link">Additional Details</Button>{" "}
            </Box>
          </Container>

          <Container mt="4" p="0">
            <Button bg="white" variant="outline" p="7" w="80%">
              {/* <LuCalendarClock size="21" mr="15" /> */}
              Product Expiry Notification
            </Button>
          </Container>

          <HStack p="3" m="1" mt="5">
            <Box
              p="2"
              borderRadius={"lg"}
              w="100%"
              onClick={() => openModal("ingredients")}
            >
              <Center>
                <PiBowlFoodLight color="#964940" size="41" />
              </Center>
              <Center p="2">
                <Text
                  fontSize={{ base: "13px", md: "17", lg: "19" }}
                  textTransform={"uppercase"}
                >
                  Ingredients{" "}
                </Text>
              </Center>
            </Box>

            <Box
              p="2"
              borderRadius={"lg"}
              w="100%"
              onClick={() => openModal("benefits")}
            >
              <Center>
                <GoNorthStar color="#964940" size="41" />
              </Center>
              <Center p="2">
                <Text
                  fontSize={{ base: "13px", md: "17", lg: "19" }}
                  textTransform={"uppercase"}
                >
                  Benefits{" "}
                </Text>
              </Center>
            </Box>
          </HStack>

          <HStack p="2" m="0" mt="-5">
            <Box
              p="5"
              borderRadius={"lg"}
              w="100%"
              onClick={() => openModal("usingProduct")}
            >
              <Center>
                <TfiHandStop color="#964940" size="41" />
              </Center>
              <Center p="2">
                <Text
                  fontSize={{ base: "13px", md: "17", lg: "19" }}
                  textTransform={"uppercase"}
                >
                  Using the product{" "}
                </Text>
              </Center>
            </Box>
          </HStack>

          <HStack p="2" m="1" mt="-2">
            <Box
              p="5"
              borderRadius={"lg"}
              w="100%"
              onClick={() => openModal("standards")}
            >
              <Center>
                <TbAward size="41" color="#964940" />
              </Center>
              <Center p="2">
                <Text
                  fontSize={{ base: "13px", md: "17", lg: "19" }}
                  textTransform={"uppercase"}
                >
                  Product standards{" "}
                </Text>
              </Center>
            </Box>

            <Box
              p="2"
              borderRadius={"lg"}
              w="100%"
              onClick={() => openModal("reviews")}
            >
              <Center>
                <TbStars size="41" color="#964940" />
              </Center>
              <Center p="2">
                <Text
                  fontSize={{ base: "13px", md: "17", lg: "19" }}
                  textTransform={"uppercase"}
                >
                  Reviews{" "}
                </Text>
              </Center>
            </Box>
          </HStack>
        </Container>
      </AnimatedBox>
      <VStack 
                    maxW="md"

      spacing={0} h="100vh" overflowY="scroll" m="0">
        <Box
          position="relative"
          w="full"
          h="99vh"
          //   overflowY="scroll"
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          _before={{
            content: '""',
            backgroundImage: "url('demo.png')",
            backgroundColor: "#000000",
            backgroundPosition: "center top 0px", // Adjusted the background position
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -2,
          }}
        >
          <Box p="10" bgGradient="linear(to-t, black, transparent)">
            <Heading
              textTransform={"uppercase"}
              textAlign={"left"}
              size="2xl"
              fontWeight={"black"}
              bgGradient="linear(to-r, red.500, green.600)"
              bgClip="text"
            >
              {" "}
              <IoCalendarClearOutline /> Cuatomer Retention Platform
            </Heading>
            <Center mb="20">
              <VStack w="100%" pt="10">
                <Button
  minW="90%"
  color="gray.600"
  bg="red.300"
  onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}
>
  Request Meeting
</Button>
<Button
  minW="90%"
  color="gray.600"
  bg="red.300"
  onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}
>
  Demo
</Button>
                <Text color="gray.200">
                  {" "}
                </Text>
              </VStack>
            </Center>
            <Box mt="5"></Box>
          </Box>
        </Box>
      </VStack>
      {/* Authenticate Modal */}
      <Modal
        isOpen={isOpen.authenticate}
        onClose={() => closeModal("authenticate")}
        size="full"
      >
        <ModalOverlay />
        <ModalContent bg="#F9F8F5">
          <ModalHeader>Authenticate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Benefits content goes here */}
            {/* <BenefitsComponent /> */}
            <Authenticate_app />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => closeModal("authenticate")}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Benefits Modal */}
      <Modal
        isOpen={isOpen.benefits}
        onClose={() => closeModal("benefits")}
        size="full"
      >
        <ModalOverlay />
        <ModalContent bg="#F9F8F5">
          <ModalHeader>Benefits</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Benefits content goes here */}
            <BenefitsComponent />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => closeModal("benefits")}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Ingredients Modal */}
      <Modal
        bg="#F9F8F5"
        isOpen={isOpen.ingredients}
        onClose={() => closeModal("ingredients")}
        size="full"
      >
        <ModalOverlay />
        <ModalContent bg="#F9F8F5">
          <ModalHeader>Ingredients</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Ingredients content goes here */}

            <Text p="2" fontSize={"15"} fontWeight={"bold"}>
              Key Ingredients
            </Text>
            <Divider />

            <HStack border="0px" p="4">
              <Box>Caffeine Rich Seed Extract Plumping </Box>
              <Box> Text comes here </Box>
            </HStack>

            <HStack border="0px" p="4">
              <Box>Image comes here </Box>
              <Box> Text comes here </Box>
            </HStack>

            <Text p="2" fontSize={"15"} fontWeight={"bold"}>
              All Ingredients
            </Text>
            <Divider />

            <SelectSearch />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => closeModal("ingredients")}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Using the Product Modal */}
      <Modal
        bg="#F9F8F5"
        isOpen={isOpen.usingProduct}
        onClose={() => closeModal("usingProduct")}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Using the Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Using the Product content goes here */}
            This is the Using the Product dialog.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => closeModal("usingProduct")}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Standards Modal */}
      <Modal
        bg="#F9F8F5"
        isOpen={isOpen.standards}
        onClose={() => closeModal("standards")}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Standards</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Standards content goes here */}
            This is the Standards dialog.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => closeModal("standards")}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Reviews Modal */}
      <Modal
        bg="#F9F8F5"
        isOpen={isOpen.reviews}
        onClose={() => closeModal("reviews")}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reviews</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Reviews content goes here */}
            This is the Reviews dialog.
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => closeModal("reviews")}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const BenefitsComponent = () => {
  return (
    <Container>
      <HStack p="3" m="1" mt="-2">
        <Text>Clean</Text>

        <Text>Vegan</Text>
        <Text>Cruelty Free</Text>
      </HStack>

      <SimpleGrid columns="2" spacing="4" p="2">
        <HStack>
          <Box>Image</Box>
          <Text>Hydrating</Text>
        </HStack>

        <HStack>
          <Box>Image</Box>
          <Text>Dewy Finish</Text>
        </HStack>
      </SimpleGrid>

      <SimpleGrid columns="2" spacing="4" p="2">
        <HStack>
          <Box>Image</Box>
          <Text>Lightweight</Text>
        </HStack>

        <HStack>
          <Box>Image</Box>
          <Text>Plumping</Text>
        </HStack>
      </SimpleGrid>

      <Heading fontSize={"lg"} fontWeight={"medium"} mt="5">
        What it does{" "}
      </Heading>
      <Divider />

      <Box border="1px" borderColor="gray.200" p="4" bg="white" mt="5">
        Weightless formula absorbs quickly into skin with zero residue, leaving
        it plumped, hydrated, and prepped for makeup
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        Designed to complement The Minimalist but can be used with any
        complexion product to give skin a healthy glow
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        4 types of hyaluronic acid target multiple layers of skin to lock in
        hydration for all-day moisture
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        Niacinamide helps brighten skin and smooth texture
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        Caffeine-rich cacao seed extract stimulates blood flow for an immediate
        plumping effect
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        Japanese goldthread root helps soothe skin
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        Supports a healthy skin barrier over time
      </Box>

      <Box border="1px" borderColor="gray.200" p="4" mt="5" bg="white">
        Non-comedogenic formula
      </Box>
    </Container>
  );
};

const ingredients = [
  { id: 1, description: "1,2-Hexanediol", type: "chemical" },
  { id: 2, description: "Butylene Glycol", type: "chemical" },
  { id: 3, description: "Caprylic/Capric Triglyceride", type: "chemical" },
  { id: 4, description: "Citric Acid", type: "chemical" },
  {
    id: 5,
    description: "Coptis Japonica Root Extract",
    type: "natural extract",
  },
  { id: 6, description: "Dextrin", type: "natural extract" },
  { id: 7, description: "Ethylhexylglycerin", type: "chemical" },
  { id: 8, description: "Hydrolyzed Sodium Hyaluronate", type: "chemical" },
  { id: 9, description: "Isononyl Isononanoate", type: "chemical" },
  { id: 10, description: "Niacinamide", type: "chemical" },
  { id: 11, description: "Pentylene Glycol", type: "chemical" },
  { id: 12, description: "Sodium Acetylated Hyaluronate", type: "chemical" },
  { id: 13, description: "Sodium Citrate", type: "chemical" },
  { id: 14, description: "Sodium Hyaluronate", type: "chemical" },
  { id: 15, description: "Sodium Hyaluronate Crosspolymer", type: "chemical" },
  {
    id: 16,
    description: "Theobroma Cacao (Cocoa) Seed Extract",
    type: "natural extract",
  },
  { id: 17, description: "Tocopherol", type: "natural extract" },
  {
    id: 18,
    description: "Trisodium Ethylenediamine Disuccinate",
    type: "chemical",
  },
];

const SelectSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const filteredIngredients = ingredients.filter((ingredient) =>
    ingredient.description.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <VStack spacing={4} p={4}>
      <Input
        placeholder="Search ingredients..."
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        bg="white"
      />
      <VStack align="start" spacing={2} w="100%">
        {filteredIngredients.map((ingredient) => (
          <>
            <Text key={ingredient.id}>
              {ingredient.description} - {ingredient.type}
            </Text>
            <Divider />
          </>
        ))}
      </VStack>
    </VStack>
  );
};

export default ImageN;
