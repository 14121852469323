import React, { useState } from "react";

import { Avatar, AvatarBadge, AvatarGroup, VStack, Input, Text, Divider, Checkbox,  FormControl, FormLabel, Box, Button } from "@chakra-ui/react";


  
  const ExpiryAlert = () => {
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission logic here
      console.log('Email:', email);
      console.log('Phone Number:', phoneNumber);
    };
  
  
    return (
      <>
      <Box p={5} maxW="400px" mx="auto">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="email">
            <FormLabel>Email Address</FormLabel>
            <Input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </FormControl>

          <FormControl id="phone-number">
            <FormLabel>Phone Number</FormLabel>
            <Input 
              type="tel" 
              value={phoneNumber} 
              onChange={(e) => setPhoneNumber(e.target.value)} 
              required 
            />
          </FormControl>

        
        </VStack>
              
      <VStack p="0" spacing="2" border="0px" fontSize={"15"} mt="25">
              <Checkbox size="lg" ml="0" w="100%" defaultChecked>
                <Text fontSize={"17"}> Alert me on Product Expiration</Text>
              </Checkbox>
              <Checkbox
                ml="0"
                w="100%"
                size="lg"
                colorScheme="blue"
                
              >
                <Text fontSize={"17"}>
                  {" "}
                  Send me Discounts and offers by mail{" "}
                </Text>
              </Checkbox>
              <Checkbox size="lg" w="100%" colorScheme="blue" defaultChecked>
                <Text fontSize={"17"}> Allow non marketing updates </Text>
              </Checkbox>
            </VStack> 
            <Button type="submit" colorScheme="blue" mt="10">Submit</Button>
      </form>
    </Box>

      </>
    );
  };
  
  export default ExpiryAlert;
  