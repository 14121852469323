import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Center,
  HStack,
  Spacer,
  IconButton,
  Button,
  ButtonGroup,
  List,
  Link,
  Text,
  Divider,
  Flex,
  Tag,
  Box,
  ListItem,
  Badge,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Checkbox,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  Editor,
  EditorState,
  convertFromRaw,
  convertToRaw,
  CompositeDecorator,
} from "draft-js";
import { CloseIcon } from "@chakra-ui/icons";
import { CiSquareChevLeft, CiSquareChevRight } from "react-icons/ci";
import BarChart from "./BarChart";
import "draft-js/dist/Draft.css";
import "../App.css"; // Import your CSS file

const initialContent = {
  blocks: [
    {
      key: "1",
      text: "Mark Anderson",
      type: "header-one",
    },
    {
      key: "2",
      text: "IT Director at a Mid-Sized Company",
      type: "header-two",
    },
    {
      key: "3",
      text: "Age: 45",
      type: "unstyled",
    },
    {
      key: "4",
      text: "Company Size: 200-500 employees",
      type: "unstyled",
    },
    {
      key: "5",
      text: "Location: New York, NY",
      type: "unstyled",
    },
    {
      key: "6",
      text: "Education: Master’s Degree in Information Technology",
      type: "unstyled",
    },
    {
      key: "7",
      text: "Demographics",
      type: "header-two",
    },
    {
      key: "8",
      text: "Gender: Male",
      type: "unstyled",
    },
    {
      key: "9",
      text: "Income: $120,000 annually",
      type: "unstyled",
    },
    {
      key: "10",
      text: "Marital Status: Married with two children",
      type: "unstyled",
    },
    {
      key: "11",
      text: "Background",
      type: "header-two",
    },
    {
      key: "12",
      text: "Oversees the IT infrastructure and strategy for the company. Responsible for technology procurement, implementation, and management. Aims to leverage technology to improve business processes and efficiency.",
      type: "unstyled",
    },
    {
      key: "13",
      text: "Goals",
      type: "header-two",
    },
    {
      key: "14",
      text: "Ensure robust and secure IT infrastructure. Streamline IT operations to reduce costs and improve efficiency. Stay up-to-date with emerging technologies to maintain a competitive edge.",
      type: "unstyled",
    },
    {
      key: "15",
      text: "Challenges",
      type: "header-two",
    },
    {
      key: "16",
      text: "Balancing budget constraints with the need for high-quality IT solutions. Managing a team of IT professionals and ensuring their ongoing training and development. Integrating new technologies with existing systems without causing disruptions.",
      type: "unstyled",
    },
    {
      key: "17",
      text: "Responsibilities",
      type: "header-two",
    },
    {
      key: "18",
      text: "Evaluating and selecting IT vendors and solutions. Managing IT projects from conception to deployment. Ensuring compliance with industry regulations and standards. Reporting IT performance and strategy to senior management.",
      type: "unstyled",
    },
    {
      key: "19",
      text: "Hobbies and Interests",
      type: "header-two",
    },
    {
      key: "20",
      text: "Interested in emerging tech trends and innovations. Enjoys reading tech blogs and attending industry conferences. Participates in local IT networking events and groups.",
      type: "unstyled",
    },
    {
      key: "21",
      text: "Decision-Making Process",
      type: "header-two",
    },
    {
      key: "22",
      text: "Prefers detailed product demonstrations and case studies. Values peer reviews and recommendations from other IT professionals. Requires clear ROI analysis and cost-benefit breakdowns.",
      type: "unstyled",
    },
    {
      key: "23",
      text: "Brands and Influences",
      type: "header-two",
    },
    {
      key: "24",
      text: "Preferred Brands: Cisco, Microsoft, VMware. Influences: Gartner reports, industry conferences, professional networks.",
      type: "unstyled",
    },
    {
      key: "25",
      text: "Quote",
      type: "header-two",
    },
    {
      key: "26",
      text: "“I need IT solutions that not only fit within our budget but also offer scalability and security. I rely on thorough evaluations and peer recommendations to make informed decisions.”",
      type: "blockquote",
    },
    {
      key: "27",
      text: "Marketing Message",
      type: "header-two",
    },
    {
      key: "28",
      text: "“Empower your IT infrastructure with our scalable and secure solutions. Trusted by IT professionals, our products are designed to streamline your operations and provide a clear ROI. See why industry leaders like Mark choose us for their technology needs.”",
      type: "unstyled",
    },
  ],
  entityMap: {},
};

const persona2Content = {
  blocks: [
    {
      key: "1",
      text: "Lisa Roberts",
      type: "header-one",
    },
    {
      key: "2",
      text: "Procurement Manager at a Large Enterprise",
      type: "header-two",
    },
    {
      key: "3",
      text: "Age: 38",
      type: "unstyled",
    },
    {
      key: "4",
      text: "Company Size: 5000+ employees",
      type: "unstyled",
    },
    {
      key: "5",
      text: "Location: Chicago, IL",
      type: "unstyled",
    },
    {
      key: "6",
      text: "Education: MBA in Supply Chain Management",
      type: "unstyled",
    },
    {
      key: "7",
      text: "Demographics",
      type: "header-two",
    },
    {
      key: "8",
      text: "Gender: Female",
      type: "unstyled",
    },
    {
      key: "9",
      text: "Income: $110,000 annually",
      type: "unstyled",
    },
    {
      key: "10",
      text: "Marital Status: Married with one child",
      type: "unstyled",
    },
    {
      key: "11",
      text: "Background",
      type: "header-two",
    },
    {
      key: "12",
      text: "Oversees the procurement process for the company, including supplier selection and contract negotiation. Focused on securing the best value for money while ensuring quality and compliance. Manages a team responsible for various procurement activities.",
      type: "unstyled",
    },
    {
      key: "13",
      text: "Goals",
      type: "header-two",
    },
    {
      key: "14",
      text: "Optimize procurement processes to reduce costs and improve efficiency. Ensure compliance with procurement policies and industry regulations. Establish strong relationships with reliable suppliers.",
      type: "unstyled",
    },
    {
      key: "15",
      text: "Challenges",
      type: "header-two",
    },
    {
      key: "16",
      text: "Balancing cost savings with the need for high-quality products and services. Navigating complex procurement regulations and compliance requirements. Managing a large volume of procurement requests and contracts.",
      type: "unstyled",
    },
    {
      key: "17",
      text: "Responsibilities",
      type: "header-two",
    },
    {
      key: "18",
      text: "Developing and implementing procurement strategies. Negotiating contracts and managing supplier relationships. Monitoring supplier performance and addressing any issues. Ensuring timely delivery of goods and services.",
      type: "unstyled",
    },
    {
      key: "19",
      text: "Hobbies and Interests",
      type: "header-two",
    },
    {
      key: "20",
      text: "Interested in supply chain innovations and best practices. Enjoys participating in procurement and supply chain professional organizations. Keeps up-to-date with industry trends through webinars and conferences.",
      type: "unstyled",
    },
    {
      key: "21",
      text: "Decision-Making Process",
      type: "header-two",
    },
    {
      key: "22",
      text: "Prefers detailed RFPs and competitive bidding processes. Values supplier reliability and track record. Requires clear, data-driven proposals and references.",
      type: "unstyled",
    },
    {
      key: "23",
      text: "Brands and Influences",
      type: "header-two",
    },
    {
      key: "24",
      text: "Preferred Brands: SAP Ariba, Oracle Procurement, IBM. Influences: Industry benchmarks, professional networks, procurement forums.",
      type: "unstyled",
    },
    {
      key: "25",
      text: "Quote",
      type: "header-two",
    },
    {
      key: "26",
      text: "“My priority is to ensure that we get the best value without compromising on quality. I rely on thorough evaluations, clear proposals, and strong supplier relationships to make informed procurement decisions.”",
      type: "blockquote",
    },
    {
      key: "27",
      text: "Marketing Message",
      type: "header-two",
    },
    {
      key: "28",
      text: "“Streamline your procurement process with our comprehensive solutions. Trusted by procurement leaders, our tools help you achieve cost savings, ensure compliance, and build strong supplier relationships. Discover why professionals like Lisa rely on us for their procurement needs.”",
      type: "unstyled",
    },
  ],
  entityMap: {},
};

const persona3Content = {
  blocks: [
    {
      key: "1",
      text: "David Chen",
      type: "header-one",
    },
    {
      key: "2",
      text: "Marketing Manager at a SaaS Company",
      type: "header-two",
    },
    {
      key: "3",
      text: "Age: 32",
      type: "unstyled",
    },
    {
      key: "4",
      text: "Company Size: 100-250 employees",
      type: "unstyled",
    },
    {
      key: "5",
      text: "Location: San Francisco, CA",
      type: "unstyled",
    },
    {
      key: "6",
      text: "Education: Bachelor’s Degree in Marketing",
      type: "unstyled",
    },
    {
      key: "7",
      text: "Demographics",
      type: "header-two",
    },
    {
      key: "8",
      text: "Gender: Male",
      type: "unstyled",
    },
    {
      key: "9",
      text: "Income: $85,000 annually",
      type: "unstyled",
    },
    {
      key: "10",
      text: "Marital Status: Single",
      type: "unstyled",
    },
    {
      key: "11",
      text: "Background",
      type: "header-two",
    },
    {
      key: "12",
      text: "Manages marketing campaigns and strategies for the SaaS company. Responsible for lead generation, brand awareness, and customer engagement. Works closely with the sales team to align marketing and sales efforts.",
      type: "unstyled",
    },
    {
      key: "13",
      text: "Goals",
      type: "header-two",
    },
    {
      key: "14",
      text: "Increase brand awareness and market reach. Generate high-quality leads that convert to customers. Optimize marketing campaigns to achieve better ROI.",
      type: "unstyled",
    },
    {
      key: "15",
      text: "Challenges",
      type: "header-two",
    },
    {
      key: "16",
      text: "Staying competitive in a rapidly evolving SaaS market. Balancing budget constraints with the need for effective marketing campaigns. Integrating marketing efforts with sales processes to ensure seamless lead conversion.",
      type: "unstyled",
    },
    {
      key: "17",
      text: "Responsibilities",
      type: "header-two",
    },
    {
      key: "18",
      text: "Developing and executing marketing strategies and campaigns. Analyzing marketing data to measure campaign performance. Collaborating with the sales team to ensure alignment and support. Managing social media, content marketing, and other digital marketing efforts.",
      type: "unstyled",
    },
    {
      key: "19",
      text: "Hobbies and Interests",
      type: "header-two",
    },
    {
      key: "20",
      text: "Interested in digital marketing trends and innovations. Enjoys attending marketing workshops and webinars. Active on social media and marketing forums.",
      type: "unstyled",
    },
    {
      key: "21",
      text: "Decision-Making Process",
      type: "header-two",
    },
    {
      key: "22",
      text: "Prefers data-driven insights and case studies. Values marketing tools that integrate well with existing systems. Requires clear demonstrations of ROI and performance metrics.",
      type: "unstyled",
    },
    {
      key: "23",
      text: "Brands and Influences",
      type: "header-two",
    },
    {
      key: "24",
      text: "Preferred Brands: HubSpot, Salesforce, Marketo. Influences: Marketing blogs, industry conferences, professional networks.",
      type: "unstyled",
    },
    {
      key: "25",
      text: "Quote",
      type: "header-two",
    },
    {
      key: "26",
      text: "“I need marketing tools that can help me track performance and generate high-quality leads. I rely on data-driven insights and seamless integration with our sales processes to make informed decisions.”",
      type: "blockquote",
    },
    {
      key: "27",
      text: "Marketing Message",
      type: "header-two",
    },
    {
      key: "28",
      text: "“Boost your marketing performance with our advanced tools designed for SaaS companies. Our solutions help you generate high-quality leads, track campaign performance, and integrate seamlessly with your sales processes. See why marketing professionals like David choose us to power their campaigns.”",
      type: "unstyled",
    },
  ],
  entityMap: {},
};

const personasContent = {
  "Mark Anderson": initialContent,
  "Lisa Roberts": persona2Content,
  "David Chen": persona3Content,
};

const personas = [
  { id: 1, name: "Mark Anderson" },
  { id: 2, name: "Lisa Roberts" },
  { id: 3, name: "David Chen" },
];

const InsertedComponent = () => {
  return (
    <Box p="4" my="4" bordercolor="red.100" border="1px">
      <Center>
        {/* <ProductRoadmap data={roadmapData} /> */}
        <BarChart />
      </Center>
    </Box>
  );
};

const InsertedComponentRoadmap = () => {
  return (
    <Box p="4" my="4" class="border" border="1px">
      <Center>
        {/* <ProductRoadmap data={roadmapData} /> */}
        <BarChart />
      </Center>
    </Box>
  );
};

const blockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  switch (type) {
    case "header-one":
      return "header-one";
    case "header-two":
      return "header-two";
    case "unstyled":
      return "unstyled";
    default:
      return null;
  }
};

const blockRendererFn = (contentBlock) => {
  const type = contentBlock.getType();
  if (type === "inserted-component") {
    return {
      component: InsertedComponent,
      editable: false,
    };
  } else if (type === "InsertedComponentRoadmap") {
    return {
      component: InsertedComponentRoadmap,
      editable: false,
    };
  }
  return null;
};

const Highlight = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const onClose = () => setIsOpen(false);

  const handleCheckboxChange = (persona) => {
    setSelectedPersonas((prev) =>
      prev.includes(persona)
        ? prev.filter((p) => p !== persona)
        : [...prev, persona]
    );
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <span
          className="special-font"
          style={{
            backgroundColor: "yellow",
            cursor: "pointer",
            borderBottom: "2px dotted black",
            position: "relative",
            display: "inline-block",
          }}
          onClick={() => setIsOpen(true)}
        >
          {children}
          {selectedPersonas.length > 0 && (
            <Badge
              ml="1"
              colorScheme="green"
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                backgroundColor: "black",
                color: "white",
              }}
            >
              {selectedPersonas.length}
            </Badge>
          )}
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Select Personas</PopoverHeader>
        <PopoverBody>
          {personas.map((persona) => (
            <Checkbox
              key={persona.id}
              isChecked={selectedPersonas.includes(persona)}
              onChange={() => handleCheckboxChange(persona)}
            >
              {persona.name}
            </Checkbox>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const findHighlight = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  let matchArr, start;
  const regex = /\b(they|User|Peter)\b/g; // Add words here
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const decorator = new CompositeDecorator([
  {
    strategy: findHighlight,
    component: Highlight,
  },
]);

const Salte = ({ editorState, setEditorState }) => {
  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    console.log("Content state:", convertToRaw(contentState));
  }, [editorState]);

  return (
    <Box
      className="special-font"
      p="5"
      border="0px solid black"
      borderRadius="md"
      mb="4"
    >
      <Editor
        editorState={editorState}
        blockStyleFn={blockStyleFn}
        blockRendererFn={blockRendererFn}
        onChange={setEditorState}
        customStyleMap={{ HIGHLIGHT: { backgroundColor: "yellow" } }}
      />
    </Box>
  );
};
const renderGridView = (selectedPersona) => {
  const content = personasContent[selectedPersona];
  if (!content) {
    return <Text>No content available for the selected persona.</Text>;
  }

  return (
    <SimpleGrid columns={[1, null, 2, 2]} spacing="4" p="5">
      {content.blocks.map((block) => (
        <Box key={block.key} p="4" borderWidth="1px" borderRadius="md">
          <Text fontSize={block.type === 'header-one' ? '2xl' : 'md'} fontWeight={block.type.includes('header') ? 'bold' : 'normal'}>
            {block.text}
          </Text>
        </Box>
      ))}
    </SimpleGrid>
  );
};

const ObjectExample = () => {
  const [selectedPersona, setSelectedPersona] = useState("Mark Anderson");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      convertFromRaw(personasContent[selectedPersona]),
      decorator
    )
  );

  const handlePersonaClick = (personaName) => {
    setSelectedPersona(personaName);
    setEditorState(
      EditorState.createWithContent(
        convertFromRaw(personasContent[personaName]),
        decorator
      )
    );
  };

  const [view, setView] = useState("draftjs");

  const switchView = (newView) => {
    setView(newView);
  };

  const [isLeftOpen, setIsLeftOpen] = useState(true);
  const [isRightOpen, setIsRightOpen] = useState(true);

  const toggleLeftBox = () => {
    setIsLeftOpen(!isLeftOpen);
  };

  const toggleRightBox = () => {
    setIsRightOpen(!isRightOpen);
  };

  

  return (
    <>
      <Flex p="5">
        {isLeftOpen && (
          <Box pt="10" flex="3" border="1px" borderColor="gray.50">
            <IconButton
              icon={<CloseIcon />}
              onClick={toggleLeftBox}
              aria-label="Close left box"
              size="sm"
              position="absolute"
              left="5"
              top="5"
            />
            <Text mt="20">Other Buyer Personas</Text>
            <Divider mb="3" />
            <List>
              {Object.keys(personasContent).map((personaName) => (
                <ListItem key={personaName}>
                  <Link
                    color={
                      selectedPersona === personaName
                        ? "facebook.600"
                        : "facebook.400"
                    }
                    onClick={() => handlePersonaClick(personaName)}
                  >
                    {personaName}
                  </Link>
                </ListItem>
              ))}
            </List>
            <Text mt="10">Team</Text>
            <Divider mb="3" />
            <Tag>Rohan Sandeep</Tag> <Tag>John Peter</Tag>
            <Text mt="10">Linked Objects</Text>
            <List>
              <ListItem>
                <Link color="facebook.400">Associate or add object</Link>
              </ListItem>
            </List>
            <Tag mr="1">Peter</Tag> <Tag>John</Tag>
          </Box>
        )}

        <Box flex="5">
          <HStack border="0px" h="10" mb="4">
            {!isLeftOpen && (
              <Button
                onClick={toggleLeftBox}
                aria-label="Close left box"
                size="lg"
                variant="ghost"
                position="relative"
              >
                <CiSquareChevRight size={31} />
              </Button>
            )}
            <Spacer />
            <ButtonGroup variant="outline" size="sm" isAttached>
              <Button
                colorScheme={view === "draftjs" ? "blue" : "gray"}
                onClick={() => switchView("draftjs")}
              >
                Document
              </Button>
              <Button
                colorScheme={view === "grid" ? "blue" : "gray"}
                onClick={() => switchView("grid")}
              >
                Grid View
              </Button>
            </ButtonGroup>
            <Spacer />
            {!isRightOpen && (
              <Button
                onClick={toggleRightBox}
                aria-label="Close right box"
                size="lg"
                variant="ghost"
                position="relative"
              >
                <CiSquareChevLeft size={31} />
              </Button>
            )}
          </HStack>

          <HStack>
          {view === 'draftjs' ? (
  <Salte editorState={editorState} setEditorState={setEditorState} />
) : (
  renderGridView(selectedPersona)
)}

          </HStack>
        </Box>

        {isRightOpen && (
          <Box
            flex="3"
            pt="10"
            border="1px"
            borderColor="gray.50"
            textAlign={"right"}
          >
            <HStack>
              <Box></Box>
              <Spacer />
              <IconButton
                icon={<CloseIcon />}
                onClick={toggleRightBox}
                aria-label="Close right box"
                size="sm"
                position="absolute"
                right="5"
                top="5"
              />
            </HStack>
            <Text mt="20">Prompts for you!</Text>
            <Divider mb="3" />
            <List>
              <ListItem>
                <Link color="facebook.400">here is an item you can add</Link>
              </ListItem>
            </List>
            <Text mt="10">Collection</Text>
            <Divider mb="3" />
            <Tag>Peter</Tag> <Tag>John</Tag>
            <Text mt="10">Collection</Text>
            <List>
              <ListItem>
                <Link color="facebook.400">Associate or Add an Epic</Link>
              </ListItem>
            </List>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default ObjectExample;
