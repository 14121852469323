import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  Container,
  Input,
  Textarea,
  Text
} from "@chakra-ui/react";

const InsertObjectsForm = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [values, setValues] = useState(['']);
  const [response, setResponse] = useState(null);

  const handleValueChange = (index, newValue) => {
    const newValues = values.slice();
    newValues[index] = newValue;
    setValues(newValues);
  };

  const handleAddValue = () => {
    setValues([...values, '']);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    
    try {
      
        const response = await fetch('https://www.tryatakian.com/api/insertObjects', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ title, description, values })
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        setResponse(data);
    } catch (error) {
        console.error('Error:', error.message);
        console.error('Error details:', error);
        setResponse({ error: error.message });
    }
};


  return (
    <ChakraProvider>
      <Container>
        <Box as="form" onSubmit={handleSubmit}>
          <Text mb="8px">Title:</Text>
          <Input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            mb="8px"
          />
          <Text mb="8px">Description:</Text>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            mb="8px"
          />
          <Text mb="8px">Values:</Text>
          {values.map((value, index) => (
            <Box key={index} mb="8px">
              <Input
                type="text"
                value={value}
                onChange={(e) => handleValueChange(index, e.target.value)}
                required
              />
            </Box>
          ))}
          <Button type="button" onClick={handleAddValue} mb="8px">
            Add Value
          </Button>
          <Button type="submit">Submit</Button>
        </Box>
        {response && (
          <Box mt="16px">
            <Text as="h3">Response</Text>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </Box>
        )}
      </Container>
    </ChakraProvider>
  );
};

export default InsertObjectsForm;
