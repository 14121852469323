import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const initialData = [
  { id: 1, name: 'A', x: 100, y: 100 },
  { id: 2, name: 'B', x: 200, y: 200 },
];

const SimpleDragDrop = () => {
  const svgRef = useRef(null);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = 500;
    const height = 500;

    const simulation = d3.forceSimulation(data)
      .force("charge", d3.forceManyBody().strength(5))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide().radius(20))
      .on("tick", ticked);

    const node = svg.selectAll("circle")
      .data(data, d => d.id)
      .join("circle")
      .attr("r", 20)
      .attr("fill", "blue")
      .call(
        d3.drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    function ticked() {
      node.attr("cx", d => d.x)
          .attr("cy", d => d.y);
    }

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

  }, [data]);

  return (
    <svg ref={svgRef} width="500" height="500"></svg>
  );
};

export default SimpleDragDrop;
