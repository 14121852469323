import Authenticate_app from "../common/authenticate";
import React, { useState } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Tooltip,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Select 
} from "@chakra-ui/react";
import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
  GrFacebook,
  GrInstagram,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

const campaignData = [
  {
    date: "14 May",
    facebook: true,
    instagram: false,
    campaign: "Campaign against Facebook",
  },
  {
    date: "15 May",
    facebook: false,
    instagram: true,
    campaign: "Campaign against Instagram",
  },
  { date: "16 May", facebook: true, instagram: false, campaign: "" },
  {
    date: "17 May",
    facebook: true,
    instagram: false,
    campaign: "Another Facebook Campaign",
  },
  { date: "18 May", facebook: false, instagram: false, campaign: "" },
  { date: "19 May", facebook: false, instagram: false, campaign: "" },
  {
    date: "20 May",
    facebook: false,
    instagram: true,
    campaign: "Another Instagram Campaign",
  },
  { date: "21 May", facebook: false, instagram: false, campaign: "" },
  {
    date: "22 May",
    facebook: true,
    instagram: false,
    campaign: "Yet Another Facebook Campaign",
  },
  {
    date: "23 May",
    facebook: false,
    instagram: true,
    campaign: "Yet Another Instagram Campaign",
  },
  { date: "24 May", facebook: true, instagram: false, campaign: "" },
  {
    date: "25 May",
    facebook: true,
    instagram: false,
    campaign: "Facebook Ad Campaign",
  },
  {
    date: "26 May",
    facebook: false,
    instagram: true,
    campaign: "Instagram Ad Campaign",
  },
  { date: "27 May", facebook: false, instagram: false, campaign: "" },
  { date: "28 May", facebook: false, instagram: false, campaign: "" },
  {
    date: "29 May",
    facebook: true,
    instagram: false,
    campaign: "Special Facebook Campaign",
  },
  { date: "30 May", facebook: false, instagram: false, campaign: "" },
  {
    date: "31 May",
    facebook: false,
    instagram: true,
    campaign: "Special Instagram Campaign",
  },
  { date: "1 June", facebook: false, instagram: false, campaign: "" },
  {
    date: "2 June",
    facebook: true,
    instagram: false,
    campaign: "Important Facebook Campaign",
  },
  {
    date: "3 June",
    facebook: false,
    instagram: true,
    campaign: "Important Instagram Campaign",
  },
  { date: "4 June", facebook: false, instagram: false, campaign: "" },
  { date: "5 June", facebook: false, instagram: false, campaign: "" },
  {
    date: "6 June",
    facebook: true,
    instagram: false,
    campaign: "Crucial Facebook Campaign",
  },
  {
    date: "7 June",
    facebook: false,
    instagram: true,
    campaign: "Crucial Instagram Campaign",
  },
  { date: "8 June", facebook: false, instagram: false, campaign: "" },
  { date: "9 June", facebook: false, instagram: false, campaign: "" },
  {
    date: "10 June",
    facebook: true,
    instagram: false,
    campaign: "Urgent Facebook Campaign",
  },
  { date: "11 June", facebook: false, instagram: false, campaign: "" },
  {
    date: "12 June",
    facebook: false,
    instagram: true,
    campaign: "Urgent Instagram Campaign",
  },
  { date: "13 June", facebook: false, instagram: false, campaign: "" },
  {
    date: "14 June",
    facebook: true,
    instagram: false,
    campaign: "Final Facebook Campaign",
  },
  {
    date: "15 June",
    facebook: false,
    instagram: true,
    campaign: "Final Instagram Campaign",
  },
];

const Layout = () => {
  const [isLeftColumnOpen, setIsLeftColumnOpen] = useState(true);
  const { colorMode, toggleColorMode } = useColorMode();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const handleCampaignClick = (campaign) => {
    if (selectedCampaign !== campaign) {
      setSelectedCampaign(campaign);
    }
  };
  
  const handleCloseDrawer = () => {
    setSelectedCampaign(null);
  };

  const toggleLeftColumn = () => {
    setIsLeftColumnOpen(!isLeftColumnOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <Flex height="120vh" border="0px">
      <Box
        flex={isLeftColumnOpen ? 1 : "0 0 10"}
        transition="flex 0.3s"
        backgroundColor={colorMode === "light" ? "white" : "gray.800"}
        p={6}
        border="0px"
        display={{ base: "none", md: "block" }}
      >
        <HStack>
          <Avatar size="sm" name="Ryan Florence" src="/Birdeyebird.png" />

          <Text
            fontWeight={"light"}
            fontSize={"21"}
            color="gray.500"
            display={isLeftColumnOpen ? 1 : "none"}
          >
            {" "}
            Travis Bond Dental Care{" "}
          </Text>
        </HStack>
        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <HStack mt="5" textAlign={"center"}>
          <Avatar
            size="sm"
            name="Ryan Florence"
            src="https://bit.ly/dan-abramov"
          />
          <Text
            fontSize={"21"}
            fontWeight={"light"}
            display={isLeftColumnOpen ? 1 : "none"}
            color="gray.500"
            as="Button"
            textAlign={"left"}
          >
            {" "}
            Jeanne T. Farris, Front Office Assistant{" "}
          </Text>{" "}
        </HStack>

        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <Box
          mt="5"
          //   backgroundColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
          height="100vh"
          p={0}
        >
          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "blue.100" : "blue.900"}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrHomeRounded} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Dashboard</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrCalendar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Appointments</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrVolume} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Campaigns</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={FiMessageSquare} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Inbox</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiDollarSign} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Payments</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={GrShareOption} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Refferals</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Reviews</Text>
          </HStack>

          <Divider
            mt="4"
            w="100%"
            bg={colorMode === "light" ? "white" : "blue.900"}
          />

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="6"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiSettings} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Settings</Text>
          </HStack>
        </Box>
      </Box>
      <Box
        flex={5}
        backgroundColor={colorMode === "light" ? "gray.100" : "gray.700"}
        p={4}
      >
        <Flex justifyContent="flex-end">
          <Button
            variant="outline"
            leftIcon={<ChevronLeftIcon />}
            onClick={toggleLeftColumn}
            mr={4}
          >
            Toggle Left Column
          </Button>
          <IconButton
            aria-label="Toggle Color Mode"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
          />
        </Flex>

        <>
          <SimpleGrid columns="3" mt="19">
            <Box>
              <Text
                fontWeight={"light"}
                fontSize={"21"}
                color={colorMode === "light" ? "gray.700" : "gray.200"}
              >
                Dashboard
              </Text>
            </Box>
            <Spacer />
            <ButtonGroup variant="outline">
              <Button variant="link">Modify Dashboard</Button>
              <Spacer />

              <Button>Filter</Button>
              <Button>Custom</Button>
            </ButtonGroup>
          </SimpleGrid>

          <SimpleGrid columns="2" spacing="4" p="0" display={"block"} mt="2">
            <Box>
              <Text
                fontWeight={"bold"}
                fontSize={"17"}
                color={colorMode === "light" ? "gray.700" : "gray.200"}
              >
                Social Media
              </Text>

              <Text
                fontWeight={"regular"}
                fontSize={"15"}
                color={colorMode === "light" ? "gray.500" : "gray.200"}
              >
                explain what this is{" "}
              </Text>
            </Box>
          </SimpleGrid>

          <SimpleGrid
            bg={colorMode === "light" ? "gray.200" : "gray.900"}
            border="1px"
            borderColor={colorMode === "light" ? "gray.100" : "gray.600"}
            p="6"
            mt="4"
            borderRadius="sm"
            shadow="sm"
            columns={{ base: 7, md: 14, sm: 1 }}
            fontSize={"12"}
            color="gray.600"
            spacing="2"
            lineHeight={"4"}
          >
            <Box
              textAlign={"center"}
              display={{ base: "none", md: "block", lg: "block" }}
            >
              MON
            </Box>

            <Box
              textAlign={"center"}
              display={{ base: "none", md: "block", lg: "block" }}
            >
              TUE
            </Box>

            <Box
              textAlign={"center"}
              display={{ base: "none", md: "block", lg: "block" }}
            >
              WED
            </Box>

            <Box
              display={{ base: "none", md: "block", lg: "block" }}
              textAlign={"center"}
            >
              THU
            </Box>

            <Box
              display={{ base: "none", md: "block", lg: "block" }}
              textAlign={"center"}
            >
              FRI
            </Box>

            <Box
              display={{ base: "none", md: "block", lg: "block" }}
              textAlign={"center"}
            >
              SAT
            </Box>

            <Box
              display={{ base: "none", md: "block", lg: "block" }}
              textAlign={"center"}
            >
              SUN
            </Box>

            <Box textAlign={"center"} display={{ base: "none", md: "block" }}>
              MON
            </Box>

            <Box display={{ base: "none", md: "block" }} textAlign={"center"}>
              TUE
            </Box>

            <Box display={{ base: "none", md: "block" }} textAlign={"center"}>
              WED
            </Box>

            <Box display={{ base: "none", md: "block" }} textAlign={"center"}>
              THU
            </Box>

            <Box display={{ base: "none", md: "block" }} textAlign={"center"}>
              FRI
            </Box>

            <Box display={{ base: "none", md: "block" }} textAlign={"center"}>
              SAT
            </Box>

            <Box display={{ base: "none", md: "block" }} textAlign={"center"}>
              SUN
            </Box>

            {campaignData.map((item) => (
              <Box
                key={item.date}
                display={{ base: "block", md: "block" }}
                textAlign="center"
                border="1px"
                borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
                p="2"
                h="20"
                fontSize="15"
                alignItems="center"
                justifyContent="center"
                position="relative"
                mb={4}
                color="gray.600"
                borderRadius={"md"}
                bg={colorMode === "light" ? "whiteAlpha.700" : "blackAlpha.800"}
                onClick={() => handleCampaignClick(item)}
                cursor="pointer"
              >
                {item.date}
                {item.facebook && (
                  <Popover>
                    <PopoverTrigger>
                      <Box
                        as={GrFacebook}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverBody>{item.campaign}</PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
                {item.instagram && (
                  <Popover>
                    <PopoverTrigger>
                      <Box
                        as={GrInstagram}
                        mt="2"
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverBody>{item.campaign}</PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              </Box>
            ))}
            <Drawer
              isOpen={!!selectedCampaign}
              onClose={handleCloseDrawer}
              placement="right"
              size="lg"
            >
              <DrawerOverlay>
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Campaign Details</DrawerHeader>
                  <DrawerBody>

                    {selectedCampaign && (
                      <Box>
                        <Box>Date: {selectedCampaign.date}</Box>
                        {selectedCampaign.facebook && (
                          <Box>
                            Facebook Campaign: {selectedCampaign.campaign}
                          </Box>
                        )}
                        {selectedCampaign.instagram && (
                          <Box>
                            Instagram Campaign: {selectedCampaign.campaign}
                          </Box>
                        )}
                      </Box>
                    )}
                    <TwoColumnLayout />

                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </SimpleGrid>
        </>
      </Box>
    </Flex>
  );
};

const ChartToggle = ({ onToggle }) => {
  return (
    <Button onClick={onToggle} mb={4}>
      Toggle Chart
    </Button>
  );
};

const BubbleChartToggle = () => {
  const { colorMode } = useColorMode();
  const [chartType, setChartType] = useState("bar");

  const data = [
    { company: "Company A", rating: 4.5, reviews: 50, color: "blue" },
    { company: "Company B", rating: 3.8, reviews: 30 },
    { company: "Company C", rating: 4.2, reviews: 40 },
    { company: "Company D", rating: 3.9, reviews: 35 },
    { company: "Company E", rating: 4.7, reviews: 70 },
    { company: "Company F", rating: 3.5, reviews: 25 },
    { company: "Company G", rating: 4.1, reviews: 45 },
    { company: "Company H", rating: 4.3, reviews: 55 },
    { company: "Company I", rating: 4.6, reviews: 60 },
  ];

  const handleToggle = () => {
    setChartType(chartType === "bar" ? "bubble" : "bar");
  };

  return (
    <Box>
      <ChartToggle onToggle={handleToggle} />
      {chartType === "bar" ? (
        <VictoryChart
          domainPadding={20}
          width={1050}
          height={250}
          style={{
            parent: {
              color: colorMode === "light" ? "black" : "white", // Adjust font color for axis labels
            },
            axis: {
              stroke: colorMode === "light" ? "black" : "white", // Adjust axis strokes
            },
          }}
        >
          <VictoryAxis
            tickValues={data.map((item) => item.company)}
            style={{
              grid: {
                stroke:
                  colorMode === "light"
                    ? "rgba(0, 0, 0, 0.1)"
                    : "rgba(255, 255, 255, 0.1)", // Adjust grid line color
                strokeWidth: 1, // Adjust grid line width
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[1, 2, 3, 4, 5]}
            style={{
              grid: {
                stroke:
                  colorMode === "light"
                    ? "rgba(0, 0, 0, 0.1)"
                    : "rgba(255, 255, 255, 0.1)", // Adjust grid line color
                strokeWidth: 1, // Adjust grid line width
              },
            }}
          />
          <VictoryBar
            theme={VictoryTheme.material}
            animate={{
              duration: 2000,
              onLoad: { duration: 200 },
            }}
            data={data}
            x="company"
            y="rating"
            style={{
              data: {
                fill: ({ datum }) =>
                  datum.color ||
                  (colorMode === "light"
                    ? "rgba(54, 162, 235, 0.6)"
                    : "rgba(54, 162, 235, 1)"),
                stroke:
                  colorMode === "light"
                    ? "rgba(54, 162, 235, 1)"
                    : "rgba(54, 162, 235, 0.6)",
                strokeWidth: 1,
              },
            }}
            labels={({ datum }) =>
              `${datum.rating} stars\n(${datum.reviews} reviews)`
            }
            labelComponent={<VictoryTooltip />}
          />
        </VictoryChart>
      ) : (
        <VictoryChart
          domainPadding={20}
          width={1050}
          height={250}
          style={{
            parent: {
              color: colorMode === "light" ? "black" : "white", // Adjust font color for axis labels
            },
            axis: {
              stroke: colorMode === "light" ? "black" : "white", // Adjust axis strokes
            },
          }}
        >
          <VictoryAxis
            tickValues={data.map((item) => item.company)}
            style={{
              grid: {
                stroke:
                  colorMode === "light"
                    ? "rgba(0, 0, 0, 0.1)"
                    : "rgba(255, 255, 255, 0.1)", // Adjust grid line color
                strokeWidth: 1, // Adjust grid line width
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickValues={[1, 2, 3, 4, 5]}
            style={{
              grid: {
                stroke:
                  colorMode === "light"
                    ? "rgba(0, 0, 0, 0.1)"
                    : "rgba(255, 255, 255, 0.1)", // Adjust grid line color
                strokeWidth: 1, // Adjust grid line width
              },
            }}
          />
          <VictoryScatter
            theme={VictoryTheme.material}
            animate={{
              duration: 2000,
              onLoad: { duration: 1000 },
            }}
            data={data}
            x="company"
            y="rating"
            size={({ datum }) => datum.reviews / 5} // Adjust the divisor to control bubble size
            style={{
              data: {
                fill: ({ index }) => {
                  const colors = [
                    "red",
                    "green",
                    "blue",
                    "orange",
                    "purple",
                    "cyan",
                    "magenta",
                    "yellow",
                    "brown",
                  ];
                  return colors[index % colors.length];
                },
                stroke:
                  colorMode === "light"
                    ? "rgba(54, 162, 235, 1)"
                    : "rgba(54, 162, 235, 0.6)",
                strokeWidth: 1,
              },
            }}
            labels={({ datum }) =>
              `${datum.rating} stars\n(${datum.reviews} reviews)`
            }
            labelComponent={<VictoryTooltip />}
          />
          <VictoryPortal>
            <VictoryLabel
              text="Reviews"
              x={50}
              y={20}
              textAnchor="middle"
              style={{ fill: "gray", fontSize: 12 }}
            />
          </VictoryPortal>
          <VictoryPortal>
            <VictoryLabel
              text="Rating"
              x={950}
              y={230}
              textAnchor="middle"
              style={{ fill: "gray", fontSize: 12 }}
            />
          </VictoryPortal>
        </VictoryChart>
      )}
    </Box>
  );
};


const TwoColumnLayout = () => {
 


  return (
   

<Flex columns={"2"}  spacing="2" mt="2" border="0px">

<Box border="0px" flex="2">
  here the select 
</Box>
<Box flex="3">
  Here the selection   
</Box>

  </Flex>

  );
};
export default Layout;
