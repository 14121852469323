import Authenticate_app from "../common/authenticate";
import { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Tooltip,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { FaHeart, FaStar, FaSmile, FaSun } from "react-icons/fa";
import { GrFingerPrint, GrGift } from "react-icons/gr";
import { BiVideo, BiSmile, BiSearch, BiMessage } from "react-icons/bi";

import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import ReactSelect from "react-select";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

import { Global, css, keyframes } from "@emotion/react";

const headlines = {
  headlines: [
    "Visual Beauty Stories: How Media and Pictures Elevate the Post-Purchase Experience for Cosmetics Brands.",
    "Unveiling Beauty Beyond Words: The Impact of Media and Pictures on Cosmetics Brands' Post-Purchase Journey.",
    "From Screens to Senses: Exploring How Media and Pictures Deepen the Connection with Cosmetics Brands.",
    "Immersive Beauty Experiences: Why Media and Pictures are Vital in Enriching Cosmetics Brands' Post-Purchase Engagement.",
    "A Visual Feast: How Media and Pictures Transform the Post-Purchase Experience with Cosmetics Brands.",
    "Beyond the Product: How Media and Pictures Paint a Vivid Story in Cosmetics Brands' Post-Purchase Journey.",
    "Picture Perfect Beauty: Unpacking the Role of Media in Enhancing Cosmetics Brands' Post-Purchase Satisfaction.",
    "Capturing Beauty Moments: How Pictures Elevate the Post-Purchase Experience with Cosmetics Brands.",
    "Visual Delights: Why Media and Pictures are Essential Components in Cosmetics Brands' Post-Purchase Arsenal.",
    "Beauty in Focus: Exploring How Media and Pictures Enrich the Post-Purchase Journey for Cosmetics Brands.",
    "Creating Lasting Impressions: The Importance of Media and Pictures in Cosmetics Brands' Post-Purchase Experience.",
    "Engaging the Senses: How Media and Pictures Heighten the Pleasure of Post-Purchase Unboxing with Cosmetics Brands.",
    "Beauty Beyond Words: The Impact of Media and Pictures in Elevating Cosmetics Brands' Post-Purchase Experience.",
    "Immersive Beauty Experiences: Why Media and Pictures are Essential for Cosmetics Brands' Post-Purchase Delight.",
    "Picture Perfect Moments: Unveiling How Media Enhances the Post-Purchase Journey with Cosmetics Brands.",
    "Visual Voyages: How Pictures Enrich the Post-Purchase Adventure with Cosmetics Brands.",
    "Crafting Beauty Narratives: The Power of Media and Pictures in Cosmetics Brands' Post-Purchase Engagement.",
    "Elevating Beauty Appreciation: Why Media and Pictures Are Crucial in Cosmetics Brands' Post-Purchase Experience.",
    "Aesthetic Elevation: How Media and Pictures Enhance the Post-Purchase Joy of Cosmetics Brands.",
    "Unleashing Beauty Brilliance: The Role of Media and Pictures in Transforming Cosmetics Brands' Post-Purchase Interaction.",
  ],
};

const icons = [
  {
    component: GrFingerPrint,
    label: "Product Verfication",
    size: "20px",
    delay: 0,
    bgColor: "yellow.500",
    iconColor: "white",
  },
  {
    component: FiStar,
    label: "Reviews and Ratings",
    size: "60px",
    delay: 0.1,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSmile,
    label: "Refferals",
    size: "150px",
    delay: 0.3,
    bgColor: "yellow.400",
    iconColor: "white",
  },
  {
    component: BiVideo,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSearch,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
];

const drop = keyframes`
  0% { top: -50%; }
  100% { top: 110%; }
`;

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #171717;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

  .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 75%,
      #ffffff 100%
    );
    animation: ${drop} 7s 0s infinite cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  .line:nth-of-type(1) {
    margin-left: -25%;
  }

  .line:nth-of-type(1)::after {
    animation-delay: 2s;
  }

  .line:nth-of-type(3) {
    margin-left: 25%;
  }

  .line:nth-of-type(3)::after {
    animation-delay: 2.5s;
  }
`;

// Function to generate random size and margins
const getRandomSizeAndMargin = () => {
  const size = Math.floor(Math.random() * 50) + 50; // Random size between 50px and 100px
  const margin = Math.floor(Math.random() * 20) + 10; // Random margin between 10px and 30px
  return { size, margin };
};

const AnimatedBox = motion(Box);
const MotionBox = motion(Box);

const Media = () => {
  const [isOpen, setIsOpen] = useState({
    benefits: false,
    ingredients: false,
    usingProduct: false,
    standards: false,
    reviews: false,
    authenticate: false,
  });

  const openModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: true }));
  };

  const closeModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: false }));
  };

  const [selectedColor, setSelectedColor] = useState("white");

  const colors = ["yellow", "blue", "white", "red"];
  const displayColors = {
    yellow: "Yellow",
    blue: "Blue",
    white: "White",
    red: "Red",
  };

  const colorStyles = {
    yellow: {
      bg: "yellow.300",
      color: "black",
    },
    blue: {
      bg: "blue.300",
      color: "white",
    },
    white: {
      bg: "gray.100",
      color: "black",
      gray: "gray.100",
    },
    red: {
      bg: "red.300",
      color: "white",
    },
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Box position="relative" overflowY="auto" height="100vh">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <AnimatedBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          color="white"
          bg="#191D26"
          minH="100vh"
          overflowY="auto"
        >
          <Container textAlign={"center"} pt="10">
            <Center mb="10">
              <BiVideo size="51" mb="10" />
            </Center>
            <HeadlineDisplay mt="10" headlines={headlines.headlines} />
            <Text fontSize={"21"} mt="10">
              Media and pictures play a crucial role in enriching the
              post-purchase experience for cosmetics brands, elevating customer
              engagement and satisfaction. They visually narrate the brand
              story, evoke emotions, and create lasting impressions, fostering
              deeper connections and loyalty.
            </Text>

           
            <ButtonGroup spacing="4" mt="10">
              <Button variant="outline" color="white"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >
                Request Demo
              </Button>
              <Button variant="solid"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >Sign up for Trial</Button>
            </ButtonGroup>
          </Container>

          <Container></Container>

          <Container mt="50" color="gray.400" mb="40">
            All Rights Reserved 2024, Tangible Spin LLP. D-U-N-S 878296827
          </Container>
        </AnimatedBox>
      </Box>
    </>
  );
};

const HeadlineDisplay = ({ headlines }) => {
  const [randomHeadline, setRandomHeadline] = useState("");

  useEffect(() => {
    // Function to select a random headline
    const getRandomHeadline = () => {
      const randomIndex = Math.floor(Math.random() * headlines.length);
      return headlines[randomIndex];
    };

    // Set random headline on initial render
    setRandomHeadline(getRandomHeadline());
  }, [headlines]);

  return (
    <Heading w="100%" fontSize={"41"} color="yellow.300" fontWeight={"black"}>
      {randomHeadline}
    </Heading>
  );
};

export default Media;
