import React, { useState } from 'react';
import {
  ChakraProvider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  CheckboxGroup,
  Checkbox,
  VStack,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Input,
  FormControl,
  FormLabel, useColorMode,
  useDisclosure, HStack, Divider as Seperator, SimpleGrid,   Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react';
import { SiXaml } from 'react-icons/si';
import { ChevronDownIcon } from "@chakra-ui/icons";

const data = [
  {
    DocumentType: "SOP",
    SOPNo: "12345",
    SOPDate: "2024-05-28",
    PartyName: "Example Party",
    ShipmentAddress: "123 Example Street, City, Country",
    OrderRefNo: "OR123456",
    PODate: "2024-05-27",
    Delivery: "2024-06-05",
    Validity: "2024-06-30",
    VehicleNo: "AB123CD",
    ModeOfDispatch: "Air",
    Freight: "100.00",
    Payment: "Credit Card",
    Transporter: "XYZ Logistics",
    QuoteDate: "2024-05-26",
    PaymentTerms: "Net 30",
    ImageDescription: "Product Image",
    Image: "http://example.com/image.jpg",
    Remarks: "Handle with care"
  },
  {
    DocumentType: "SOP",
    SOPNo: "12346",
    SOPDate: "2024-05-29",
    PartyName: "Another Party",
    ShipmentAddress: "456 Another Street, City, Country",
    OrderRefNo: "OR123457",
    PODate: "2024-05-28",
    Delivery: "2024-06-06",
    Validity: "2024-07-01",
    VehicleNo: "EF456GH",
    ModeOfDispatch: "Sea",
    Freight: "150.00",
    Payment: "Bank Transfer",
    Transporter: "ABC Transport",
    QuoteDate: "2024-05-27",
    PaymentTerms: "Net 45",
    ImageDescription: "Product Image 2",
    Image: "http://example.com/image2.jpg",
    Remarks: "Fragile"
  }
];

const allColumns = [
  "DocumentType", "SOPNo", "SOPDate", "PartyName", "ShipmentAddress",
  "OrderRefNo", "PODate", "Delivery", "Validity", "VehicleNo",
  "ModeOfDispatch", "Freight", "Payment", "Transporter", "QuoteDate",
  "PaymentTerms", "ImageDescription", "Image", "Remarks"
];

const defaultColumns = ["DocumentType", "SOPNo", "PartyName", "Delivery"];

const SalesOrder = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [orders, setOrders] = useState(data);
  const [visibleColumns, setVisibleColumns] = useState(defaultColumns);
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const [currentOrder, setCurrentOrder] = useState(null);
  const [newOrder, setNewOrder] = useState({});

  const handleColumnChange = (e) => {
    const { value, checked } = e.target;
    setVisibleColumns((prev) =>
      checked ? [...prev, value] : prev.filter((col) => col !== value)
    );
  };

  const handleEditClick = (order) => {
    setCurrentOrder(order);
    onEditOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentOrder({ ...currentOrder, [name]: value });
  };

  const handleSave = () => {
    setOrders(orders.map(order => (order.SOPNo === currentOrder.SOPNo ? currentOrder : order)));
    onEditClose();
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewOrder({ ...newOrder, [name]: value });
  };

  const handleAddNewOrder = () => {
    setOrders([...orders, newOrder]);
    setNewOrder({});
    onAddClose();
  };

  return (
    <ChakraProvider>
      <Box p={4}>
      
        <SimpleGrid columns={3} spacing={4}>

          <Box></Box>
   
          <Box></Box>
          <HStack mb={4} textAlign={"right"} w="100%">

          <Menu w="100%">
      <MenuButton as={Button} w="100%" rightIcon={<ChevronDownIcon />}>
        Select Columns
      </MenuButton>
      <MenuList maxHeight="200px" overflowY="auto">
        <VStack align="start">
          <CheckboxGroup colorScheme="teal" defaultValue={defaultColumns}>
            {allColumns.map((col) => (
              <MenuItem key={col} closeOnSelect={false}>
                <Checkbox
                  value={col}
                  isChecked={visibleColumns.includes(col)}
                  onChange={handleColumnChange}
                >
                  {col}
                </Checkbox>
              </MenuItem>
            ))}
          </CheckboxGroup>
        </VStack>
      </MenuList>
    </Menu>


          <Button w="100%"
                          color={colorMode === "light" ? "gray.700" : "gray.200"}

          variant="outline" onClick={onAddOpen}> New Sales Order</Button>
        </HStack>
        </SimpleGrid>
        
        <Table  >
          <Thead>
            <Tr>
              {visibleColumns.map((col) => (
                <Th key={col}>{col}</Th>
              ))}
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orders.map((order, index) => (
              <Tr key={index}>
                {visibleColumns.map((col) => (
                  <Td key={col}>{order[col]}</Td>
                ))}
                <Td>
                  <Button size="sm" onClick={() => handleEditClick(order)}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Drawer isOpen={isEditOpen} placement="right" size="xl" onClose={onEditClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit Order</DrawerHeader>
              <Seperator />
            <DrawerBody>
              <VStack spacing={2} align="start">
                {allColumns.map((col) => (
                  <FormControl key={col}>
                    <FormLabel>{col}</FormLabel>
                    <Input
                      name={col}
                      value={currentOrder ? currentOrder[col] : ''}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                ))}
              </VStack>
            </DrawerBody>

            <DrawerFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSave}>
                Save
              </Button>
              <Button variant="ghost" onClick={onEditClose}>Cancel</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>

        <Drawer isOpen={isAddOpen} placement="right" size="xl" onClose={onAddClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create New Sales Order</DrawerHeader>
            <Seperator />

            <DrawerBody>
              <VStack spacing={2} align="start">
                {allColumns.map((col) => (
                  <FormControl key={col}>
                    <FormLabel>{col}</FormLabel>
                    <Input
                      name={col}
                      value={newOrder[col] || ''}
                      onChange={handleNewInputChange}
                    />
                  </FormControl>
                ))}
              </VStack>
            </DrawerBody>

            <DrawerFooter>
              <Button colorScheme="blue" mr={3} onClick={handleAddNewOrder}>
                Add
              </Button>
              <Button variant="ghost" onClick={onAddClose}>Cancel</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    </ChakraProvider>
  );
};

export default SalesOrder;
