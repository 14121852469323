import React, { useState, useEffect } from 'react';
import {
  Box,
  ChakraProvider,
  Select,
  extendTheme,
} from '@chakra-ui/react';
// import ReactSelect from 'react-select';
import * as d3 from 'd3';

const customTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});

// Initial data and other variables omitted for brevity...
const initialData = [
    {
        "id": 1,
        "name": "Task 1",
        "priority": "High",
        "description": "Complete the initial project setup.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-06-15",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "4 hours",
        "tags": ["setup", "project"],
        "color": 'red',
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 2,
        "name": "Task 2",
        "priority": "Medium",
        "description": "Design the homepage layout.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-06-20",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "To Do",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "8 hours",
        "tags": ["design", "homepage"],
        "dependencies": ["Task 1"],
        "color": 'red',
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 3,
        "name": "Task 3",
        "priority": "Low",
        "description": "Develop the user authentication module.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-07-01",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "16 hours",
        "tags": ["development", "authentication"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 4,
        "name": "Task 4",
        "priority": "High",
        "description": "Set up continuous integration.",
        "assignees": ["Brown", "John"],
        "dueDate": "2024-06-25",
        "progress": "Completed",
        "status": "Done",
        "workflow": "Done",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "12 hours",
        "tags": ["CI", "setup"],
        "dependencies": ["Task 1"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 5,
        "name": "Task 5",
        "priority": "Medium",
        "description": "Write unit tests for the API.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-07-05",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Code Review",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "10 hours",
        "tags": ["testing", "API"],
        "dependencies": ["Task 3"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 6,
        "name": "Task 6",
        "priority": "Low",
        "description": "Create database schemas.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-07-10",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "Backlog",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "6 hours",
        "tags": ["database", "design"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 7,
        "name": "Task 7",
        "priority": "High",
        "description": "Implement caching for the API.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-06-30",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Testing",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "14 hours",
        "tags": ["development", "API"],
        "dependencies": ["Task 3"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 8,
        "name": "Task 8",
        "priority": "Medium",
        "description": "Optimize front-end performance.",
        "assignees": ["Brown", "John"],
        "dueDate": "2024-07-15",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "20 hours",
        "tags": ["performance", "frontend"],
        "dependencies": ["Task 2"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 9,
        "name": "Task 9",
        "priority": "Low",
        "description": "Conduct user testing for the new feature.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-07-20",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "To Do",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "12 hours",
        "tags": ["testing", "user"],
        "dependencies": ["Task 5"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 10,
        "name": "Task 10",
        "priority": "High",
        "description": "Prepare project documentation.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-06-18",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "To Do",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "10 hours",
        "tags": ["documentation", "project"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 11,
        "name": "Task 11",
        "priority": "Medium",
        "description": "Refactor existing codebase.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-07-22",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Code Review",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "15 hours",
        "tags": ["refactoring", "code"],
        "dependencies": ["Task 7"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 12,
        "name": "Task 12",
        "priority": "Low",
        "description": "Design email templates for marketing.",
        "assignees": ["Brown", "John"],
        "dueDate": "2024-07-25",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "Backlog",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "8 hours",
        "tags": ["design", "email"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 13,
        "name": "Task 13",
        "priority": "High",
        "description": "Set up monitoring for the server.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-07-28",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "12 hours",
        "tags": ["monitoring", "server"],
        "value": 25,
        "percentage": 70,
    },
    {
        "id": 14,
        "name": "Task 14",
        "priority": "Medium",
        "description": "Implement security best practices.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-07-30",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "Backlog",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "18 hours",
        "tags": ["security", "best practices"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 15,
        "name": "Task 15",
        "priority": "Low",
        "description": "Create a backup strategy.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-07-25",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Testing",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "5 hours",
        "tags": ["backup", "strategy"],
        "value": 20,
        "percentage": 70,
    }
];


const ClusteredView = () => {
  const [horizontal, setHorizontal] = useState('');
  const [vertical, setVertical] = useState('');
  // const [selectedTasks, setSelectedTasks] = useState([]);
  const [data, setData] = useState(initialData);

  const columns = ["priority", "dueDate", "progress", "status", "workflow"];

  const handleHorizontalChange = (e) => {
    setHorizontal(e.target.value);
  };

  const handleVerticalChange = (e) => {
    setVertical(e.target.value);
  };

  // const handleTaskSelectChange = (selectedOptions) => {
  //   setSelectedTasks(selectedOptions ? selectedOptions.map(option => option.value) : []);
  // };

  const getClusters = (data, horizontal, vertical) => {
    const clusters = {};

    data.forEach(item => {
      const horValue = item[horizontal];
      const verValue = vertical ? item[vertical] : null;

      if (!clusters[horValue]) {
        clusters[horValue] = vertical ? {} : [];
      }

      if (verValue) {
        if (!clusters[horValue][verValue]) {
          clusters[horValue][verValue] = [];
        }
        clusters[horValue][verValue].push(item);
      } else {
        clusters[horValue].push(item);
      }
    });

    return clusters;
  };

  const getUniqueValues = (data, column) => {
    return [...new Set(data.map(item => item[column]))];
  };

  useEffect(() => {
    const svg = d3.select("#cluster-svg");
    svg.selectAll("*").remove();

    const containerWidth = svg.node().parentNode.clientWidth;
    const containerHeight = svg.node().parentNode.clientHeight;
    const width = containerWidth;
    const height = containerHeight;

    const clusters = getClusters(data, horizontal, vertical);

    const horGroups = getUniqueValues(data, horizontal);
    const horGroupWidth = width / horGroups.length;

    if (vertical) {
      const verGroups = getUniqueValues(data, vertical);
      const verGroupHeight = height / verGroups.length;

      verGroups.forEach((verKey, j) => {
        svg.append("text")
          .attr("x", 10)
          .attr("y", j * verGroupHeight + verGroupHeight / 2)
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .attr("transform", `rotate(-90, 10, ${j * verGroupHeight + verGroupHeight / 2})`)
          .text(verKey);
      });
    }

    horGroups.forEach((horKey, i) => {
      const horGroup = svg.append("g")
        .attr("transform", `translate(${i * horGroupWidth}, 0)`);

      horGroup.append("rect")
        .attr("width", horGroupWidth)
        .attr("height", height)
        .attr("fill", "rgba(240, 240, 240, 0.5)")  // Subtle background color
        .attr("stroke", "gray");

      horGroup.append("text")
        .attr("x", horGroupWidth / 2)
        .attr("y", 20)
        .attr("text-anchor", "middle")
        .text(horKey);

      if (vertical) {
        const verGroups = getUniqueValues(data, vertical);
        const verGroupHeight = height / verGroups.length;

        verGroups.forEach((verKey, j) => {
          const verGroup = horGroup.append("g")
            .attr("transform", `translate(0, ${j * verGroupHeight})`);

          verGroup.append("rect")
            .attr("width", horGroupWidth)
            .attr("height", verGroupHeight)
            .attr("fill", "rgba(220, 220, 220, 0.5)")  // Subtle background color
            .attr("stroke", "blue")
            .attr("class", "drop-target");

          const clusterData = clusters[horKey][verKey] || [];

          const drag = d3.drag()
            .on("start", (event, d) => {
              d3.select(event.sourceEvent.target).raise().attr("stroke", "black");
              d.fx = null;
              d.fy = null;
            })
            .on("drag", (event, d) => {
              d.x = event.x;
              d.y = event.y;
              d3.select(event.sourceEvent.target)
                .attr("cx", d.x)
                .attr("cy", d.y);
            })
            .on("end", (event, d) => {
              d3.select(event.sourceEvent.target).attr("stroke", null);
              let dropped = false;
              svg.selectAll(".drop-target")
                .attr("fill", (targetData, index, nodes) => {
                  const target = nodes[index];
                  const rect = target.getBoundingClientRect();
                  const mouseX = event.sourceEvent.clientX;
                  const mouseY = event.sourceEvent.clientY;

                  if (mouseX > rect.left && mouseX < rect.right && mouseY > rect.top && mouseY < rect.bottom) {
                    dropped = true;
                    // Update the data to reflect the move
                    const newData = data.map(item => {
                      if (item.id === d.id) {
                        item[horizontal] = horKey;
                        if (vertical) {
                          item[vertical] = verKey;
                        }
                      }
                      return item;
                    });
                    setData(newData);

                    // Animate the background of the drop target
                    d3.select(target)
                      .transition()
                      .duration(500)
                      .attr("fill", "rgba(0, 255, 0, 0.3)")
                      .transition()
                      .duration(500)
                      .attr("fill", "rgba(220, 220, 220, 0.5)");
                  }

                  return dropped ? "rgba(0, 255, 0, 0.3)" : "rgba(220, 220, 220, 0.5)";
                });

              if (!dropped) {
                // If not dropped in a target, reset the circle to its original position
                d3.select(event.sourceEvent.target)
                  .transition()
                  .duration(500)
                  .attr("cx", d.x0)
                  .attr("cy", d.y0);
              } else {
                d.x0 = d.x;
                d.y0 = d.y;
              }
            });

          const circles = verGroup.selectAll("circle")
            .data(clusterData)
            .enter()
            .append("circle")
            .attr("r", 10)
            .attr("fill", "grey")
            // .attr("stroke", d => selectedTasks.includes(d.name) ? "black" : "none")
            .attr("stroke-width", 2)
            .attr("cx", (d, idx) => 20 + idx * 20)
            .attr("cy", verGroupHeight / 2)
            .each(function (d) {
              d.x0 = d3.select(this).attr("cx");
              d.y0 = d3.select(this).attr("cy");
            })
            .call(drag);
        });
      } else {
        const clusterData = clusters[horKey] || [];

        const drag = d3.drag()
          .on("start", (event, d) => {
            d3.select(event.sourceEvent.target).raise().attr("stroke", "black");
            d.fx = null;
            d.fy = null;
          })
          .on("drag", (event, d) => {
            d.x = event.x;
            d.y = event.y;
            d3.select(event.sourceEvent.target)
              .attr("cx", d.x)
              .attr("cy", d.y);
          })
          .on("end", (event, d) => {
            d3.select(event.sourceEvent.target).attr("stroke", null);
            let dropped = false;
            svg.selectAll(".drop-target")
              .attr("fill", (targetData, index, nodes) => {
                const target = nodes[index];
                const rect = target.getBoundingClientRect();
                const mouseX = event.sourceEvent.clientX;
                const mouseY = event.sourceEvent.clientY;

                if (mouseX > rect.left && mouseX < rect.right && mouseY > rect.top && mouseY < rect.bottom) {
                  dropped = true;
                  // Update the data to reflect the move
                  const newData = data.map(item => {
                    if (item.id === d.id) {
                      item[horizontal] = horKey;
                    }
                    return item;
                  });
                  setData(newData);

                  // Animate the background of the drop target
                  d3.select(target)
                    .transition()
                    .duration(500)
                    .attr("fill", "rgba(0, 255, 0, 0.3)")
                    .transition()
                    .duration(500)
                    .attr("fill", "rgba(220, 220, 220, 0.5)");
                }

                return dropped ? "rgba(0, 255, 0, 0.3)" : "rgba(240, 240, 240, 0.5)";
              });

            if (!dropped) {
              // If not dropped in a target, reset the circle to its original position
              d3.select(event.sourceEvent.target)
                .transition()
                .duration(500)
                .attr("cx", d.x0)
                .attr("cy", d.y0);
            } else {
              d.x0 = d.x;
              d.y0 = d.y;
            }
          });

        const circles = horGroup.selectAll("circle")
          .data(clusterData)
          .enter()
          .append("circle")
          .attr("r", 10)
          .attr("fill", "grey")
          // .attr("stroke", d => selectedTasks.includes(d.name) ? "black" : "none")
          .attr("stroke-width", 2)
          .attr("cx", (d, idx) => 20 + idx * 20)
          .attr("cy", height / 2)
          .each(function (d) {
            d.x0 = d3.select(this).attr("cx");
            d.y0 = d3.select(this).attr("cy");
          })
          .call(drag);
      }
    });
  }, [horizontal, vertical, data]);

  // const taskOptions = initialData.map(task => ({
  //   value: task.name,
  //   label: task.name
  // }));

  return (
    <ChakraProvider theme={customTheme}>
      <Box p={5}>
        {/* <ReactSelect
          options={taskOptions}
          onChange={handleTaskSelectChange}
          placeholder="Search and select task"
          isClearable
          isMulti
          mb={4}
        /> */}
        <Select onChange={handleHorizontalChange} placeholder="Select Horizontal Column" mb={4}>
          {columns.map(column => (
            <option key={column} value={column}>{column}</option>
          ))}
        </Select>
        <Select onChange={handleVerticalChange} placeholder="Select Vertical Column" mb={4}>
          {columns.map(column => (
            <option key={column} value={column} disabled={column === horizontal}>
              {column}
            </option>
          ))}
        </Select>
        <Box width="100%" height="600px">
          <svg id="cluster-svg" width="100%" height="100%"></svg>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

const TasksNew = () => (
  <ChakraProvider>
    <ClusteredView />
  </ChakraProvider>
);

export default TasksNew;
