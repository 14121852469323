import React from 'react';
import { Box, Text, VStack, SimpleGrid } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useState } from 'react';
import CartIcon from './components/CartIcon';
// JSON data
const boxData = {
    boxes: {
      small: [
        { id: 1, name: 'Small Box 1', size: '6" x 6" x 6"', dimensions: { length: 6, width: 6, height: 6 } },
        { id: 2, name: 'Small Box 2', size: '8" x 8" x 8"', dimensions: { length: 8, width: 8, height: 8 } },
        { id: 3, name: 'Small Box 3', size: '10" x 6" x 4"', dimensions: { length: 10, width: 6, height: 4 } }
      ],
      medium: [
        { id: 4, name: 'Medium Box 1', size: '12" x 9" x 6"', dimensions: { length: 12, width: 9, height: 6 } },
        { id: 5, name: 'Medium Box 2', size: '14" x 10" x 4"', dimensions: { length: 14, width: 10, height: 4 } },
        { id: 6, name: 'Medium Box 3', size: '16" x 12" x 8"', dimensions: { length: 16, width: 12, height: 8 } }
      ],
      large: [
        { id: 7, name: 'Large Box 1', size: '18" x 18" x 16"', dimensions: { length: 18, width: 18, height: 16 } },
        { id: 8, name: 'Large Box 2', size: '20" x 20" x 20"', dimensions: { length: 20, width: 20, height: 20 } },
        { id: 9, name: 'Large Box 3', size: '24" x 18" x 18"', dimensions: { length: 24, width: 18, height: 18 } }
      ],
      extra_large: [
        { id: 10, name: 'Extra Large Box 1', size: '24" x 24" x 24"', dimensions: { length: 24, width: 24, height: 24 } },
        { id: 11, name: 'Extra Large Box 2', size: '30" x 30" x 30"', dimensions: { length: 30, width: 30, height: 30 } },
        { id: 12, name: 'Extra Large Box 3', size: '36" x 36" x 36"', dimensions: { length: 36, width: 36, height: 36 } }
      ]
    }
  };
const flattenBoxData = (data) => {
  const categories = Object.keys(data);
  return categories.reduce((acc, category) => {
    const boxes = data[category].map(box => ({ ...box, category }));
    return [...acc, ...boxes];
  }, []);
};

const allBoxes = flattenBoxData(boxData.boxes);

const PackageList = () => {
  const [filteredBoxes, setFilteredBoxes] = useState(allBoxes);

  const options = allBoxes.map(box => ({
    value: box.id,
    label: `${box.name} - ${box.size} - ${box.dimensions.length}x${box.dimensions.width}x${box.dimensions.height}`,
    box
  }));

  const handleSelectChange = (option) => {
    setFilteredBoxes(option ? [option.box] : allBoxes);
  };

  return (
    <Box p={5}>
            <Box>
        <CartIcon />
        </Box>
      <ReactSelect
        options={options}
        onChange={handleSelectChange}
        isClearable
        placeholder="Search by name, size, or dimensions..."
      />
      <SimpleGrid mt={5} spacing={4} columns={{ base: 1, md: 4, lg: 6}}>
        {filteredBoxes.map(box => (
          <Box key={box.id} p={5} borderRadius={"md"} borderWidth="1px">
            <Link to={`/package/${box.id}`}>
              <Text fontSize="xl">{box.name} 1</Text>
              <Text>Size: {box.size}</Text>
              <Text>Dimensions: {box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height}</Text>
            </Link>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default PackageList;
