import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ChakraProvider,
  Button,
  useColorMode,
  Switch,
  Flex,
  Text,
  extendTheme,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SimpleGrid,
  IconButton,
  ButtonGroup,
  useColorModeValue, Image, TableContainer, Stack, Avatar, Badge, Heading, Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  CheckboxGroup,
  VStack,
} from "@chakra-ui/react";
import * as d3 from "d3";
import { motion, AnimatePresence } from "framer-motion";
import { LuImport, LuList,  } from "react-icons/lu";
import { BiExport, BiImport } from "react-icons/bi";
import { LuCreditCard } from "react-icons/lu";
import Papa from 'papaparse';
import TaskManager from "./taskManager";
import ReactSelect from "react-select";
import { FiColumns, FiFilter, FiSearch, FiType, FiTable } from "react-icons/fi";
import { BsBoundingBoxCircles, BsFileBarGraph } from "react-icons/bs";
import { BiCircle } from "react-icons/bi";
import { FaFileCsv, FaMoon, FaSun } from "react-icons/fa6";
import { AiOutlineDotChart } from "react-icons/ai";
import Board from 'react-ui-kanban';
import { LuGroup } from "react-icons/lu";
import { FileText, List } from "react-feather";
import TextManager from "./textManager";

const customTheme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

const initialData = [
  {
    id: 1,
    name: "Apple Inc.",
    percentage: 70,
    importance: "High",
    value: 30,
    group: "A",
    color: "red",
    segment: "Retail",
    geography: "North America",
    size: "Large",
    techStack: "React",
    owner: "John",
  },
  {
    id: 2,
    name: "Goldman Sachs",
    percentage: 40,
    importance: "Medium",
    value: 10,
    group: "A",
    color: "green",
    segment: "Finance",
    geography: "Europe",
    size: "Medium",
    techStack: "Angular",
    owner: "Doe",
  },
  {
    id: 3,
    name: "Pfizer",
    percentage: 90,
    importance: "Low",
    value: 20,
    group: "B",
    color: "blue",
    segment: "Healthcare",
    geography: "Asia",
    size: "Small",
    techStack: "Vue",
    owner: "Smith",
  },
  {
    id: 4,
    name: "Google",
    percentage: 50,
    importance: "High",
    value: 40,
    group: "B",
    color: "yellow",
    segment: "Technology",
    geography: "South America",
    size: "Large",
    techStack: "Node.js",
    owner: "Brown",
  },
  {
    id: 5,
    name: "Woolworths",
    percentage: 30,
    importance: "Medium",
    value: 15,
    group: "C",
    color: "magenta",
    segment: "Retail",
    geography: "Australia",
    size: "Medium",
    techStack: "Django",
    owner: "Taylor",
  },
  {
    id: 6,
    name: "JP Morgan",
    percentage: 60,
    importance: "Low",
    value: 25,
    group: "C",
    color: "cyan",
    segment: "Finance",
    geography: "North America",
    size: "Small",
    techStack: "Flask",
    owner: "Anderson",
  },
  {
    id: 7,
    name: "Johnson & Johnson",
    percentage: 80,
    importance: "High",
    value: 35,
    group: "A",
    color: "orange",
    segment: "Healthcare",
    geography: "Europe",
    size: "Large",
    techStack: "Spring",
    owner: "Thomas",
  },
  {
    id: 8,
    name: "Microsoft",
    percentage: 45,
    importance: "Medium",
    value: 18,
    group: "A",
    color: "purple",
    segment: "Technology",
    geography: "Asia",
    size: "Medium",
    techStack: "Laravel",
    owner: "Jackson",
  },
  {
    id: 9,
    name: "Target",
    percentage: 55,
    importance: "Low",
    value: 22,
    group: "B",
    color: "pink",
    segment: "Retail",
    geography: "South America",
    size: "Small",
    techStack: "Ruby on Rails",
    owner: "White",
  },
  {
    id: 10,
    name: "Commonwealth Bank",
    percentage: 65,
    importance: "High",
    value: 28,
    group: "B",
    color: "brown",
    segment: "Finance",
    geography: "Australia",
    size: "Large",
    techStack: "Express",
    owner: "Harris",
  },
  {
    id: 11,
    name: "Medtronic",
    percentage: 35,
    importance: "Medium",
    value: 12,
    group: "C",
    color: "lime",
    segment: "Healthcare",
    geography: "North America",
    size: "Medium",
    techStack: "Symfony",
    owner: "Clark",
  },
  {
    id: 12,
    name: "SAP",
    percentage: 75,
    importance: "Low",
    value: 33,
    group: "C",
    color: "navy",
    segment: "Technology",
    geography: "Europe",
    size: "Small",
    techStack: "ASP.NET",
    owner: "Lewis",
  },
  {
    id: 13,
    name: "Amazon",
    percentage: 85,
    importance: "High",
    value: 37,
    group: "A",
    color: "teal",
    segment: "Retail",
    geography: "Asia",
    size: "Large",
    techStack: "Java",
    owner: "Walker",
  },
  {
    id: 14,
    name: "UBS",
    percentage: 25,
    importance: "Medium",
    value: 8,
    group: "A",
    color: "olive",
    segment: "Finance",
    geography: "South America",
    size: "Medium",
    techStack: "Python",
    owner: "Robinson",
  },
  {
    id: 15,
    name: "Moderna",
    percentage: 95,
    importance: "Low",
    value: 42,
    group: "B",
    color: "maroon",
    segment: "Healthcare",
    geography: "Australia",
    size: "Small",
    techStack: "C#",
    owner: "Young",
  },
  {
    id: 16,
    name: "Tesla",
    percentage: 20,
    importance: "High",
    value: 5,
    group: "B",
    color: "silver",
    segment: "Technology",
    geography: "North America",
    size: "Large",
    techStack: "PHP",
    owner: "King",
  },
  {
    id: 17,
    name: "Unilever",
    percentage: 50,
    importance: "Medium",
    value: 19,
    group: "C",
    color: "gold",
    segment: "Retail",
    geography: "Europe",
    size: "Medium",
    techStack: "Go",
    owner: "Wright",
  },
  {
    id: 18,
    name: "HSBC",
    percentage: 60,
    importance: "Low",
    value: 24,
    group: "C",
    color: "coral",
    segment: "Finance",
    geography: "Asia",
    size: "Small",
    techStack: "Rust",
    owner: "Scott",
  },
  {
    id: 19,
    name: "Roche",
    percentage: 70,
    importance: "High",
    value: 31,
    group: "A",
    color: "aqua",
    segment: "Healthcare",
    geography: "South America",
    size: "Large",
    techStack: "Kotlin",
    owner: "Green",
  },
  {
    id: 20,
    name: "Atlassian",
    percentage: 40,
    importance: "Medium",
    value: 11,
    group: "A",
    color: "plum",
    segment: "Technology",
    geography: "Australia",
    size: "Medium",
    techStack: "Swift",
    owner: "Baker",
  },
  {
    id: 21,
    name: "Procter & Gamble",
    percentage: 90,
    importance: "Low",
    value: 21,
    group: "B",
    color: "khaki",
    segment: "Retail",
    geography: "North America",
    size: "Small",
    techStack: "Objective-C",
    owner: "Nelson",
  },
  {
    id: 22,
    name: "Barclays",
    percentage: 50,
    importance: "High",
    value: 41,
    group: "B",
    color: "crimson",
    segment: "Finance",
    geography: "Europe",
    size: "Large",
    techStack: "Scala",
    owner: "Carter",
  },
  {
    id: 23,
    name: "Merck",
    percentage: 30,
    importance: "Medium",
    value: 16,
    group: "C",
    color: "azure",
    segment: "Healthcare",
    geography: "Asia",
    size: "Medium",
    techStack: "Perl",
    owner: "Mitchell",
  },
  {
    id: 24,
    name: "IBM",
    percentage: 60,
    importance: "Low",
    value: 26,
    group: "C",
    color: "ivory",
    segment: "Technology",
    geography: "South America",
    size: "Small",
    techStack: "Haskell",
    owner: "Perez",
  },
  {
    id: 25,
    name: "Walmart",
    percentage: 80,
    importance: "High",
    value: 36,
    group: "A",
    color: "orchid",
    segment: "Retail",
    geography: "Australia",
    size: "Large",
    techStack: "Clojure",
    owner: "Roberts",
  },
  {
    id: 26,
    name: "Citibank",
    percentage: 45,
    importance: "Medium",
    value: 17,
    group: "A",
    color: "lavender",
    segment: "Finance",
    geography: "North America",
    size: "Medium",
    techStack: "Elixir",
    owner: "Turner",
  },
  {
    id: 27,
    name: "Sanofi",
    percentage: 55,
    importance: "Low",
    value: 23,
    group: "B",
    color: "peach",
    segment: "Healthcare",
    geography: "Europe",
    size: "Small",
    techStack: "Julia",
    owner: "Phillips",
  },
  {
    id: 28,
    name: "Tencent",
    percentage: 65,
    importance: "High",
    value: 29,
    group: "B",
    color: "mint",
    segment: "Technology",
    geography: "Asia",
    size: "Large",
    techStack: "Solidity",
    owner: "Campbell",
  },
  {
    id: 29,
    name: "Nestle",
    percentage: 35,
    importance: "Medium",
    value: 13,
    group: "C",
    color: "rose",
    segment: "Retail",
    geography: "South America",
    size: "Medium",
    techStack: "TypeScript",
    owner: "Parker",
  },
  {
    id: 1,
    name: "Apple Inc.",
    percentage: 70,
    importance: "High",
    value: 30,
    group: "A",
    color: "red",
    segment: "Retail",
    geography: "North America",
    size: "Large",
    techStack: "React",
    owner: "John",
  },
  {
    id: 2,
    name: "Goldman Sachs",
    percentage: 40,
    importance: "Medium",
    value: 10,
    group: "A",
    color: "green",
    segment: "Finance",
    geography: "Europe",
    size: "Medium",
    techStack: "Angular",
    owner: "Doe",
  },
  {
    id: 3,
    name: "Pfizer",
    percentage: 90,
    importance: "Low",
    value: 20,
    group: "B",
    color: "blue",
    segment: "Healthcare",
    geography: "Asia",
    size: "Small",
    techStack: "Vue",
    owner: "Smith",
  },
  {
    id: 4,
    name: "Google",
    percentage: 50,
    importance: "High",
    value: 40,
    group: "B",
    color: "yellow",
    segment: "Technology",
    geography: "South America",
    size: "Large",
    techStack: "Node.js",
    owner: "Brown",
  },
  {
    id: 5,
    name: "Woolworths",
    percentage: 30,
    importance: "Medium",
    value: 15,
    group: "C",
    color: "magenta",
    segment: "Retail",
    geography: "Australia",
    size: "Medium",
    techStack: "Django",
    owner: "Taylor",
  },
  {
    id: 6,
    name: "JP Morgan",
    percentage: 60,
    importance: "Low",
    value: 25,
    group: "C",
    color: "cyan",
    segment: "Finance",
    geography: "North America",
    size: "Small",
    techStack: "Flask",
    owner: "Anderson",
  },
  {
    id: 7,
    name: "Johnson & Johnson",
    percentage: 80,
    importance: "High",
    value: 35,
    group: "A",
    color: "orange",
    segment: "Healthcare",
    geography: "Europe",
    size: "Large",
    techStack: "Spring",
    owner: "Thomas",
  },
  {
    id: 8,
    name: "Microsoft",
    percentage: 45,
    importance: "Medium",
    value: 18,
    group: "A",
    color: "purple",
    segment: "Technology",
    geography: "Asia",
    size: "Medium",
    techStack: "Laravel",
    owner: "Jackson",
  },
  {
    id: 9,
    name: "Target",
    percentage: 55,
    importance: "Low",
    value: 22,
    group: "B",
    color: "pink",
    segment: "Retail",
    geography: "South America",
    size: "Small",
    techStack: "Ruby on Rails",
    owner: "White",
  },
  {
    id: 10,
    name: "Commonwealth Bank",
    percentage: 65,
    importance: "High",
    value: 28,
    group: "B",
    color: "brown",
    segment: "Finance",
    geography: "Australia",
    size: "Large",
    techStack: "Express",
    owner: "Harris",
  },
  {
    id: 11,
    name: "Medtronic",
    percentage: 35,
    importance: "Medium",
    value: 12,
    group: "C",
    color: "lime",
    segment: "Healthcare",
    geography: "North America",
    size: "Medium",
    techStack: "Symfony",
    owner: "Clark",
  },
  {
    id: 12,
    name: "SAP",
    percentage: 75,
    importance: "Low",
    value: 33,
    group: "C",
    color: "navy",
    segment: "Technology",
    geography: "Europe",
    size: "Small",
    techStack: "ASP.NET",
    owner: "Lewis",
  },
  {
    id: 13,
    name: "Amazon",
    percentage: 85,
    importance: "High",
    value: 37,
    group: "A",
    color: "teal",
    segment: "Retail",
    geography: "Asia",
    size: "Large",
    techStack: "Java",
    owner: "Walker",
  },
  {
    id: 14,
    name: "UBS",
    percentage: 25,
    importance: "Medium",
    value: 8,
    group: "A",
    color: "olive",
    segment: "Finance",
    geography: "South America",
    size: "Medium",
    techStack: "Python",
    owner: "Robinson",
  },
  {
    id: 15,
    name: "Moderna",
    percentage: 95,
    importance: "Low",
    value: 42,
    group: "B",
    color: "maroon",
    segment: "Healthcare",
    geography: "Australia",
    size: "Small",
    techStack: "C#",
    owner: "Young",
  },
  {
    id: 16,
    name: "Tesla",
    percentage: 20,
    importance: "High",
    value: 5,
    group: "B",
    color: "silver",
    segment: "Technology",
    geography: "North America",
    size: "Large",
    techStack: "PHP",
    owner: "King",
  },
  {
    id: 17,
    name: "Unilever",
    percentage: 50,
    importance: "Medium",
    value: 19,
    group: "C",
    color: "gold",
    segment: "Retail",
    geography: "Europe",
    size: "Medium",
    techStack: "Go",
    owner: "Wright",
  },
  {
    id: 18,
    name: "HSBC",
    percentage: 60,
    importance: "Low",
    value: 24,
    group: "C",
    color: "coral",
    segment: "Finance",
    geography: "Asia",
    size: "Small",
    techStack: "Rust",
    owner: "Scott",
  },
  {
    id: 19,
    name: "Roche",
    percentage: 70,
    importance: "High",
    value: 31,
    group: "A",
    color: "aqua",
    segment: "Healthcare",
    geography: "South America",
    size: "Large",
    techStack: "Kotlin",
    owner: "Green",
  },
  {
    id: 20,
    name: "Atlassian",
    percentage: 40,
    importance: "Medium",
    value: 11,
    group: "A",
    color: "plum",
    segment: "Technology",
    geography: "Australia",
    size: "Medium",
    techStack: "Swift",
    owner: "Baker",
  },
  {
    id: 21,
    name: "Procter & Gamble",
    percentage: 90,
    importance: "Low",
    value: 21,
    group: "B",
    color: "khaki",
    segment: "Retail",
    geography: "North America",
    size: "Small",
    techStack: "Objective-C",
    owner: "Nelson",
  },
  {
    id: 22,
    name: "Barclays",
    percentage: 50,
    importance: "High",
    value: 41,
    group: "B",
    color: "crimson",
    segment: "Finance",
    geography: "Europe",
    size: "Large",
    techStack: "Scala",
    owner: "Carter",
  },
  {
    id: 23,
    name: "Merck",
    percentage: 30,
    importance: "Medium",
    value: 16,
    group: "C",
    color: "azure",
    segment: "Healthcare",
    geography: "Asia",
    size: "Medium",
    techStack: "Perl",
    owner: "Mitchell",
  },
  {
    id: 24,
    name: "IBM",
    percentage: 60,
    importance: "Low",
    value: 26,
    group: "C",
    color: "ivory",
    segment: "Technology",
    geography: "South America",
    size: "Small",
    techStack: "Haskell",
    owner: "Perez",
  },
  {
    id: 25,
    name: "Walmart",
    percentage: 80,
    importance: "High",
    value: 36,
    group: "A",
    color: "orchid",
    segment: "Retail",
    geography: "Australia",
    size: "Large",
    techStack: "Clojure",
    owner: "Roberts",
  },
  {
    id: 26,
    name: "Citibank",
    percentage: 45,
    importance: "Medium",
    value: 17,
    group: "A",
    color: "lavender",
    segment: "Finance",
    geography: "North America",
    size: "Medium",
    techStack: "Elixir",
    owner: "Turner",
  },
  {
    id: 27,
    name: "Sanofi",
    percentage: 55,
    importance: "Low",
    value: 23,
    group: "B",
    color: "peach",
    segment: "Healthcare",
    geography: "Europe",
    size: "Small",
    techStack: "Julia",
    owner: "Phillips",
  },
  {
    id: 28,
    name: "Tencent",
    percentage: 65,
    importance: "High",
    value: 29,
    group: "B",
    color: "mint",
    segment: "Technology",
    geography: "Asia",
    size: "Large",
    techStack: "Solidity",
    owner: "Campbell",
  },
  {
    id: 29,
    name: "Nestle",
    percentage: 35,
    importance: "Medium",
    value: 13,
    group: "C",
    color: "rose",
    segment: "Retail",
    geography: "South America",
    size: "Medium",
    techStack: "TypeScript",
    owner: "Parker",
  },
  {
    id: 1,
    name: "Apple Inc.",
    percentage: 70,
    importance: "High",
    value: 30,
    group: "A",
    color: "red",
    segment: "Retail",
    geography: "North America",
    size: "Large",
    techStack: "React",
    owner: "John",
  },
  {
    id: 2,
    name: "Goldman Sachs",
    percentage: 40,
    importance: "Medium",
    value: 10,
    group: "A",
    color: "green",
    segment: "Finance",
    geography: "Europe",
    size: "Medium",
    techStack: "Angular",
    owner: "Doe",
  },
  {
    id: 3,
    name: "Pfizer",
    percentage: 90,
    importance: "Low",
    value: 20,
    group: "B",
    color: "blue",
    segment: "Healthcare",
    geography: "Asia",
    size: "Small",
    techStack: "Vue",
    owner: "Smith",
  },
  {
    id: 4,
    name: "Google",
    percentage: 50,
    importance: "High",
    value: 40,
    group: "B",
    color: "yellow",
    segment: "Technology",
    geography: "South America",
    size: "Large",
    techStack: "Node.js",
    owner: "Brown",
  },
  {
    id: 5,
    name: "Woolworths",
    percentage: 30,
    importance: "Medium",
    value: 15,
    group: "C",
    color: "magenta",
    segment: "Retail",
    geography: "Australia",
    size: "Medium",
    techStack: "Django",
    owner: "Taylor",
  },
  {
    id: 6,
    name: "JP Morgan",
    percentage: 60,
    importance: "Low",
    value: 25,
    group: "C",
    color: "cyan",
    segment: "Finance",
    geography: "North America",
    size: "Small",
    techStack: "Flask",
    owner: "Anderson",
  },
  {
    id: 7,
    name: "Johnson & Johnson",
    percentage: 80,
    importance: "High",
    value: 35,
    group: "A",
    color: "orange",
    segment: "Healthcare",
    geography: "Europe",
    size: "Large",
    techStack: "Spring",
    owner: "Thomas",
  },
  {
    id: 8,
    name: "Microsoft",
    percentage: 45,
    importance: "Medium",
    value: 18,
    group: "A",
    color: "purple",
    segment: "Technology",
    geography: "Asia",
    size: "Medium",
    techStack: "Laravel",
    owner: "Jackson",
  },
  {
    id: 9,
    name: "Target",
    percentage: 55,
    importance: "Low",
    value: 22,
    group: "B",
    color: "pink",
    segment: "Retail",
    geography: "South America",
    size: "Small",
    techStack: "Ruby on Rails",
    owner: "White",
  },
  {
    id: 10,
    name: "Commonwealth Bank",
    percentage: 65,
    importance: "High",
    value: 28,
    group: "B",
    color: "brown",
    segment: "Finance",
    geography: "Australia",
    size: "Large",
    techStack: "Express",
    owner: "Harris",
  },
  {
    id: 11,
    name: "Medtronic",
    percentage: 35,
    importance: "Medium",
    value: 12,
    group: "C",
    color: "lime",
    segment: "Healthcare",
    geography: "North America",
    size: "Medium",
    techStack: "Symfony",
    owner: "Clark",
  },
  {
    id: 12,
    name: "SAP",
    percentage: 75,
    importance: "Low",
    value: 33,
    group: "C",
    color: "navy",
    segment: "Technology",
    geography: "Europe",
    size: "Small",
    techStack: "ASP.NET",
    owner: "Lewis",
  },
  {
    id: 13,
    name: "Amazon",
    percentage: 85,
    importance: "High",
    value: 37,
    group: "A",
    color: "teal",
    segment: "Retail",
    geography: "Asia",
    size: "Large",
    techStack: "Java",
    owner: "Walker",
  },
  {
    id: 14,
    name: "UBS",
    percentage: 25,
    importance: "Medium",
    value: 8,
    group: "A",
    color: "olive",
    segment: "Finance",
    geography: "South America",
    size: "Medium",
    techStack: "Python",
    owner: "Robinson",
  },
  {
    id: 15,
    name: "Moderna",
    percentage: 95,
    importance: "Low",
    value: 42,
    group: "B",
    color: "maroon",
    segment: "Healthcare",
    geography: "Australia",
    size: "Small",
    techStack: "C#",
    owner: "Young",
  },
  {
    id: 16,
    name: "Tesla",
    percentage: 20,
    importance: "High",
    value: 5,
    group: "B",
    color: "silver",
    segment: "Technology",
    geography: "North America",
    size: "Large",
    techStack: "PHP",
    owner: "King",
  },
  {
    id: 17,
    name: "Unilever",
    percentage: 50,
    importance: "Medium",
    value: 19,
    group: "C",
    color: "gold",
    segment: "Retail",
    geography: "Europe",
    size: "Medium",
    techStack: "Go",
    owner: "Wright",
  },
  {
    id: 18,
    name: "HSBC",
    percentage: 60,
    importance: "Low",
    value: 24,
    group: "C",
    color: "coral",
    segment: "Finance",
    geography: "Asia",
    size: "Small",
    techStack: "Rust",
    owner: "Scott",
  },
  {
    id: 19,
    name: "Roche",
    percentage: 70,
    importance: "High",
    value: 31,
    group: "A",
    color: "aqua",
    segment: "Healthcare",
    geography: "South America",
    size: "Large",
    techStack: "Kotlin",
    owner: "Green",
  },
  {
    id: 20,
    name: "Atlassian",
    percentage: 40,
    importance: "Medium",
    value: 11,
    group: "A",
    color: "plum",
    segment: "Technology",
    geography: "Australia",
    size: "Medium",
    techStack: "Swift",
    owner: "Baker",
  },
  {
    id: 21,
    name: "Procter & Gamble",
    percentage: 90,
    importance: "Low",
    value: 21,
    group: "B",
    color: "khaki",
    segment: "Retail",
    geography: "North America",
    size: "Small",
    techStack: "Objective-C",
    owner: "Nelson",
  },
  {
    id: 22,
    name: "Barclays",
    percentage: 50,
    importance: "High",
    value: 41,
    group: "B",
    color: "crimson",
    segment: "Finance",
    geography: "Europe",
    size: "Large",
    techStack: "Scala",
    owner: "Carter",
  },
  {
    id: 23,
    name: "Merck",
    percentage: 30,
    importance: "Medium",
    value: 16,
    group: "C",
    color: "azure",
    segment: "Healthcare",
    geography: "Asia",
    size: "Medium",
    techStack: "Perl",
    owner: "Mitchell",
  },
  {
    id: 24,
    name: "IBM",
    percentage: 60,
    importance: "Low",
    value: 26,
    group: "C",
    color: "ivory",
    segment: "Technology",
    geography: "South America",
    size: "Small",
    techStack: "Haskell",
    owner: "Perez",
  },
  {
    id: 25,
    name: "Walmart",
    percentage: 80,
    importance: "High",
    value: 36,
    group: "A",
    color: "orchid",
    segment: "Retail",
    geography: "Australia",
    size: "Large",
    techStack: "Clojure",
    owner: "Roberts",
  },
  {
    id: 26,
    name: "Citibank",
    percentage: 45,
    importance: "Medium",
    value: 17,
    group: "A",
    color: "lavender",
    segment: "Finance",
    geography: "North America",
    size: "Medium",
    techStack: "Elixir",
    owner: "Turner",
  },
  {
    id: 27,
    name: "Sanofi",
    percentage: 55,
    importance: "Low",
    value: 23,
    group: "B",
    color: "peach",
    segment: "Healthcare",
    geography: "Europe",
    size: "Small",
    techStack: "Julia",
    owner: "Phillips",
  },
  {
    id: 28,
    name: "Tencent",
    percentage: 65,
    importance: "High",
    value: 29,
    group: "B",
    color: "mint",
    segment: "Technology",
    geography: "Asia",
    size: "Large",
    techStack: "Solidity",
    owner: "Campbell",
  },
  {
    id: 29,
    name: "Nestle",
    percentage: 35,
    importance: "Medium",
    value: 13,
    group: "C",
    color: "rose",
    segment: "Retail",
    geography: "South America",
    size: "Medium",
    techStack: "TypeScript",
    owner: "Parker",
  },
  {
    id: 1,
    name: "Apple Inc.",
    percentage: 70,
    importance: "High",
    value: 30,
    group: "A",
    color: "red",
    segment: "Retail",
    geography: "North America",
    size: "Large",
    techStack: "React",
    owner: "John",
  },
  {
    id: 2,
    name: "Goldman Sachs",
    percentage: 40,
    importance: "Medium",
    value: 10,
    group: "A",
    color: "green",
    segment: "Finance",
    geography: "Europe",
    size: "Medium",
    techStack: "Angular",
    owner: "Doe",
  },
  {
    id: 3,
    name: "Pfizer",
    percentage: 90,
    importance: "Low",
    value: 20,
    group: "B",
    color: "blue",
    segment: "Healthcare",
    geography: "Asia",
    size: "Small",
    techStack: "Vue",
    owner: "Smith",
  },
  {
    id: 4,
    name: "Google",
    percentage: 50,
    importance: "High",
    value: 40,
    group: "B",
    color: "yellow",
    segment: "Technology",
    geography: "South America",
    size: "Large",
    techStack: "Node.js",
    owner: "Brown",
  },
  {
    id: 5,
    name: "Woolworths",
    percentage: 30,
    importance: "Medium",
    value: 15,
    group: "C",
    color: "magenta",
    segment: "Retail",
    geography: "Australia",
    size: "Medium",
    techStack: "Django",
    owner: "Taylor",
  },
  {
    id: 6,
    name: "JP Morgan",
    percentage: 60,
    importance: "Low",
    value: 25,
    group: "C",
    color: "cyan",
    segment: "Finance",
    geography: "North America",
    size: "Small",
    techStack: "Flask",
    owner: "Anderson",
  },
  {
    id: 7,
    name: "Johnson & Johnson",
    percentage: 80,
    importance: "High",
    value: 35,
    group: "A",
    color: "orange",
    segment: "Healthcare",
    geography: "Europe",
    size: "Large",
    techStack: "Spring",
    owner: "Thomas",
  },
  {
    id: 8,
    name: "Microsoft",
    percentage: 45,
    importance: "Medium",
    value: 18,
    group: "A",
    color: "purple",
    segment: "Technology",
    geography: "Asia",
    size: "Medium",
    techStack: "Laravel",
    owner: "Jackson",
  },
  {
    id: 9,
    name: "Target",
    percentage: 55,
    importance: "Low",
    value: 22,
    group: "B",
    color: "pink",
    segment: "Retail",
    geography: "South America",
    size: "Small",
    techStack: "Ruby on Rails",
    owner: "White",
  },
  {
    id: 10,
    name: "Commonwealth Bank",
    percentage: 65,
    importance: "High",
    value: 28,
    group: "B",
    color: "brown",
    segment: "Finance",
    geography: "Australia",
    size: "Large",
    techStack: "Express",
    owner: "Harris",
  },
  {
    id: 11,
    name: "Medtronic",
    percentage: 35,
    importance: "Medium",
    value: 12,
    group: "C",
    color: "lime",
    segment: "Healthcare",
    geography: "North America",
    size: "Medium",
    techStack: "Symfony",
    owner: "Clark",
  },
  {
    id: 12,
    name: "SAP",
    percentage: 75,
    importance: "Low",
    value: 33,
    group: "C",
    color: "navy",
    segment: "Technology",
    geography: "Europe",
    size: "Small",
    techStack: "ASP.NET",
    owner: "Lewis",
  },
  {
    id: 13,
    name: "Amazon",
    percentage: 85,
    importance: "High",
    value: 37,
    group: "A",
    color: "teal",
    segment: "Retail",
    geography: "Asia",
    size: "Large",
    techStack: "Java",
    owner: "Walker",
  },
  {
    id: 14,
    name: "UBS",
    percentage: 25,
    importance: "Medium",
    value: 8,
    group: "A",
    color: "olive",
    segment: "Finance",
    geography: "South America",
    size: "Medium",
    techStack: "Python",
    owner: "Robinson",
  },
  {
    id: 15,
    name: "Moderna",
    percentage: 95,
    importance: "Low",
    value: 42,
    group: "B",
    color: "maroon",
    segment: "Healthcare",
    geography: "Australia",
    size: "Small",
    techStack: "C#",
    owner: "Young",
  },
  {
    id: 16,
    name: "Tesla",
    percentage: 20,
    importance: "High",
    value: 5,
    group: "B",
    color: "silver",
    segment: "Technology",
    geography: "North America",
    size: "Large",
    techStack: "PHP",
    owner: "King",
  },
  {
    id: 17,
    name: "Unilever",
    percentage: 50,
    importance: "Medium",
    value: 19,
    group: "C",
    color: "gold",
    segment: "Retail",
    geography: "Europe",
    size: "Medium",
    techStack: "Go",
    owner: "Wright",
  },
  {
    id: 18,
    name: "HSBC",
    percentage: 60,
    importance: "Low",
    value: 24,
    group: "C",
    color: "coral",
    segment: "Finance",
    geography: "Asia",
    size: "Small",
    techStack: "Rust",
    owner: "Scott",
  },
  {
    id: 19,
    name: "Roche",
    percentage: 70,
    importance: "High",
    value: 31,
    group: "A",
    color: "aqua",
    segment: "Healthcare",
    geography: "South America",
    size: "Large",
    techStack: "Kotlin",
    owner: "Green",
  },
  {
    id: 20,
    name: "Atlassian",
    percentage: 40,
    importance: "Medium",
    value: 11,
    group: "A",
    color: "plum",
    segment: "Technology",
    geography: "Australia",
    size: "Medium",
    techStack: "Swift",
    owner: "Baker",
  },
  {
    id: 21,
    name: "Procter & Gamble",
    percentage: 90,
    importance: "Low",
    value: 21,
    group: "B",
    color: "khaki",
    segment: "Retail",
    geography: "North America",
    size: "Small",
    techStack: "Objective-C",
    owner: "Nelson",
  },
  {
    id: 22,
    name: "Barclays",
    percentage: 50,
    importance: "High",
    value: 41,
    group: "B",
    color: "crimson",
    segment: "Finance",
    geography: "Europe",
    size: "Large",
    techStack: "Scala",
    owner: "Carter",
  },
  {
    id: 23,
    name: "Merck",
    percentage: 30,
    importance: "Medium",
    value: 16,
    group: "C",
    color: "azure",
    segment: "Healthcare",
    geography: "Asia",
    size: "Medium",
    techStack: "Perl",
    owner: "Mitchell",
  },
  {
    id: 24,
    name: "IBM",
    percentage: 60,
    importance: "Low",
    value: 26,
    group: "C",
    color: "ivory",
    segment: "Technology",
    geography: "South America",
    size: "Small",
    techStack: "Haskell",
    owner: "Perez",
  },
  {
    id: 25,
    name: "Walmart",
    percentage: 80,
    importance: "High",
    value: 36,
    group: "A",
    color: "orchid",
    segment: "Retail",
    geography: "Australia",
    size: "Large",
    techStack: "Clojure",
    owner: "Roberts",
  },
  {
    id: 26,
    name: "Citibank",
    percentage: 45,
    importance: "Medium",
    value: 17,
    group: "A",
    color: "lavender",
    segment: "Finance",
    geography: "North America",
    size: "Medium",
    techStack: "Elixir",
    owner: "Turner",
  },
  {
    id: 27,
    name: "Sanofi",
    percentage: 55,
    importance: "Low",
    value: 23,
    group: "B",
    color: "peach",
    segment: "Healthcare",
    geography: "Europe",
    size: "Small",
    techStack: "Julia",
    owner: "Phillips",
  },
  {
    id: 28,
    name: "Tencent",
    percentage: 65,
    importance: "High",
    value: 29,
    group: "B",
    color: "mint",
    segment: "Technology",
    geography: "Asia",
    size: "Large",
    techStack: "Solidity",
    owner: "Campbell",
  },
  {
    id: 29,
    name: "Nestle",
    percentage: 35,
    importance: "Medium",
    value: 13,
    group: "C",
    color: "rose",
    segment: "Retail",
    geography: "South America",
    size: "Medium",
    techStack: "TypeScript",
    owner: "Parker",
  },
  {
    id: 1,
    name: "Apple Inc.",
    percentage: 70,
    importance: "High",
    value: 30,
    group: "A",
    color: "red",
    segment: "Retail",
    geography: "North America",
    size: "Large",
    techStack: "React",
    owner: "John",
  },
  {
    id: 2,
    name: "Goldman Sachs",
    percentage: 40,
    importance: "Medium",
    value: 10,
    group: "A",
    color: "green",
    segment: "Finance",
    geography: "Europe",
    size: "Medium",
    techStack: "Angular",
    owner: "Doe",
  },
  {
    id: 3,
    name: "Pfizer",
    percentage: 90,
    importance: "Low",
    value: 20,
    group: "B",
    color: "blue",
    segment: "Healthcare",
    geography: "Asia",
    size: "Small",
    techStack: "Vue",
    owner: "Smith",
  },
  {
    id: 4,
    name: "Google",
    percentage: 50,
    importance: "High",
    value: 40,
    group: "B",
    color: "yellow",
    segment: "Technology",
    geography: "South America",
    size: "Large",
    techStack: "Node.js",
    owner: "Brown",
  },
  {
    id: 5,
    name: "Woolworths",
    percentage: 30,
    importance: "Medium",
    value: 15,
    group: "C",
    color: "magenta",
    segment: "Retail",
    geography: "Australia",
    size: "Medium",
    techStack: "Django",
    owner: "Taylor",
  },
  {
    id: 6,
    name: "JP Morgan",
    percentage: 60,
    importance: "Low",
    value: 25,
    group: "C",
    color: "cyan",
    segment: "Finance",
    geography: "North America",
    size: "Small",
    techStack: "Flask",
    owner: "Anderson",
  },
  {
    id: 7,
    name: "Johnson & Johnson",
    percentage: 80,
    importance: "High",
    value: 35,
    group: "A",
    color: "orange",
    segment: "Healthcare",
    geography: "Europe",
    size: "Large",
    techStack: "Spring",
    owner: "Thomas",
  },
  {
    id: 8,
    name: "Microsoft",
    percentage: 45,
    importance: "Medium",
    value: 18,
    group: "A",
    color: "purple",
    segment: "Technology",
    geography: "Asia",
    size: "Medium",
    techStack: "Laravel",
    owner: "Jackson",
  },
  {
    id: 9,
    name: "Target",
    percentage: 55,
    importance: "Low",
    value: 22,
    group: "B",
    color: "pink",
    segment: "Retail",
    geography: "South America",
    size: "Small",
    techStack: "Ruby on Rails",
    owner: "White",
  },
  {
    id: 10,
    name: "Commonwealth Bank",
    percentage: 65,
    importance: "High",
    value: 28,
    group: "B",
    color: "brown",
    segment: "Finance",
    geography: "Australia",
    size: "Large",
    techStack: "Express",
    owner: "Harris",
  },
  {
    id: 11,
    name: "Medtronic",
    percentage: 35,
    importance: "Medium",
    value: 12,
    group: "C",
    color: "lime",
    segment: "Healthcare",
    geography: "North America",
    size: "Medium",
    techStack: "Symfony",
    owner: "Clark",
  },
  {
    id: 12,
    name: "SAP",
    percentage: 75,
    importance: "Low",
    value: 33,
    group: "C",
    color: "navy",
    segment: "Technology",
    geography: "Europe",
    size: "Small",
    techStack: "ASP.NET",
    owner: "Lewis",
  },
  {
    id: 13,
    name: "Amazon",
    percentage: 85,
    importance: "High",
    value: 37,
    group: "A",
    color: "teal",
    segment: "Retail",
    geography: "Asia",
    size: "Large",
    techStack: "Java",
    owner: "Walker",
  },
  {
    id: 14,
    name: "UBS",
    percentage: 25,
    importance: "Medium",
    value: 8,
    group: "A",
    color: "olive",
    segment: "Finance",
    geography: "South America",
    size: "Medium",
    techStack: "Python",
    owner: "Robinson",
  },
  {
    id: 15,
    name: "Moderna",
    percentage: 95,
    importance: "Low",
    value: 42,
    group: "B",
    color: "maroon",
    segment: "Healthcare",
    geography: "Australia",
    size: "Small",
    techStack: "C#",
    owner: "Young",
  },
  {
    id: 16,
    name: "Tesla",
    percentage: 20,
    importance: "High",
    value: 5,
    group: "B",
    color: "silver",
    segment: "Technology",
    geography: "North America",
    size: "Large",
    techStack: "PHP",
    owner: "King",
  },
  {
    id: 17,
    name: "Unilever",
    percentage: 50,
    importance: "Medium",
    value: 19,
    group: "C",
    color: "gold",
    segment: "Retail",
    geography: "Europe",
    size: "Medium",
    techStack: "Go",
    owner: "Wright",
  },
  {
    id: 18,
    name: "HSBC",
    percentage: 60,
    importance: "Low",
    value: 24,
    group: "C",
    color: "coral",
    segment: "Finance",
    geography: "Asia",
    size: "Small",
    techStack: "Rust",
    owner: "Scott",
  },
  {
    id: 19,
    name: "Roche",
    percentage: 70,
    importance: "High",
    value: 31,
    group: "A",
    color: "aqua",
    segment: "Healthcare",
    geography: "South America",
    size: "Large",
    techStack: "Kotlin",
    owner: "Green",
  },
  {
    id: 20,
    name: "Atlassian",
    percentage: 40,
    importance: "Medium",
    value: 11,
    group: "A",
    color: "plum",
    segment: "Technology",
    geography: "Australia",
    size: "Medium",
    techStack: "Swift",
    owner: "Baker",
  },
  {
    id: 21,
    name: "Procter & Gamble",
    percentage: 90,
    importance: "Low",
    value: 21,
    group: "B",
    color: "khaki",
    segment: "Retail",
    geography: "North America",
    size: "Small",
    techStack: "Objective-C",
    owner: "Nelson",
  },
  {
    id: 22,
    name: "Barclays",
    percentage: 50,
    importance: "High",
    value: 41,
    group: "B",
    color: "crimson",
    segment: "Finance",
    geography: "Europe",
    size: "Large",
    techStack: "Scala",
    owner: "Carter",
  },
  {
    id: 23,
    name: "Merck",
    percentage: 30,
    importance: "Medium",
    value: 16,
    group: "C",
    color: "azure",
    segment: "Healthcare",
    geography: "Asia",
    size: "Medium",
    techStack: "Perl",
    owner: "Mitchell",
  },
  {
    id: 24,
    name: "IBM",
    percentage: 60,
    importance: "Low",
    value: 26,
    group: "C",
    color: "ivory",
    segment: "Technology",
    geography: "South America",
    size: "Small",
    techStack: "Haskell",
    owner: "Perez",
  },
  {
    id: 25,
    name: "Walmart",
    percentage: 80,
    importance: "High",
    value: 36,
    group: "A",
    color: "orchid",
    segment: "Retail",
    geography: "Australia",
    size: "Large",
    techStack: "Clojure",
    owner: "Roberts",
  },
  {
    id: 26,
    name: "Citibank",
    percentage: 45,
    importance: "Medium",
    value: 17,
    group: "A",
    color: "lavender",
    segment: "Finance",
    geography: "North America",
    size: "Medium",
    techStack: "Elixir",
    owner: "Turner",
  },
  {
    id: 27,
    name: "Sanofi",
    percentage: 55,
    importance: "Low",
    value: 23,
    group: "B",
    color: "peach",
    segment: "Healthcare",
    geography: "Europe",
    size: "Small",
    techStack: "Julia",
    owner: "Phillips",
  },
  {
    id: 28,
    name: "Tencent",
    percentage: 65,
    importance: "High",
    value: 29,
    group: "B",
    color: "mint",
    segment: "Technology",
    geography: "Asia",
    size: "Large",
    techStack: "Solidity",
    owner: "Campbell",
  },
  {
    id: 29,
    name: "Nestle",
    percentage: 35,
    importance: "Medium",
    value: 13,
    group: "C",
    color: "rose",
    segment: "Retail",
    geography: "South America",
    size: "Medium",
    techStack: "TypeScript",
    owner: "Parker",
  },
];

const colorOptions = [
  "red",
  "green",
  "blue",
  "yellow",
  "magenta",
  "cyan",
  "orange",
  "purple",
  "pink",
  "brown",
  "black",
  "gray",
];

const ImportCsv = ({ data, setData }) => {
  const [nameField, setNameField] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const headers = results.meta.fields;
          const newRows = results.data.map((row, index) => {
            const newRow = {
              id: data.length + index + 1,
              name: row[nameField] || null,
              percentage: row.percentage ? parseFloat(row.percentage) : null,
              importance: row.importance || null,
              value: row.value ? parseFloat(row.value) : null,
              group: row.group || null,
              color: row.color || null,
              segment: row.segment || null,
              geography: row.geography || null,
              size: row.size || null,
              techStack: row.techStack || null,
              owner: row.owner || null,
            };
            // Add any additional fields
            headers.forEach((header) => {
              if (!Object.keys(newRow).includes(header)) {
                newRow[header] = row[header] || null;
              }
            });
            return newRow;
          });

          // Update existing data with additional fields
          const updatedData = data.map((item) => {
            const updatedItem = { ...item };
            headers.forEach((header) => {
              if (!Object.keys(updatedItem).includes(header)) {
                updatedItem[header] = null;
              }
            });
            return updatedItem;
          });

          setData([...updatedData, ...newRows]);
        },
      });
    }
  };

  return (
    <Box p={4}>
      <FormControl mb={4}>
        <FormLabel>CSV Header for Name</FormLabel>
        <Input
          placeholder="Enter CSV header for Name"
          value={nameField}
          onChange={(e) => setNameField(e.target.value)}
        />
      </FormControl>
      <Input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        mb={4}
      />
    </Box>
  );
};

const ClusteredBubbleChart = ({
  data,
  clusterByAttribute,
  onCircleClick,
  onCircleDoubleClick,
  onUpdateAttribute,
  setDraggingInfo,
  setNewCircle,
  highlightedCircles,
}) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [highlightedCluster, setHighlightedCluster] = useState(null);
  const [draggedNode, setDraggedNode] = useState(null);
  const [validClusters, setValidClusters] = useState([]);
  const [showNames, setShowNames] = useState(true);

  const updateCircles = () => {
    const svg = d3.select(svgRef.current);
    svg
      .selectAll("circle")
      .attr("stroke", (d) =>
        highlightedCircles.includes(d.id) ? "black" : "none"
      )
      .attr("stroke-width", (d) => (highlightedCircles.includes(d.id) ? 2 : 0))
      .style("opacity", (d) =>
        highlightedCircles.length > 0
          ? highlightedCircles.includes(d.id)
            ? 1
            : 0.2
          : 1
      );
    addFloatingNames();
  };

  const addFloatingNames = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll(".circle-name").remove();
    if (showNames) {
      svg
        .selectAll(".circle-name")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "circle-name")
        .attr("x", (d) => d.x)
        .attr("y", (d) => d.y - d.value - 5)
        .attr("text-anchor", "middle")
        .text((d) => d.name);
    }
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const container = d3.select(containerRef.current);

    const updateDimensions = () => {
      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      svg
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("width", width)
        .attr("height", height);

      const attributeGroups = {};
      const uniqueValues = Array.from(
        new Set(data.map((d) => d[clusterByAttribute]))
      );
      uniqueValues.forEach((value, index) => {
        attributeGroups[value] = {
          x: (index + 1) * (width / (uniqueValues.length + 1)),
          y: height / 2,
          width: width / (uniqueValues.length + 1),
          height: height - 100,
        };
      });

      setValidClusters(uniqueValues);

      const simulation = d3
        .forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(5))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force(
          "collision",
          d3.forceCollide().radius((d) => d.value + 5)
        )
        .alphaDecay(0.1)
        .on("tick", ticked);

      if (clusterByAttribute && clusterByAttribute !== "None") {
        simulation
          .force(
            "x",
            d3
              .forceX((d) => attributeGroups[d[clusterByAttribute]].x)
              .strength(0.5)
          )
          .force(
            "y",
            d3
              .forceY((d) => attributeGroups[d[clusterByAttribute]].y)
              .strength(0.5)
          )
          .alpha(1)
          .restart();

        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();

        Object.keys(attributeGroups).forEach((attribute) => {
          svg
            .append("rect")
            .attr("class", `cluster-box ${attribute}`)
            .attr(
              "x",
              attributeGroups[attribute].x -
                width / (2 * (uniqueValues.length + 1))
            )
            .attr("y", 50)
            .attr("width", width / (uniqueValues.length + 1))
            .attr("height", height - 150)
            .attr("fill", "none")
            .attr("stroke", "gray")
            .attr("stroke-dasharray", "4")
            .lower();

          svg
            .append("text")
            .attr("class", "label")
            .attr("x", attributeGroups[attribute].x)
            .attr("y", 40)
            .attr("text-anchor", "middle")
            .text(attribute);
        });

        svg.selectAll(".input-box").remove();
        Object.keys(attributeGroups).forEach((attribute) => {
          const group = attributeGroups[attribute];
          svg
            .append("foreignObject")
            .attr("class", "input-box")
            .attr("x", group.x - width / (2 * (uniqueValues.length + 1)))
            .attr("y", height - 80)
            .attr("width", width / (uniqueValues.length + 1))
            .attr("height", 60).html(`
              <div xmlns="http://www.w3.org/1999/xhtml">
                <input type="text" placeholder="Add new circle" style="width:100%; height:30px; padding:5px;" id="input-${attribute}" />
                <Divider />
                <button style="width:100%; height:30px;" onclick="window.addNewCircle('${attribute}')">Add</button>
              </div>
            `);
        });

        window.addNewCircle = (attribute) => {
          const input = document.getElementById(`input-${attribute}`);
          if (input.value.trim() !== "") {
            const newCircle = {
              id: data.length + 1,
              name: input.value,
              value: 30,
              percentage: 50,
              importance: "Medium",
              group: attribute,
              color: "gray",
              [clusterByAttribute]: attribute,
            };
            setNewCircle(newCircle);
            input.value = "";
            simulation.nodes(data).restart(); // Restart the simulation with updated nodes
          }
        };
      } else {
        simulation
          .force("x", d3.forceX(width / 2).strength(0.1))
          .force("y", d3.forceY(height / 2).strength(0.1))
          .alpha(1)
          .restart();

        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();
        svg.selectAll(".input-box").remove();
      }

      const node = svg
        .selectAll("g")
        .data(data, (d) => d.id)
        .join(
          (enter) => {
            const g = enter
              .append("g")
              .call(
                d3
                  .drag()
                  .on("start", dragstarted)
                  .on("drag", dragged)
                  .on("end", dragended)
              );

            g.append("circle")
              .attr("r", (d) => d.value)
              .attr("fill", (d) => d.color)
              .attr("stroke", (d) =>
                highlightedCircles.includes(d.id) ? "blue" : "none"
              )
              .attr("stroke-width", (d) =>
                highlightedCircles.includes(d.id) ? 2 : 0
              )
              .style("opacity", (d) =>
                highlightedCircles.length > 0
                  ? highlightedCircles.includes(d.id)
                    ? 1
                    : 0.2
                  : 1
              )
              .on("mouseenter", function (event, d) {
                d3.select(this).attr("stroke", "black");
                showTooltip(event, d);
              })
              .on("mouseleave", function (event, d) {
                d3.select(this).attr(
                  "stroke",
                  highlightedCircles.includes(d.id) ? "black" : "none"
                );
                hideTooltip();
              })
              .on("click", function (event, d) {
                onCircleClick(d);
              })
              .on("dblclick", function (event, d) {
                onCircleDoubleClick(d);
              });

            g.append("title").text(
              (d) =>
                `Name: ${d.name}\nPercentage: ${d.percentage}%\nImportance: ${d.importance}`
            );

            return g;
          },
          (update) => update,
          (exit) => exit.remove()
        );

      function ticked() {
        node.attr("transform", (d) => {
          if (
            clusterByAttribute &&
            clusterByAttribute !== "None" &&
            !draggedNode
          ) {
            const group = attributeGroups[d[clusterByAttribute]];
            d.x = Math.max(
              group.x - group.width / 2 + d.value,
              Math.min(group.x + group.width / 2 - d.value, d.x)
            );
            d.y = Math.max(
              50 + d.value,
              Math.min(group.y + group.height / 2 - d.value, d.y)
            );
          }
          return `translate(${d.x}, ${d.y})`;
        });
        addFloatingNames();
      }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;

        setDraggedNode(d);

        data.forEach((node) => {
          if (node.id !== d.id) {
            node.fx = node.x;
            node.fy = node.y;
          }
        });
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;

        if (clusterByAttribute && clusterByAttribute !== "None") {
          const newAttribute = getCluster(event.x, attributeGroups);
          if (newAttribute !== highlightedCluster) {
            setHighlightedCluster(newAttribute);
            d3.selectAll(".cluster-box").attr("fill", "none");
            d3.select(`.cluster-box.${newAttribute}`).attr(
              "fill",
              "rgba(0, 0, 255, 0.1)"
            );
            setDraggingInfo(
              `Circle ${d.name} is moving to ${newAttribute} cluster`
            );
          }
        }
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
        setDraggedNode(null);

        const newAttribute = getCluster(event.x, attributeGroups);
        if (newAttribute && d[clusterByAttribute] !== newAttribute) {
          d[clusterByAttribute] = newAttribute;
          onUpdateAttribute(d);
          setDraggingInfo(
            `Circle ${d.name} has been moved to ${newAttribute} cluster`
          );
        } else {
          setDraggingInfo(`Circle ${d.name} was not moved to a valid cluster`);
        }
        setHighlightedCluster(null);
        d3.selectAll(".cluster-box").attr("fill", "none");

        data.forEach((node) => {
          if (node.id !== d.id) {
            node.fx = null;
            node.fy = null;
          }
        });

        simulation.alpha(1).restart();
      }

      function showTooltip(event, d) {
        const tooltip = d3
          .select("#tooltip")
          .style("opacity", 1)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`)
          .html(
            `Name: ${d.name}<br>Percentage: ${d.percentage}%<br>Importance: ${d.importance}`
          );
      }

      function hideTooltip() {
        d3.select("#tooltip").style("opacity", 0);
      }

      function getCluster(x, groups) {
        const distances = Object.keys(groups).map((key) => ({
          key,
          distance: Math.abs(x - groups[key].x),
        }));
        const closest = distances.reduce((acc, curr) =>
          curr.distance < acc.distance ? curr : acc
        );
        return closest.key;
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [
    data,
    clusterByAttribute,
    highlightedCircles,
    highlightedCluster,
    onUpdateAttribute,
    draggedNode,
    setDraggingInfo,
  ]);

  useEffect(() => {
    updateCircles();
  }, [highlightedCircles]);

  useEffect(() => {
    addFloatingNames();
  }, [showNames, data]);

  return (
    <Box
      ref={containerRef}
      width="100%"
      height="80vh"
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Button
        onClick={() => setShowNames(!showNames)}
        position="absolute"
        top="10px"
        left="10px"
      >
        Toggle Names
      </Button>
      <svg ref={svgRef}></svg>
      <div
        id="tooltip"
        style={{
          position: "absolute",
          opacity: 0,
          background: "lightgray",
          padding: "5px",
          borderRadius: "5px",
          pointerEvents: "none",
        }}
      ></div>
      
    </Box>
  );
};

const DataTable = ({ data, onEdit, defaultColumns }) => {
  const [visibleColumns, setVisibleColumns] = React.useState(defaultColumns);

  const handleColumnChange = (e) => {
    const { value, checked } = e.target;
    setVisibleColumns((prev) =>
      checked ? [...prev, value] : prev.filter((col) => col !== value)
    );
  };

  const renderColumns = () => {
    if (!data || data.length === 0) return null;

    return visibleColumns.map((col) => <Th key={col}>{col}</Th>);
  };

  return (
    <Menu>
      <MenuButton as={Button} w="100%" rightIcon={<LuCreditCard />}>
        Select Columns
      </MenuButton>
      <MenuList maxHeight="200px" overflowY="auto">
        <VStack align="start">
          <CheckboxGroup colorScheme="teal" defaultValue={defaultColumns}>
            {Object.keys(data[0]).map((col) => (
              <MenuItem key={col} closeOnSelect={false}>
                <Checkbox
                  value={col}
                  isChecked={visibleColumns.includes(col)}
                  onChange={handleColumnChange}
                >
                  {col}
                </Checkbox>
              </MenuItem>
            ))}
          </CheckboxGroup>
        </VStack>
      </MenuList>
      <TableContainer >
        <Table variant={"striped"}>
          <Thead>
            <Tr>
              {renderColumns()}
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => (
              <Tr key={item.id}>
                {visibleColumns.map((col) => (
                  <Td key={item[col]}>{item[col]}</Td>
                ))}
                <Td>
                  <Button size="sm" onClick={() => onEdit(item)}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Menu>
  );
};

const CardLayout_1 = ({ data, onEdit }) => {
  return (
    <TableContainer bg="white">
   
<SimpleGrid columns={4} spacing={4} p="5" textAlign={"left"}>



        { data.map(( item) => (
          <Box
        maxW={'320px'}
        w={'full'}
        rounded={'md'}
        p={6}
        bg="teal.50"
        >
        <Avatar
          size={'xl'}
          src={
            'https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ'
          }
          alt={'Avatar Alt'}
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: 'green.300',
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
        {item.name}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          @lindsey_jam3s
        </Text>


        <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
          <Badge
            px={2}
            py={1}
            fontWeight={'400'}>
            #art
          </Badge>
          <Badge
            px={2}
            py={1}
            fontWeight={'400'}>
            #photography
          </Badge>
          <Badge
            px={2}
            py={1}
            fontWeight={'400'}>
            #music
          </Badge>
        </Stack>

        <Stack mt={8} direction={'row'} spacing={4}>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            _focus={{
              bg: 'gray.200',
            }}>
            Message
          </Button>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            bg={'blue.400'}
            color={'white'}
            boxShadow={
              '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            }
            _hover={{
              bg: 'blue.500',
            }}
            _focus={{
              bg: 'blue.500',
            }}>
            Follow
          </Button>
        </Stack>
      </Box>

        ))}

</SimpleGrid>
    
    </TableContainer>
  );
};


const CardLayout = ({ data, onEdit, clusterByAttribute }) => {
  // Function to group data based on the selected attribute
  const groupData = (data, attribute) => {
    const groupedData = {};
    data.forEach((item) => {
      const key = item[attribute];
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);
    });
    return groupedData;
  };

  // Filter and group data based on the selected attribute
  const groupedData = clusterByAttribute !== 'None' ? groupData(data, clusterByAttribute) : { 'All': data };

  return (
    <div>
      {/* Render cards grouped by the selected attribute */}

      {Object.entries(groupedData).map(([group, items]) => (
        <>
                  <Heading as="h2" size="lg" p="5" mb={4}>{group}</Heading>

        <SimpleGrid columns={{base: 2, md: 4, lg: 6 }} spacing={4} p="5" textAlign={"left"} key={group}>
          {items.map((item) => (
                      <Box
                      maxW={'320px'}
                      w={'full'}
                      rounded={'md'}
                      p={6}
                      bg="teal.50"
                      >
                     
                      <Heading fontSize={'2xl'} fontFamily={'body'}>
                      {item.name}
                      </Heading>
                      <Text fontWeight={600} color={'gray.500'} mb={4}>
                        @lindsey_jam3s
                      </Text>
              
              
    
  
                    </Box>
          ))}
        </SimpleGrid>
        </>
      ))}
    </div>
  );
};


const KanbanLayout = ({ data, onEdit }) => {
  // Transform data into format suitable for Kanban board
  const transformDataForKanban = (data) => {
    // Group data objects based on the importance field
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.importance]) {
        acc[item.importance] = [];
      }
      acc[item.importance].push(item);
      return acc;
    }, {});
  
    // Create swimlanes with grouped data
    const lanes = Object.keys(groupedData).map((importance) => ({
      id: importance.toLowerCase(), // Use importance as the ID for the lane
      title: `${importance} Importance`, // Title for the lane
      cards: groupedData[importance].map((item) => ({
        id: item.id, // Unique ID for the card
        title: item.name, // Name of the card
        description: `Percentage: ${item.percentage}, Value: ${item.value}`, // Description of the card
        metadata: item, // Additional metadata if needed
      })),
    }));
  
    return { lanes };
  };
  
  const customLaneStyle = {
    border: '0',
    backgroundColor: 'initial',
  };
  // Transform data for Kanban board
  const kanbanData = transformDataForKanban(data);

  return (
    <div>
      <h1>Kanban Layout</h1>
      <Board data={kanbanData} />
    </div>
  );
};


const Multiple = () => {
  const [isSearchSelected, setIsSearchSelected] = useState(false);
const [isIconButtonSelected, setIsIconButtonSelected] = useState(false);

  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [clusterByAttribute, setClusterByAttribute] = useState("importance");
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [editCircle, setEditCircle] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    percentage: "",
    importance: "",
    value: "",
    group: "",
    color: "black",
  });
  const [draggingInfo, setDraggingInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [highlightedCircles, setHighlightedCircles] = useState([]);
  const [layout, setLayout] = useState('card');

  // Update filteredData whenever data changes
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };
  
  const handleAddCircle = () => {
    setEditCircle(null);
    setFormValues({
      name: "",
      percentage: "",
      importance: "",
      value: "",
      group: "",
      color: "black",
    });
    onOpen();
  };

  const handleSave = () => {
    if (editCircle) {
      setData(
        data.map((d) =>
          d.id === editCircle.id
            ? {
                ...editCircle,
                ...formValues,
                value: parseInt(formValues.value, 10),
                percentage: parseInt(formValues.percentage, 10),
              }
            : d
        )
      );
    } else {
      const newCircle = {
        id: data.length + 1,
        name: formValues.name,
        percentage: parseInt(formValues.percentage, 10),
        importance: formValues.importance,
        value: parseInt(formValues.value, 10),
        group: formValues.group,
        color: formValues.color,
      };
      setData([...data, newCircle]);
    }
    onClose();
  };

  const handleClusterByAttribute = (e) => {
    setClusterByAttribute(e.target.value);
  };

  const handleCircleClick = (circle) => {
    const selected = selectedOptions.find(
      (option) => option.value === circle.id
    );

    if (!selected) {
      const newSelectedOptions = [
        ...selectedOptions,
        { value: circle.id, label: circle.name },
      ];
      setSelectedOptions(newSelectedOptions);
      setHighlightedCircles(newSelectedOptions.map((option) => option.value));
      setFilteredData(
        data.filter((d) =>
          newSelectedOptions.map((option) => option.value).includes(d.id)
        )
      );
    }
  };

  const handleCircleDoubleClick = (circle) => {
    setEditCircle(circle);
    setFormValues({
      name: circle.name,
      percentage: circle.percentage.toString(),
      importance: circle.importance,
      value: circle.value.toString(),
      group: circle.group,
      color: circle.color,
    });
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdateAttribute = (updatedCircle) => {
    setData(data.map((d) => (d.id === updatedCircle.id ? updatedCircle : d)));
    toast({
      title: `Attribute updated for ${updatedCircle.name}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setHighlightedCircles(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
    setFilteredData(
      selectedOptions.length > 0
        ? data.filter((d) =>
            selectedOptions.map((option) => option.value).includes(d.id)
          )
        : data
    );
  };

  const options = initialData.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const [selectedButton, setSelectedButton] = useState(0);

  const bgColor = useColorModeValue("gray.100", "gray.700");

  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  const handleSearchClick = () => {
    setIsSearchSelected(!isSearchSelected);
  };

  const handleIconButtonClick = () => {
    setIsIconButtonSelected(!isIconButtonSelected);
  };

  return (
    <>
      <Box p="4">
        <SimpleGrid columns={{ base: 1, md: 2 }} mb={1}>
          <Box mb="5"></Box>
          <SimpleGrid columns="2" spacing="1">
            {/* <ButtonGroup spacing={0} borderRadius="lg">
              <IconButton
                aria-label="Import CSV"
                icon={<BiImport />}
                borderRadius={"sm"}
                variant="link"
              />
              <IconButton
                aria-label="Export CSV"
                icon={<FaFileCsv />}
                borderRadius={"sm"}
                variant="link"
              />
            </ButtonGroup> */}
            <ButtonGroup spacing={0} borderRadius="lg" variant="outline">
              <IconButton
                aria-label="Card Layout"
                icon={<LuCreditCard />}
                isActive={selectedButton === 1}
                bg={selectedButton === 0 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange('card')}
                borderRadius={"sm"}
              />
              <IconButton
                aria-label="Kanban Layout"
                icon={<FiColumns />}
                isActive={selectedButton === 1}
                bg={selectedButton === 0 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange('kanban')}
                borderRadius={"sm"}
              />
              <IconButton
                aria-label="Table Layout"
                icon={<FiTable />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange('table')}
              />
              <IconButton
                aria-label="Text Layout"
                icon={<FiType />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange('text')}
              />
               <IconButton
                aria-label="Bubble Layout"
                icon={<AiOutlineDotChart />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange('bubble')}
              />
               <IconButton
                aria-label="List Layout"
                icon={<LuList />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange('list')}
              />
            </ButtonGroup>
            <ButtonGroup w="100%" spacing={2} borderRadius="lg" variant="outline">
    
              <IconButton
                variant="outline"
                borderRadius={"md"}
                onClick={handleSearchClick}
                aria-label="Search"
                icon={<FiSearch />}
              />
              <IconButton
                variant="outline"
                borderRadius={"md"}
                onClick={handleSearchClick}
                aria-label="Group"
                icon={<LuGroup />}
              />
              <IconButton
                              variant="outline"

                aria-label="Export CSV"
                icon={<FaFileCsv />}
                borderRadius={"sm"}
              />
            </ButtonGroup>
          </SimpleGrid>
        </SimpleGrid>
      </Box>
  
      <Divider />
      <Box p={0}>
        <AnimatePresence>
          {isSearchSelected && (
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="5" border="0px" p="5">
              <ReactSelect
                isMulti
                options={options}
                value={selectedOptions}
                onChange={handleSelectChange}
                placeholder="Search..."
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginBottom: "10px",
                    borderRadius: "0px",
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                  }),
                }}
              />
              <Select
                value={clusterByAttribute}
                onChange={handleClusterByAttribute}
              >
                <option value="None">None</option>
                <option value="importance">Importance</option>
                <option value="segment">Segment</option>
                <option value="geography">Geography</option>
                <option value="size">Size</option>
                <option value="techStack">Tech Stack</option>
                <option value="owner">Owner</option>
              </Select>
            </SimpleGrid>
          )}
        </AnimatePresence>
        <Divider mb="0" />
        <Box textAlign={"right"} p="5">
          <Button onClick={handleAddCircle}>Add Contact</Button>
        </Box>
        <Box>
          {isIconButtonSelected && (
            <>
              <ClusteredBubbleChart
                data={data}
                clusterByAttribute={clusterByAttribute}
                onCircleClick={handleCircleClick}
                onCircleDoubleClick={handleCircleDoubleClick}
                onUpdateAttribute={handleUpdateAttribute}
                setDraggingInfo={setDraggingInfo}
                highlightedCircles={highlightedCircles}
              />
            </>
          )}
        </Box>
        {layout === 'card' && <CardLayout data={filteredData} onEdit={handleCircleDoubleClick} clusterByAttribute={clusterByAttribute} />}
        {layout === 'table' && <DataTable data={filteredData} onEdit={handleCircleDoubleClick} defaultColumns={['name', 'percentage', 'importance', 'value', 'group', 'color']} />}
        {layout === 'kanban' && <KanbanLayout data={filteredData} onEdit={handleCircleDoubleClick} />}
        {layout === 'text' && <TextManager data={filteredData} onEdit={handleCircleDoubleClick} />}
        {layout === 'bubble' && <ClusteredBubbleChart
                data={data}
                clusterByAttribute={clusterByAttribute}
                onCircleClick={handleCircleClick}
                onCircleDoubleClick={handleCircleDoubleClick}
                onUpdateAttribute={handleUpdateAttribute}
                setDraggingInfo={setDraggingInfo}
                highlightedCircles={highlightedCircles}
              />}

      </Box>
      <Divider />
      <Box>
        <ImportCsv data={data} setData={setData} />
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{editCircle ? "Edit Circle" : "Add Circle"}</DrawerHeader>
          <DrawerBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Percentage</FormLabel>
              <Input
                name="percentage"
                type="number"
                value={formValues.percentage}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Importance</FormLabel>
              <Select
                name="importance"
                value={formValues.importance}
                onChange={handleInputChange}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Value</FormLabel>
              <Input
                name="value"
                type="number"
                value={formValues.value}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Group</FormLabel>
              <Input
                name="group"
                value={formValues.group}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Color</FormLabel>
              <Select
                name="color"
                value={formValues.color}
                onChange={handleInputChange}
              >
                {['red', 'blue', 'green', 'yellow', 'black'].map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Multiple;