import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ChakraProvider,
  Button,
  useColorMode,
  Switch,
  Flex,
  Text,
  extendTheme,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  InputGroup,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import * as d3 from "d3";
import ReactSelect from "react-select";

const customTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});
// Initial data and other variables omitted for brevity...
const initialData = [
    {
        "id": 1,
        "name": "Task 1",
        "priority": "High",
        "description": "Complete the initial project setup.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-06-15",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "4 hours",
        "tags": ["setup", "project"],
        "color": 'red',
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 2,
        "name": "Task 2",
        "priority": "Medium",
        "description": "Design the homepage layout.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-06-20",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "To Do",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "8 hours",
        "tags": ["design", "homepage"],
        "dependencies": ["Task 1"],
        "color": 'red',
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 3,
        "name": "Task 3",
        "priority": "Low",
        "description": "Develop the user authentication module.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-07-01",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "16 hours",
        "tags": ["development", "authentication"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 4,
        "name": "Task 4",
        "priority": "High",
        "description": "Set up continuous integration.",
        "assignees": ["Brown", "John"],
        "dueDate": "2024-06-25",
        "progress": "Completed",
        "status": "Done",
        "workflow": "Done",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "12 hours",
        "tags": ["CI", "setup"],
        "dependencies": ["Task 1"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 5,
        "name": "Task 5",
        "priority": "Medium",
        "description": "Write unit tests for the API.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-07-05",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Code Review",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "10 hours",
        "tags": ["testing", "API"],
        "dependencies": ["Task 3"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 6,
        "name": "Task 6",
        "priority": "Low",
        "description": "Create database schemas.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-07-10",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "Backlog",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "6 hours",
        "tags": ["database", "design"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 7,
        "name": "Task 7",
        "priority": "High",
        "description": "Implement caching for the API.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-06-30",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Testing",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "14 hours",
        "tags": ["development", "API"],
        "dependencies": ["Task 3"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 8,
        "name": "Task 8",
        "priority": "Medium",
        "description": "Optimize front-end performance.",
        "assignees": ["Brown", "John"],
        "dueDate": "2024-07-15",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "20 hours",
        "tags": ["performance", "frontend"],
        "dependencies": ["Task 2"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 9,
        "name": "Task 9",
        "priority": "Low",
        "description": "Conduct user testing for the new feature.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-07-20",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "To Do",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "12 hours",
        "tags": ["testing", "user"],
        "dependencies": ["Task 5"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 10,
        "name": "Task 10",
        "priority": "High",
        "description": "Prepare project documentation.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-06-18",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "To Do",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "10 hours",
        "tags": ["documentation", "project"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 11,
        "name": "Task 11",
        "priority": "Medium",
        "description": "Refactor existing codebase.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-07-22",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Code Review",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "15 hours",
        "tags": ["refactoring", "code"],
        "dependencies": ["Task 7"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 12,
        "name": "Task 12",
        "priority": "Low",
        "description": "Design email templates for marketing.",
        "assignees": ["Brown", "John"],
        "dueDate": "2024-07-25",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "Backlog",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "8 hours",
        "tags": ["design", "email"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 13,
        "name": "Task 13",
        "priority": "High",
        "description": "Set up monitoring for the server.",
        "assignees": ["John", "Doe"],
        "dueDate": "2024-07-28",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "In Progress",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "12 hours",
        "tags": ["monitoring", "server"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 14,
        "name": "Task 14",
        "priority": "Medium",
        "description": "Implement security best practices.",
        "assignees": ["Doe", "Smith"],
        "dueDate": "2024-07-30",
        "progress": "Not Started",
        "status": "To Do",
        "workflow": "Backlog",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "18 hours",
        "tags": ["security", "best practices"],
        "value": 20,
        "percentage": 70,
    },
    {
        "id": 15,
        "name": "Task 15",
        "priority": "Low",
        "description": "Create a backup strategy.",
        "assignees": ["Smith", "Brown"],
        "dueDate": "2024-07-25",
        "progress": "In Progress",
        "status": "In Progress",
        "workflow": "Testing",
        "creationDate": "2024-06-01",
        "lastUpdated": "2024-06-02",
        "estimatedTime": "5 hours",
        "tags": ["backup", "strategy"],
        "value": 20,
        "percentage": 70,
    }
];



const colorOptions = [
  'red', 'green', 'blue', 'yellow', 'magenta', 'cyan', 'orange', 'purple', 'pink', 'brown', 'black', 'gray'
];

const generateGroups = (data, selectedColumn, width, height) => {
  const uniqueValues = Array.from(new Set(data.map(item => item[selectedColumn])));
  const numGroups = uniqueValues.length;

  return uniqueValues.reduce((groups, value, index) => {
    const x = (index + 1) * (width / (numGroups + 1));
    const y = height / 2;
    groups[value] = { x, y, width: width / (numGroups + 1), height: height - 100 };
    return groups;
  }, {});
};


const ClusteredBubbleChart = ({
  data,
  selectedColumn,
  onCircleDoubleClick,
  onUpdateImportance,
  setDraggingInfo,
  setNewCircle,
  highlightedCircles,
  containerRef,
  clusterByImportance,
}) => {
  const svgRef = useRef(null);
  const [highlightedCluster, setHighlightedCluster] = useState(null);
  const [draggedNode, setDraggedNode] = useState(null);
  const simulationRef = useRef(null);

  const updateCircles = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('circle')
      .attr('stroke', (d) =>
        highlightedCircles.includes(d.id) ? 'black' : 'none'
      )
      .attr('stroke-width', (d) =>
        highlightedCircles.includes(d.id) ? 2 : 0
      )
      .style('opacity', (d) =>
        highlightedCircles.length > 0
          ? highlightedCircles.includes(d.id)
            ? 1
            : 0.2
          : 1
      );
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const container = d3.select(containerRef.current);

    const updateDimensions = () => {
      const width = container.node().clientWidth;
      const height = container.node().clientHeight;
      svg
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("width", width)
        .attr("height", height);

      let dynamicGroups = {};

      if (clusterByImportance) {
        dynamicGroups = generateGroups(data, selectedColumn, width, height);

        // Add boxes for clusters
        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();
        Object.keys(dynamicGroups).forEach((key) => {
          svg
            .append("rect")
            .attr("class", `cluster-box ${key}`)
            .attr("x", dynamicGroups[key].x - width / (Object.keys(dynamicGroups).length + 1) / 2)
            .attr("y", 50)
            .attr("width", width / (Object.keys(dynamicGroups).length + 1))
            .attr("height", height - 150)
            .attr("fill", "none")
            .attr("stroke", "gray")
            .attr("stroke-dasharray", "4")
            .lower();

          svg
            .append("text")
            .attr("class", "label")
            .attr("x", dynamicGroups[key].x)
            .attr("y", 40)
            .attr("text-anchor", "middle")
            .text(key);
        });
      } else {
        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();
      }

      if (simulationRef.current) {
        simulationRef.current.stop();
      }

      const simulation = d3
        .forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(5))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force(
          "collision",
          d3.forceCollide().radius((d) => d.value + 5)
        )
        .alphaDecay(0.1)
        .on("tick", ticked);

      simulationRef.current = simulation;

      if (clusterByImportance) {
        simulation
          .force(
            "x",
            d3.forceX((d) => dynamicGroups[d[selectedColumn]].x).strength(0.5)
          )
          .force(
            "y",
            d3.forceY((d) => dynamicGroups[d[selectedColumn]].y).strength(0.5)
          )
          .alpha(1)
          .restart();
      } else {
        simulation
          .force("x", d3.forceX(width / 2).strength(0.5))
          .force("y", d3.forceY(height / 2).strength(0.5))
          .alpha(1)
          .restart();
      }

      function ticked() {
        const node = svg.selectAll("g").data(data, d => d.id).join(
          enter => {
            const g = enter.append("g")
              .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));

            g.append("circle")
              .attr("r", (d) => d.value)
              .attr("fill", (d) => d.color)
              .attr("stroke", (d) =>
                highlightedCircles.includes(d.id) ? "black" : "none"
              )
              .attr("stroke-width", (d) =>
                highlightedCircles.includes(d.id) ? 2 : 0
              )
              .style("opacity", (d) =>
                highlightedCircles.length > 0
                  ? highlightedCircles.includes(d.id)
                    ? 1
                    : 0.2
                  : 1
              )
              .on("mouseenter", function (event, d) {
                d3.select(this).attr("stroke", "black");
                showTooltip(event, d);
              })
              .on("mouseleave", function (event, d) {
                d3.select(this).attr(
                  "stroke",
                  highlightedCircles.includes(d.id) ? "black" : "none"
                );
                hideTooltip();
              })
              .on("dblclick", function (event, d) {
                onCircleDoubleClick(d);
              });

            g.append("title").text(
              (d) =>
                `Name: ${d.name}\nPercentage: ${d.percentage}%\nImportance: ${d.importance}`
            );

            return g;
          },
          update => update,
          exit => exit.remove()
        );

        node.attr("transform", (d) => {
          if (clusterByImportance) {
            const group = dynamicGroups[d[selectedColumn]];
            d.x = Math.max(group.x - group.width / 2 + d.value, Math.min(group.x + group.width / 2 - d.value, d.x));
            d.y = Math.max(50 + d.value, Math.min(group.y + group.height / 2 - d.value, d.y));
          }
          return `translate(${d.x}, ${d.y})`;
        });
      }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
        setDraggedNode(d);
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;

        const newImportance = getCluster(event.x, dynamicGroups);
        if (newImportance !== highlightedCluster) {
          setHighlightedCluster(newImportance);
          d3.selectAll(".cluster-box").attr("fill", "none");
          d3.select(`.cluster-box.${newImportance}`).attr(
            "fill",
            "rgba(0, 0, 255, 0.1)"
          );
          setDraggingInfo(
            `Circle ${d.name} is moving to ${newImportance} cluster`
          );
        }
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
        setDraggedNode(null);

        const newImportance = getCluster(event.x, dynamicGroups);
        if (d[selectedColumn] !== newImportance) {
          d[selectedColumn] = newImportance;
          onUpdateImportance(d);
          setDraggingInfo(
            `Circle ${d.name} has been moved to ${newImportance} cluster`
          );
        }
        setHighlightedCluster(null);
        d3.selectAll(".cluster-box").attr("fill", "none");

        simulation.alpha(1).restart();
      }

      function showTooltip(event, d) {
        const tooltip = d3
          .select("#tooltip")
          .style("opacity", 1)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`)
          .html(
            `Name: ${d.name}<br>Percentage: ${d.percentage}%<br>Importance: ${d.importance}`
          );
      }

      function hideTooltip() {
        d3.select("#tooltip").style("opacity", 0);
      }

      function getCluster(x, groups) {
        const distances = Object.keys(groups).map((key) => ({
          key,
          distance: Math.abs(x - groups[key].x),
        }));
        if (distances.length === 0) {
          return null; // or a default value
        }
        return distances.reduce((acc, curr) =>
          curr.distance < acc.distance ? curr : acc
        ).key;
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [
    data,
    clusterByImportance,
    selectedColumn,
    highlightedCircles,
    highlightedCluster,
    onUpdateImportance,
    draggedNode,
    setDraggingInfo,
  ]);

  useEffect(() => {
    updateCircles();
  }, [highlightedCircles]);

  return (
    <Box
      ref={containerRef}
      width="100%"
      height="80vh"
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <svg ref={svgRef}></svg>
      <div
        id="tooltip"
        style={{
          position: "absolute",
          opacity: 0,
          background: "lightgray",
          padding: "5px",
          borderRadius: "5px",
          pointerEvents: "none",
        }}
      ></div>
    </Box>
  );
};


const TasksSimple = () => {
  const containerRef = useRef(null); // Define containerRef here
  const [data, setData] = useState(initialData);
  const [selectedColumn, setSelectedColumn] = useState('importance'); // Default column for clustering
  const [clusterByImportance, setClusterByImportance] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [editCircle, setEditCircle] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    percentage: "",
    importance: "",
    value: "",
    group: "",
    color: "black",
  });
  const [draggingInfo, setDraggingInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [highlightedCircles, setHighlightedCircles] = useState([]);

  const handleAddCircle = () => {
    setEditCircle(null);
    setFormValues({
      name: "",
      percentage: "",
      importance: "",
      value: "",
      group: "",
      color: "black",
    });
    onOpen();
  };

  const handleSave = () => {
    if (editCircle) {
      setData(
        data.map((d) =>
          d.id === editCircle.id
            ? {
                ...editCircle,
                ...formValues,
                value: parseInt(formValues.value, 10),
                percentage: parseInt(formValues.percentage, 10),
              }
            : d
        )
      );
    } else {
      const newCircle = {
        id: data.length + 1,
        name: formValues.name,
        percentage: parseInt(formValues.percentage, 10),
        importance: formValues.importance,
        value: parseInt(formValues.value, 10),
        group: formValues.group,
        color: formValues.color,
      };
      setData([...data, newCircle]);
    }
    onClose();
  };

  const handleClusterByImportance = () => {
    setClusterByImportance(!clusterByImportance);
  };

  const handleCircleDoubleClick = (circle) => {
    setEditCircle(circle);
    setFormValues({
      name: circle.name,
      percentage: circle.percentage.toString(),
      importance: circle.importance,
      value: circle.value.toString(),
      group: circle.group,
      color: circle.color,
    });
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const setNewCircle = (name, importance) => {
    const newCircle = {
      id: data.length + 1,
      name,
      percentage: 0,
      importance,
      value: 50,
      group: "New",
      color: "gray",
    };
    setData([...data, newCircle]);
  };

  const handleUpdateImportance = (updatedCircle) => {
    setData(data.map((d) => (d.id === updatedCircle.id ? updatedCircle : d)));
    toast({
      title: `Importance updated for ${updatedCircle.name}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setHighlightedCircles(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const handleColumnChange = (e) => {
    setSelectedColumn(e.target.value);
  };

  const options = initialData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <ChakraProvider theme={customTheme}>
      <Box p={5}>
        <Flex justifyContent="space-between" mb={4}>
          <Button onClick={handleAddCircle}>Add Circle</Button>
          <Button onClick={handleClusterByImportance}>
            {clusterByImportance ? "Uncluster" : "Cluster by Importance"}
          </Button>
          <Flex alignItems="center">
            <Text mr={2}>Dark Mode</Text>
            <Switch
              isChecked={colorMode === "dark"}
              onChange={toggleColorMode}
            />
          </Flex>
        </Flex>
        <Select onChange={handleColumnChange} placeholder="Select Column">
          <option value="priority">Priority</option>
          <option value="dueDate">Due Date</option>
          <option value="progress">Progress</option>
          <option value="status">Status</option>
          <option value="estimatedTime">Estimated Time</option>
          <option value="dependencies">Dependencies</option>
          <option value="workflow">Workflow</option>

        </Select>
        <ReactSelect
          isMulti
          options={options}
          value={selectedOptions}
          onChange={handleSelectChange}
          placeholder="Search and select goals"
          styles={{
            container: (provided) => ({
              ...provided,
              marginBottom: "16px",
            }),
          }}
        />
        <Text mb={4}>{draggingInfo}</Text>
        <ClusteredBubbleChart
          data={data}
          selectedColumn={selectedColumn}
          onCircleDoubleClick={handleCircleDoubleClick}
          onUpdateImportance={handleUpdateImportance}
          setDraggingInfo={setDraggingInfo}
          setNewCircle={setNewCircle}
          highlightedCircles={highlightedCircles}
          containerRef={containerRef}
          clusterByImportance={clusterByImportance}
        />
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {editCircle ? "Edit Circle" : "Add Circle"}
          </DrawerHeader>
          <DrawerBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Percentage</FormLabel>
              <Input
                name="percentage"
                type="number"
                value={formValues.percentage}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Importance</FormLabel>
              <Select
                name="importance"
                value={formValues.importance}
                onChange={handleInputChange}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Value</FormLabel>
              <Input
                name="value"
                type="number"
                value={formValues.value}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Group</FormLabel>
              <Input
                name="group"
                value={formValues.group}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Color</FormLabel>
              <Select
                name="color"
                value={formValues.color}
                onChange={handleInputChange}
              >
                {colorOptions.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default TasksSimple;
