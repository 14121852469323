import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Box, ChakraProvider, theme } from '@chakra-ui/react';
import * as d3 from 'd3';
import useResizeObserver from 'use-resize-observer';

const objects = {
    "flows": [
        { "id": 1, "name": "Sign Up with Email" },
        { "id": 2, "name": "Sign Up with Social Media" },
        { "id": 3, "name": "Add a New Task" },
        { "id": 4, "name": "Edit a Task" },
        { "id": 5, "name": "Invite Collaborator" },
        { "id": 6, "name": "Assign Task to Collaborator" }
    ],
    "projects": [
        { "id": 1, "name": "User Onboarding" },
        { "id": 2, "name": "Security Enhancements" },
        { "id": 3, "name": "Task Features" },
        { "id": 4, "name": "UI/UX Improvements" },
        { "id": 5, "name": "Collaboration Tools" },
        { "id": 6, "name": "Notification System" }
    ],
    "personas": [
        { "id": 1, "name": "Tech-savvy User" },
        { "id": 2, "name": "Privacy-conscious User" },
        { "id": 3, "name": "Busy Professional" },
        { "id": 4, "name": "Student" },
        { "id": 5, "name": "Team Lead" },
        { "id": 6, "name": "Remote Worker" }
    ]
};

const scenarios = [
    {
        "scenario_id": 1,
        "scenario_name": "User Registration",
        "flows": [1, 2],
        "projects": [1, 2],
        "personas": [1, 2]
    },
    {
        "scenario_id": 2,
        "scenario_name": "Login",
        "flows": [3, 4],
        "projects": [3, 4],
        "personas": [3, 4]
    },
    {
        "scenario_id": 3,
        "scenario_name": "Password Reset",
        "flows": [5, 6],
        "projects": [5, 6],
        "personas": [5, 6]
    },
    {
        "scenario_id": 4,
        "scenario_name": "Profile Update",
        "flows": [1, 3],
        "projects": [2, 4],
        "personas": [2, 5]
    },
    {
        "scenario_id": 5,
        "scenario_name": "Task Creation",
        "flows": [4, 6],
        "projects": [3, 6],
        "personas": [1, 6]
    },
    {
        "scenario_id": 6,
        "scenario_name": "Task Assignment",
        "flows": [2, 4],
        "projects": [1, 3],
        "personas": [2, 4]
    },
    {
        "scenario_id": 7,
        "scenario_name": "Task Editing",
        "flows": [1, 5],
        "projects": [2, 5],
        "personas": [3, 5]
    },
    {
        "scenario_id": 8,
        "scenario_name": "Task Deletion",
        "flows": [3, 6],
        "projects": [3, 6],
        "personas": [1, 6]
    },
    {
        "scenario_id": 9,
        "scenario_name": "Collaboration Invitation",
        "flows": [2, 4],
        "projects": [1, 2],
        "personas": [3, 5]
    },
    {
        "scenario_id": 10,
        "scenario_name": "Project Creation",
        "flows": [4, 5],
        "projects": [2, 6],
        "personas": [2, 4]
    }
];

const scenarioColors = d3.scaleOrdinal(d3.schemeCategory10).domain(scenarios.map(d => d.scenario_id));

const ScenarioView = () => {
    const [selectedObject, setSelectedObject] = useState('uncluster');
    const { ref: containerRef, width = 800, height = 600 } = useResizeObserver();

    const handleObjectChange = (selectedOption) => {
        setSelectedObject(selectedOption.value);
    };

    useEffect(() => {
        drawClusters(selectedObject);
    }, [selectedObject, width, height]);

    const drawClusters = (objectType) => {
        const svg = d3.select('#chart')
            .attr('width', width)
            .attr('height', height);

        svg.selectAll('*').remove();

        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('position', 'absolute')
            .style('text-align', 'center')
            .style('width', '120px')
            .style('height', '28px')
            .style('padding', '2px')
            .style('font', '12px sans-serif')
            .style('background', 'lightsteelblue')
            .style('border', '0px')
            .style('border-radius', '8px')
            .style('pointer-events', 'none')
            .style('opacity', 0);

        if (objectType === 'uncluster') {
            const circles = svg.selectAll('circle')
                .data(scenarios)
                .enter().append('circle')
                .attr('r', 15)
                .attr('fill', d => scenarioColors(d.scenario_id))
                .on('mouseover', function (event, d) {
                    tooltip.transition()
                        .duration(200)
                        .style('opacity', .9);
                    tooltip.html(d.scenario_name)
                        .style('left', (event.pageX) + 'px')
                        .style('top', (event.pageY - 28) + 'px');
                })
                .on('mouseout', function () {
                    tooltip.transition()
                        .duration(500)
                        .style('opacity', 0);
                });

            const simulation = d3.forceSimulation(scenarios)
                .force('charge', d3.forceManyBody().strength(5))
                .force('center', d3.forceCenter(width / 2, height / 2))
                .force('collision', d3.forceCollide().radius(20))
                .on('tick', () => {
                    circles
                        .attr('cx', d => d.x)
                        .attr('cy', d => d.y);
                });
        } else {
            const data = objects[objectType];
            const numCols = data.length > 4 ? Math.ceil(data.length / 2) : data.length;
            const numRows = Math.ceil(data.length / numCols);
            const clusterWidth = width / numCols;
            const clusterHeight = height / numRows;

            const clusters = svg.selectAll('.cluster')
                .data(data)
                .enter().append('g')
                .attr('class', 'cluster')
                .attr('transform', (d, i) => `translate(${(i % numCols) * clusterWidth}, ${Math.floor(i / numCols) * clusterHeight})`);

            clusters.append('rect')
                .attr('width', clusterWidth - 20)
                .attr('height', clusterHeight - 20)
                .attr('fill', 'none')
                .attr('stroke', 'lightblue')
                .attr('stroke-width', 2);

            clusters.append('text')
                .attr('x', (clusterWidth - 20) / 2)
                .attr('y', 20)
                .attr('text-anchor', 'middle')
                .text(d => d.name);

            clusters.each(function(d) {
                const cluster = d3.select(this);
                const relevantScenarios = scenarios.filter(scenario => scenario[objectType].includes(d.id));

                const circles = cluster.selectAll('circle')
                    .data(relevantScenarios)
                    .enter().append('circle')
                    .attr('r', 15)
                    .attr('fill', d => scenarioColors(d.scenario_id))
                    .attr('cx', (clusterWidth - 20) / 2)
                    .attr('cy', (clusterHeight - 20) / 2)
                    .on('mouseover', function (event, d) {
                        tooltip.transition()
                            .duration(200)
                            .style('opacity', .9);
                        tooltip.html(d.scenario_name)
                            .style('left', (event.pageX) + 'px')
                            .style('top', (event.pageY - 28) + 'px');
                    })
                    .on('mouseout', function () {
                        tooltip.transition()
                            .duration(500)
                            .style('opacity', 0);
                    });

                const simulation = d3.forceSimulation(relevantScenarios)
                    .force('charge', d3.forceManyBody().strength(5))
                    .force('center', d3.forceCenter((clusterWidth - 20) / 2, (clusterHeight - 20) / 2))
                    .force('collision', d3.forceCollide().radius(20))
                    .on('tick', () => {
                        circles
                            .attr('cx', d => d.x)
                            .attr('cy', d => d.y);
                    });
            });
        }
    };

    return (
        <ChakraProvider theme={theme}>
            <Box p={4} ref={containerRef} style={{ width: '100%', height: '100vh' }}>
                <Select
                    options={[{ value: 'uncluster', label: 'Uncluster' }, ...Object.keys(objects).map(key => ({ value: key, label: key.charAt(0).toUpperCase() + key.slice(1) }))]}
                    onChange={handleObjectChange}
                    defaultValue={{ value: 'uncluster', label: 'Uncluster' }}
                />
                <svg id="chart"></svg>
            </Box>
        </ChakraProvider>
    );
};

export default ScenarioView;
