import React, { useState } from 'react';
import { SimpleGrid, Box, Heading, Text, Divider, useDisclosure } from '@chakra-ui/react';
import SWOTDrawer from './SWOTDrawer';

// Custom Box component with hover effect
const CommonBox = (props) => (
  <Box
    borderWidth="1px"
    w="100%"
    borderRadius="0.5rem"
    transition="all 0.2s"
    m="2"
    p="4"
    _hover={{
      backgroundColor: "blue.500",
      boxShadow: "md",
      transform: "scale(1.00)",
      color: "blue.100"
    }}
    {...props}
  />
);

const SWOTGrid = ({ filteredData = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [drawerContent, setDrawerContent] = useState({ header: '', tabsContent: [] });

  const swotData = filteredData.map((company) => {
    const name = company?.groups?.basic_information?.fields?.name[0] || 'N/A';
    const initials = company?.name || 'N/A';
    const { strength = [], weakness = [], threat = [], opportunities = [] } = company?.groups?.swot_analysis?.fields || {};

    const formatItems = (items) => items.map((item) => `${initials} - ${item}`);

    return {
      name,
      initials,
      strength: formatItems(strength),
      weakness: formatItems(weakness),
      threat: formatItems(threat),
      opportunities: formatItems(opportunities)
    };
  });

  const handleItemClick = (item, type) => {
    setDrawerContent({
      header: `${type} - ${item}`,
      tabsContent: [
        { label: 'Details', content: `Detailed information about ${item}` },
        { label: 'Impact', content: `Impact analysis of ${item}` },
        { label: 'Mitigation', content: `Mitigation strategies for ${item}` },
      ]
    });
    onOpen();
  };

  const renderCommonBox = (item, type, bg, idx) => (
    <CommonBox
      bg={bg}
      p="2"
      mb="1"
      key={idx}
      ml={0}
      onClick={() => handleItemClick(item, type)}
    >
      {item}
    </CommonBox>
  );

  return (
    <>
      <SimpleGrid  columns={{ sm: 1, md: 2}} spacing={2} p="4">
        <Box borderWidth="1px" borderRadius="lg" p={4} bg="white" boxShadow="sm">
          <Heading as="h3" size="md" mb={3}>Strengths</Heading>
          <Divider mb="2" />
          <SimpleGrid columns="3" spacing="2" border="0px">
            {swotData.flatMap((company, index) => 
              company.strength.map((item, idx) =>
                renderCommonBox(item, 'Strength', 'green.100', `${index}-${idx}`)
              )
            )}
          </SimpleGrid>
        </Box>
        <Box borderWidth="1px" borderRadius="lg" p={4} bg="white" boxShadow="sm">
          <Heading as="h3" size="md" mb={3}>Weaknesses</Heading>
          <Divider mb="2" />
          <SimpleGrid columns="3" spacing="2" border="0px">
            {swotData.flatMap((company, index) =>
              company.weakness.map((item, idx) =>
                renderCommonBox(item, 'Weakness', 'red.100', `${index}-${idx}`)
              )
            )}
          </SimpleGrid>
        </Box>
        <Box borderWidth="1px" borderRadius="lg" p={4} bg="white" boxShadow="sm">
          <Heading as="h3" size="md" mb={3}>Opportunities</Heading>
          <Divider mb="2" />
          <SimpleGrid columns="3" spacing="2" border="0px">
            {swotData.flatMap((company, index) =>
              company.opportunities.map((item, idx) =>
                renderCommonBox(item, 'Opportunity', 'yellow.100', `${index}-${idx}`)
              )
            )}
          </SimpleGrid>
        </Box>
        <Box borderWidth="1px" borderRadius="lg" p={4} bg="white" boxShadow="sm">
          <Heading as="h3" size="md" mb={3}>Threats</Heading>
          <Divider mb="2" />
          <SimpleGrid columns="3" spacing="2" border="0px">
            {swotData.flatMap((company, index) =>
              company.threat.map((item, idx) =>
                renderCommonBox(item, 'Threat', 'blackAlpha.100', `${index}-${idx}`)
              )
            )}
          </SimpleGrid>
        </Box>
      </SimpleGrid>
      <SWOTDrawer isOpen={isOpen} onClose={onClose} header={drawerContent.header} tabsContent={drawerContent.tabsContent} />
    </>
  );
};

export default SWOTGrid;
