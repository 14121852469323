import Authenticate_app from "../common/authenticate";
import React, { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  VStack,
  Heading,
  Tag,
} from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import { FiLuruHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";

import {
  GrLuruHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
  GrHome,
  GrHomeRounded,
} from "react-icons/gr";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup, Link } from "@chakra-ui/react";
import Multiple from "./multiple";
import Import from "./import";
import LogoutButton from "./LogoutButton";
import { supabase } from "./supabaseClient";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { TbMapDollar } from "react-icons/tb";
import { FaRegChessKnight } from "react-icons/fa6";
import { MdDesk } from "react-icons/md";

import { LuWorkflow } from "react-icons/lu";
import NavItem from "./components/NavItem";
import ListHome from "./compete/ListHome";

const LuruHome = () => {
  const navigate = useNavigate();
  const [protectedData, setProtectedData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isLeftColumnOpen, setIsLeftColumnOpen] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleLeftColumn = () => {
    setIsLeftColumnOpen(!isLeftColumnOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const fetchProtectedData = async () => {
    console.log("fetchProtectedData");
    try {
      // Retrieve the session using the correct method
      //const { data: session, error } = await supabase.auth.getSession();
      const { data: newData, error } = await supabase.auth.getSession();
      const { session, user } = newData;

      if (error) {
        throw new Error("Unable to get session");
      }

      if (!session) {
        throw new Error("User is not authenticated");
      }

      const token = session.access_token;

      console.log("fetchProtectedData token", token);

      const response = await fetch("https://www.tryatakian.com/api/protected", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch protected data");
      }

      const data = await response.json();
      setProtectedData(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProtectedData();
  }, []);

  const displayText = useBreakpointValue({ base: "none", md: "inline" });

  return (
    <Flex minH="120vh" border="0px" w="100%">
      <Box
        flex={isLeftColumnOpen ? 2 : "0 0 10"}
        transition="flex 0.3s"
        backgroundColor={colorMode === "light" ? "white" : "gray.800"}
        p={6}
        border="0px"
        display={{ base: "none", md: "block" }}
      >
        <HStack>
          <Center>
            <div>
              {/* {protectedData && <pre>{JSON.stringify(protectedData, null, 2)}</pre>} */}
            </div>
            <Image
              size={isLeftColumnOpen ? "md" : "md"}
              w="50px"
              name="Ryan Florence"
              src="/logo.svg"
            />

            <Text
              fontWeight={"light"}
              fontSize={"21"}
              color="gray.500"
              display={isLeftColumnOpen ? 1 : "none"}
              p="4"
            >
              {" "}
              Atakian Corp{" "}
            </Text>
          </Center>
        </HStack>
        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <HStack mt="5" textAlign={"center"}>
          <Avatar
            size={isLeftColumnOpen ? "sm" : "md"}
            name="Ryan Florence"
            src="https://bit.ly/dan-abramov"
          />
          <Text
            fontSize={"21"}
            fontWeight={"light"}
            display={
              isLeftColumnOpen && displayText === "inline" ? "inline" : "none"
            }
            color="gray.500"
            as="Button"
            textAlign={"left"}
            p="4"
          >
            {" "}
            Rohan Sandeep, Lead Manager{" "}
          </Text>{" "}
        </HStack>

        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
  
          <Box mt="5" height="100vh" p={0}>
                  <NavItem
        icon={MdDesk}
        label="Work Center"
        to="/workcenter"
        isLeftColumnOpen={isLeftColumnOpen}
      />
      <NavItem
        icon={FaRegChessKnight}
        label="GTM"
        to="/gtm"
        isLeftColumnOpen={isLeftColumnOpen}
      />
      <NavItem
        icon={LuWorkflow}
        label="Conversion"
        to="/luru"
        isLeftColumnOpen={isLeftColumnOpen}
      />
      <Divider mt="5" />
      <NavItem
        icon={FaRegChessKnight}
        label="Another GTM"
        to="/another-gtm"
        isLeftColumnOpen={isLeftColumnOpen}
      />

          </Box>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
            onClick={() => navigate("/workcenter")} // Inline navigation function
          >
            <Icon as={MdDesk} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Work Center</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={FaRegChessKnight} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>GTM </Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "blue.100" : "blue.900"}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={LuWorkflow} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Conversion </Text>
          </HStack>

          <Divider mt="5" />

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={FaRegChessKnight} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>GTM </Text>
          </HStack>
        </Box>


      <Box
        flex={6}
        backgroundColor={colorMode === "light" ? "gray.100" : "gray.700"}
        p={2}
      >
        {/* <HStack>
          <FullWidthDrawer />

          <Avatar name="Ryan Florence" src="/Birdeyebird.png" size="sm" />
          <Text
            fontWeight={"light"}
            fontSize={"21"}
            color="gray.500"
            display={1}
            lineHeight={1}
            p="4"
          >
            Stone Creek Communities
          </Text>
        </HStack> */}

        <Flex display="none" justifyContent="flex-end" 
        
        
        >
          <Button
            variant="outline"
            leftIcon={<ChevronLeftIcon />}
            onClick={toggleLeftColumn}
            mr={4}
          ></Button>
          <IconButton
            aria-label="Toggle Color Mode"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
          />
        </Flex>

        <>
          <SimpleGrid columns="3" mt="19" display="none">
            <Box>
              <Text
                fontWeight={"light"}
                fontSize={"21"}
                color={colorMode === "light" ? "gray.700" : "gray.200"}
              >
                Conversion Workbench 1
              </Text>
            </Box>
            <Spacer />
            <Box>
              {" "}
              <LogoutButton />{" "}
            </Box>
          </SimpleGrid>

          <Box p="0">
            <Import />
            {/* <ListHome /> */}
          </Box>
        </>
      </Box>
    </Flex>
  );
};

export default LuruHome;
