import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import {
  Box,
  ChakraProvider,
  Button,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";

const initialData = [
  { id: 1, name: 'A', value: 20, importance: 'High', segment: 'Retail' },
  { id: 2, name: 'B', value: 25, importance: 'Medium', segment: 'Finance' },
  { id: 3, name: 'C', value: 30, importance: 'Low', segment: 'Healthcare' },
];

const colorOptions = ['red', 'green', 'blue', 'yellow', 'magenta'];

const ClusteredBubbleChart = ({ data, clusterByAttribute, onCircleDoubleClick, onUpdateAttribute }) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const container = d3.select(containerRef.current);

    const updateDimensions = () => {
      const width = container.node().clientWidth;
      const height = container.node().clientHeight;

      svg.attr("viewBox", `0 0 ${width} ${height}`).attr("width", width).attr("height", height);

      const uniqueValues = Array.from(new Set(data.map(d => d[clusterByAttribute])));
      const attributeGroups = {};
      
      uniqueValues.forEach((value, index) => {
        attributeGroups[value] = {
          x: (index + 1) * (width / (uniqueValues.length + 1)),
          y: height / 2,
        };
      });

      const simulation = d3.forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(5))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force("collision", d3.forceCollide().radius(d => d.value + 5))
        .on("tick", ticked);

      if (clusterByAttribute) {
        simulation
          .force("x", d3.forceX(d => attributeGroups[d[clusterByAttribute]].x).strength(0.5))
          .force("y", d3.forceY(d => attributeGroups[d[clusterByAttribute]].y).strength(0.5))
          .alpha(1).restart();
      } else {
        simulation
          .force("x", d3.forceX(width / 2).strength(0.1))
          .force("y", d3.forceY(height / 2).strength(0.1))
          .alpha(1).restart();
      }

      const node = svg.selectAll("circle").data(data, d => d.id).join(
        enter => {
          const circle = enter.append("circle")
            .attr("r", d => d.value)
            .attr("fill", "blue")
            .call(
              d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended)
            );

          circle.append("title").text(d => d.name);
          return circle;
        },
        update => update,
        exit => exit.remove()
      );

      function ticked() {
        node.attr("cx", d => d.x).attr("cy", d => d.y);
      }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [data, clusterByAttribute]);

  return (
    <Box ref={containerRef} width="100%" height="500px" position="relative" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <svg ref={svgRef}></svg>
    </Box>
  );
};

const Drop = () => {
  const [data, setData] = useState(initialData);
  const [clusterByAttribute, setClusterByAttribute] = useState('importance');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = useState({ name: "", value: "", importance: "", segment: "" });

  const handleAddCircle = () => {
    setFormValues({ name: "", value: "", importance: "", segment: "" });
    onOpen();
  };

  const handleSave = () => {
    const newCircle = {
      id: data.length + 1,
      name: formValues.name,
      value: parseInt(formValues.value, 10),
      importance: formValues.importance,
      segment: formValues.segment,
    };
    setData([...data, newCircle]);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <ChakraProvider>
      <Box p={5}>
        <Button onClick={handleAddCircle}>Add Circle</Button>
        <Select value={clusterByAttribute} onChange={e => setClusterByAttribute(e.target.value)}>
          <option value="importance">Importance</option>
          <option value="segment">Segment</option>
        </Select>
        <ClusteredBubbleChart data={data} clusterByAttribute={clusterByAttribute} />
      </Box>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Circle</DrawerHeader>
          <DrawerBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={formValues.name} onChange={handleInputChange} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Value</FormLabel>
              <Input name="value" type="number" value={formValues.value} onChange={handleInputChange} />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Importance</FormLabel>
              <Select name="importance" value={formValues.importance} onChange={handleInputChange}>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Segment</FormLabel>
              <Select name="segment" value={formValues.segment} onChange={handleInputChange}>
                <option value="Retail">Retail</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>Save</Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default Drop;
