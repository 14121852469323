import React, { useEffect, useRef, useState } from 'react';
import { SimpleGrid, Box, Text } from '@chakra-ui/react';
import * as d3 from 'd3';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Define the item type for React DnD
const ItemTypes = {
  BUBBLE: 'BUBBLE',
};

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// DraggableBubble component
const DraggableBubble = ({ data, onDragStart, onDragEnd }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.BUBBLE,
    item: { id: data.id, cluster: data.cluster },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => onDragEnd(item, monitor.didDrop()),
  });

  useEffect(() => {
    if (isDragging) {
      onDragStart(data.id);
    }
  }, [isDragging, data.id, onDragStart]);

  console.log(`Rendering bubble: ${data.id} at (${data.x}, ${data.y})`);

  return (
    <div
      ref={drag}
      style={{
        position: 'absolute',
        left: `${data.x}px`,
        top: `${data.y}px`,
        width: `${data.radius * 1}px`,
        height: `${data.radius * 1}px`,
        backgroundColor: data.color || 'red',
        borderRadius: '50%',
        opacity: isDragging ? 0.5 : 1,
        transform: 'translate(-50%, -50%)',
        zIndex: isDragging ? 1000 : 'auto',
      }}
    />
  );
};

// BubbleChart component
const BubbleChart = ({ data, clusterName, onDrop }) => {
  const [bubbles, setBubbles] = useState(data);

  const svgRef = useRef(null);

  useEffect(() => {
    const container = document.getElementById(`cluster-${clusterName}`);
    const width = container.clientWidth;
    const height = container.clientHeight;
    const maxRadius = 30; // Maximum radius for bubbles

    console.log(`Initializing cluster: ${clusterName}, width: ${width}, height: ${height}`);

    // Use d3.pack to compute initial positions
    const pack = d3.pack()
      .size([width, height])
      .padding(2);

    const root = d3.hierarchy({ children: data })
      .sum((d) => d.value);

    const nodes = pack(root).leaves();

    data.forEach((d, i) => {
      d.x = nodes[i].x;
      d.y = nodes[i].y;
      d.radius = Math.min(nodes[i].r, maxRadius);
      d.color = getRandomColor();

      console.log(`Positioning bubble ${d.id} at (${d.x}, ${d.y}) with radius ${d.radius}`);
    });

    const keepInBounds = () => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        node.x = Math.max(node.radius, Math.min(width - node.radius, node.x));
        node.y = Math.max(node.radius, Math.min(height - node.radius, node.y));
      }
    };

    const simulation = d3.forceSimulation(data)
      .force('charge', d3.forceManyBody().strength(-30))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide().radius((d) => d.radius + 1))
      .on('tick', () => {
        keepInBounds();
        setBubbles([...data]);
      });

    return () => simulation.stop();
  }, [data, clusterName]);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.BUBBLE,
    drop: (item) => onDrop(item.id, clusterName),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const handleDragStart = (id) => {
    console.log(`Drag started for bubble with id: ${id}`);
  };

  const handleDragEnd = (item, didDrop) => {
    if (!didDrop) {
      console.log(`Drag ended for bubble with id: ${item.id}, but it did not drop.`);
    } else {
      console.log(`Bubble with id: ${item.id} was dropped.`);
    }
  };

  return (
    <Box
    ref={drop}
    id={`cluster-${clusterName}`}
    position="relative"
    width="100%"
    height="100%"
    transition="background-color 0.3s"
    borderWidth="1px"
    borderColor="gray.200"
    borderRadius="md"
  >
    <Text
      position="absolute"
      top="10px"
      left="50%"
      transform="translateX(-50%)"
      fontWeight="bold"
    >
      {clusterName || 'Undefined'}
    </Text>
    {bubbles.map((d) => (
      <DraggableBubble
        key={d.id}
        data={d}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      />
    ))}
    {isOver && (
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0,0,0,0.1)"
      ></Box>
    )}
  </Box>
  );
};

// ClusterGrid component
const ClusterGrid = ({ data, clusterByAttribute, onDrop }) => {
  const clusters = Array.from(new Set(data.map((item) => item[clusterByAttribute])));

  const getClusterData = (clusterName) => {
    return data.filter((item) => item[clusterByAttribute] === clusterName);
  };

  return (
    <SimpleGrid columns={2} spacing={10}>
      {clusters.map((clusterName, index) => (
        <Box
          key={index}
          border="1px solid #ccc"
          p="5"
          width="100%"
          height="400px"
        >
          <BubbleChart
            data={getClusterData(clusterName)}
            clusterName={clusterName}
            onDrop={onDrop}
          />
        </Box>
      ))}
    </SimpleGrid>
  );
};

// MainComponent
const MainComponent = ({ data, setData, clusterByAttribute, attributeMapping  }) => {
  console.log("data", data, "obeline", typeof data)
  const [setBubbles] = useState([data]);

  const handleDrop_1 = (id, newCluster) => {
    const updatedData = data.map((item) =>
      item.id === id ? { ...item, [clusterByAttribute]: newCluster } : item
    );
    console.log(updatedData);
    // Handle this updated data accordingly
  };

  const handleDrop = (id, newCluster) => {
    const updatedBubbles = data.map((bubble) =>
      bubble.id === id ? { ...bubble, [clusterByAttribute]: newCluster } : bubble
    );
    console.log(`Bubble ${id} moved to cluster ${newCluster}`);
    console.log(updatedBubbles);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ClusterGrid
        data={data}
        clusterByAttribute={clusterByAttribute}
        onDrop={handleDrop}
      />
    </DndProvider>
  );
};

export default MainComponent;
