import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { Box, Text, Center } from '@chakra-ui/react';

const SunburstChart = ({ data }) => {
    const ref = useRef();
    const boxRef = useRef();
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

    const updateDimensions = () => {
        const newWidth = boxRef.current ? boxRef.current.offsetWidth : window.innerWidth;
        const newHeight = boxRef.current ? boxRef.current.offsetHeight : window.innerHeight;
        setDimensions({ width: newWidth, height: newHeight });
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        updateDimensions(); // Update dimensions initially
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        if (!data || !data.children || data.children.length === 0) {
            console.log("No data or children available");
            return;
        }

        const width = dimensions.width;
        const height = dimensions.height;
        const radius = Math.min(width, height) / 2;
        const color = d3.scaleOrdinal(d3.schemeCategory10);

        const partition = d3.partition()
            .size([Math.PI, radius * radius]);

        const root = d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);

        partition(root);

        const arc = d3.arc()
            .startAngle(d => d.x0)
            .endAngle(d => d.x1)
            .innerRadius(d => Math.sqrt(d.y0))
            .outerRadius(d => Math.sqrt(d.y1));

        const svg = d3.select(ref.current)
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);

        const path = svg.selectAll("path")
            .data(root.descendants().filter(d => d.depth))
            .enter().append("path")
            .attr("d", arc)
            .style("stroke", "#fff")
            .style("fill", d => color((d.children ? d : d.parent).data.name))
            .style("fill-rule", "evenodd")
            .on("click", (event, d) => {
                setSelectedScenario(d.data.name);
            });

        path.append("title")
            .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${d.value}`);

        return () => {
            d3.select(ref.current).selectAll('*').remove();
        };
    }, [data, dimensions]);

    return (
        <>
            <Box ref={boxRef} height="80vh" border="0px" marginTop="-24" p="0">
                <svg ref={ref}></svg>
                {selectedScenario && (
                    <Center position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                        <Box p="4" bg="white" borderWidth="1px" borderRadius="lg" shadow="md">
                            <Text fontSize="lg" fontWeight="bold">{selectedScenario}</Text>
                        </Box>
                    </Center>
                )}
            </Box>
        </>
    );
};

export default SunburstChart;
