import Authenticate_app from "../common/authenticate";
import React, { useState } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion, useAnimation, useViewportScroll } from "framer-motion";
import { useEffect } from "react";
import { BiMobileAlt, BiQr, BiCertification, BiStar } from "react-icons/bi";
import {
  GrFingerPrint,
  GrPlay,
  GrLogin,
  GrShop,
  GrNotification,
  GrCertificate,
  GrAlert,
  GrCircleAlert,
} from "react-icons/gr";
import { HiOutlineSearchCircle, HiOutlineHand } from "react-icons/hi";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { IoCalendarClearOutline } from "react-icons/io5";

import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiArrowRight, FiHome, FiArrowLeft } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SunIcon,
  MoonIcon,
} from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import { CloseIcon, CheckCircleIcon, InfoIcon } from "@chakra-ui/icons";
import IngredientsSearch from "./components/ingredientsSearch";
import ExpiryAlert from "./components/expiryAlert";
import WelcomeForm from "../welcome";
import Welcome from "../homepage/welcome";

const FullDemo = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  // this is for hiding and displaying content
  const [selectedItem, setSelectedItem] = useState(null);

  // this is for hiding the Product valdiation box and displaying a different one
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const menuItems = [
    {
      id: 1,
      icon: BiMobileAlt,
      title: "Experince on you Mobile Device",
      description: "Highly adviced to experience the demo on mobile",
    },
    {
      id: 2,
      icon: BiQr,
      title: "QR tag or NFC tag",
      description:
        "Customer accesses the post purchase experience through QR tag or NFC tag",
    },
    {
      id: 3,
      icon: GrFingerPrint,
      title: "Product Validation",
      description:
        "Serialised product validation prevent counterfieting and ensures product authenticity",
    },
    {
      id: 4,
      icon: HiOutlineSearchCircle,
      title: "Product Ingredients search",
      description: "Search for product ingredients with their benefits",
    },
    {
      id: 5,
      icon: GrNotification,
      title: "Expiry Alerts ",
      description:
        "Customers elect to receive expiry alerts for their products",
    },
    {
      id: 6,
      icon: FiStar,
      title: "Reviews and Feedback",
      description: "Reviews and feedback on the product",
    },
    {
      id: 7,
      icon: HiOutlineHand,
      title: "Product usage and guides",
      description: "Reviews and feedback on the product",
    },
    {
      id: 8,
      icon: GrLogin,
      title: "Post Login Experience",
      description: "Reviews and feedback on the product",
    },
    {
      id: 9,
      icon: GrCircleAlert,
      title: "Error Scenario and  Misuse",
      description: "Reviews and feedback on the product",
    },
    {
      id: 10,
      icon: GrPlay,
      title: "Demo",
      description: "Request Demo",
    },
  ];

  const renderContent = (itemId) => {
    switch (itemId) {
      case 1:
        return (
          <Box bg="blue.100" p={4}>
            Demo Coming soon
          </Box>
        );
      case 2:
        return (
          <Box p={4}>
            <Mobile1 />
          </Box>
        );
      case 3:
        return (
          <Box p={4}>
            <Mobile1 />
          </Box>
        );
      case 4:
        return (
          <Box p={4}>
            {" "}
            <Mobile2 />
          </Box>
        );
      case 5:
        return (
          <Box p={4}>
            {" "}
            <Mobile3 />
          </Box>
        );
      case 6:
        return (
          <Box bg="red.100" p={4}>
            Demo Coming soon
          </Box>
        );
      case 7:
        return (
          <Box bg="red.100" p={4}>
            Demo Coming soon
          </Box>
        );
      case 8:
        return (
          <Box bg="red.100" p={4}>
            Demo coming soon
          </Box>
        );
      case 10:
        return <WelcomeForm />;
      default:
        return (
          <Box p={4}>
            <Heading fontWeight={"regular"}>Product Video Demo</Heading>
            <Text p="4" pl="0">
              Welcome to Atakian Engage, the ultimate solution for transforming
              your interaction with beauty products.
            </Text>

            <Text display="none">
              Our platform offers a seamless and engaging experience, for your
              customers. With features like anti-counterfeiting measures,
              product authenticity verification, ingredient transparency, and
              customer feedback integration.
            </Text>

            <iframe
              width="100%"
              height="600"
              src="https://www.youtube.com/embed/YFHyFlHdvnM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            ></iframe>
            <Divider mt="10" />

            <Box>
              <WelcomeForm />
            </Box>

            <Divider />

            <SimpleGrid columns={{ base: 2, md: 2 }} spacing={10} p={2} mt="10">
              <Box
                border="1px"
                borderColor="gray.100"
                bg="white"
                borderRadius={"md"}
                p="2"
              >
                <Text
                  mb="2"
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"13"}
                >
                  Anti-counterfeiting{" "}
                </Text>
                <Text>
                  Brands can now leverage advanced anti-counterfeiting measures
                  to ensure their products' authenticity and integrity. This
                  feature helps prevent the production and distribution of fake
                  or unauthorized replicas, safeguarding both the brand and its
                  customers. By implementing robust anti-counterfeiting
                  strategies, brands can maintain consumer trust, enhance
                  product reliability, and protect their reputation in the
                  market.
                </Text>
              </Box>

              <Box
                border="1px"
                borderColor="gray.100"
                bg="white"
                borderRadius={"md"}
                p="2"
              >
                <Text
                  mb="2"
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"13"}
                >
                  Ingredients search
                </Text>
                <Text>
                  {" "}
                  Customers can effortlessly verify and explore the ingredients
                  in their beauty products to ensure safety, effectiveness, and
                  alignment with their personal preferences and regulatory
                  standards. With our intuitive ingredients search feature, your
                  customers can make informed decisions and trust the products
                  they apply to their skin.
                </Text>
              </Box>

              <Box
                border="1px"
                borderColor="gray.100"
                bg="white"
                borderRadius={"md"}
                p="2"
              >
                <Text
                  mb="2"
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"13"}
                >
                  First party data
                </Text>
                <Text>
                  Brands can now gain access to first-hand data while ensuring
                  full compliance with regulatory standards. This feature allows
                  brands to collect, analyze, and utilize customer data
                  responsibly, helping them understand consumer preferences and
                  behavior better. By adhering to strict compliance protocols,
                  brands can build trust and transparency, ultimately enhancing
                  customer satisfaction and loyalty. skin.
                </Text>
              </Box>

              <Box
                border="1px"
                borderColor="gray.100"
                bg="white"
                borderRadius={"md"}
                p="2"
              >
                <Text
                  mb="2"
                  textTransform={"uppercase"}
                  fontWeight={"bold"}
                  fontSize={"13"}
                >
                  Expiry Alerts
                </Text>
                <Text>
                  Ensure your customers always use fresh and effective products
                  with our new Product Expiry Alerts feature. Brands can now
                  notify customers about upcoming product expiration dates,
                  helping them manage their inventory and avoid using outdated
                  products. This proactive approach not only enhances customer
                  safety and satisfaction but also reinforces trust and
                  reliability in your brand.
                </Text>
              </Box>
            </SimpleGrid>
          </Box>
        );
    }
  };

  return (
    <>
      <Flex minH="120vh">
        <Box
          flex={isCollapsed ? "0" : "2"}
          bgGradient="linear(to-b, facebook.500, facebook.700)"
          p={4}
          display={isCollapsed ? "none" : "block"}
        >
          <Box textAlign={"right"}>
            <IconButton
              icon={<FiArrowLeft />}
              onClick={() => setIsCollapsed(true)}
              aria-label="Collapse"
              variant="outline"
              color="facebook.100"
              borderColor="facebook.100"
              mb={4}
            />
          </Box>
          <Box>
            <Text color="whiteAlpha.900" fontSize={"19"}>
              This guide introduces you to the features of Atakian Engage, a
              Post-purchase engagement tool that enhances customer satisfaction
              and loyalty for Cosmetics and skin care products.
            </Text>
          </Box>
          <Box mt="5">
            <List spacing={3}>
              {menuItems.map((item) => (
                <ListItem
                  key={item.id}
                  onClick={() => setSelectedItem(item.id)}
                  cursor="pointer"
                  p={4}
                  _hover={{ bg: "facebook.700" }}
                  bg={selectedItem === item.id ? "facebook.900" : "transparent"}
                  borderRadius="md"
                >
                  <Flex align="center">
                    <ListIcon
                      as={item.icon}
                      boxSize={9}
                      color="whiteAlpha.900"
                    />
                    <VStack align="start" spacing={0} ml={2}>
                      <Text fontWeight="regular" color="whiteAlpha.900">
                        {item.title}
                      </Text>
                      <Text fontSize="sm" color="whiteAlpha.900">
                        {item.description}
                      </Text>
                    </VStack>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Box flex={isCollapsed ? "1" : "5"} bg="gray.50" p={4}>
          <IconButton
            icon={<FiArrowRight />}
            onClick={() => setIsCollapsed(false)}
            aria-label="Collapse"
            mb={4}
            variant="outline"
            color="facebook.700"
            borderColor="facebook.700"
            display={isCollapsed ? "block" : "none"}
          />

          <Box flex="1" p={4}>
            {renderContent(selectedItem)}
          </Box>
        </Box>
      </Flex>
    </>
  );
};

function Mobile1() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);

  return (
    <Flex minH="100vh" p={4}>
      <Box flex="1" p={4}>
        {/* <HStack color="black" mt="220" w="100%">
          tewnty percent of the screen width
          <Text color="gray.400">tap here </Text>
          <FiArrowRight />
        </HStack> */}
      </Box>

      {isAuthenticated ? (
        <Box
          width={["100%", "100%", "420px"]}
          border="1px"
          borderColor="gray.200"
          p={0}
          color="black"
          shadow={"xl"}
          borderRadius={"md"}
          bg="white"
        >
          <Box p="5" textAlign={"right"}>
            {/* Content to display after authentication */}

            <IconButton
              variant="outline"
              icon={<CloseIcon />}
              onClick={() => setIsAuthenticated(false)}
            >
              {" "}
            </IconButton>
          </Box>
          <Box p="5">
            <Authenticate_app />
          </Box>
        </Box>
      ) : (
        <Box
          width={["100%", "100%", "420px"]}
          border="1px"
          borderColor="gray.200"
          p={0}
          color="black"
          shadow={"xl"}
          borderRadius={"md"}
          position={"relative"}
        >
          <Box p="5" position="absolute">
            {isPurchased ? (
              <Box
                bg="facebook.900"
                color="white"
                ml="180"
                borderRadius={"md"}
                shadow={"md"}
              >
                <HStack p="2">
                  <GrCertificate />
                  <Text color="white">Marked as purchased</Text>
                </HStack>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Image
            src="/demo/productimage.png"
            alt="product image"
            borderTopRadius={"md"}
          />

          <Box border="0px" p="2">
            {" "}
            <Button
              borderRadius={"sm"}
              bg="blackAlpha.800"
              color="whiteAlpha.700"
              w="100%"
              fontWeight={"regular"}
              onClick={() => setIsAuthenticated(true)}
            >
              {" "}
              Authenticate Product{" "}
            </Button>
            <HStack color="black" w="100%" ml="-200" mt="-7">
              <Text color="gray.400">tap here </Text>
              <FiArrowRight />
            </HStack>
          </Box>
          <Box border="0px" p="2">
            {" "}
            <Text
              fontSize={"23"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              textAlign={"center"}
            >
              Special bold hair spary{" "}
            </Text>
            <Text fontSize={"21"} fontWeight={"medium"} textAlign={"center"}>
              Glassy Pale{" "}
            </Text>
            <Text mt="2" p="2" textAlign={"center"}>
              A serum-based, high-pigment blush with 70%+ skincare and
              proprietary complexes that hydrate and make skin look more plump
              in a longwear, glassy balm.
            </Text>
            <Box border="0px" p="2">
              {" "}
              <Button
                borderRadius={"sm"}
                bg="blackAlpha.500"
                color="whiteAlpha.900"
                w="100%"
                fontWeight={"regular"}
              >
                {" "}
                Inform me before the Product Expiry date{" "}
              </Button>
              <HStack p="3" m="1" mt="5">
                <Box
                  p="2"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("ingredients")}
                >
                  <Center>
                    <PiBowlFoodLight color="#000" size="41" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                    >
                      Ingredients{" "}
                    </Text>
                  </Center>
                </Box>

                <Box
                  p="2"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("benefits")}
                >
                  <Center>
                    <GoNorthStar color="#000" size="41" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                    >
                      Benefits{" "}
                    </Text>
                  </Center>
                </Box>
              </HStack>
              <HStack p="2" m="0" mt="-5">
                <Box
                  p="5"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("usingProduct")}
                >
                  <Center>
                    <TfiHandStop color="#000" size="41" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                    >
                      Using the product{" "}
                    </Text>
                  </Center>
                </Box>
              </HStack>
              <HStack p="2" m="1" mt="-2">
                <Box
                  p="5"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("standards")}
                >
                  <Center>
                    <GrCertificate size="22" color="#000" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                      textAlign={"center"}
                    >
                      Product standards{" "}
                    </Text>
                  </Center>
                </Box>

                <Box
                  p="2"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("reviews")}
                >
                  <Center>
                    <BiStar size="22" color="#000" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                      textAlign={"center"}
                    >
                      Reviews and Feedback{" "}
                    </Text>
                  </Center>
                </Box>
              </HStack>
            </Box>
          </Box>
        </Box>
      )}

      <Box flex="1" p={4}>
        <Text color="black"></Text>
      </Box>
    </Flex>
  );
}

function Mobile2() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPurchased, setIsPurchased] = useState(true);
  const [isIngredientsClicked, setIsIngredientsClicked] = useState(false);

  return (
    <Flex minH="100vh" p={4}>
      <Box flex="1" p={4}>
        {/* <HStack color="black" mt="220" w="100%">
          tewnty percent of the screen width
          <Text color="gray.400">tap here </Text>
          <FiArrowRight />
        </HStack> */}
      </Box>

      {isAuthenticated ? (
        <Box
          width={["100%", "100%", "420px"]}
          border="1px"
          borderColor="gray.200"
          p={0}
          color="black"
          shadow={"xl"}
          borderRadius={"md"}
          bg="white"
        >
          <Box p="5" textAlign={"right"}>
            {/* Content to display after authentication */}

            <IconButton
              variant="outline"
              icon={<CloseIcon />}
              onClick={() => setIsAuthenticated(false)}
            >
              {" "}
            </IconButton>
          </Box>
          <Box p="5">
            <Authenticate_app />
          </Box>
        </Box>
      ) : (
        <>
          {isIngredientsClicked ? (
            <>
              <Box
                width={["100%", "100%", "420px"]}
                border="1px"
                borderColor="gray.200"
                p={0}
                color="black"
                shadow={"xl"}
                borderRadius={"md"}
                position={"relative"}
              >
                <Box p="5" textAlign={"right"}>
                  {/* Content to display after authentication */}

                  <IconButton
                    variant="outline"
                    icon={<CloseIcon />}
                    onClick={() => setIsIngredientsClicked(false)}
                  >
                    {" "}
                  </IconButton>
                </Box>

                <IngredientsSearch />
              </Box>
            </>
          ) : (
            <Box
              width={["100%", "100%", "420px"]}
              border="1px"
              borderColor="gray.200"
              p={0}
              color="black"
              shadow={"xl"}
              borderRadius={"md"}
              position={"relative"}
            >
              <Box p="5" position="absolute">
                {isPurchased ? (
                  <Box
                    bg="facebook.900"
                    color="white"
                    ml="180"
                    borderRadius={"md"}
                    shadow={"md"}
                  >
                    <HStack p="2">
                      <GrShop />
                      <Text color="white">Marked as purchased</Text>
                    </HStack>
                  </Box>
                ) : (
                  <> if </>
                )}
              </Box>
              <Image
                src="/demo/productimage.png"
                alt="product image"
                borderTopRadius={"md"}
              />

              <Box border="0px" p="2">
                {" "}
                <Button
                  borderRadius={"sm"}
                  bg="blackAlpha.800"
                  color="whiteAlpha.700"
                  w="100%"
                  fontWeight={"regular"}
                  // onClick={() => setIsAuthenticated(true)}
                >
                  {" "}
                  Login{" "}
                </Button>
              </Box>
              <Box border="0px" p="2">
                {" "}
                <Text
                  fontSize={"23"}
                  textTransform={"uppercase"}
                  fontWeight={"extrabold"}
                  textAlign={"center"}
                >
                  Special bold hair spary{" "}
                </Text>
                <Text
                  fontSize={"21"}
                  fontWeight={"medium"}
                  textAlign={"center"}
                >
                  Glassy Pale{" "}
                </Text>
                <Text mt="2" p="2" textAlign={"center"}>
                  A serum-based, high-pigment blush with 70%+ skincare and
                  proprietary complexes that hydrate and make skin look more
                  plump in a longwear, glassy balm.
                </Text>
                <Box border="0px" p="2">
                  {" "}
                  <Button
                    borderRadius={"sm"}
                    bg="blackAlpha.500"
                    color="whiteAlpha.900"
                    w="100%"
                    fontWeight={"regular"}
                  >
                    {" "}
                    Inform me before the Product Expiry date{" "}
                  </Button>
                  <HStack p="3" m="1" mt="5">
                    <Box
                      p="2"
                      borderRadius={"lg"}
                      w="100%"
                      onClick={() => setIsIngredientsClicked(true)}
                      // onClick={() => openModal("ingredients")}
                    >
                      <Center>
                        <PiBowlFoodLight color="#000" size="41" />
                      </Center>
                      <Center p="2">
                        <Text
                          fontSize={{ base: "13px", md: "17", lg: "19" }}
                          textTransform={"uppercase"}
                        >
                          <Button variant="link">Ingredients </Button>
                        </Text>
                      </Center>

                      <Box position="relative">
                        <HStack
                          color="black"
                          postion="absolute"
                          w="100%"
                          ml="-150"
                          mt="-25"
                        >
                          <Text color="gray.400">tap here </Text>
                          <FiArrowRight />
                        </HStack>
                      </Box>
                    </Box>

                    <Box
                      p="2"
                      borderRadius={"lg"}
                      w="100%"
                      // onClick={() => openModal("benefits")}
                    >
                      <Center>
                        <GoNorthStar color="#000" size="41" />
                      </Center>
                      <Center p="2">
                        <Text
                          fontSize={{ base: "13px", md: "17", lg: "19" }}
                          textTransform={"uppercase"}
                        >
                          Benefits{" "}
                        </Text>
                      </Center>
                    </Box>
                  </HStack>
                  <HStack p="2" m="0" mt="-5">
                    <Box
                      p="5"
                      borderRadius={"lg"}
                      w="100%"
                      // onClick={() => openModal("usingProduct")}
                    >
                      <Center>
                        <TfiHandStop color="#000" size="41" />
                      </Center>
                      <Center p="2">
                        <Text
                          fontSize={{ base: "13px", md: "17", lg: "19" }}
                          textTransform={"uppercase"}
                        >
                          Using the product{" "}
                        </Text>
                      </Center>
                    </Box>
                  </HStack>
                  <HStack p="2" m="1" mt="-2">
                    <Box
                      p="5"
                      borderRadius={"lg"}
                      w="100%"
                      // onClick={() => openModal("standards")}
                    >
                      <Center>
                        <GrCertificate size="22" color="#000" />
                      </Center>
                      <Center p="2">
                        <Text
                          fontSize={{ base: "13px", md: "17", lg: "19" }}
                          textTransform={"uppercase"}
                          textAlign={"center"}
                        >
                          Product standards{" "}
                        </Text>
                      </Center>
                    </Box>

                    <Box
                      p="2"
                      borderRadius={"lg"}
                      w="100%"
                      // onClick={() => openModal("reviews")}
                    >
                      <Center>
                        <BiStar size="22" color="#000" />
                      </Center>
                      <Center p="2">
                        <Text
                          fontSize={{ base: "13px", md: "17", lg: "19" }}
                          textTransform={"uppercase"}
                          textAlign={"center"}
                        >
                          Reviews and Feedback{" "}
                        </Text>
                      </Center>
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}

      <Box flex="1" p={4}>
        <Text color="black"></Text>
      </Box>
    </Flex>
  );
}

function Mobile3() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPurchased, setIsPurchased] = useState(false);
  const [isExpiryClicked, setIsExpiryClicked] = useState(false);

  return (
    <Flex minH="100vh" p={4}>
      <Box flex="1" p={4}>
        {/* <HStack color="black" mt="220" w="100%">
          tewnty percent of the screen width
          <Text color="gray.400">tap here </Text>
          <FiArrowRight />
        </HStack> */}
      </Box>

      {isExpiryClicked ? (
        <Box
          width={["100%", "100%", "420px"]}
          border="1px"
          borderColor="gray.200"
          p={0}
          color="black"
          shadow={"xl"}
          borderRadius={"md"}
          bg="white"
        >
          <Box p="5" textAlign={"right"}>
            {/* Content to display after authentication */}

            <IconButton
              variant="outline"
              icon={<CloseIcon />}
              onClick={() => setIsExpiryClicked(false)}
            >
              {" "}
            </IconButton>
          </Box>
          <Box p="5">
            <ExpiryAlert />
          </Box>
        </Box>
      ) : (
        <Box
          width={["100%", "100%", "420px"]}
          border="1px"
          borderColor="gray.200"
          p={0}
          color="black"
          shadow={"xl"}
          borderRadius={"md"}
          position={"relative"}
        >
          <Box p="5" position="absolute">
            {isPurchased ? (
              <Box
                bg="facebook.900"
                color="white"
                ml="180"
                borderRadius={"md"}
                shadow={"md"}
              >
                <HStack p="2">
                  <GrCertificate />
                  <Text color="white">Marked as purchased</Text>
                </HStack>
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Image
            src="/demo/productimage.png"
            alt="product image"
            borderTopRadius={"md"}
          />

          <Box border="0px" p="2">
            {" "}
            <Button
              borderRadius={"sm"}
              bg="blackAlpha.800"
              color="whiteAlpha.700"
              w="100%"
              fontWeight={"regular"}
              onClick={() => setIsAuthenticated(true)}
            >
              {" "}
              Authenticate Product{" "}
            </Button>
          </Box>
          <Box border="0px" p="2">
            {" "}
            <Text
              fontSize={"23"}
              textTransform={"uppercase"}
              fontWeight={"extrabold"}
              textAlign={"center"}
            >
              Special bold hair spary{" "}
            </Text>
            <Text fontSize={"21"} fontWeight={"medium"} textAlign={"center"}>
              Glassy Pale{" "}
            </Text>
            <Text mt="2" p="2" textAlign={"center"}>
              A serum-based, high-pigment blush with 70%+ skincare and
              proprietary complexes that hydrate and make skin look more plump
              in a longwear, glassy balm.
            </Text>
            <Box border="0px" p="2">
              {" "}
              <Button
                borderRadius={"sm"}
                bg="blackAlpha.500"
                color="whiteAlpha.900"
                w="100%"
                fontWeight={"regular"}
                onClick={() => setIsExpiryClicked(true)}
              >
                {" "}
                Inform me before the Product Expiry date{" "}
              </Button>
              <HStack color="black" w="100%" ml="-200" mt="-7">
                <Text color="gray.400">tap here </Text>
                <FiArrowRight />
              </HStack>
              <HStack p="3" m="1" mt="5">
                <Box
                  p="2"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("ingredients")}
                >
                  <Center>
                    <PiBowlFoodLight color="#000" size="41" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                    >
                      Ingredients{" "}
                    </Text>
                  </Center>
                </Box>

                <Box
                  p="2"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("benefits")}
                >
                  <Center>
                    <GoNorthStar color="#000" size="41" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                    >
                      Benefits{" "}
                    </Text>
                  </Center>
                </Box>
              </HStack>
              <HStack p="2" m="0" mt="-5">
                <Box
                  p="5"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("usingProduct")}
                >
                  <Center>
                    <TfiHandStop color="#000" size="41" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                    >
                      Using the product{" "}
                    </Text>
                  </Center>
                </Box>
              </HStack>
              <HStack p="2" m="1" mt="-2">
                <Box
                  p="5"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("standards")}
                >
                  <Center>
                    <GrCertificate size="22" color="#000" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                      textAlign={"center"}
                    >
                      Product standards{" "}
                    </Text>
                  </Center>
                </Box>

                <Box
                  p="2"
                  borderRadius={"lg"}
                  w="100%"
                  // onClick={() => openModal("reviews")}
                >
                  <Center>
                    <BiStar size="22" color="#000" />
                  </Center>
                  <Center p="2">
                    <Text
                      fontSize={{ base: "13px", md: "17", lg: "19" }}
                      textTransform={"uppercase"}
                      textAlign={"center"}
                    >
                      Reviews and Feedback{" "}
                    </Text>
                  </Center>
                </Box>
              </HStack>
            </Box>
          </Box>
        </Box>
      )}

      <Box flex="1" p={4}>
        <Text color="black"></Text>
      </Box>
    </Flex>
  );
}

export default FullDemo;
