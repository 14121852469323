// src/ContactForm.js
import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'
const WelcomeForm = () => {

  const toast = useToast();

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    // phone: '',
    comments: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        process.env.REACT_APP_HASURA_GRAPHQL_URL,
        {
          query: `
            mutation MyMutation($name: String!, $company: String, $email: String!, $phone: String, $comments: String) {
              insert_leads_leadinformation(objects: {comments: $comments, company: $company, email: $email, name: $name, phone: $phone}) {
                returning {
                  id
                }
              }
            }
          `,
          variables: formData,
        },
        {
          headers: {
            'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
          },
        }
      );
      toast({
        title: 'Success!',
        description: 'Form submitted successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error submitting form', error);
      toast({
        title: 'Error',
        description: 'Failed to submit form.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Box maxWidth="500px" mx="auto" mt="50px" p="10px">
      <form onSubmit={handleSubmit}>
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input name="name" value={formData.name} onChange={handleChange} />
        </FormControl>
        <FormControl id="company">
          <FormLabel>Company</FormLabel>
          <Input name="company" value={formData.company} onChange={handleChange} />
        </FormControl>
        <FormControl id="email" isRequired>
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={formData.email} onChange={handleChange} />
        </FormControl>
        {/* <FormControl id="phone">
          <FormLabel>Phone</FormLabel>
          <Input name="phone" value={formData.phone} onChange={handleChange} />
        </FormControl> */}
        <FormControl id="comments">
          <FormLabel>Comments</FormLabel>
          <Textarea name="comments" value={formData.comments} onChange={handleChange} />
        </FormControl>
        <Button mt="4" colorScheme="teal" type="submit">Submit</Button>
      </form>
    </Box>
  );
};

export default WelcomeForm;
