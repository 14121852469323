import React, { useContext } from 'react';
import { Box, Icon, Badge, SimpleGrid, Spacer, Text, HStack, Button } from '@chakra-ui/react';
import { FaShoppingCart } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import CartContext from './CartContext';
import { BsCart2 } from "react-icons/bs";
import { MdHistory } from "react-icons/md";
import { MdListAlt } from "react-icons/md";

const CartIcon = () => {
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();

  const itemCount = cart.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <>
    <SimpleGrid columns="4" p="4">
      <Spacer />
      <HStack spacing="2"> <Button  variant="outline"><MdHistory /></Button>
      <Button  variant="outline"><MdListAlt /></Button>
       </HStack>
      <Spacer />

      <HStack columns="3" border="0px" onClick={() => navigate('/package/cartpage')} cursor="pointer" alignContent={"right"}>
      <Spacer />
      <Text>Cart</Text>
      <Button variant="outline">
      <Icon as={BsCart2}        
 /></Button>
      {itemCount > 0 && (
        <Badge
        
          bg="red.500"
          borderRadius="full"
          color="white"
          fontSize="0.8em"
          p={1}
          minW="20px"
        >  
          {itemCount}
        </Badge>
      )}
          </HStack>


    </SimpleGrid>
   
    </>
  );
};

export default CartIcon;
