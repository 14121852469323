import Authenticate_app from "../common/authenticate";
import { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Tooltip,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { FaHeart, FaStar, FaSmile, FaSun } from "react-icons/fa";
import { GrFingerPrint, GrGift, GrList } from "react-icons/gr";
import { BiVideo, BiSmile, BiSearch, BiMessage } from "react-icons/bi";
import { VscSymbolColor } from "react-icons/vsc";
import Stars from "../components/star";
import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import ReactSelect from "react-select";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

import { Global, css, keyframes } from "@emotion/react";

const headlines = {
  headlines: [
    "Experience seamless navigation with our theme, crafted for intuitive and enjoyable usability.",
    "Our cohesive design ensures a seamless journey, boosting your satisfaction and engagement.",
    "Reflecting our brand's essence, our theme fosters trust and loyalty with every interaction.",
    "Captivating aesthetics capture attention and keep you engaged longer than ever.",
    "Enjoy effortless interactions with our user-friendly theme, reducing cognitive load.",
    "Inclusivity at its finest—our accessible theme provides a superior experience for all.",
    "Dive into content with ease, thanks to our theme's impeccable readability.",
    "Our responsive design ensures a consistent, stunning experience on any device.",
    "Explore and engage more with our theme, designed to increase your time on site.",
    "Exuding professionalism, our polished theme enhances credibility and user confidence.",
  ],
};

const icons = [
  {
    component: GrFingerPrint,
    label: "Product Verfication",
    size: "20px",
    delay: 0,
    bgColor: "yellow.500",
    iconColor: "white",
  },
  {
    component: FiStar,
    label: "Reviews and Ratings",
    size: "60px",
    delay: 0.1,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSmile,
    label: "Refferals",
    size: "150px",
    delay: 0.3,
    bgColor: "yellow.400",
    iconColor: "white",
  },
  {
    component: BiVideo,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSearch,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
];

const drop = keyframes`
  0% { top: -50%; }
  100% { top: 110%; }
`;

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #171717;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

  .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 75%,
      #ffffff 100%
    );
    animation: ${drop} 7s 0s infinite cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  .line:nth-of-type(1) {
    margin-left: -25%;
  }

  .line:nth-of-type(1)::after {
    animation-delay: 2s;
  }

  .line:nth-of-type(3) {
    margin-left: 25%;
  }

  .line:nth-of-type(3)::after {
    animation-delay: 2.5s;
  }
`;

// Function to generate random size and margins
const getRandomSizeAndMargin = () => {
  const size = Math.floor(Math.random() * 50) + 50; // Random size between 50px and 100px
  const margin = Math.floor(Math.random() * 20) + 10; // Random margin between 10px and 30px
  return { size, margin };
};

const AnimatedBox = motion(Box);
const MotionBox = motion(Box);

const Themes = () => {
  const [isOpen, setIsOpen] = useState({
    benefits: false,
    ingredients: false,
    usingProduct: false,
    standards: false,
    reviews: false,
    authenticate: false,
  });

  const openModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: true }));
  };

  const closeModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: false }));
  };

  const [selectedColor, setSelectedColor] = useState("white");

  const colors = ["yellow", "blue", "white", "red"];
  const displayColors = {
    yellow: "Yellow",
    blue: "Blue",
    white: "White",
    red: "Red",
  };

  const colorStyles = {
    yellow: {
      bg: "yellow.300",
      color: "black",
    },
    blue: {
      bg: "blue.300",
      color: "white",
    },
    white: {
      bg: "gray.100",
      color: "black",
      gray: "gray.100",
    },
    red: {
      bg: "red.300",
      color: "white",
    },
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Box position="relative" overflowY="auto" height="100vh">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <AnimatedBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          color="white"
          bg="#191D26"
          minH="100vh"
          overflowY="auto"
        >
          <Container textAlign={"center"} pt="10">
            <Center mb="10">
              <VscSymbolColor size="51" mb="10" />
            </Center>
            <HeadlineDisplay mt="10" headlines={headlines.headlines} />
            <Text fontSize={"21"} mt="10">
              Aesthetic design enhances post-purchase engagement by providing
              visually appealing and intuitive user experiences, encouraging
              customers to interact more frequently and deeply with the product.
            </Text>
            <Text fontSize={"21"} mt="10">
              Atakian Engage offers a range of components and tools that make
              This consistent and enjoyable engagement fosters a stronger
              emotional connection to the brand. As a result, customers are more
              likely to develop loyalty and advocate for the brand, driving
              long-term success.
            </Text>

            <ButtonGroup spacing="4" mt="10">
              <Button variant="outline" color="white">
                Request Demo
              </Button>
              <Button variant="solid">Sign up for Trial</Button>
            </ButtonGroup>
          </Container>

          <Container></Container>

          <Box bg="gray.50" color="black" w="100%" mt="10">
            <Container lineHeight={"2"} p="4">
              <Heading>
                Captivating aesthetics capture attention and keep you engaged
                longer than ever
              </Heading>

              <Text mt="10">
                Captivating aesthetics play a crucial role in capturing
                attention and enhancing user engagement. Research has shown that
                visually appealing designs can increase a user's willingness to
                engage with a product by 94% .
              </Text>
              <Text mt="10">
                Well-designed interfaces not only draw users in but also keep
                them engaged longer, as they find the experience more enjoyable
                and intuitive. This prolonged engagement leads to a deeper
                connection with the product, resulting in higher levels of
                satisfaction and loyalty. For instance, a study by Adobe found
                that 38% of people will stop engaging with a website if the
                content or layout is unattractive .
              </Text>
              <Text mt="10">
                Investing in captivating aesthetics is essential for creating a
                memorable user experience that encourages ongoing interaction
                and builds a loyal customer base.
              </Text>
              <Heading size="lg" as="h2" mt="10">
                Negative Results of poor post purchase engagement 
              </Heading>
              <Divider />

              <Image borderRadius={"md"} mt="10" src="/theme.png" />
              <List mt="5">
                <ListItem>
                  {" "}
                  <ListIcon as={GrList} mt={5} color="red.500" />
                  Negative First Impressions: : An unattractive or poorly
                  designed interface can immediately deter potential customers.
                  First impressions are formed within milliseconds, and if the
                  design is unappealing, users may leave the site or app without
                  exploring further. This can lead to high bounce rates, where
                  users quickly exit the page, and low conversion rates, where
                  few visitors turn into paying customers.
                </ListItem>

                <ListItem mt="5">
                  {" "}
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Low Loyalty: When users encounter a cluttered or visually
                  unappealing Post purchase interface, they are less likely to
                  stay and engaged with the content which can be detrimental to
                  business growth..
                </ListItem>

                <ListItem mt="5">
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Frustrating User Experience: : Poor aesthetic design often
                  leads to a confusing layout and inconsistent visual elements.
                  This can frustrate users, making it challenging for them to
                  navigate the site or find specific products and information. A
                  frustrating user experience can drive customers away, reducing
                  repeat visits and diminishing overall satisfaction. .
                </ListItem>

                <ListItem mt="5">
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Eroding Trust in the Brand: Consistency and professionalism in
                  design are crucial for building trust. If a site looks
                  unprofessional or is difficult to navigate, users may question
                  the credibility and reliability of the brand. This erosion of
                  trust can prevent customers from making purchases and reduce
                  their likelihood of recommending the brand to others.
                </ListItem>

                <ListItem mt="5">
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Reduced Perceived Value of Products: : A poorly designed
                  interface may fail to showcase the benefits and qualities of
                  cosmetic products effectively. High-quality visuals and a
                  well-organized layout help communicate the value and efficacy
                  of products. Without this, users may perceive the products as
                  less desirable or effective, impacting their purchase
                  decisions.
                </ListItem>

                <ListItem mt="5">
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Competitive Disadvantage: : In the cosmetics industry, where
                  visual appeal and branding are critical, a poorly designed
                  interface can put a brand at a significant disadvantage.
                  Competitors with visually appealing, user-friendly websites
                  are more likely to attract and retain customers, making it
                  harder for poorly designed brands to compete.
                </ListItem>
              </List>

              <Heading color="gray.800" size="lg" as="h2" mt="10">
                Atakian themes for post purchase engagement can help!
              </Heading>
              <Divider />
              <Image borderRadius={"md"} mt="10" src="/theme.png" />

              <Text mt="10">
                A well-designed themes management system at both the product and
                brand level can significantly enhance post-purchase engagement
                by addressing several critical issues outlined above. By
                ensuring a visually appealing and cohesive interface, companies
                can make a positive first impression, reducing bounce rates and
                increasing conversion rates. 
                </Text>
                <Text mt="10">
                An intuitive and consistent design
                enhances user experience, making it easier for customers to
                navigate, find information, and interact with the brand, thereby
                building trust and satisfaction. Showcasing product benefits
                effectively through high-quality visuals and organized layouts
                elevates the perceived value of cosmetics, encouraging repeat
                purchases. 
                </Text>
                
          

              <Box>

              </Box>
      
              <ButtonGroup spacing="4" mt="30" mb="30" theme="teal" w="100%">
                <Button bg="gray.500" color="gray.100" w="100%">
                  Request Demo
                </Button>
                <Button w="100%" color="blue.100" bg="facebook.700" variant="solid">
                  Sign up for Trial
                </Button>
              </ButtonGroup>
            </Container>
          </Box>
        </AnimatedBox>
      </Box>

      <Container mt="50" color="gray.400" mb="40">
        All Rights Reserved 2024, Tangible Spin LLP. D-U-N-S 878296827
      </Container>
    </>
  );
};

const HeadlineDisplay = ({ headlines }) => {
  const [randomHeadline, setRandomHeadline] = useState("");

  useEffect(() => {
    // Function to select a random headline
    const getRandomHeadline = () => {
      const randomIndex = Math.floor(Math.random() * headlines.length);
      return headlines[randomIndex];
    };

    // Set random headline on initial render
    setRandomHeadline(getRandomHeadline());
  }, [headlines]);

  return (
    <Heading w="100%" fontSize={"41"} color="yellow.300" fontWeight={"black"}>
      {randomHeadline}
    </Heading>
  );
};

export default Themes;
