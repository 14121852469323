import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Input, Textarea, FormControl, FormLabel, Text, VStack, Heading, Divider, Stack, Checkbox, RadioGroup, Radio, Select, useToast, CheckboxGroup  } from '@chakra-ui/react';
import CartContext from './components/CartContext';

const formFields = {
    "formFields": {
      "1": [
        { "type": "text", "label": "Recipient Name", "name": "recipientName" },
        { "type": "textarea", "label": "Address", "name": "address" },
        { "type": "select", "label": "Shipping Method", "name": "shippingMethod", "options": ["Standard", "Express", "Overnight"] },
        { "type": "checkbox", "label": "Gift Wrap", "name": "giftWrap" }
      ],
      "2": [
        { "type": "text", "label": "Project Name", "name": "projectName" },
        { "type": "textarea", "label": "Project Description", "name": "projectDescription" },
        { "type": "select", "label": "Material", "name": "material", "options": ["Cardboard", "Plastic", "Metal"] },
        { "type": "file", "label": "Upload Blueprint", "name": "blueprint" }
      ],
      "3": [
        { "type": "text", "label": "Event Name", "name": "eventName" },
        { "type": "radio", "label": "Urgency", "name": "urgency", "options": ["High", "Medium", "Low"] },
        { "type": "checkbox-group", "label": "Services", "name": "services", "options": ["Packing", "Shipping", "Insurance"] }
      ]
    }
  }

  
const boxData = {
    boxes: {
      small: [
        { 
          id: 1, 
          name: 'Small Box 1', 
          size: '6" x 6" x 6"', 
          dimensions: { length: 6, width: 6, height: 6 },
          category: ['boxes', 'small'] 
        },
        { 
          id: 2, 
          name: 'Small Box 2', 
          size: '8" x 8" x 8"', 
          dimensions: { length: 8, width: 8, height: 8 },
          category: ['boxes', 'small'] 
        },
        { 
          id: 3, 
          name: 'Small Box 3', 
          size: '10" x 6" x 4"', 
          dimensions: { length: 10, width: 6, height: 4 },
          category: ['boxes', 'small'] 
        }
      ],
      medium: [
        { 
          id: 4, 
          name: 'Medium Box 1', 
          size: '12" x 9" x 6"', 
          dimensions: { length: 12, width: 9, height: 6 },
          category: ['boxes', 'medium'] 
        },
        { 
          id: 5, 
          name: 'Medium Box 2', 
          size: '14" x 10" x 4"', 
          dimensions: { length: 14, width: 10, height: 4 },
          category: ['boxes', 'medium'] 
        },
        { 
          id: 6, 
          name: 'Medium Box 3', 
          size: '16" x 12" x 8"', 
          dimensions: { length: 16, width: 12, height: 8 },
          category: ['boxes', 'medium'] 
        }
      ],
      large: [
        { 
          id: 7, 
          name: 'Large Box 1', 
          size: '18" x 18" x 16"', 
          dimensions: { length: 18, width: 18, height: 16 },
          category: ['boxes', 'large'] 
        },
        { 
          id: 8, 
          name: 'Large Box 2', 
          size: '20" x 20" x 20"', 
          dimensions: { length: 20, width: 20, height: 20 },
          category: ['boxes', 'large'] 
        },
        { 
          id: 9, 
          name: 'Large Box 3', 
          size: '24" x 18" x 18"', 
          dimensions: { length: 24, width: 18, height: 18 },
          category: ['boxes', 'large'] 
        }
      ],
      extra_large: [
        { 
          id: 10, 
          name: 'Extra Large Box 1', 
          size: '24" x 24" x 24"', 
          dimensions: { length: 24, width: 24, height: 24 },
          category: ['boxes', 'extra_large'] 
        },
        { 
          id: 11, 
          name: 'Extra Large Box 2', 
          size: '30" x 30" x 30"', 
          dimensions: { length: 30, width: 30, height: 30 },
          category: ['boxes', 'extra_large'] 
        },
        { 
          id: 12, 
          name: 'Extra Large Box 3', 
          size: '36" x 36" x 36"', 
          dimensions: { length: 36, width: 36, height: 36 },
          category: ['boxes', 'extra_large'] 
        }
      ]
    }
  };
  
  
  const flattenBoxData = (data) => {
    const categories = Object.keys(data);
    return categories.reduce((acc, category) => {
      const boxes = data[category].map(box => ({ ...box, category }));
      return [...acc, ...boxes];
    }, []);
  };
  
  const allBoxes = flattenBoxData(boxData.boxes);
  
  const PackageDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const box = allBoxes.find(box => box.id === parseInt(id, 10));
  
    const [quantity, setQuantity] = useState(1);
    const [needByDate, setNeedByDate] = useState('');
    const [comments, setComments] = useState('');
    const [formData, setFormData] = useState({});
    const { addToCart } = useContext(CartContext);
    const toast = useToast();
  
    const handleOrder = () => {
      const item = { ...box, quantity, needByDate, comments, formData };
      addToCart(item);
      navigate('/package/cartpage');
      toast({
        title: "Item added to cart.",
        description: "You can view your cart items in the cart page.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    };
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    };
  
    if (!box) {
      return <Box p={5}>Box not found</Box>;
    }
  
    // Ensure correct box ID is used
    const additionalFields = formFields.formFields[box.id.toString()] || [];
  
    return (
      <Box p={5}>
        <Text fontSize="2xl">{box.name}</Text>

        <Text>Size: {box.size}</Text>
        <Text>Dimensions: {box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height}</Text>
  
        <VStack spacing={4} mt={5}>
          <FormControl id="quantity">
            <FormLabel>Quantity</FormLabel>
            <Input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value, 10))} />
          </FormControl>
  
          <FormControl id="need-by-date">
            <FormLabel>Need By Date</FormLabel>
            <Input type="date" value={needByDate} onChange={(e) => setNeedByDate(e.target.value)} />
          </FormControl>
  
          <FormControl id="comments">
            <FormLabel>Comments</FormLabel>
            <Textarea value={comments} onChange={(e) => setComments(e.target.value)} />
          </FormControl>
  
          <Box w="100%">
            <Heading>Additional Details needed</Heading>
            <Divider />
          </Box>
  
          {additionalFields.map((field, index) => (
            <FormControl key={index} id={field.name}>
              <FormLabel>{field.label}</FormLabel>
              {field.type === 'text' && <Input type="text" name={field.name} onChange={handleChange} />}
              {field.type === 'textarea' && <Textarea name={field.name} onChange={handleChange} />}
              {field.type === 'select' && (
                <Select name={field.name} onChange={handleChange}>
                  {field.options.map((option, i) => (
                    <option key={i} value={option}>{option}</option>
                  ))}
                </Select>
              )}
              {field.type === 'checkbox' && <Checkbox name={field.name} onChange={handleChange}>{field.label}</Checkbox>}
              {field.type === 'checkbox-group' && (
                <CheckboxGroup>
                  <Stack>
                    {field.options.map((option, i) => (
                      <Checkbox key={i} name={field.name} value={option} onChange={handleChange}>{option}</Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              )}
              {field.type === 'radio' && (
                <RadioGroup name={field.name} onChange={handleChange}>
                  <Stack>
                    {field.options.map((option, i) => (
                      <Radio key={i} value={option}>{option}</Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
              {field.type === 'file' && <Input type="file" name={field.name} onChange={handleChange} />}
            </FormControl>
          ))}
  
          <Button onClick={handleOrder} colorScheme="blue">Order</Button>
        </VStack>
      </Box>
    );
  };
  
  export default PackageDetail;
  