import React, { useState, useEffect } from 'react';
import { ChakraProvider, Center, HStack, Spacer, IconButton, Button, List, Link, Text, Divider, Flex, Tag, SimpleGrid, Container, Badge, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Checkbox, Box, ListItem } from '@chakra-ui/react';
import { Editor, EditorState, convertFromRaw, convertToRaw, CompositeDecorator } from 'draft-js';
import { TagLeftIcon } from '@chakra-ui/react';
import 'draft-js/dist/Draft.css';
import '../App.css'; // Import your CSS file
import { CloseIcon } from '@chakra-ui/icons';
import { CiSquareChevLeft } from "react-icons/ci";
import { CiSquareChevRight } from "react-icons/ci";
import BarChart from './BarChart';
import ProductRoadmap from './ProductRoadmap';
const initialContent = {
  blocks: [
    {
      key: '1',
      text: 'Track Invoices and Payments',
      type: 'header-one',
    },
    {
      key: '2',
      text: 'When managing multiple invoices and payments from suppliers, User wants to have a centralized system to monitor the status of all transactions, so User can easily identify overdue payments and take necessary actions promptly.',
      type: 'unstyled',
    },
    {
      key: '3',
      text: 'Inserted Component',
      type: 'inserted-component', // Custom block type
    },
    {
      key: '4',
      text: 'Context:',
      type: 'header-two',
    },
    {
      key: '5',
      text: 'Procurement professionals often handle a large volume of invoices and payments from different suppliers. Keeping track of these transactions manually or through disjointed systems can lead to inefficiencies, missed payments, and strained supplier relationships.',
      type: 'unstyled',
    },
    {
      key: '6',
      text: 'Inserted Component',
      type: 'InsertedComponentRoadmap', // Custom block type
    },
  ],
  entityMap: {},
};
const roadmapData = [
  {
    "category": "Milestones",
    "tasks": [
      { "name": "Community Site Beta", "start": "2024-01-01", "end": "2024-03-31", "type": "milestone" },
      { "name": "Android Mobile App Launch", "start": "2024-04-01", "end": "2024-06-30", "type": "milestone" },
      { "name": "iOS Mobile App Launch", "start": "2024-07-01", "end": "2024-09-30", "type": "milestone" }
    ]
  },
  {
    "category": "Self Serve",
    "tasks": [
      { "name": "Two-Factor Authentication", "start": "2024-01-01", "end": "2024-02-15", "type": "task" },
      { "name": "Single-Sign On", "start": "2024-02-16", "end": "2024-03-31", "type": "task" },
      { "name": "User Avatar", "start": "2024-04-01", "end": "2024-05-15", "type": "task" }
    ]
  },
  {
    "category": "Mobile",
    "tasks": [
      { "name": "iOS App", "start": "2024-01-01", "end": "2024-02-28", "type": "task" },
      { "name": "Android App", "start": "2024-03-01", "end": "2024-04-30", "type": "task" },
      { "name": "Push Notifications", "start": "2024-05-01", "end": "2024-06-15", "type": "task" }
    ]
  }
];

const personas = [
  { id: 1, name: 'Peter' },
  { id: 2, name: 'John' },
  { id: 3, name: 'July' },
];

const InsertedComponent = () => {
  return <Box  p="4" my="4"  bordercolor="red.100" border="1px">
  <Center>
  {/* <ProductRoadmap data={roadmapData} /> */}
  <BarChart />  
  </Center>
</Box>;
};


const InsertedComponentRoadmap = () => {
  return <Box  p="4" my="4"   class="border" border="1px">
    <Center>
    {/* <ProductRoadmap data={roadmapData} /> */}
    <BarChart />  
    </Center>
  </Box>;
};

const blockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  switch (type) {
    case 'header-one':
      return 'header-one';
    case 'header-two':
      return 'header-two';
    case 'unstyled':
      return 'unstyled';
    default:
      return null;
  }
};

const blockRendererFn = (contentBlock) => {
  const type = contentBlock.getType();
  if (type === 'inserted-component') {
    return {
      component: InsertedComponent,
      editable: false,
    };
  } else if (type === 'InsertedComponentRoadmap') {
    return {
      component: InsertedComponentRoadmap,
      editable: false,
    };
  }
  return null;
};



const Highlight = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPersonas, setSelectedPersonas] = useState([]);
  const onClose = () => setIsOpen(false);

  const handleCheckboxChange = (persona) => {
    setSelectedPersonas((prev) =>
      prev.includes(persona)
        ? prev.filter((p) => p !== persona)
        : [...prev, persona]
    );
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <span
          className="special-font"
          style={{
            backgroundColor: 'yellow',
            cursor: 'pointer',
            borderBottom: '2px dotted black',
            position: 'relative',
            display: 'inline-block',
          }}
          onClick={() => setIsOpen(true)}
        >
          {children}
          {selectedPersonas.length > 0 && (
            <Badge
              ml="1"
              colorScheme="green"
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                backgroundColor: 'black',
                color: 'white',
              }}
            >
              {selectedPersonas.length}
            </Badge>
          )}
        </span>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Select Personas</PopoverHeader>
        <PopoverBody>
          {personas.map((persona) => (
            <Checkbox
              key={persona.id}
              isChecked={selectedPersonas.includes(persona)}
              onChange={() => handleCheckboxChange(persona)}
            >
              {persona.name}
            </Checkbox>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const findHighlight = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  let matchArr, start;
  const regex = /\b(they|User|Peter)\b/g; // Add words here
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const decorator = new CompositeDecorator([
  {
    strategy: findHighlight,
    component: Highlight,
  },
]);

const Salte = () => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(initialContent), decorator)
  );

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    console.log('Content state:', convertToRaw(contentState));
  }, [editorState]);

  return (
    <Box className="special-font" p="5" border="0px solid black" borderRadius="md" mb="4">
      <Editor
        editorState={editorState}
        blockStyleFn={blockStyleFn}
        blockRendererFn={blockRendererFn} // Add this line
        onChange={setEditorState}
        customStyleMap={{ HIGHLIGHT: { backgroundColor: 'yellow' } }}
      />
    </Box>
  );
};

const contentState = convertFromRaw(initialContent);

const App = () => {

  const [isLeftOpen, setIsLeftOpen] = useState(true);
  const [isRightOpen, setIsRightOpen] = useState(true);

  const toggleLeftBox = () => {
    setIsLeftOpen(!isLeftOpen);
  };

  const toggleRightBox = () => {
    setIsRightOpen(!isRightOpen);
  };


  return (
    <>
<Flex p="5">
      {isLeftOpen && (
        <Box pt="10" flex="3" border="1px" borderColor="gray.50">
          <IconButton
            icon={<CloseIcon />}
            onClick={toggleLeftBox}
            aria-label="Close left box"
            size="sm"
            position="absolute"
            left="5"
            top="5"
          />
          <Text mt="20">Prompts for you!</Text>
          <Divider mb="3" />
          <List>
            <ListItem>
              <Link color="facebook.400">here is an item you can add</Link>
            </ListItem>
          </List>
          <Text mt="10">Team</Text>
          <Divider mb="3" />
          <Tag>Rohan Sandeep </Tag> <Tag>John Peter </Tag>
          <Text mt="10">Linked Objects </Text>
          <List>
            <ListItem>
              <Link color="facebook.400">Associate or add object</Link>
              
            </ListItem>
          
          </List>
          <Tag mr="1">Peter</Tag> <Tag>John</Tag>
        </Box>
      )}


      <Box flex="5">
      <HStack border="0px" h="10">
      {!isLeftOpen && 
      
      <Button
            onClick={toggleLeftBox}
            aria-label="Close left box"
            size="lg"
            variant="ghost"
            position="relative"
  
          >
            <CiSquareChevRight  size={31} />
          </Button>
      }
      <Spacer />
            {!isRightOpen && 
            
            <Button
            onClick={toggleRightBox}
            aria-label="Close left box"
            size="lg"
            variant="ghost"
            position="relative"
  
          >
            <CiSquareChevLeft size={31} />
          </Button>
            
            
            }

          </HStack>
     
          <HStack>
        <Salte blockStyleFn={blockStyleFn} /> </HStack>
      </Box>

      {isRightOpen && (
        <Box flex="3" pt="10" border="1px" borderColor="gray.50" textAlign={"right"}>
          
          <HStack>
            <Box>  </Box>
            <Spacer />
          <IconButton
            icon={<CloseIcon />}
            onClick={toggleRightBox}
            aria-label="Close right box"
            size="sm"
            position="absolute"
            right="5"
            top="5"
          />
          </HStack>
          <Text mt="20">Prompts for you!</Text>
          <Divider mb="3" />
          <List>
            <ListItem>
              <Link color="facebook.400">here is an item you can add</Link>
            </ListItem>
          </List>
          <Text mt="10">Collection</Text>
          <Divider mb="3" />
          <Tag>Peter</Tag> <Tag>John</Tag>
          <Text mt="10">Collection</Text>
          <List>
            <ListItem>
              <Link color="facebook.400">Associate or Add an Epic</Link>
            </ListItem>
          </List>
        </Box>
      )}

    </Flex>
    </>
  );
};

export default App;
