// LogoutButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/signin'); // Redirect to sign-in page on sign out
  };

  return (
    <button onClick={handleLogout}>
      Logout
    </button>
  );
};

export default LogoutButton;
