import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Select,
  TableContainer,
  HStack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Alert,
  AlertIcon,
  Text,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { VscSymbolString } from "react-icons/vsc";
import { GoNumber } from "react-icons/go";
import { DeleteIcon } from "@chakra-ui/icons";
import CreatableSelect from "react-select/creatable";
import ReactSelect from "react-select";
import { Edit2 } from "react-feather";

const attributeOptions = {
  Name: "Name",
  Stage: "Stage",
  group: "Company Name",
  // Add more attributes as needed
};

const ManageCompetitionAttributes = ({ setNewData }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [bubbleColorHeader, setBubbleColorHeader] = useState("");
  const [bubbleSizeHeader, setBubbleSizeHeader] = useState("");
  const [newData, setNewDataLocal] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedNValue, setSelectedNValue] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [groupedAttributes, setGroupedAttributes] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [layout, setLayout] = useState("viewall");
  const [type, setType] = useState("none");
  const [fieldTitle, setFieldTitle] = useState("");
  const [objectId, setObjectId] = useState(3); // Assuming you need an object ID from somewhere
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState([]);

  const MAX_STEP = 3;

  const handleOpenDrawer = () => {
    setIsOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsOpen(false);
    setCurrentStep(1);
    setIsEditing(false); // Reset the editing state when closing the drawer
  };

  const handleNext = () => {
    if (currentStep < MAX_STEP) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const setDataWithHeaders = (newData, newHeaders) => {
    setHeaders(newHeaders);
    setNewDataLocal(newData);
    setSelectedTitles(
      Object.fromEntries(newHeaders.map((header) => [header.header, header.header]))
    );
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleSubmit = async () => {
    const selectValues = selectedValue.map((value) => value.label);

    const payload = {
      title: fieldTitle,
      type: type,
      selectValues: selectValues,
      objectId: objectId,
      group: selectedGroup?.label || "",
    };

    try {
      const response = await fetch(
        "https://www.tryatakian.com/api/mutateAttributes",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);

      // Handle successful submission (e.g., close drawer, update state)
      setNewData(result);
      toast({
        title: "Field created.",
        description: "The new field has been successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      handleCloseDrawer();
      fetchData(); // Re-fetch the data to update the list of attributes
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditSubmit = async () => {
    const selectValues = options.map((option) => option.label);

    const payload = {
      id: selectedItem?.attribute.id,
      title: fieldTitle,
      type: type,
      selectValues: selectValues,
      objectId: objectId,
      group: selectedGroup?.label || "",
      removeValues: [], // Add logic to handle removal of values if needed
    };

    try {
      const response = await fetch(
        "https://www.tryatakian.com/api/updateAttributes",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result);

      // Handle successful submission (e.g., close drawer, update state)
      setNewData(result);
      toast({
        title: "Field updated.",
        description: "The field has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      handleCloseDrawer();
      fetchData(); // Re-fetch the data to update the list of attributes
    } catch (error) {
      setError(error.message);
    }
  };

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const enteredValue = event.target.value.trim();
      if (enteredValue && !options.find((opt) => opt.value === enteredValue)) {
        const newOption = { value: enteredValue, label: enteredValue };
        setSelectedValue([...selectedValue, newOption]);
      }
    }
  };

  const optionsN = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        "https://www.tryatakian.com/api/competitionAttributes"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data, "Competition 1")

      setData(data.atakiancorp_objects);
      const grouped = data.atakiancorp_objects.reduce((acc, object) => {
        const attributes = groupAttributes(object.attributes_objects);
        return { ...acc, ...attributes };
      }, {});
      setGroupedAttributes(grouped);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const groupAttributes = (attributesObjects) => {
    return attributesObjects.reduce((groups, item) => {
      const group = item.group;
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(item);
      return groups;
    }, {});
  };

  const handleGroupChange = (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const handleGroupCreate = (inputValue) => {
    const newGroup = { value: inputValue, label: inputValue };
    setGroupedAttributes((prevGroups) => ({
      ...prevGroups,
      [inputValue]: [],
    }));
    setSelectedGroup(newGroup);
  };

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const renderIcon = (type) => {
    if (type === "text") return <VscSymbolString size="24" />;
    if (type === "select") return <GoNumber size="24" />;
    return null;
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setFieldTitle(item.attribute.title);
    setSelectedGroup({ value: item.group, label: item.group });
    setType(item.attribute.type);
    setOptions(item.attribute.attributes_values.map(value => ({ value: value.title, label: value.title })));
    setLayout("edit");
    setIsEditing(true);
  };

  const handleOptionsChange = (newOptions) => {
    setOptions(newOptions);
  };

  const handleOptionsKeyDown = (event) => {
    if (event.key === "Enter") {
      const enteredValue = event.target.value.trim();
      if (enteredValue && !options.find((opt) => opt.value === enteredValue)) {
        const newOption = { value: enteredValue, label: enteredValue };
        setOptions([...options, newOption]);
      }
    }
  };

  return (
    <>
      <Button variant="outline" onClick={handleOpenDrawer}>
        Manage Competition Fields
      </Button>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleCloseDrawer}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Manage Fields</DrawerHeader>
          <Divider />
          <DrawerBody>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}

            {layout === "viewall" && (
              <>
                <HStack w="100%">
                  <Box></Box>
                  <Spacer />
                  <Button onClick={() => handleLayoutChange("create")}>
                    {" "}
                    Create{" "}
                  </Button>
                </HStack>
                {Object.keys(groupedAttributes).map((group, groupIndex) => (
                  <Box key={groupIndex} mt="5">
                    <Heading as="h3" size="lg" mb="3">
                      {group}
                    </Heading>
                    <TableContainer>
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            <Th>Name</Th>
                            <Th>Edit</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {groupedAttributes[group].map((attributeObj, idx) => (
                            <Tr key={idx}>
                              <Td w="70%">
                                <HStack>
                                  {renderIcon(attributeObj.attribute.type)}
                                  <Text>{attributeObj.attribute.title}</Text>
                                </HStack>
                              </Td>

                              <Td>
                                <HStack spacing="2">
                                  <Button variant="outline">
                                    <Edit2
                                      onClick={() =>
                                        handleEditClick(attributeObj)
                                      }
                                    />
                                  </Button>

                                  <Button variant="outline">
                                    <DeleteIcon />
                                  </Button>
                                </HStack>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                ))}
              </>
            )}

            {layout === "create" && (
              <>
                <Button
                  variant="outline"
                  onClick={() => handleLayoutChange("viewall")}
                >
                  {" "}
                  Back{" "}
                </Button>

                <SimpleGrid
                  columns={2}
                  spacing={4}
                  w="100%"
                  p="4"
                  borderRadius={"md"}
                  pb="0"
                >
                  <FormControl>
                    <FormLabel>Field Title</FormLabel>
                    <Input
                      color="gray.800"
                      bg="white"
                      value={fieldTitle}
                      onChange={(e) => setFieldTitle(e.target.value)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Field Type</FormLabel>
                    <Select
                      color="gray.800"
                      bg="white"
                      placeholder="Select field"
                      onChange={handleTypeChange}
                    >
                      <option value="text">Text</option>
                      <option value="number">Number</option>
                      <option value="select">Select</option>
                      <option value="image">Image</option>
                      <option value="date">Date</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="radio">Radio</option>
                      <option value="attribute">Existing Attribute</option>
                      <option value="object">Object</option>
                      <option value="list">List</option>

                    </Select>
                  </FormControl>
                </SimpleGrid>

                <Box p="4">
                  {(type === "checkbox" ||
                    type === "select" ||
                    type === "radio") && (
                    <FormControl mb="5">
                      <FormLabel>Options</FormLabel>
                      <ReactSelect
                        options={options}
                        value={selectedValue}
                        onChange={handleChange}
                        isMulti
                        onKeyDown={handleKeyDown}
                        styles={{ width: "100%" }} // Optional styling
                      />
                    </FormControl>
                  )}

                  {(type === "attribute" || type === "object") && (
                    <FormControl>
                      <FormLabel>Existing Attribute or Object </FormLabel>
                      Existing attribute of Object list is displayed here
                    </FormControl>
                  )}

{(type === "attribute" || type === "list") && (
                  <FormControl mb="5">
                  <FormLabel>List Categories</FormLabel>
                  <ReactSelect
                    options={options}
                    value={selectedValue}
                    onChange={handleChange}
                    isMulti
                    onKeyDown={handleKeyDown}
                    styles={{ width: "100%" }} // Optional styling
                  />
                </FormControl>
                  )}

                  <FormControl>
                    <FormLabel>Groups</FormLabel>
                    <CreatableSelect
                      options={Object.keys(groupedAttributes).map((group) => ({
                        value: group,
                        label: group,
                      }))}
                      value={selectedGroup}
                      onChange={handleGroupChange}
                      onCreateOption={handleGroupCreate}
                      isClearable
                    />
                  </FormControl>
                </Box>

                <Button variant="outline" onClick={handleSubmit}>
                  Add
                </Button>
              </>
            )}

            {layout === "edit" && (
              <>
                <Button
                  variant="outline"
                  onClick={() => handleLayoutChange("viewall")}
                >
                  {" "}
                  Back{" "}
                </Button>
                <Box p="4">
                  <FormControl>
                    <FormLabel>Field Title</FormLabel>
                    <Input
                      color="gray.800"
                      bg="white"
                      value={fieldTitle}
                      onChange={(e) => setFieldTitle(e.target.value)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Groups</FormLabel>
                    <CreatableSelect
                      options={Object.keys(groupedAttributes).map((group) => ({
                        value: group,
                        label: group,
                      }))}
                      value={selectedGroup}
                      onChange={handleGroupChange}
                      onCreateOption={handleGroupCreate}
                      isClearable
                    />
                  </FormControl>

                  {(type === "checkbox" ||
                    type === "select" ||
                    type === "radio") && (
                    <FormControl mb="5">
                      <FormLabel>Options</FormLabel>
                      <ReactSelect
                        options={options}
                        value={options}
                        onChange={handleOptionsChange}
                        isMulti
                        onKeyDown={handleOptionsKeyDown}
                        styles={{ width: "100%" }} // Optional styling
                      />
                    </FormControl>
                  )}
                </Box>
                <Button variant="outline" onClick={handleEditSubmit}>
                  Save Changes
                </Button>
              </>
            )}
          </DrawerBody>
          <Divider />
          <DrawerFooter>
            <SimpleGrid columns={2} spacing={4} w="100%">
              {currentStep !== 1 && (
                <Button onClick={handlePrevious} variant="link">
                  Previous
                </Button>
              )}
              {currentStep !== MAX_STEP && (
                <Button onClick={handleNext} colorScheme="blue" ml={4}>
                  Next
                </Button>
              )}
              {currentStep === MAX_STEP && (
                <Button onClick={handleSubmit} colorScheme="blue" ml={4}>
                  Submit
                </Button>
              )}
            </SimpleGrid>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ManageCompetitionAttributes;
