import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Divider,
  useDisclosure,
  Text,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

const CreateCompetitionEntryAdd = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({});
  const [groupedAttributes, setGroupedAttributes] = useState({});
  const [error, setError] = useState(null);

  const handleChange = (id, value, type = 'text', groupName = null) => {
    if (type === 'select' || type === 'list' || type === 'viz') {
      setFormData((prev) => ({
        ...prev,
        [id]: { field: id, value: value, type: type, ...(groupName && { groupName }) },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: { field: id, value: value, type: type },
      }));
    }
  };

  const handleSubmit = async () => {
    const finalFormData = {
      ...formData,
      objectType: { field: 'objectType', value: 'competition', type: 'text' },
      objectId: { field: 'objectId', value: 3, type: 'number' }, // Ensuring objectId is a number
      objectName: {
        field: 'objectName',
        value: formData.objectName?.value || '',
        type: 'text',
      },
    };

    console.log('Form Data:', finalFormData);

    try {
      const response = await fetch(
        'https://www.tryatakian.com/api/createContact',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(finalFormData),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Serverless Function Response:', result);
      // Handle success response
    } catch (error) {
      console.error('Error:', error);
      // Handle error response
    }
  };

  const groupAttributes = (attributes) => {
    return attributes.reduce((acc, attr) => {
      const group = attr.group || 'default';
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(attr);
      return acc;
    }, {});
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        'https://www.tryatakian.com/api/competitionAttributes'
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data, 'Competition');

      const grouped = data.atakiancorp_objects.reduce((acc, object) => {
        const attributes = groupAttributes(object.attributes_objects);
        return { ...acc, ...attributes };
      }, {});
      setGroupedAttributes(grouped);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderFormFields = (groupedAttributes) => {
    return Object.entries(groupedAttributes).map(([group, attributes]) => (
      <Box key={group} mb={4}>
        <Box as="h3" fontSize="lg" mb={2}>
          {group}
        </Box>
        <Divider mb="3" />
        {attributes.map((item) => {
          const { attribute } = item;
          const { title, type, id, attributes_values } = attribute;

          switch (type) {
            case 'text':
              return (
                <FormControl key={id} mb={4}>
                  <FormLabel>{title}</FormLabel>
                  <Input onChange={(e) => handleChange(id, e.target.value, 'text')} />
                </FormControl>
              );
            case 'number':
              return (
                <FormControl key={id} mb={4}>
                  <FormLabel>{title}</FormLabel>
                  <NumberInput onChange={(value) => handleChange(id, value, 'number')}>
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              );
            case 'select':
              return (
                <FormControl key={id} mb={4}>
                  <FormLabel>{title}</FormLabel>
                  <Select onChange={(e) => handleChange(id, e.target.value, 'select')}>
                    {attributes_values.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              );
            case 'checkbox':
              return (
                <FormControl key={id} mb={4}>
                  <FormLabel>{title}</FormLabel>
                  <Stack spacing={5} direction="row">
                    {attributes_values.map((option) => (
                      <Checkbox
                        key={option.id}
                        onChange={(e) => handleChange(id, e.target.checked, 'checkbox')}
                      >
                        {option.title}
                      </Checkbox>
                    ))}
                  </Stack>
                </FormControl>
              );
            case 'radio':
              return (
                <FormControl key={id} mb={4}>
                  <FormLabel>{title}</FormLabel>
                  <RadioGroup onChange={(value) => handleChange(id, value, 'radio')}>
                    <Stack spacing={5} direction="row">
                      {attributes_values.map((option) => (
                        <Radio key={option.id} value={option.id}>
                          {option.title}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              );
            case 'list':
              return (
                <ListInput
                  key={id}
                  title={title}
                  id={id}
                  handleChange={(id, value) =>
                    handleChange(id, value, 'list', group)
                  }
                  attributes_values={attributes_values}
                />
              );
            case 'viz':
              return (
                <VizInput
                  key={id}
                  title={title}
                  id={id}
                  handleChange={(id, value) =>
                    handleChange(id, value, 'viz', group)
                  }
                  attributes_values={attributes_values}
                />
              );
            // Add more cases as needed...
            default:
              return null;
          }
        })}
      </Box>
    ));
  };

  const informationAttributes = Object.entries(groupedAttributes).reduce((acc, [group, attributes]) => {
    const filteredAttributes = attributes.filter(item => item.attribute.type !== 'viz');
    if (filteredAttributes.length > 0) {
      acc[group] = filteredAttributes;
    }
    return acc;
  }, {});

  const visualizationAttributes = Object.entries(groupedAttributes).reduce((acc, [group, attributes]) => {
    const filteredAttributes = attributes.filter(item => item.attribute.type === 'viz');
    if (filteredAttributes.length > 0) {
      acc[group] = filteredAttributes;
    }
    return acc;
  }, {});

  return (
    <Container>
               <Box mb={4}>
          <FormControl mb={4}>
            <FormLabel>Object Name</FormLabel>
            <Input
              onChange={(e) => handleChange('objectName', e.target.value, 'text')}
            />
          </FormControl>
        </Box>
      <Tabs>
        <TabList>
          <Tab>Information</Tab>
          <Tab>Visualisation</Tab>
          <Tab>Others</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {renderFormFields(informationAttributes)}
          </TabPanel>
          <TabPanel>
            {renderFormFields(visualizationAttributes)}
          </TabPanel>
          <TabPanel>
            <p>Three!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {error && <Box color="red.500">{error}</Box>}

      <Button variant="outline" mr={3} onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="blue" onClick={handleSubmit}>
        Submit
      </Button>
    </Container>
  );
};

const ListInput = ({ id, title, handleChange, attributes_values }) => {
  const [inputs, setInputs] = useState(
    attributes_values.map(() => [{ id: Date.now(), value: '' }])
  );

  const handleInputChange = (groupIndex, index, event) => {
    const newInputs = [...inputs];
    newInputs[groupIndex][index].value = event.target.value;
    setInputs(newInputs);
    handleChange(id, attributes_values.map((attr, idx) => ({
      title: attr.title,
      id: attr.id,
      values: newInputs[idx]
    })));
  };

  const handleAddInput = (groupIndex) => {
    const newInputs = [...inputs];
    newInputs[groupIndex] = [
      ...newInputs[groupIndex],
      { id: Date.now(), value: '' },
    ];
    setInputs(newInputs);
    handleChange(id, attributes_values.map((attr, idx) => ({
      title: attr.title,
      id: attr.id,
      values: newInputs[idx]
    })));
  };

  const handleRemoveInput = (groupIndex, index) => {
    const newInputs = [...inputs];
    newInputs[groupIndex] = newInputs[groupIndex].filter((_, i) => i !== index);
    setInputs(newInputs);
    handleChange(id, attributes_values.map((attr, idx) => ({
      title: attr.title,
      id: attr.id,
      values: newInputs[idx]
    })));
  };

  return (
    <FormControl mb={4}>
      <Stack spacing={3}>
        {attributes_values.map((option, groupIndex) => (
          <Box key={option.id}>
            <Text>{option.title}</Text>
            <Divider />
            {inputs[groupIndex].map((input, index) => (
              <Box key={input.id} display="flex" alignItems="center">
                <Input
                  value={input.value}
                  onChange={(event) =>
                    handleInputChange(groupIndex, index, event)
                  }
                  ml={2}
                />
                <Button
                  onClick={() => handleRemoveInput(groupIndex, index)}
                  colorScheme="red"
                  ml={2}
                >
                  Remove
                </Button>
              </Box>
            ))}
            <Button
              onClick={() => handleAddInput(groupIndex)}
              colorScheme="blue"
              mt={2}
            >
              Add
            </Button>
          </Box>
        ))}
      </Stack>
    </FormControl>
  );
};



const VizInput = ({ id, title, handleChange, attributes_values }) => {
    const [inputs, setInputs] = useState(
      attributes_values.map((attr) => ({
        id: attr.id,
        value: '',
        object_customs: attr.object?.object_customs?.map((custom) => ({
          id: custom.id,
          value: ''
        })) || []
      }))
    );
  
    const handleInputChange = (groupIndex, index, event) => {
      const newInputs = [...inputs];
      newInputs[groupIndex].object_customs[index].value = event.target.value;
      setInputs(newInputs);
      handleChange(id, attributes_values.map((attr, idx) => ({
        title: attr.title,
        id: attr.id,
        object_customs: newInputs[idx].object_customs,
      })));
    };
  
    const handleAddInput = (groupIndex) => {
      const newInputs = [...inputs];
      newInputs[groupIndex].object_customs = [
        ...newInputs[groupIndex].object_customs,
        { id: Date.now(), value: '' },
      ];
      setInputs(newInputs);
      handleChange(id, attributes_values.map((attr, idx) => ({
        title: attr.title,
        id: attr.id,
        object_customs: newInputs[idx].object_customs,
      })));
    };
  
    const handleRemoveInput = (groupIndex, index) => {
      const newInputs = [...inputs];
      newInputs[groupIndex].object_customs = newInputs[groupIndex].object_customs.filter((_, i) => i !== index);
      setInputs(newInputs);
      handleChange(id, attributes_values.map((attr, idx) => ({
        title: attr.title,
        id: attr.id,
        object_customs: newInputs[idx].object_customs,
      })));
    };
  
    return (
      <FormControl mb={4}>
        <FormLabel>{title}</FormLabel>
        <Stack spacing={3}>
          {attributes_values.map((option, groupIndex) => (
            <Box key={option.id}>
              <Text>{option.title}</Text>
              <Divider />
              {inputs[groupIndex].object_customs.map((custom, index) => (
                <Box key={custom.id} display="flex" alignItems="center">
                  <Text>{option.object?.object_customs[index]?.title || ''}</Text>
                  <Input
                    value={custom.value}
                    onChange={(event) =>
                      handleInputChange(groupIndex, index, event)
                    }
                    ml={2}
                  />
                  <Button
                    onClick={() => handleRemoveInput(groupIndex, index)}
                    colorScheme="red"
                    ml={2}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
              <Button
                onClick={() => handleAddInput(groupIndex)}
                colorScheme="blue"
                mt={2}
              >
                Add
              </Button>
            </Box>
          ))}
        </Stack>
      </FormControl>
    );
  };
  

export default CreateCompetitionEntryAdd;
