import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Box, Spinner } from '@chakra-ui/react';

const Heatmap = ({ data }) => {
    const svgRef = useRef();
    const wrapperRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 400 });

    useEffect(() => {
        const handleResize = () => {
            if (wrapperRef.current) {
                const { clientWidth } = wrapperRef.current;
                setDimensions({ width: clientWidth, height: 400 });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!data || data.length === 0) return;

        // Set dimensions and margins
        const margin = { top: 30, right: 30, bottom: 30, left: 100 };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;

        // Clear existing SVG content
        d3.select(svgRef.current).selectAll('*').remove();

        // Append SVG element
        const svg = d3.select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Prepare data
        const transformedData = [];
        data.forEach(record => {
            const name = record.name;
            const featureData = record.groups?.feature_analysis_a?.fields?.single || [];
            featureData.forEach(feature => {
                if (feature.featureName && feature.value) {
                    transformedData.push({
                        name,
                        featureName: feature.featureName,
                        value: +feature.value,
                    });
                }
            });
        });

        // Define scales and axes
        const xScale = d3.scaleBand()
            .range([0, width])
            .domain([...new Set(transformedData.map(d => d.featureName))])
            .padding(0.05);
        
        const yScale = d3.scaleBand()
            .range([height, 0])
            .domain([...new Set(transformedData.map(d => d.name))])
            .padding(0.05);

        const colorScale = d3.scaleSequential()
            .interpolator(d3.interpolateBlues)
            .domain([1, 5]);

        // Add axes
        svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(xScale));

        svg.append('g')
            .attr('class', 'y-axis')
            .call(d3.axisLeft(yScale));

        // Add heatmap cells
        svg.selectAll()
            .data(transformedData, d => d.name + ':' + d.featureName)
            .enter()
            .append('rect')
            .attr('x', d => xScale(d.featureName))
            .attr('y', d => yScale(d.name))
            .attr('width', xScale.bandwidth())
            .attr('height', yScale.bandwidth())
            .style('fill', d => colorScale(d.value));

    }, [data, dimensions]);

    if (!data) {
        return <Spinner size="xl" />;
    }

    return (
        <Box ref={wrapperRef} width="100%">
            <svg ref={svgRef}></svg>
        </Box>
    );
};

export default Heatmap;
