import {
  SimpleGrid,
  Heading,
  Divider,
  Stack,
  Text,
  useRadioGroup,
  HStack,
  Box,
  VStack,
  Spacer, Button
} from "@chakra-ui/react";
import React, { useState } from "react";
import RadioCard from "./RadioCard";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";


import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const Authenticate_app = () => {
  return (
    <>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                Why Authenticate
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text>
              Authenticating the purchase of cosmetic products is crucial to
              ensure their genuineness and safety. Counterfeit cosmetics can
              contain harmful ingredients that pose serious health risks, such
              as skin irritations, allergic reactions, and long-term damage.
            </Text>
            <Text mt="5">
            By validating the authenticity of their purchases, users can protect
            themselves from these dangers, ensure they receive the intended
            benefits of high-quality ingredients, and maintain their overall
            skin health. Additionally, authenticating products supports ethical
            business practices and helps maintain the reputation and integrity
            of trusted cosmetic brands.</Text>
      
          </AccordionPanel>
        </AccordionItem>

      </Accordion>
      <Spacer />
      <Selections />
    </>
  );
};

function Selections() {
  const options = [
    "Register purchase",
    "Mark as purchased",
    "Check authenticity",
  ];
  const [selectedValue, setSelectedValue] = useState("react");

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "register",
    onChange: (value) => setSelectedValue(value),
  });

  const group = getRootProps();

  return (
    <>
      <VStack {...group} mt="5" w="100%">
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </VStack>

      <Box mt={4}>
        {selectedValue === "Register purchase" && (
          <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
            <Heading size="21">Settings </Heading>
            <Divider />

            <Text pt="4" fontSize={"17"}>
              Registering the Product, also marks the product as purchased and
              helps check authenticity of the product.
            </Text>
            <Text pt="4" fontSize={"17"}>
              {" "}
              Please make your Privacy selections{" "}
            </Text>

            <VStack p="0" spacing="2" border="0px" fontSize={"15"}>
              <Checkbox size="lg" ml="0" w="100%">
                <Text fontSize={"17"}> Alert me on Product Expiration</Text>
              </Checkbox>
              <Checkbox
                ml="0"
                w="100%"
                size="lg"
                colorScheme="blue"
                defaultChecked
              >
                <Text fontSize={"17"}>
                  {" "}
                  Send me Discounts and offers by mail{" "}
                </Text>
              </Checkbox>
              <Checkbox size="lg" w="100%" colorScheme="blue" defaultChecked>
                <Text fontSize={"17"}> Allow non marketing updates </Text>
              </Checkbox>
            </VStack>
          </Box>
        )}
        {selectedValue === "Mark as purchased" && (
          <Box p={4} bg="white" borderWidth="1px" borderRadius="md">
            
            <Text mb="4">
            Marking as purchased helps us with anti-counterfeiting. Keeping your purchase safe, while not sharing your personal information.
          </Text>
          <Text mb="4">
            Once you mark it as purchased, the product once scanned will show as purchased. If you wish to associate your email with the product, please select Register Purchase option above.
          </Text>
          </Box>
        )}
        {selectedValue === "Check authenticity" && (
          <Box p={4} bg="orange.50" borderWidth="1px" borderRadius="md">
            This is a validation of the product authenticity only.
          </Box>
        )}
        <Box p="2" mt="5">
          <Button w="100%">Continue</Button> 
        </Box>
      </Box>
    </>
  );
}

export default Authenticate_app;
