import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Input,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Tag,
  TagLabel,
  Select,
  useDisclosure,
  DrawerCloseButton,
  SimpleGrid,
  Flex,
  IconButton,
  Switch,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { ViewIcon, ViewOffIcon, CloseIcon } from "@chakra-ui/icons";
import SunburstChart from "./SunburstChart";
import Nav from "./Nav"; // Assuming this is a custom component
import ReactSelect from "react-select";
import { FaSun, FaTable } from "react-icons/fa";
const initialData = {
  stateCategories: [
    {
      name: "User Journey",
      description:
        "Stages of user interaction and engagement with the product.",
      states: [
        {
          name: "Onboarding",
          description:
            "The initial stage where users are getting started with the product.",
        },
        {
          name: "Just Started",
          description:
            "Users have recently started using the product but are still in the early stages of interaction.",
        },
        {
          name: "Long Time",
          description:
            "Users who have been using the product for an extended period and are familiar with its features.",
        },
      ],
    },
    {
      name: "Data Usage",
      description: "Levels of data usage by users in the product.",
      states: [
        {
          name: "Little Data",
          description: "Users who are using a minimal amount of data.",
        },
        {
          name: "Medium Data",
          description: "Users who are using a moderate amount of data.",
        },
        {
          name: "Lots of Data",
          description: "Users who are using a large amount of data.",
        },
      ],
    },
    {
      name: "Engagement Level",
      description: "Different levels of user engagement with the product.",
      states: [
        {
          name: "Low Engagement",
          description: "Users who interact with the product infrequently.",
        },
        {
          name: "Moderate Engagement",
          description:
            "Users who interact with the product regularly but not extensively.",
        },
        {
          name: "High Engagement",
          description:
            "Users who interact with the product frequently and extensively.",
        },
      ],
    },
    {
      name: "Feature Usage",
      description: "Extent of usage of different features within the product.",
      states: [
        {
          name: "Few Features",
          description:
            "Users who utilize only a small number of available features.",
        },
        {
          name: "Some Features",
          description:
            "Users who utilize a moderate number of available features.",
        },
        {
          name: "Many Features",
          description:
            "Users who utilize a large number of available features.",
        },
      ],
    },
  ],
};

const buildHierarchy = (scenarios) => {
  const root = { name: "root", children: [] };
  const idToNodeMap = { root };

  scenarios.forEach(({ id, title, parent }) => {
    const node = { name: title, value: 1, children: [] };
    idToNodeMap[id] = node;

    const parentNode = idToNodeMap[parent] || root;
    parentNode.children.push(node);
  });

  return root;
};

const ThreePartScreen = ({ leftContent, middleContent, rightContent }) => {
  const [showLeft, setShowLeft] = useState(true);
  const [showRight, setShowRight] = useState(true);

  return (
    <Flex height="100vh" overflow={"scroll"}>
      {showLeft && (
        <Box flex="2" bg="blue.100">
          {leftContent}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bg="gray.200"
        flex="4"
      >
        {middleContent}
        <Button mb="4" onClick={() => setShowLeft(!showLeft)} size="sm">
          Toggle Left
        </Button>
        <Button onClick={() => setShowRight(!showRight)} size="sm">
          Toggle Right
        </Button>
      </Box>
      {showRight && (
        <Box flex="2" bg="green.100" display="block">
          {rightContent}
        </Box>
      )}
    </Flex>
  );
};

const ParentScenarioSelect = ({
  scenarios,
  currentScenarioId,
  handleParentChange,
}) => {
  const options = scenarios
    .filter((scenario) => scenario.id !== currentScenarioId)
    .map((scenario) => ({
      value: scenario.id,
      label: scenario.title,
    }));

  return (
    <ReactSelect
      placeholder="Select Parent Scenario"
      options={options}
      onChange={(selectedOption) =>
        handleParentChange(
          currentScenarioId,
          selectedOption ? selectedOption.value : ""
        )
      }
    />
  );
};

const ScenarioList = ({
  scenarios = [],
  handleScenarioTitleChange,
  removeStateFromScenario,
  handleAttributeChange,
}) => {
  const handleInputChange = (scenarioId, attributeTitle, event) => {
    handleAttributeChange(scenarioId, attributeTitle, event.target.value);
  };

  const handleSelectChange = (scenarioId, attributeTitle, event) => {
    handleAttributeChange(scenarioId, attributeTitle, event.target.value);
  };

  const handleMultipleSelectChange = (scenarioId, attributeTitle, event) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    handleAttributeChange(scenarioId, attributeTitle, selectedValues);
  };

  const handleSwitchChange = (scenarioId, attributeTitle, event) => {
    handleAttributeChange(
      scenarioId,
      attributeTitle,
      event.target.checked.toString()
    );
  };

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Scenario Title</Th>
          <Th>States</Th>
          {scenarios.length > 0 &&
            scenarios[0].attributes &&
            scenarios[0].attributes.map((attr) => (
              <Th key={attr.title}>{attr.title}</Th>
            ))}
        </Tr>
      </Thead>
      <Tbody>
        {scenarios.map((scenario) => (
          <Tr key={scenario.id}>
            <Td>
              <Input
                value={scenario.title}
                onChange={(e) =>
                  handleScenarioTitleChange(scenario.id, e.target.value)
                }
                placeholder="Scenario Title"
                variant="flushed"
              />
            </Td>
            <Td>
              <Flex wrap="wrap">
                {scenario.states.map((state) => (
                  <Tag key={state} size="lg" colorScheme="teal" m={1}>
                    <TagLabel>{state}</TagLabel>
                    <IconButton
                      icon={<CloseIcon />}
                      size="xs"
                      variant="unstyled"
                      onClick={() =>
                        removeStateFromScenario(scenario.id, state)
                      }
                      aria-label="Remove state"
                    />
                  </Tag>
                ))}
              </Flex>
            </Td>
            {scenario.attributes &&
              scenario.attributes.map((attribute) => (
                <Td key={attribute.title}>
                  {attribute.type === "text" && (
                    <Input
                      value={attribute.defaultValue}
                      onChange={(e) =>
                        handleInputChange(scenario.id, attribute.title, e)
                      }
                      placeholder="Enter text"
                    />
                  )}
                  {attribute.type === "single" && (
                    <Select
                      value={attribute.defaultValue}
                      onChange={(e) =>
                        handleSelectChange(scenario.id, attribute.title, e)
                      }
                    >
                      {attribute.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                  {attribute.type === "multiple" && (
                    <Select
                      multiple
                      value={attribute.defaultValue}
                      onChange={(e) =>
                        handleMultipleSelectChange(
                          scenario.id,
                          attribute.title,
                          e
                        )
                      }
                    >
                      {attribute.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                  {attribute.type === "boolean" && (
                    <Switch
                      isChecked={attribute.defaultValue === "true"}
                      onChange={(e) =>
                        handleSwitchChange(scenario.id, attribute.title, e)
                      }
                    />
                  )}
                </Td>
              ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const FunctionalStates = () => {
  const [stateData, setStateData] = useState(initialData);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newStateName, setNewStateName] = useState("");
  const [newStates, setNewStates] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFirstOpen,
    onOpen: onFirstOpen,
    onClose: onFirstClose,
  } = useDisclosure();

  const [isListView, setIsListView] = useState(true);
  const [parentScenarios, setParentScenarios] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [attributeType, setAttributeType] = useState("text");
  const [defaultValueInput, setDefaultValueInput] = useState("");
  const [selectedDefaultValue, setSelectedDefaultValue] = useState("");
  const [attributeOptions, setAttributeOptions] = useState([]);

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions || []);
  };

  const generateCombinations = (arrays) => {
    const result = [[]];
    for (const array of arrays) {
      const temp = [];
      for (const res of result) {
        for (const item of array) {
          temp.push([...res, item]);
        }
      }
      result.splice(0, result.length, ...temp);
    }
    return result;
  };

  const generateScenarios = () => {
    if (selectedCategories.length > 0) {
      const allStates = selectedCategories.map((category) =>
        category.states.map((state) => state.name)
      );
      const combinations = generateCombinations(allStates);
      const newScenarios = combinations.map((combination) => ({
        id: nanoid(),
        title: `Scenario ${nanoid(5)}`,
        states: combination,
      }));

      setScenarios((prevScenarios) => [...prevScenarios, ...newScenarios]);
    }
  };

  const handleParentChange = (scenarioId, parentId) => {
    const parentScenario = scenarios.find(
      (scenario) => scenario.id === parentId
    );
    const parentTitle = parentScenario ? parentScenario.title : "";

    setParentScenarios((prev) => ({ ...prev, [scenarioId]: parentId }));

    setScenarios((prevScenarios) =>
      prevScenarios.map((scenario) =>
        scenario.id === scenarioId
          ? { ...scenario, parent: parentId, scenarioParent: parentTitle }
          : scenario
      )
    );
  };

  useEffect(() => {
    console.log(scenarios, "scenarios");
  }, [scenarios]);

  const addState = () => {
    if (newStateName) {
      setNewStates([...newStates, { name: newStateName, description: "" }]);
      setNewStateName("");
    }
  };

  const handleNewCategorySubmit = () => {
    if (newCategoryName && newStates.length > 0) {
      const newCategory = {
        name: newCategoryName,
        description: "",
        states: newStates,
      };
      setStateData({
        ...stateData,
        stateCategories: [...stateData.stateCategories, newCategory],
      });
      setNewCategoryName("");
      setNewStates([]);
      onClose();
    }
  };

  const removeStateFromScenario = (scenarioId, stateName) => {
    setScenarios(
      scenarios.map((scenario) => {
        if (scenario.id === scenarioId) {
          return {
            ...scenario,
            states: scenario.states.filter((state) => state !== stateName),
          };
        }
        return scenario;
      })
    );
  };

  const handleScenarioTitleChange = (scenarioId, newTitle) => {
    setScenarios(
      scenarios.map((scenario) => {
        if (scenario.id === scenarioId) {
          return { ...scenario, title: newTitle };
        }
        return scenario;
      })
    );
  };

  const hierarchicalData = buildHierarchy(scenarios); // Assuming this is a function you've defined
  console.log("Hierarchical Data:", hierarchicalData);

  useEffect(() => {
    console.log(parentScenarios, scenarios, "parentScenarios");
  }, [parentScenarios, scenarios, hierarchicalData]);

  useEffect(() => {
    console.log(attributes, "attributes");
  }, [attributes]);

  const handleAddAttribute = () => {
    // Create the new attribute object
    const newAttribute = {
      title: newCategoryName,
      type: attributeType,
      options: attributeOptions,
      defaultValue: selectedDefaultValue,
    };

    // Update the attributes state
    setAttributes((prevAttributes) => [...prevAttributes, newAttribute]);

    // Close the drawer or modal
    onFirstClose();
  };

  useEffect(() => {
    const handleNewAttributeSubmit = () => {
      setScenarios((prevScenarios) =>
        prevScenarios.map((scenario) => {
          const updatedAttributes = attributes.map((attribute) => {
            let defaultVal = attribute.defaultValue;
            if (attribute.type === "boolean") {
              defaultVal = defaultVal === "true";
            } else if (attribute.type === "single") {
              defaultVal = defaultVal;
            } else if (attribute.type === "multiple") {
              defaultVal = attribute.options.filter(
                (option) => option.value === defaultVal
              );
            }
            return { ...attribute, defaultValue: defaultVal };
          });
          return { ...scenario, attributes: updatedAttributes };
        })
      );
    };

    // Run the function to update scenarios whenever attributes change
    handleNewAttributeSubmit();
  }, [attributes]);

  const handleAttributeTypeChange = (event) => {
    setAttributeType(event.target.value);
    setDefaultValueInput("");
    setAttributeOptions([]);
  };

  const handleAddOption = () => {
    if (defaultValueInput.trim() !== "") {
      setAttributeOptions([
        ...attributeOptions,
        { value: defaultValueInput, label: defaultValueInput },
      ]);
      setDefaultValueInput("");
    }
  };

  const handleAttributeChange = (scenarioId, attributeTitle, newValue) => {
    setScenarios(prevScenarios => 
      prevScenarios.map(scenario => {
        if (scenario.id === scenarioId) {
          return {
            ...scenario,
            attributes: scenario.attributes.map(attr => 
              attr.title === attributeTitle ? { ...attr, defaultValue: newValue } : attr
            )
          };
        }
        return scenario;
      })
    );
  };
  

  const [view, setView] = useState("sunburst");

  return (
    <ChakraProvider>
      <Nav />
      {console.log(stateData.stateCategories, "stateData")}
      <ThreePartScreen
        leftContent={
          <Box p={4}>
            <ReactSelect
              isMulti
              options={stateData.stateCategories.map((category) => ({
                value: category,
                label: category.name,
                states: category.states,
              }))}
              onChange={(selectedOptions) =>
                handleCategoryChange(selectedOptions)
              }
              placeholder="Select State Categories"
            />
            <Button mt={4} onClick={generateScenarios}>
              Generate Scenarios
            </Button>
            <Button mt={4} ml={4} onClick={onOpen}>
              Add New State Category
            </Button>
            <Button mt={4} ml={4} onClick={() => setIsListView(!isListView)}>
              {isListView ? <ViewIcon /> : <ViewOffIcon />}
            </Button>

            {isListView ? (
              <VStack mt={4} spacing={4} display={"block"}>
                {scenarios.map((scenario) => (
                  <Box
                    key={scenario.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    w="100%"
                  >
                    <HStack spacing={2} mb={2}>
                      <Input
                        value={scenario.title}
                        onChange={(e) =>
                          handleScenarioTitleChange(scenario.id, e.target.value)
                        }
                        placeholder="Scenario Title"
                      />
                    </HStack>
                    <HStack spacing={2} mb={2}>
                      <ParentScenarioSelect
                        scenarios={scenarios}
                        currentScenarioId={scenario.id}
                        handleParentChange={handleParentChange}
                      />
                    </HStack>
                    <HStack spacing={2}>
                      {scenario.states.map((state) => (
                        <Tag key={state} size="lg" colorScheme="teal">
                          <TagLabel>{state}</TagLabel>
                          <IconButton
                            icon={<CloseIcon />}
                            size="xs"
                            variant="unstyled"
                            onClick={() =>
                              removeStateFromScenario(scenario.id, state)
                            }
                            aria-label="Remove state"
                          />
                        </Tag>
                      ))}
                    </HStack>
                  </Box>
                ))}
              </VStack>
            ) : (
              <SimpleGrid columns={[1, null, 2]} spacing={4} mt={4}>
                {scenarios.map((scenario) => (
                  <Box
                    key={scenario.id}
                    p={4}
                    borderWidth="1px"
                    borderRadius="lg"
                  >
                    <Flex justify="space-between" mb={2}>
                      <Input
                        value={scenario.title}
                        onChange={(e) =>
                          handleScenarioTitleChange(scenario.id, e.target.value)
                        }
                        placeholder="Scenario Title"
                        variant="flushed"
                      />
                    </Flex>
                    <Flex wrap="wrap">
                      {scenario.states.map((state) => (
                        <Tag key={state} size="lg" colorScheme="teal" m={1}>
                          <TagLabel>{state}</TagLabel>
                          <IconButton
                            icon={<CloseIcon />}
                            size="xs"
                            variant="unstyled"
                            onClick={() =>
                              removeStateFromScenario(scenario.id, state)
                            }
                            aria-label="Remove state"
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </Box>
        }
        middleContent={
          <Box w="100%" p="0" m="0">
            <Flex justify="flex-end" mb={4}>
              <IconButton
                icon={<FaSun />}
                onClick={() => setView("sunburst")}
                aria-label="Sunburst View"
                isActive={view === "sunburst"}
                m={1}
              />
              <IconButton
                icon={<FaTable />}
                onClick={() => setView("table")}
                aria-label="Table View"
                isActive={view === "table"}
                m={1}
              />
            </Flex>
            {view === "sunburst" ? (
              <Box>
                <SunburstChart data={hierarchicalData} />
              </Box>
            ) : (
              <Box>
                <ScenarioList
                  scenarios={scenarios}
                  handleScenarioTitleChange={handleScenarioTitleChange}
                  removeStateFromScenario={removeStateFromScenario}
                  handleAttributeChange={handleAttributeChange}
                />
              </Box>
            )}
          </Box>
        }
        rightContent={
          <Box>
            <Button onClick={onFirstOpen}>Open First Dialog</Button>
          </Box>
        }
      />

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create a new state category</DrawerHeader>

            <DrawerBody>
              <FormControl mb={4}>
                <FormLabel>Category Name</FormLabel>
                <Input
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder="Category Name"
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>State Name</FormLabel>
                <HStack>
                  <Input
                    value={newStateName}
                    onChange={(e) => setNewStateName(e.target.value)}
                    placeholder="State Name"
                  />
                  <Button onClick={addState}>Add State</Button>
                </HStack>
              </FormControl>
              <VStack spacing={2}>
                {newStates.map((state, index) => (
                  <Tag key={index} size="lg" colorScheme="teal">
                    <TagLabel>{state.name}</TagLabel>
                  </Tag>
                ))}
              </VStack>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleNewCategorySubmit}>
                Submit
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Drawer
        isOpen={isFirstOpen}
        size="lg"
        placement="right"
        onClose={onFirstClose}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create / Edit Attribute</DrawerHeader>

            <DrawerBody>
              <FormControl mb={4}>
                <FormLabel>Attribute Title</FormLabel>
                <Input
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  placeholder="Attribute Title"
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Attribute Type</FormLabel>
                <Select
                  value={attributeType}
                  onChange={handleAttributeTypeChange}
                >
                  <option value="text">Text</option>
                  <option value="single">Single Select</option>
                  <option value="multiple">Multiple Select</option>
                  <option value="boolean">Boolean</option>
                </Select>
              </FormControl>
              {(attributeType === "single" || attributeType === "multiple") && (
                <>
                  <FormControl mb={4}>
                    <FormLabel>Options</FormLabel>
                    <HStack>
                      <Input
                        value={defaultValueInput}
                        onChange={(e) => setDefaultValueInput(e.target.value)}
                        placeholder="Option Value"
                      />
                      <Button onClick={handleAddOption}>Add Option</Button>
                    </HStack>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Default Value</FormLabel>
                    <Select
                      value={selectedDefaultValue}
                      onChange={(e) => setSelectedDefaultValue(e.target.value)}
                    >
                      {attributeOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <VStack mt={2} spacing={2}>
                    {attributeOptions.map((option, index) => (
                      <Tag key={index} size="lg" colorScheme="teal">
                        <TagLabel>{option.label}</TagLabel>
                      </Tag>
                    ))}
                  </VStack>
                </>
              )}
              {attributeType === "text" && (
                <FormControl mb={4}>
                  <FormLabel>Default Value</FormLabel>
                  <Input
                    value={selectedDefaultValue}
                    onChange={(e) => setSelectedDefaultValue(e.target.value)}
                    placeholder="Default Value"
                  />
                </FormControl>
              )}
              {attributeType === "boolean" && (
                <FormControl mb={4}>
                  <FormLabel>Default Value</FormLabel>
                  <Select
                    value={selectedDefaultValue}
                    onChange={(e) => setSelectedDefaultValue(e.target.value)}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Select>
                </FormControl>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onFirstClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleAddAttribute}>
                Submit
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </ChakraProvider>
  );
};

export default FunctionalStates;
