import React, { useContext } from 'react';
import { Box, Text, VStack, HStack, Button, Heading, Divider, Input   } from '@chakra-ui/react';
import CartContext from './CartContext';
import { GiChatBubble } from 'react-icons/gi';
import { FiMessageCircle, FiPhone, FiMail } from 'react-icons/fi';

const CartPage = () => {
  const { cart } = useContext(CartContext);

  if (cart.length === 0) {
    return <Box p={5}><Text>No items in the cart</Text></Box>;
  }

  return (
    <Box p={5} >
      <Text fontSize="2xl" fontWeight={"bold"} mb={5}>Order Summary </Text>
      <Divider />
      <VStack spacing={4} mt="5">
        {cart.map((item, index) => (
          <Box key={index}  borderWidth="1px" borderRadius="lg" p={4} w="100%">
            <Text fontSize="xl" fontWeight={"bold"}>{item.name}</Text>
            <Text mt="3" borderRadius={"md"} border="1px" borderColor={"gray.100"} p="2"> {item.size}</Text>
            {/* <Text>Dimensions: {item.dimensions.length} x {item.dimensions.width} x {item.dimensions.height}</Text> */}
            {/* <Text>Quantity: {item.quantity}</Text> */}
            <Input value={item.quantity} mt="3" />
            <Text>{item.needByDate}</Text>
            <Text mt="3" borderRadius={"md"} border="1px" borderColor={"gray.100"} p="2">  Pla {item.comments}</Text>

          </Box>
        ))}
      </VStack>
     
      <HStack mt={5}>
      <Button variant={"outline"}> <FiMail /> &nbsp; Email </Button>  <Button variant={"outline"}> <FiPhone /> &nbsp; Phone </Button>  <Button variant={"outline"}> <FiMessageCircle /> &nbsp; Chat </Button> <Button colorScheme="blue">Checkout</Button>
      </HStack>
    </Box>
  );
};

export default CartPage;
