import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button, Divider, 
  DrawerFooter
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import EditTimeline from './EditTimeline';
const EditDrawer = ({
  isOpen,
  onClose,
  formValues,
  handleInputChange,
  handleSave,
  editCircle
}) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {editCircle ? "Edit Circle 1" : "Add Circle"}
        </DrawerHeader>
        <Divider />
        <DrawerBody>
        <FormControl mb={4}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
            />
          </FormControl>
        <Tabs>
  <TabList>
    <Tab>Data</Tab>
    <Tab>Activities </Tab>
    <Tab>Timeline</Tab>
  </TabList>

  <TabPanels>
    <TabPanel>
     
    <FormControl mb={4}>
            <FormLabel>Percentage</FormLabel>
            <Input
              name="percentage"
              type="number"
              value={formValues.percentage}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Importance</FormLabel>
            <Select
              name="importance"
              value={formValues.importance}
              onChange={handleInputChange}
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Value</FormLabel>
            <Input
              name="value"
              type="number"
              value={formValues.value}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Group</FormLabel>
            <Input
              name="group"
              value={formValues.group}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Color</FormLabel>
            <Select
              name="color"
              value={formValues.color}
              onChange={handleInputChange}
            >
              {["red", "blue", "green", "yellow", "black"].map((color) => (
                <option key={color} value={color}>
                  {color}
                </option>
              ))}
            </Select>
          </FormControl>
    </TabPanel>
    <TabPanel>
      <EditTimeline />
    </TabPanel>
    <TabPanel>
      <p>three!</p>
    </TabPanel>
  </TabPanels>
</Tabs>
   
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </DrawerBody>
        <DrawerFooter
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Button variant="outline" onClick={onClose}>
              Cancel</Button>
            </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};



export default EditDrawer;
