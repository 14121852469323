import React from 'react';
import { HStack, Icon, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';

const NavItem = ({ icon, label, to, isLeftColumnOpen }) => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const location = useLocation();

  const isActive = location.pathname === to;
  const bgColor = isActive ? (colorMode === 'light' ? 'blue.100' : 'blue.900') : 'transparent';

  return (
    <Tooltip label={label} aria-label={label}>
      <HStack
        spacing={4}
        alignItems="center"
        mb={0}
        p="3"
        bg={bgColor}
        as="Button"
        textAlign={"left"}
        w="100%"
        onClick={() => navigate(to)}
      >
        <Icon as={icon} boxSize={4} />
        <Text display={isLeftColumnOpen ? 'inline' : 'none'}>
          {label}
        </Text>
      </HStack>
    </Tooltip>
  );
};

export default NavItem;
