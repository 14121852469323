import React from 'react';
import { ChakraProvider, Box, Heading, Text } from '@chakra-ui/react';

function WorkCenter() {
    return (

            <Box p={4}>
                <Heading size="lg" mb={2}>Hello, Chakra UI!</Heading>
                <Text fontSize="xl">Welcome to your React app with Chakra UI.</Text>
            </Box>
    
    );
}

export default WorkCenter;