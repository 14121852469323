import Authenticate_app from "../common/authenticate";
import React, { useState } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Tooltip,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { FaHeart, FaStar, FaSmile, FaSun, FaGrinHearts } from "react-icons/fa";
import { GrBarChart, GrFingerPrint, GrGift } from "react-icons/gr";
import { BiVideo, BiSmile, BiSearch, BiMessage, BiNotification, BiBell, BiGrid, BiLoader } from "react-icons/bi";
import { VscSymbolColor } from "react-icons/vsc";

import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiHeart, FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import ReactSelect from "react-select";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

import { Global, css, keyframes } from "@emotion/react";

const icons = [
  {
    component: GrFingerPrint,
    label: "Product Verfication",
    size: "20px",
    delay: 0,
    bgColor: "yellow.500",
    iconColor: "white",
    route: "/verfication"

  },
  {
    component: FiStar,
    label: "Reviews and Ratings",
    size: "60px",
    delay: 0.1,
    bgColor: "red.500",
    iconColor: "white",
    route: "/reviews"

  },
  {
    component: VscSymbolColor,
    label: "Themes and Backgrounds",
    size: "150px",
    delay: 0.3,
    bgColor: "yellow.400",
    iconColor: "white",
    route: "/themes"

  },
  {
    component: BiVideo,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
    route: "/media"

  },
  {
    component: BiSearch,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
    route: "/ingredients"

  },
  {
    component: BiBell,
    label: "Expiry Notification",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
    route: "/expiry"

  },
  {
    component: GrBarChart,
    label: "Analytics",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
    route: "/analytics"

  },
  {
    component: FiHeart,
    label: "Loyalty",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
    route: "/loyalty"

  },
];

const drop = keyframes`
  0% { top: -50%; }
  100% { top: 110%; }
`;

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #171717;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

  .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 75%,
      #ffffff 100%
    );
    animation: ${drop} 7s 0s infinite cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  .line:nth-of-type(1) {
    margin-left: -25%;
  }

  .line:nth-of-type(1)::after {
    animation-delay: 2s;
  }

  .line:nth-of-type(3) {
    margin-left: 25%;
  }

  .line:nth-of-type(3)::after {
    animation-delay: 2.5s;
  }
`;

// Function to generate random size and margins
const getRandomSizeAndMargin = () => {
  const size = Math.floor(Math.random() * 50) + 50; // Random size between 50px and 100px
  const margin = Math.floor(Math.random() * 20) + 10; // Random margin between 10px and 30px
  return { size, margin };
};

const AnimatedBox = motion(Box);
const MotionBox = motion(Box);

const Home = () => {
  const [isOpen, setIsOpen] = useState({
    benefits: false,
    ingredients: false,
    usingProduct: false,
    standards: false,
    reviews: false,
    authenticate: false,
  });

  const openModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: true }));
  };

  const closeModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: false }));
  };

  const [selectedColor, setSelectedColor] = useState("white");

  const colors = ["yellow", "blue", "white", "red"];
  const displayColors = {
    yellow: "Yellow",
    blue: "Blue",
    white: "White",
    red: "Red",
  };

  const colorStyles = {
    yellow: {
      bg: "yellow.300",
      color: "black",
    },
    blue: {
      bg: "blue.300",
      color: "white",
    },
    white: {
      bg: "gray.100",
      color: "black",
      gray: "gray.100",
    },
    red: {
      bg: "red.300",
      color: "white",
    },
  };

  const navigate = useNavigate();

  const handleClick = (route) => {
    // Navigate to the specified route upon icon click
    navigate(route);
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Box position="relative" overflowY="auto" height="100vh">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <AnimatedBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          color="white"
          bg="#191D26"
          minH="100vh"
          overflowY="auto"
        >
          
          <Container textAlign={"center"} pt="10">
            <SimpleGrid columns={{ base: 2, md: 4, lg: 4 }} spacing="6" p={10}>
              {icons.map((icon, index) => {
                const { size, margin } = getRandomSizeAndMargin();
                return (
                  <Tooltip label={icon.label} key={index}>
                    <MotionBox
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                      // boxSize={`${size}px`}
                      cursor="pointer"
                      color={icon.bgColor}
                      whileHover={{ scale: 1.2 }}
                      animate={{ scale: [1, 1.1, 1] }}
                      transition={{
                        duration: 2,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatType: "reverse",
                        delay: icon.delay,
                      }}
                      onClick={() => handleClick(icon.route)}
                      border="1px solid #333"
                      p="4"
                      w="100%"
                      >
                      <Icon
                        as={icon.component}
                        boxSize={21}
                        color={icon.iconColor}
                      />
                    </MotionBox>
                  </Tooltip>
                );
              })}
            </SimpleGrid>
            <Heading
              w="100%"
              fontSize={"61"}
              color="yellow.300"
              fontWeight={"black"}
            >
              RMF for Cosmetics and Skin Care
            </Heading>
            <Text fontSize={"21"} mt="10">
              Atakian is an innovative post-purchase engagement tool tailored
              for the Cosmetics industry, designed to enhance customer
              experience and ensure product safety and satisfaction.
            </Text>
            <Text fontSize={"21"} mt="10">
              The <Box as="span" textDecoration="underline">Atakian Retention Management Framework </Box>tackles the task of
              enhancing customer loyalty and lifetime value through personalized
              engagement strategies, data-driven insights, and ongoing
              optimization efforts, fostering enduring relationships and driving
              sustainable growth.
            </Text>
            <ButtonGroup spacing="4" mt="10">
              <Button variant="outline" color="white"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >
                Request Demo
              </Button>
              <Button variant="solid"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >Sign up for Trial</Button>
            </ButtonGroup>
          </Container>
          <Container mt="50" color="gray.400" mb="40">
            All Rights Reserved 2024, Tangible Spin LLP. D-U-N-S 878296827
          </Container>
        </AnimatedBox>
      </Box>
    </>
  );
};

export default Home;
