import Authenticate_app from "../common/authenticate";
import { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Tooltip,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { FaHeart, FaStar, FaSmile, FaSun } from "react-icons/fa";
import { GrFingerPrint, GrGift, GrList } from "react-icons/gr";
import { BiVideo, BiSmile, BiSearch, BiMessage } from "react-icons/bi";

import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import ReactSelect from "react-select";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

import { Global, css, keyframes } from "@emotion/react";

const headlines = {
  headlines: [
    "Safeguarding Your Beauty: Our Trusted Anti-Counterfeiting Measures for Premium Cosmetics",
    "Defending Your Glow: Discover Our Advanced Anti-Counterfeiting Solutions for Luxury Cosmetics",
    "Authenticity Assured: Explore Our Innovative Anti-Counterfeiting Techniques for Cosmetics",
    "Beauty Beyond Replicas: Our Commitment to Anti-Counterfeiting in Cosmetics",
    "Shielding Your Elegance: Unveil Our Cutting-Edge Anti-Counterfeiting Technology for Cosmetics",
    "Genuine Glamour: How We Ensure Authenticity with Our Anti-Counterfeiting Practices in Cosmetics",
    "Empowering Your Beauty Journey: Explore Our Anti-Counterfeiting Initiatives in Cosmetics",
    "Flawless Trust: Uncover Our Proven Anti-Counterfeiting Solutions for Cosmetics",
    "Elevate Your Ritual: Discover Our Rigorous Anti-Counterfeiting Measures for Cosmetics",
    "Beauty Unmasked: Our Pioneering Anti-Counterfeiting Strategies for Cosmetics",
    "Pure Confidence: Trust Our Anti-Counterfeiting Protocols for Authentic Cosmetics",
    "Radiance with Reliability: Our Unwavering Commitment to Anti-Counterfeiting in Cosmetics",
    "A Defiant Stand Against Imitations: Learn About Our Anti-Counterfeiting Efforts in Cosmetics",
    "Unlock Authentic Beauty: How Our Anti-Counterfeiting Measures Ensure Genuine Cosmetics",
    "Innovation Meets Integrity: Dive into Our Anti-Counterfeiting Solutions for Cosmetics",
    "Your Beauty, Our Priority: Discover Our Vigilant Anti-Counterfeiting Approach to Cosmetics",
    "Beautify with Confidence: Our Proactive Anti-Counterfeiting Strategies for Cosmetics",
  ],
};

const icons = [
  {
    component: GrFingerPrint,
    label: "Product Verfication",
    size: "20px",
    delay: 0,
    bgColor: "yellow.500",
    iconColor: "white",
  },
  {
    component: FiStar,
    label: "Reviews and Ratings",
    size: "60px",
    delay: 0.1,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSmile,
    label: "Refferals",
    size: "150px",
    delay: 0.3,
    bgColor: "yellow.400",
    iconColor: "white",
  },
  {
    component: BiVideo,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSearch,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
];

const drop = keyframes`
  0% { top: -50%; }
  100% { top: 110%; }
`;

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #171717;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

  .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 75%,
      #ffffff 100%
    );
    animation: ${drop} 7s 0s infinite cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  .line:nth-of-type(1) {
    margin-left: -25%;
  }

  .line:nth-of-type(1)::after {
    animation-delay: 2s;
  }

  .line:nth-of-type(3) {
    margin-left: 25%;
  }

  .line:nth-of-type(3)::after {
    animation-delay: 2.5s;
  }
`;

// Function to generate random size and margins
const getRandomSizeAndMargin = () => {
  const size = Math.floor(Math.random() * 50) + 50; // Random size between 50px and 100px
  const margin = Math.floor(Math.random() * 20) + 10; // Random margin between 10px and 30px
  return { size, margin };
};

const AnimatedBox = motion(Box);
const MotionBox = motion(Box);

const Verification = () => {
  const [isOpen, setIsOpen] = useState({
    benefits: false,
    ingredients: false,
    usingProduct: false,
    standards: false,
    reviews: false,
    authenticate: false,
  });

  const openModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: true }));
  };

  const closeModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: false }));
  };

  const [selectedColor, setSelectedColor] = useState("white");

  const colors = ["yellow", "blue", "white", "red"];
  const displayColors = {
    yellow: "Yellow",
    blue: "Blue",
    white: "White",
    red: "Red",
  };

  const colorStyles = {
    yellow: {
      bg: "yellow.300",
      color: "black",
    },
    blue: {
      bg: "blue.300",
      color: "white",
    },
    white: {
      bg: "gray.100",
      color: "black",
      gray: "gray.100",
    },
    red: {
      bg: "red.300",
      color: "white",
    },
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Box position="relative" overflowY="auto" height="100vh">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <AnimatedBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          color="white"
          bg="#191D26"
          minH="100vh"
          overflowY="auto"
        >
          <Container textAlign={"center"} pt="10">
            <Center mb="10">
              <GrFingerPrint size="51" mb="10" />
            </Center>
            <HeadlineDisplay mt="10" headlines={headlines.headlines} />
            <Text fontSize={"21"} mt="10">
              Verifying product authenticity stands as an indispensable pillar
              for brands, safeguarding their esteemed reputation and nurturing
              unwavering trust among customers.
            </Text>
            <Text fontSize={"21"} mt="10">
              Upholding brand integrity but also shields consumers from
              counterfeit replicas, ensuring they receive products of genuine
              quality, thereby elevating overall customer satisfaction and
              bolstering long-term brand loyalty with unwavering certainty.
            </Text>
            <ButtonGroup spacing="4" mt="10">
              <Button variant="outline" color="white"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >
                Request Demo
              </Button>
              <Button variant="solid"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >Sign up for Trial</Button>
            </ButtonGroup>
          </Container>

          <Container></Container>

          <Box bg="gray.50" color="black" w="100%" mt="10">
            <Container lineHeight={"2"} p="4">
              <Heading>
                Ensuring Authenticity and Safety: How Anti-Counterfeiting
                Measures Secure the Future of Cosmetic Brands
              </Heading>

              <Text mt="10">
                Counterfeiting in the cosmetics industry refers to the illegal
                imitation or replication of popular cosmetic products for the
                purpose of deceiving consumers into believing they're buying the
                real thing. These fakes are often made with cheap, low-quality
                ingredients, and can pose serious health risks to consumers.
              </Text>
              <Heading size="lg" as="h2" mt="10">
                Counterfeit cosmetics products are bad for business
              </Heading>
              <Divider />

              <Image borderRadius={"md"} mt="10" src="/anti.png" />
              <List mt="5">
                <ListItem>
                  {" "}
                  <ListIcon as={GrList} mt={5} color="red.500" />
                  Consumer Health Risks: Counterfeit cosmetics frequently
                  contain harmful substances like lead, arsenic, and mercury.
                  These ingredients can cause allergic reactions, skin
                  irritation, and even serious health problems [SCRIBOS,
                  Counterfeiting and cosmetics: A significant problem for
                  consumers] cosméticos-a-significant-problem-for-consumers).
                </ListItem>

                <ListItem mt="5">
                  {" "}
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Loss of Brand Reputation: When a consumer has a negative
                  experience with a counterfeit product, they may associate that
                  experience with the real brand, damaging the brand's
                  reputation AlpVision, Counterfeit Cosmetics Can Damage Brand
                  Reputation More than Grey Market .
                </ListItem>

                <ListItem mt="5">
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Economic Impact: Counterfeiting leads to lost sales for
                  legitimate cosmetic companies, hurting their bottom line. The
                  industry loses billions of dollars annually due to
                  counterfeiting The Personal Care Association, Counterfeit -
                  Cosmetics Europe.
                </ListItem>

                <ListItem mt="5">
                  <ListIcon as={GrList} color="red.500" mt="5" />
                  Undermines Innovation: When counterfeiters copy successful
                  products, it discourages legitimate companies from investing
                  in research and development of new products.
                </ListItem>
              </List>

              <Heading color="gray.800" size="lg" as="h2" mt="10">
                Atakian anti-counterfeiting solutions can help! 
              </Heading>
              <Divider />
              <Image borderRadius={"md"} mt="10" src="/face.png" />

              <Text mt="10">
                Creating an anti-counterfeiting strategy is critical for
                cosmetic brands, safeguarding both their reputation and the
                health of their consumers. Counterfeit cosmetics often contain
                harmful substances that can lead to serious health issues,
                making it imperative for brands to implement robust
                anti-counterfeiting measures. With the advanced features of
                Atakian Engage, cosmetic brands can enhance their packaging with
                serialized Atakian QR codes and NFC tags, providing consumers
                with secure and reliable authentication methods.
              </Text>

              <Text mt="5">
                Atakian Engage offers a range of components and tools that make
                it easy to integrate anti-counterfeiting measures into cosmetic
                packaging. Serialized Atakian QR codes can be generated and
                added to product labels using Atakian Engage's QR code
                component. These unique codes allow consumers to scan the
                product with their smartphone to verify its authenticity
                instantly. By leveraging Atakian Engage's QR code component,
                cosmetic brands can ensure that each product has a unique
                identifier, making it difficult for counterfeiters to replicate.
              </Text>
              <Text mt="5">
                NFC tags are another effective anti-counterfeiting measure that
                can be seamlessly integrated into cosmetic packaging using
                Atakian Engage. NFC tags can store a variety of information
                about the product, such as its manufacturing date, distribution
                history, and authenticity status. By incorporating NFC tags into
                their packaging, cosmetic brands can provide consumers with an
                interactive and engaging way to verify the authenticity of their
                products.
              </Text>
              <ButtonGroup spacing="4" mt="30" mb="30" theme="teal" w="100%">
                <Button bg="gray.500" w="100%">
                  Request Demo
                </Button>
                <Button w="100%" bg="facebook.700" variant="solid">
                  Sign up for Trial
                </Button>
              </ButtonGroup>
            </Container>
          </Box>
        </AnimatedBox>
      </Box>

      <Container mt="50" color="gray.400" mb="40">
        All Rights Reserved 2024, Tangible Spin LLP. D-U-N-S 878296827
      </Container>
    </>
  );
};

const HeadlineDisplay = ({ headlines }) => {
  const [randomHeadline, setRandomHeadline] = useState("");

  useEffect(() => {
    // Function to select a random headline
    const getRandomHeadline = () => {
      const randomIndex = Math.floor(Math.random() * headlines.length);
      return headlines[randomIndex];
    };

    // Set random headline on initial render
    setRandomHeadline(getRandomHeadline());
  }, [headlines]);

  return (
    <Heading w="100%" fontSize={"41"} color="yellow.300" fontWeight={"black"}>
      {randomHeadline}
    </Heading>
  );
};

export default Verification;
