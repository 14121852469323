import Authenticate_app from "../common/authenticate";
import React, { useState } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  VStack,
  Heading,
  Tag,
} from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams, useNavigate } from 'react-router-dom';

import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";

import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import SalesOrder from "./salesOrder";
const SalesOrderHome = () => {

    const navigate = useNavigate();

  const [isLeftColumnOpen, setIsLeftColumnOpen] = useState(true);
  const { colorMode, toggleColorMode } = useColorMode();
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleLeftColumn = () => {
    setIsLeftColumnOpen(!isLeftColumnOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <Flex minH="120vh" border="0px" w="100%">
      <Box
        flex={isLeftColumnOpen ? 2 : "0 0 10"}
        transition="flex 0.3s"
        backgroundColor={colorMode === "light" ? "white" : "gray.800"}
        p={6}
        border="0px"
        display={{ base: "none", md: "block" }}
      >
        <HStack>
          <Center>
            <Avatar
              size={isLeftColumnOpen ? "sm" : "md"}
              name="Ryan Florence"
              src="/Birdeyebird.png"
            />

            <Text
              fontWeight={"light"}
              fontSize={"21"}
              color="gray.500"
              display={isLeftColumnOpen ? 1 : "none"}
              p="4"
            >
              {" "}
              Stone Creek Communities{" "}
            </Text>
          </Center>
        </HStack>
        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <HStack mt="5" textAlign={"center"}>
          <Avatar
            size={isLeftColumnOpen ? "sm" : "md"}
            name="Ryan Florence"
            src="https://bit.ly/dan-abramov"
          />
          <Text
            fontSize={"21"}
            fontWeight={"light"}
            display={isLeftColumnOpen ? 1 : "none"}
            color="gray.500"
            as="Button"
            textAlign={"left"}
            p="4"
          >
            {" "}
            Jeanne T. Farris, Front Office Assistant{" "}
          </Text>{" "}
        </HStack>

        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <Box
          mt="5"
          //   backgroundColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
          height="100vh"
          p={0}
        >
          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
            onClick={() => navigate('/manish/')}

          >
            <Icon as={GrHomeRounded} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Dashboard</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrCalendar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Masters</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrVolume} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Manufacturing</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "blue.100" : "blue.900"}

            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={FiMessageSquare} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Sales Order Process</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiDollarSign} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Vendor Order Process</Text>
          </HStack>

       

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Manufacturing Operations </Text>
          </HStack>


          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Job Work Process </Text>
          </HStack>


          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Inventory Control  </Text>
          </HStack>

          <Divider
            mt="4"
            w="100%"
            bg={colorMode === "light" ? "white" : "blue.900"}
          />

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiSettings} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Settings</Text>
          </HStack>
        </Box>
      </Box>

      <Box
        flex={6}
        backgroundColor={colorMode === "light" ? "gray.100" : "gray.700"}
        p={2}
      >
        {/* <HStack>
          <FullWidthDrawer />

          <Avatar name="Ryan Florence" src="/Birdeyebird.png" size="sm" />
          <Text
            fontWeight={"light"}
            fontSize={"21"}
            color="gray.500"
            display={1}
            lineHeight={1}
            p="4"
          >
            Stone Creek Communities
          </Text>
        </HStack> */}

        <Flex justifyContent="flex-end" display={{ base: "none", md: "block" }}>
          <Button
            variant="outline"
            leftIcon={<ChevronLeftIcon />}
            onClick={toggleLeftColumn}
            mr={4}
          >
            
          </Button>
          <IconButton
            aria-label="Toggle Color Mode"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
          />
        </Flex>

        <>
          <SimpleGrid
            columns="3"
            mt="19"
          >
            <Box>
              <Text
                fontWeight={"light"}
                fontSize={"21"}
                color={colorMode === "light" ? "gray.700" : "gray.200"}
              >
                Sales Order Process 
              </Text>
            </Box>
            <Spacer />
                <Box> </Box>
          </SimpleGrid>



            <Box p="4">
                    {/* <SalesOrderHome /> */}
                    <SalesOrder />
            </Box>



        </>
      </Box>
    </Flex>
  );
};



export default SalesOrderHome;
