import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  ChakraProvider,
  Button,
  useColorMode,
  Switch,
  Flex,
  Text,
  extendTheme,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  InputGroup,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import * as d3 from "d3";
import ReactSelect from "react-select";
const customTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});

// Initial data and other variables omitted for brevity...
const initialData = [
  { id: 1, name: 'Goal A', percentage: 70, importance: 'High', value: 30, group: 'A', color: 'red', segment: 'Retail', geography: 'North America', size: 'Large', techStack: 'React', owner: 'John' },
  { id: 2, name: 'Goal B', percentage: 40, importance: 'Medium', value: 10, group: 'A', color: 'green', segment: 'Finance', geography: 'Europe', size: 'Medium', techStack: 'Angular', owner: 'Doe' },
  { id: 3, name: 'Goal C', percentage: 90, importance: 'Low', value: 20, group: 'B', color: 'blue', segment: 'Healthcare', geography: 'Asia', size: 'Small', techStack: 'Vue', owner: 'Smith' },
  { id: 4, name: 'Goal D', percentage: 50, importance: 'High', value: 40, group: 'B', color: 'yellow', segment: 'Technology', geography: 'South America', size: 'Large', techStack: 'Node.js', owner: 'Brown' },
  { id: 5, name: 'Goal E', percentage: 30, importance: 'Medium', value: 15, group: 'C', color: 'magenta', segment: 'Retail', geography: 'Australia', size: 'Medium', techStack: 'Django', owner: 'Taylor' },
  { id: 6, name: 'Goal F', percentage: 60, importance: 'Low', value: 25, group: 'C', color: 'cyan', segment: 'Finance', geography: 'North America', size: 'Small', techStack: 'Flask', owner: 'Anderson' },
  { id: 7, name: 'Goal G', percentage: 80, importance: 'High', value: 35, group: 'A', color: 'orange', segment: 'Healthcare', geography: 'Europe', size: 'Large', techStack: 'Spring', owner: 'Thomas' },
  { id: 8, name: 'Goal H', percentage: 45, importance: 'Medium', value: 18, group: 'A', color: 'purple', segment: 'Technology', geography: 'Asia', size: 'Medium', techStack: 'Laravel', owner: 'Jackson' },
  { id: 9, name: 'Goal I', percentage: 55, importance: 'Low', value: 22, group: 'B', color: 'pink', segment: 'Retail', geography: 'South America', size: 'Small', techStack: 'Ruby on Rails', owner: 'White' },
  { id: 10, name: 'Goal J', percentage: 65, importance: 'High', value: 28, group: 'B', color: 'brown', segment: 'Finance', geography: 'Australia', size: 'Large', techStack: 'Express', owner: 'Harris' },
  { id: 11, name: 'Goal K', percentage: 35, importance: 'Medium', value: 12, group: 'C', color: 'lime', segment: 'Healthcare', geography: 'North America', size: 'Medium', techStack: 'Symfony', owner: 'Clark' },
  { id: 12, name: 'Goal L', percentage: 75, importance: 'Low', value: 33, group: 'C', color: 'navy', segment: 'Technology', geography: 'Europe', size: 'Small', techStack: 'ASP.NET', owner: 'Lewis' },
  { id: 13, name: 'Goal M', percentage: 85, importance: 'High', value: 37, group: 'A', color: 'teal', segment: 'Retail', geography: 'Asia', size: 'Large', techStack: 'Java', owner: 'Walker' },
  { id: 14, name: 'Goal N', percentage: 25, importance: 'Medium', value: 8, group: 'A', color: 'olive', segment: 'Finance', geography: 'South America', size: 'Medium', techStack: 'Python', owner: 'Robinson' },
  { id: 15, name: 'Goal O', percentage: 95, importance: 'Low', value: 42, group: 'B', color: 'maroon', segment: 'Healthcare', geography: 'Australia', size: 'Small', techStack: 'C#', owner: 'Young' },
  { id: 16, name: 'Goal P', percentage: 20, importance: 'High', value: 5, group: 'B', color: 'silver', segment: 'Technology', geography: 'North America', size: 'Large', techStack: 'PHP', owner: 'King' },
  { id: 17, name: 'Goal Q', percentage: 50, importance: 'Medium', value: 19, group: 'C', color: 'gold', segment: 'Retail', geography: 'Europe', size: 'Medium', techStack: 'Go', owner: 'Wright' },
  { id: 18, name: 'Goal R', percentage: 60, importance: 'Low', value: 24, group: 'C', color: 'coral', segment: 'Finance', geography: 'Asia', size: 'Small', techStack: 'Rust', owner: 'Scott' },
  { id: 19, name: 'Goal S', percentage: 70, importance: 'High', value: 31, group: 'A', color: 'aqua', segment: 'Healthcare', geography: 'South America', size: 'Large', techStack: 'Kotlin', owner: 'Green' },
  { id: 20, name: 'Goal T', percentage: 40, importance: 'Medium', value: 11, group: 'A', color: 'plum', segment: 'Technology', geography: 'Australia', size: 'Medium', techStack: 'Swift', owner: 'Baker' },
  { id: 21, name: 'Goal U', percentage: 90, importance: 'Low', value: 21, group: 'B', color: 'khaki', segment: 'Retail', geography: 'North America', size: 'Small', techStack: 'Objective-C', owner: 'Nelson' },
  { id: 22, name: 'Goal V', percentage: 50, importance: 'High', value: 41, group: 'B', color: 'crimson', segment: 'Finance', geography: 'Europe', size: 'Large', techStack: 'Scala', owner: 'Carter' },
  { id: 23, name: 'Goal W', percentage: 30, importance: 'Medium', value: 16, group: 'C', color: 'azure', segment: 'Healthcare', geography: 'Asia', size: 'Medium', techStack: 'Perl', owner: 'Mitchell' },
  { id: 24, name: 'Goal X', percentage: 60, importance: 'Low', value: 26, group: 'C', color: 'ivory', segment: 'Technology', geography: 'South America', size: 'Small', techStack: 'Haskell', owner: 'Perez' },
  { id: 25, name: 'Goal Y', percentage: 80, importance: 'High', value: 36, group: 'A', color: 'orchid', segment: 'Retail', geography: 'Australia', size: 'Large', techStack: 'Clojure', owner: 'Roberts' },
  { id: 26, name: 'Goal Z', percentage: 45, importance: 'Medium', value: 17, group: 'A', color: 'lavender', segment: 'Finance', geography: 'North America', size: 'Medium', techStack: 'Elixir', owner: 'Turner' },
  { id: 27, name: 'Goal AA', percentage: 55, importance: 'Low', value: 23, group: 'B', color: 'peach', segment: 'Healthcare', geography: 'Europe', size: 'Small', techStack: 'Julia', owner: 'Phillips' },
  { id: 28, name: 'Goal AB', percentage: 65, importance: 'High', value: 29, group: 'B', color: 'mint', segment: 'Technology', geography: 'Asia', size: 'Large', techStack: 'Solidity', owner: 'Campbell' },
  { id: 29, name: 'Goal AC', percentage: 35, importance: 'Medium', value: 13, group: 'C', color: 'rose', segment: 'Retail', geography: 'South America', size: 'Medium', techStack: 'TypeScript', owner: 'Parker' },
];

const colorOptions = [
  'red', 'green', 'blue', 'yellow', 'magenta', 'cyan', 'orange', 'purple', 'pink', 'brown', 'black', 'gray'
];


const ClusteredBubbleChart = ({
  data,
  clusterByImportance,
  onCircleDoubleClick,
  onUpdateImportance,
  setDraggingInfo,
  setNewCircle,
  highlightedCircles,
}) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [highlightedCluster, setHighlightedCluster] = useState(null);
  const [draggedNode, setDraggedNode] = useState(null);

  const updateCircles = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('circle')
      .attr('stroke', (d) =>
        highlightedCircles.includes(d.id) ? 'black' : 'none'
      )
      .attr('stroke-width', (d) =>
        highlightedCircles.includes(d.id) ? 2 : 0
      )
      .style('opacity', (d) =>
        highlightedCircles.length > 0
          ? highlightedCircles.includes(d.id)
            ? 1
            : 0.2
          : 1
      );
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const container = d3.select(containerRef.current);

    const updateDimensions = () => {
      const width = container.node().clientWidth;
      const height = container.node().clientHeight;
      svg
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("width", width)
        .attr("height", height);

      const importanceGroups = {
        High: {
          x: width / 4,
          y: height / 2,
          width: width / 4,
          height: height - 100,
        },
        Medium: {
          x: width / 2,
          y: height / 2,
          width: width / 4,
          height: height - 100,
        },
        Low: {
          x: (3 * width) / 4,
          y: height / 2,
          width: width / 4,
          height: height - 100,
        },
      };

      let simulation = d3
        .forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(5))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force(
          "collision",
          d3.forceCollide().radius((d) => d.value + 5)
        )
        .alphaDecay(0.1)
        .on("tick", ticked);

      if (clusterByImportance) {
        simulation
          .force(
            "x",
            d3.forceX((d) => importanceGroups[d.importance].x).strength(0.5)
          )
          .force(
            "y",
            d3.forceY((d) => importanceGroups[d.importance].y).strength(0.5)
          )
          .alpha(1)
          .restart();

        // Remove existing boxes and labels
        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();

        // Add boxes for clusters
        Object.keys(importanceGroups).forEach((importance) => {
          svg
            .append("rect")
            .attr("class", `cluster-box ${importance}`)
            .attr("x", importanceGroups[importance].x - width / 8)
            .attr("y", 50)
            .attr("width", width / 4)
            .attr("height", height - 150) // Adjusted height to make space for input box
            .attr("fill", "none")
            .attr("stroke", "gray")
            .attr("stroke-dasharray", "4")
            .lower();

          svg
            .append("text")
            .attr("class", "label")
            .attr("x", importanceGroups[importance].x)
            .attr("y", 40)
            .attr("text-anchor", "middle")
            .text(importance);
        });

        // Add input boxes for each cluster
        svg.selectAll(".input-box").remove();
        Object.keys(importanceGroups).forEach((importance) => {
          const group = importanceGroups[importance];
          svg
            .append("foreignObject")
            .attr("class", "input-box")
            .attr("x", group.x - width / 8)
            .attr("y", height - 80) // Position at the bottom of the cluster
            .attr("width", width / 4)
            .attr("height", 60).html(`
              <div xmlns="http://www.w3.org/1999/xhtml">
                <input type="text" placeholder="Add new circle" style="width:100%; height:30px; padding:5px;" id="input-${importance}" />
                <Divider />
                <button style="width:100%; height:30px;" onclick="window.addNewCircle('${importance}')">Add</button>
              </div>
            `);
        });

        // Define the global function to add a new circle
        window.addNewCircle = (importance) => {
          const input = document.getElementById(`input-${importance}`);
          if (input.value.trim() !== "") {
            setNewCircle(input.value, importance);
            input.value = "";
          }
        };
      } else {
        simulation
          .force("x", d3.forceX(width / 2).strength(0.1))
          .force("y", d3.forceY(height / 2).strength(0.1))
          .alpha(1)
          .restart();

        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();
        svg.selectAll(".input-box").remove();
      }

      const node = svg
        .selectAll("g")
        .data(data, (d) => d.id)
        .join(
          (enter) => {
            const g = enter
              .append("g")
              .call(
                d3
                  .drag()
                  .on("start", dragstarted)
                  .on("drag", dragged)
                  .on("end", dragended)
              );
            g.append("circle")
              .attr("r", (d) => d.value)
              .attr("fill", (d) => d.color)
              .attr("stroke", (d) =>
                highlightedCircles.includes(d.id) ? "black" : "none"
              )
              .attr("stroke-width", (d) =>
                highlightedCircles.includes(d.id) ? 2 : 0
              )
              .style("opacity", (d) =>
                highlightedCircles.length > 0
                  ? highlightedCircles.includes(d.id)
                    ? 1
                    : 0.2
                  : 1
              )
              .on("mouseenter", function (event, d) {
                d3.select(this).attr("stroke", "black");
                showTooltip(event, d);
              })
              .on("mouseleave", function (event, d) {
                d3.select(this).attr(
                  "stroke",
                  highlightedCircles.includes(d.id) ? "black" : "none"
                );
                hideTooltip();
              })
              .on("dblclick", function (event, d) {
                onCircleDoubleClick(d);
              });

            g.append("title").text(
              (d) =>
                `Name: ${d.name}\nPercentage: ${d.percentage}%\nImportance: ${d.importance}`
            );

            return g;
          },
          (update) => update,
          (exit) => exit.remove()
        );

      function ticked() {
        node.attr("transform", (d) => {
          if (clusterByImportance && !draggedNode) {
            const group = importanceGroups[d.importance];
            d.x = Math.max(
              group.x - group.width / 2 + d.value,
              Math.min(group.x + group.width / 2 - d.value, d.x)
            );
            d.y = Math.max(
              50 + d.value,
              Math.min(group.y + group.height / 2 - d.value, d.y)
            );
          }
          return `translate(${d.x}, ${d.y})`;
        });
      }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
        setDraggedNode(d);

        // Fix positions of other nodes
        data.forEach((node) => {
          if (node.id !== d.id) {
            node.fx = node.x;
            node.fy = node.y;
          }
        });
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;

        if (clusterByImportance) {
          const newImportance = getCluster(event.x, importanceGroups);
          if (newImportance !== highlightedCluster) {
            setHighlightedCluster(newImportance);
            d3.selectAll(".cluster-box").attr("fill", "none");
            d3.select(`.cluster-box.${newImportance}`).attr(
              "fill",
              "rgba(0, 0, 255, 0.1)"
            );
            setDraggingInfo(
              `Circle ${d.name} is moving to ${newImportance} cluster`
            );
          }
        }
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
        setDraggedNode(null);

        // Determine new cluster
        const newImportance = getCluster(event.x, importanceGroups);
        if (d.importance !== newImportance) {
          d.importance = newImportance;
          onUpdateImportance(d);
          setDraggingInfo(
            `Circle ${d.name} has been moved to ${newImportance} cluster`
          );
        }
        setHighlightedCluster(null);
        d3.selectAll(".cluster-box").attr("fill", "none");

        // Remove fixed positions for other nodes
        data.forEach((node) => {
          if (node.id !== d.id) {
            node.fx = null;
            node.fy = null;
          }
        });

        simulation.alpha(1).restart();
      }

      function showTooltip(event, d) {
        const tooltip = d3
          .select("#tooltip")
          .style("opacity", 1)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`)
          .html(
            `Name: ${d.name}<br>Percentage: ${d.percentage}%<br>Importance: ${d.importance}`
          );
      }

      function hideTooltip() {
        d3.select("#tooltip").style("opacity", 0);
      }

      function getCluster(x, groups) {
        const distances = Object.keys(groups).map((key) => ({
          key,
          distance: Math.abs(x - groups[key].x),
        }));
        return distances.reduce((acc, curr) =>
          curr.distance < acc.distance ? curr : acc
        ).key;
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [
    data,
    clusterByImportance,
    highlightedCircles, // Add highlightedCircles to the dependency array
    highlightedCluster,
    onUpdateImportance,
    draggedNode,
    setDraggingInfo,
  ]);

  useEffect(() => {
    updateCircles(); // Update circles when highlightedCircles changes
  }, [highlightedCircles]);

  return (
    <Box
      ref={containerRef}
      width="100%"
      height="80vh"
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <svg ref={svgRef}></svg>
      <div
        id="tooltip"
        style={{
          position: "absolute",
          opacity: 0,
          background: "lightgray",
          padding: "5px",
          borderRadius: "5px",
          pointerEvents: "none",
        }}
      ></div>
    </Box>
  );
};

const Simple = () => {
  const [data, setData] = useState(initialData);
  const [clusterByImportance, setClusterByImportance] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [editCircle, setEditCircle] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    percentage: "",
    importance: "",
    value: "",
    group: "",
    color: "black",
  });
  const [draggingInfo, setDraggingInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [highlightedCircles, setHighlightedCircles] = useState([]);

  const handleAddCircle = () => {
    setEditCircle(null);
    setFormValues({
      name: "",
      percentage: "",
      importance: "",
      value: "",
      group: "",
      color: "black",
    });
    onOpen();
  };

  const handleSave = () => {
    if (editCircle) {
      setData(
        data.map((d) =>
          d.id === editCircle.id
            ? {
                ...editCircle,
                ...formValues,
                value: parseInt(formValues.value, 10),
                percentage: parseInt(formValues.percentage, 10),
              }
            : d
        )
      );
    } else {
      const newCircle = {
        id: data.length + 1,
        name: formValues.name,
        percentage: parseInt(formValues.percentage, 10),
        importance: formValues.importance,
        value: parseInt(formValues.value, 10),
        group: formValues.group,
        color: formValues.color,
      };
      setData([...data, newCircle]);
    }
    onClose();
  };

  const handleClusterByImportance = () => {
    setClusterByImportance(!clusterByImportance);
  };

  const handleCircleDoubleClick = (circle) => {
    setEditCircle(circle);
    setFormValues({
      name: circle.name,
      percentage: circle.percentage.toString(),
      importance: circle.importance,
      value: circle.value.toString(),
      group: circle.group,
      color: circle.color,
    });
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const setNewCircle = (name, importance) => {
    const newCircle = {
      id: data.length + 1,
      name,
      percentage: 0,
      importance,
      value: 50,
      group: "New",
      color: "gray",
    };
    setData([...data, newCircle]);
  };

  const handleUpdateImportance = (updatedCircle) => {
    setData(data.map((d) => (d.id === updatedCircle.id ? updatedCircle : d)));
    toast({
      title: `Importance updated for ${updatedCircle.name}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setHighlightedCircles(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const options = initialData.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <ChakraProvider theme={customTheme}>
      <Box p={5}>
        <Flex justifyContent="space-between" mb={4}>
          <Button onClick={handleAddCircle}>Add Circle</Button>
          <Button onClick={handleClusterByImportance}>
            {clusterByImportance ? "Uncluster" : "Cluster by Importance"}
          </Button>
          <Flex alignItems="center">
            <Text mr={2}>Dark Mode</Text>
            <Switch
              isChecked={colorMode === "dark"}
              onChange={toggleColorMode}
            />
          </Flex>
        </Flex>
        <ReactSelect
          isMulti
          options={options}
          value={selectedOptions}
          onChange={handleSelectChange}
          placeholder="Search and select goals"
          styles={{
            container: (provided) => ({
              ...provided,
              marginBottom: "16px",
            }),
          }}
        />
        <Text mb={4}>{draggingInfo}</Text>
        <ClusteredBubbleChart
          data={data}
          clusterByImportance={clusterByImportance}
          onCircleDoubleClick={handleCircleDoubleClick}
          onUpdateImportance={handleUpdateImportance}
          setDraggingInfo={setDraggingInfo}
          setNewCircle={setNewCircle}
          highlightedCircles={highlightedCircles}
        />
      </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {editCircle ? "Edit Circle" : "Add Circle"}
          </DrawerHeader>
          <DrawerBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Percentage</FormLabel>
              <Input
                name="percentage"
                type="number"
                value={formValues.percentage}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Importance</FormLabel>
              <Select
                name="importance"
                value={formValues.importance}
                onChange={handleInputChange}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Value</FormLabel>
              <Input
                name="value"
                type="number"
                value={formValues.value}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Group</FormLabel>
              <Input
                name="group"
                value={formValues.group}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Color</FormLabel>
              <Select
                name="color"
                value={formValues.color}
                onChange={handleInputChange}
              >
                {colorOptions.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default Simple;
