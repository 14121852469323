import React, { useState } from "react";

import { Avatar, AvatarBadge, AvatarGroup, VStack, Input, Text, Divider  } from "@chakra-ui/react";

const ingredients = [
    { id: 1, description: "1,2-Hexanediol", type: "chemical" },
    { id: 2, description: "Butylene Glycol", type: "chemical" },
    { id: 3, description: "Caprylic/Capric Triglyceride", type: "chemical" },
    { id: 4, description: "Citric Acid", type: "chemical" },
    {
      id: 5,
      description: "Coptis Japonica Root Extract",
      type: "natural extract",
    },
    { id: 6, description: "Dextrin", type: "natural extract" },
    { id: 7, description: "Ethylhexylglycerin", type: "chemical" },
    { id: 8, description: "Hydrolyzed Sodium Hyaluronate", type: "chemical" },
    { id: 9, description: "Isononyl Isononanoate", type: "chemical" },
    { id: 10, description: "Niacinamide", type: "chemical" },
    { id: 11, description: "Pentylene Glycol", type: "chemical" },
    { id: 12, description: "Sodium Acetylated Hyaluronate", type: "chemical" },
    { id: 13, description: "Sodium Citrate", type: "chemical" },
    { id: 14, description: "Sodium Hyaluronate", type: "chemical" },
    { id: 15, description: "Sodium Hyaluronate Crosspolymer", type: "chemical" },
    {
      id: 16,
      description: "Theobroma Cacao (Cocoa) Seed Extract",
      type: "natural extract",
    },
    { id: 17, description: "Tocopherol", type: "natural extract" },
    {
      id: 18,
      description: "Trisodium Ethylenediamine Disuccinate",
      type: "chemical",
    },
  ];
  
  const IngredientsSearch = () => {
    const [searchValue, setSearchValue] = useState("");
    const filteredIngredients = ingredients.filter((ingredient) =>
      ingredient.description.toLowerCase().includes(searchValue.toLowerCase())
    );
  
    return (
      <VStack spacing={4} p={4}>
        <Input
          placeholder="Search ingredients..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          bg="white"
        />
        <VStack align="start" spacing={2} w="100%">
          {filteredIngredients.map((ingredient) => (
            <>
              <Text key={ingredient.id}>
                {ingredient.description} - {ingredient.type}
              </Text>
              <Divider />
            </>
          ))}
        </VStack>
      </VStack>
    );
  };
  
  export default IngredientsSearch;
  