import React from 'react';
import { Box, keyframes } from '@chakra-ui/react';
import { css } from '@emotion/react';

const fall = keyframes`
  to {
    transform: translate3d(-30em, 0, 0);
  }
`;

const tailFade = keyframes`
  0%, 50% {
    width: var(--star-tail-length);
    opacity: 1;
  }
  70%, 80% {
    width: 0;
    opacity: 0.4;
  }
  100% {
    width: 0;
    opacity: 0;
  }
`;

const blink = keyframes`
  50% {
    opacity: 0.6;
  }
`;

const Star = ({ index }) => {
  const randomRange = (min, max) => Math.random() * (max - min) + min;

  const starStyles = css`
    --star-tail-length: ${randomRange(5, 7.5)}em;
    --top-offset: ${randomRange(0, 100)}vh;
    --fall-duration: ${randomRange(6, 12)}s;
    --fall-delay: ${randomRange(0, 10)}s;
  `;

  return (
    <Box
      className="star"
      css={starStyles}
      position="absolute"
      top="var(--top-offset)"
      left="0"
      width="var(--star-tail-length)"
      height="2px"
      background="linear-gradient(45deg, currentColor, transparent)"
      borderRadius="50%"
      filter="drop-shadow(0 0 6px currentColor)"
      animation={`${fall} var(--fall-duration) var(--fall-delay) linear infinite, ${tailFade} var(--fall-duration) var(--fall-delay) ease-out infinite`}
      _before={{
        content: `''`,
        position: 'absolute',
        top: '0',
        left: 'calc(var(--star-width) / -2)',
        width: 'var(--star-width)',
        height: '100%',
        background: 'linear-gradient(45deg, transparent, currentColor, transparent)',
        borderRadius: 'inherit',
        animation: `${blink} 2s linear infinite`,
        transform: 'rotate(45deg)',
      }}
      _after={{
        content: `''`,
        position: 'absolute',
        top: '0',
        left: 'calc(var(--star-width) / -2)',
        width: 'var(--star-width)',
        height: '100%',
        background: 'linear-gradient(45deg, transparent, currentColor, transparent)',
        borderRadius: 'inherit',
        animation: `${blink} 2s linear infinite`,
        transform: 'rotate(-45deg)',
      }}
    />
  );
};

const Stars = () => {
  const starsArray = Array.from({ length: 50 }, (_, i) => <Star key={i} index={i} />);
  return (
    <Box
      className="stars"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="120%"
      transform="rotate(-45deg)"
    >
      {starsArray}
    </Box>
  );
};

export default Stars;
