import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  Text,
  extendTheme,
  Flex,Container 
} from '@chakra-ui/react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const inboundMarketingTasks = [
  {
    id: 'task-1',
    task: 'Content Marketing',
    subtasks: [
      {
        id: 'subtask-1',
        subtask: 'Blogging',
        activities: [
          { id: 'activity-1', activity: 'Keyword Research' },
          { id: 'activity-2', activity: 'Content Creation' },
          { id: 'activity-5', activity: 'SEO Optimization' },
        ],
      },
      {
        id: 'subtask-2',
        subtask: 'Video Marketing',
        activities: [
          { id: 'activity-3', activity: 'Script Writing' },
          { id: 'activity-4', activity: 'Video Production' },
          { id: 'activity-6', activity: 'Editing' },
        ],
      },
    ],
  },
  {
    id: 'task-2',
    task: 'Social Media Marketing',
    subtasks: [
      {
        id: 'subtask-3',
        subtask: 'Content Creation',
        activities: [
          { id: 'activity-7', activity: 'Graphic Design' },
          { id: 'activity-8', activity: 'Post Scheduling' },
        ],
      },
      {
        id: 'subtask-4',
        subtask: 'Community Engagement',
        activities: [
          { id: 'activity-9', activity: 'Responding to Comments' },
          { id: 'activity-10', activity: 'Engaging with Influencers' },
        ],
      },
    ],
  },
  {
    id: 'task-3',
    task: 'Email Marketing',
    subtasks: [
      {
        id: 'subtask-5',
        subtask: 'Campaign Planning',
        activities: [
          { id: 'activity-11', activity: 'Audience Segmentation' },
          { id: 'activity-12', activity: 'Content Drafting' },
        ],
      },
      {
        id: 'subtask-6',
        subtask: 'Performance Analysis',
        activities: [
          { id: 'activity-13', activity: 'A/B Testing' },
          { id: 'activity-14', activity: 'Metrics Review' },
        ],
      },
    ],
  },
];

const DragType = {
  SUBTASK: 'subtask',
  ACTIVITY: 'activity',
};

const TaskBoard = () => {
  const [tasks, setTasks] = useState(inboundMarketingTasks);
  const [isDragging, setIsDragging] = useState(false);

  const moveSubtask = (draggedSubtaskId, hoveredSubtaskId) => {
    setTasks((prevTasks) => {
      const newTasks = [...prevTasks];
      let draggedSubtask = null;

      newTasks.forEach((task) => {
        const index = task.subtasks.findIndex((subtask) => subtask.id === draggedSubtaskId);
        if (index !== -1) {
          draggedSubtask = task.subtasks[index];
          task.subtasks.splice(index, 1);
        }
      });

      newTasks.forEach((task) => {
        const hoveredIndex = task.subtasks.findIndex((subtask) => subtask.id === hoveredSubtaskId);
        if (hoveredIndex !== -1 && draggedSubtask) {
          task.subtasks.splice(hoveredIndex, 0, draggedSubtask);
        }
      });

      return newTasks;
    });
  };

  const moveActivity = (draggedActivityId, targetActivityId, sourceSubtaskId, targetSubtaskId) => {
    setTasks((prevTasks) => {
      let draggedActivity = null;

      const newTasks = prevTasks.map((task) => {
        const newSubtasks = task.subtasks.map((subtask) => {
          if (subtask.id === sourceSubtaskId) {
            const newActivities = [...subtask.activities];
            const draggedActivityIndex = newActivities.findIndex((act) => act.id === draggedActivityId);
            if (draggedActivityIndex !== -1) {
              [draggedActivity] = newActivities.splice(draggedActivityIndex, 1);
            }
            return { ...subtask, activities: newActivities };
          }
          return subtask;
        });

        return { ...task, subtasks: newSubtasks };
      });

      if (!draggedActivity) return prevTasks; // Ensure draggedActivity is defined

      const updatedTasks = newTasks.map((task) => {
        const updatedSubtasks = task.subtasks.map((subtask) => {
          if (subtask.id === targetSubtaskId) {
            const newActivities = [...subtask.activities];
            const targetActivityIndex = targetActivityId
              ? newActivities.findIndex((act) => act.id === targetActivityId)
              : newActivities.length;
            newActivities.splice(targetActivityIndex, 0, draggedActivity);
            return { ...subtask, activities: newActivities };
          }
          return subtask;
        });

        return { ...task, subtasks: updatedSubtasks };
      });

      return updatedTasks;
    });
  };

  return (
    <Box p={5}>
      <Heading>Inbound Marketing</Heading>
      <Flex wrap="wrap" alignItems="center">
        {tasks.map((task) => (
          <Flex key={task.id} mt={5} alignItems="center" wrap="wrap" flexShrink={0}>
            <Text size="md" p={2} color="blue" borderWidth="1px" borderRadius="md" fontWeight="regular" mr={2}>{task.task}</Text>
            {task.subtasks && task.subtasks.map((subtask, subtaskIndex) => (
              <React.Fragment key={subtask.id}>
                <DroppableArea id={subtask.id} type={DragType.SUBTASK} isDragging={isDragging}>
                  <Text p={2} borderWidth="1px" borderRadius="md" fontWeight="regular" color="red" mr={2}>{subtask.subtask} </Text>
                </DroppableArea>
                {subtask.activities.map((activity, activityIndex) => (
                  <React.Fragment key={activity.id}>
                    <Activity
                      activity={activity}
                      subtaskId={subtask.id}
                      moveActivity={moveActivity}
                      setIsDragging={setIsDragging}
                      isDragging={isDragging}
                    />
                    <DroppableArea id={activity.id} type={DragType.ACTIVITY} before isDragging={isDragging}>
                      <Box width="1px" p={2} borderWidth="1px" borderRadius="md" fontWeight="regular" bg={activityIndex === 0 ? 'transparent' : 'gray.300'} />
                    </DroppableArea>
                  </React.Fragment>
                ))}
                {subtaskIndex < task.subtasks.length - 1 && (
                  <Box mx={2} color="green" width="5px" height="40px" bg="gray.100" />
                )}
              </React.Fragment>
            ))}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

const Subtask = ({ subtask, moveSubtask, moveActivity, setIsDragging, isDragging }) => {
  const [{ isDraggingSubtask }, subtaskRef] = useDrag({
    type: DragType.SUBTASK,
    item: { id: subtask.id },
    collect: (monitor) => ({
      isDraggingSubtask: !!monitor.isDragging(),
    }),
    options: {
      item: () => {
        setIsDragging(true);
        return { id: subtask.id };
      },
    },
    end: () => setIsDragging(false),
  });

  const [, dropSubtaskRef] = useDrop({
    accept: DragType.SUBTASK,
    hover(item) {
      if (item.id !== subtask.id) {
        moveSubtask(item.id, subtask.id);
      }
    },
  });

  const [, drop] = useDrop({
    accept: DragType.ACTIVITY,
    drop: (item) => moveActivity(item.id, null, item.subtaskId, subtask.id),
  });

  return (
    <Flex
      ref={(node) => subtaskRef(dropSubtaskRef(node))}
      p={2}
      borderWidth="1px"
      borderRadius="md"
      direction="column"
      alignItems="center"
      color="black"
      opacity={isDraggingSubtask ? 0.5 : 1}
    >
      <Text fontWeight="regular" color="black">{subtask.subtask}</Text>
      <Flex direction="column" alignItems="center">
        {subtask.activities.map((activity) => (
          <DroppableArea key={activity.id} id={activity.id} type={DragType.ACTIVITY} before isDragging={isDragging}>
            <Activity activity={activity} subtaskId={subtask.id} moveActivity={moveActivity} />
          </DroppableArea>
        ))}
      </Flex>
    </Flex>
  );
};

const Activity = ({ activity, subtaskId, moveActivity, setIsDragging, isDragging }) => {
  const [{ isDraggingActivity }, drag] = useDrag({
    type: DragType.ACTIVITY,
    item: { id: activity.id, subtaskId },
    collect: (monitor) => ({
      isDraggingActivity: !!monitor.isDragging(),
    }),
    options: {
      item: () => {
        setIsDragging(true);
        return { id: activity.id, subtaskId };
      },
    },
    end: () => setIsDragging(false),
  });

  const [, drop] = useDrop({
    accept: DragType.ACTIVITY,
    hover(item) {
      if (item.id !== activity.id) {
        moveActivity(item.id, activity.id, item.subtaskId, subtaskId);
      }
    },
  });

  return (
    <Box
      ref={(node) => drag(drop(node))}
      p={2}
      borderWidth="1px"
      borderRadius="md"
      mb={1}
      bg="gray.100"
      textAlign="center"
      width="auto"
      flexShrink={0}
      opacity={isDraggingActivity ? 0.5 : 1}
    >
      {activity.activity}
    </Box>
  );
};

const DroppableArea = ({ id, type, children, before, isDragging }) => {
  const [{ isOver }, drop] = useDrop({
    accept: type,
    drop: () => ({ id }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Box
      ref={drop}
      mx={before ? 0 : 1}
      px={before ? 1 : 0}
      borderWidth="1px"
      borderColor={isOver ? 'blue.500' : 'transparent'}
      borderRadius="md"
      bg={isOver ? 'blue.200' : 'transparent'}
      transition="background-color 0.2s, border-color 0.2s"
      minWidth={before ? '15px' : 'auto'}
      minHeight={before ? '15px' : 'auto'}
      display="inline-flex"
      alignItems="center"
    //   opacity={isDragging ? 1 : 0.1}
    >
      {children}
    </Box>
  );
};

const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'gray.800' : 'white',
        color: props.colorMode === 'dark' ? 'white' : 'gray.800',
      },
    }),
  },
});

const TextManager = () => (
  <ChakraProvider theme={customTheme}>
    <DndProvider backend={HTML5Backend}>
     
    <Flex
    w="100%"
// Adjust height as needed
    justifyContent="center"
    alignItems="center"
  >
      <TaskBoard />
      </Flex>
    </DndProvider>
  </ChakraProvider>
);

export default TextManager;
