import Authenticate_app from "../common/authenticate";
import { useState, useEffect } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  Stack,
  useColorModeValue,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  Heading,
  Tooltip,
  Input,
  ListIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { LuCalendarClock } from "react-icons/lu";
import { TbStars } from "react-icons/tb";
import { PiBowlFoodLight } from "react-icons/pi";
import { TfiHandStop } from "react-icons/tfi";
import { TfiMedallAlt } from "react-icons/tfi";
import { TbAward } from "react-icons/tb";
import { GoNorthStar } from "react-icons/go";
import { FaHeart, FaStar, FaSmile, FaSun } from "react-icons/fa";
import { GrFingerPrint, GrGift } from "react-icons/gr";
import { BiVideo, BiSmile, BiSearch, BiMessage } from "react-icons/bi";

import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";
import { Bar } from "react-chartjs-2";

import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryBar,
  VictoryTooltip,
  VictoryTheme,
  VictoryPortal,
  VictoryLabel,
  VictoryContainer,
} from "victory";
import ReactSelect from "react-select";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

import { Global, css, keyframes } from "@emotion/react";

const headlines = {
  headlines: [
    "Unlocking Beauty Networks: The Power of Referrals in Cosmetics Brands' Growth Strategy.",
    "Peer-Driven Beauty Confidence: How Referrals Shape the Cosmetic Choices of Tomorrow.",
    "Referrals: The Unsung Heroes of Cosmetic Brands' Success Stories.",
    "Building Beauty Communities: Why Referrals Are Vital for Cosmetics Brands Today.",
    "Trust in Recommendations: How Referrals Drive Confidence in Cosmetics Brands.",
    "Referrals: The Cornerstone of Authentic Beauty Experiences with Cosmetics Brands.",
    "Referrals Redefine Beauty: Discovering the Impact on Cosmetics Brands' Reach and Relevance.",
    "The Ripple Effect of Referrals: How They Propel Cosmetics Brands to New Heights.",
    "Referrals: Bridging the Gap Between Cosmetics Brands and Their Loyal Customers.",
    "Cosmetics Brands' Secret Weapon: Unveiling the Power of Referrals.",
    "Referrals: Elevating Cosmetics Brands' Connection with Beauty Enthusiasts Worldwide.",
    "From Word-of-Mouth to Global Reach: How Referrals Shape Cosmetics Brands' Success.",
    "The Referral Revolution: Transforming Cosmetics Brands' Approach to Marketing and Growth.",
    "Referrals: Driving Authenticity and Trust in Cosmetics Brands' Offerings.",
    "Referrals: Fueling the Beauty Journey with Cosmetics Brands' Products and Services.",
    "Cosmetics Brands' Trusted Allies: The Impact of Referrals on Customer Engagement and Loyalty.",
    "The Referral Effect: Exploring How It Propels Cosmetics Brands' Reputation and Reach.",
    "Referrals: Amplifying Cosmetics Brands' Influence in the Beauty Industry.",
    "The Power of Personal Recommendations: How Referrals Drive Success for Cosmetics Brands.",
    "Referrals Reign: How They Cement Cosmetics Brands' Position as Industry Leaders.",
  ],
};

const icons = [
  {
    component: GrFingerPrint,
    label: "Product Verfication",
    size: "20px",
    delay: 0,
    bgColor: "yellow.500",
    iconColor: "white",
  },
  {
    component: FiStar,
    label: "Reviews and Ratings",
    size: "60px",
    delay: 0.1,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSmile,
    label: "Refferals",
    size: "150px",
    delay: 0.3,
    bgColor: "yellow.400",
    iconColor: "white",
  },
  {
    component: BiVideo,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
  {
    component: BiSearch,
    label: "Media and How to Guides",
    size: "20px",
    delay: 0.9,
    bgColor: "red.500",
    iconColor: "white",
  },
];

const drop = keyframes`
  0% { top: -50%; }
  100% { top: 110%; }
`;

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #171717;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }

  .line::after {
    content: "";
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 75%,
      #ffffff 100%
    );
    animation: ${drop} 7s 0s infinite cubic-bezier(0.4, 0.26, 0, 0.97);
  }

  .line:nth-of-type(1) {
    margin-left: -25%;
  }

  .line:nth-of-type(1)::after {
    animation-delay: 2s;
  }

  .line:nth-of-type(3) {
    margin-left: 25%;
  }

  .line:nth-of-type(3)::after {
    animation-delay: 2.5s;
  }
`;

// Function to generate random size and margins
const getRandomSizeAndMargin = () => {
  const size = Math.floor(Math.random() * 50) + 50; // Random size between 50px and 100px
  const margin = Math.floor(Math.random() * 20) + 10; // Random margin between 10px and 30px
  return { size, margin };
};

const AnimatedBox = motion(Box);
const MotionBox = motion(Box);

const Refer = () => {
  const [isOpen, setIsOpen] = useState({
    benefits: false,
    ingredients: false,
    usingProduct: false,
    standards: false,
    reviews: false,
    authenticate: false,
  });

  const openModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: true }));
  };

  const closeModal = (modal) => {
    setIsOpen((prevState) => ({ ...prevState, [modal]: false }));
  };

  const [selectedColor, setSelectedColor] = useState("white");

  const colors = ["yellow", "blue", "white", "red"];
  const displayColors = {
    yellow: "Yellow",
    blue: "Blue",
    white: "White",
    red: "Red",
  };

  const colorStyles = {
    yellow: {
      bg: "yellow.300",
      color: "black",
    },
    blue: {
      bg: "blue.300",
      color: "white",
    },
    white: {
      bg: "gray.100",
      color: "black",
      gray: "gray.100",
    },
    red: {
      bg: "red.300",
      color: "white",
    },
  };

  return (
    <>
      <Global styles={GlobalStyles} />
      <Box position="relative" overflowY="auto" height="100vh">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <AnimatedBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
          color="white"
          bg="#191D26"
          minH="100vh"
          overflowY="auto"
        >
          <Container textAlign={"center"} pt="10">
            <Center mb="10">
              <FiStar size="51" mb="10" />
            </Center>
            <HeadlineDisplay mt="10" headlines={headlines.headlines} />
            <Text fontSize={"21"} mt="10">
              Referrals serve as powerful endorsements, amplifying cosmetic
              brands' credibility and trustworthiness through personal
              recommendations.s, ultimately driving brand credibility, loyalty,
              and growth.
            </Text>

            <Text fontSize={"21"} mt="10">
              They expand brand reach organically, tapping into existing
              networks and communities, while fostering lasting relationships
              and driving sustainable growth by converting satisfied customers
              into brand advocates.
            </Text>

            <Text fontSize={"21"} mt="10">
              Referrals also offer invaluable insights, enriching brands with
              genuine feedback and enhancing customer loyalty, thus solidifying
              their position as industry leaders in the competitive beauty
              landscape.converting satisfied customers into brand advocates.
            </Text>

            <ButtonGroup spacing="4" mt="10">
              <Button variant="outline" color="white"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >
                Request Demo
              </Button>
              <Button variant="solid"
                onClick={() => window.open('https://calendly.com/justin-lnbs/customer-retention-demo', '_blank')}

              >Sign up for Trial</Button>
            </ButtonGroup>
          </Container>

          <Container></Container>

          <Container mt="50" color="gray.400" mb="40">
            All Rights Reserved 2024, Tangible Spin LLP. D-U-N-S 878296827
          </Container>
        </AnimatedBox>
      </Box>
    </>
  );
};

const HeadlineDisplay = ({ headlines }) => {
  const [randomHeadline, setRandomHeadline] = useState("");

  useEffect(() => {
    // Function to select a random headline
    const getRandomHeadline = () => {
      const randomIndex = Math.floor(Math.random() * headlines.length);
      return headlines[randomIndex];
    };

    // Set random headline on initial render
    setRandomHeadline(getRandomHeadline());
  }, [headlines]);

  return (
    <Heading w="100%" fontSize={"41"} color="yellow.300" fontWeight={"black"}>
      {randomHeadline}
    </Heading>
  );
};

export default Refer;
