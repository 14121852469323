import Authenticate_app from "../common/authenticate";
import React, { useState } from "react";
import {
  Flex,
  HStack,
  Box,
  Button,
  IconButton,
  useColorMode,
  Image,
  Text,
  Divider,
  List,
  ListItem,
  useBreakpoint,
  useBreakpointValue,
  Icon,
  SimpleGrid,
  Container,
  Spacer,
  ButtonGroup,
  Center,
  VStack,
  Heading,
  Tag,
} from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams, useNavigate } from 'react-router-dom';

import { FiHome } from "react-icons/fi";
import { FiMoon, FiSun } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa6";

import {
  GrHomeRounded,
  GrCalendar,
  GrVolume,
  GrShareOption,
} from "react-icons/gr";

import {
  FiMessageSquare,
  FiDollarSign,
  FiStar,
  FiSettings,
} from "react-icons/fi";
import { ChevronLeftIcon, SunIcon, MoonIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
const Manish = () => {
  const [isLeftColumnOpen, setIsLeftColumnOpen] = useState(true);
  const { colorMode, toggleColorMode } = useColorMode();
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleLeftColumn = () => {
    setIsLeftColumnOpen(!isLeftColumnOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  const navigate = useNavigate();

  return (
    <Flex minH="120vh" border="0px" w="100%">
      <Box
        flex={isLeftColumnOpen ? 2 : "0 0 10"}
        transition="flex 0.3s"
        backgroundColor={colorMode === "light" ? "white" : "gray.800"}
        p={6}
        border="0px"
        display={{ base: "none", md: "block" }}
      >
        <HStack>
          <Center>
            <Avatar
              size={isLeftColumnOpen ? "sm" : "md"}
              name="Ryan Florence"
              src="/Birdeyebird.png"
            />

            <Text
              fontWeight={"light"}
              fontSize={"21"}
              color="gray.500"
              display={isLeftColumnOpen ? 1 : "none"}
              p="4"
            >
              {" "}
              Stone Creek Communities{" "}
            </Text>
          </Center>
        </HStack>
        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <HStack mt="5" textAlign={"center"}>
          <Avatar
            size={isLeftColumnOpen ? "sm" : "md"}
            name="Ryan Florence"
            src="https://bit.ly/dan-abramov"
          />
          <Text
            fontSize={"21"}
            fontWeight={"light"}
            display={isLeftColumnOpen ? 1 : "none"}
            color="gray.500"
            as="Button"
            textAlign={"left"}
            p="4"
          >
            {" "}
            Jeanne T. Farris, Front Office Assistant{" "}
          </Text>{" "}
        </HStack>

        <Divider
          mt="4"
          color={colorMode === "light" ? "gray.100" : "gray.700"}
        />
        <Box
          mt="5"
          //   backgroundColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
          height="100vh"
          p={0}
        >
          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "blue.100" : "blue.900"}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrHomeRounded} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Dashboard</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrCalendar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Masters</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            <Icon as={GrVolume} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Manufacturing</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
            onClick={() => navigate('/manish/sales')}

          >
            <Icon as={FiMessageSquare} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Sales Order Process</Text>
          </HStack>

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiDollarSign} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Vendor Order Process</Text>
          </HStack>

       

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Manufacturing Operations </Text>
          </HStack>


          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Job Work Process </Text>
          </HStack>


          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiStar} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Inventory Control  </Text>
          </HStack>

          <Divider
            mt="4"
            w="100%"
            bg={colorMode === "light" ? "white" : "blue.900"}
          />

          <HStack
            spacing={4}
            alignItems="center"
            mb={0}
            p="3"
            bg={colorMode === "light" ? "white" : ""}
            as="Button"
            textAlign={"left"}
            w="100%"
          >
            {" "}
            <Icon as={FiSettings} boxSize={4} />
            <Text display={isLeftColumnOpen ? 0 : "none"}>Settings</Text>
          </HStack>
        </Box>
      </Box>

      <Box
        flex={6}
        backgroundColor={colorMode === "light" ? "gray.100" : "gray.700"}
        p={2}
      >



        <>
          <SimpleGrid
            columns="3"
            mt="19"
            display={{ base: "none", md: "block" }}
          >
            <Box>
              <Text
                fontWeight={"light"}
                fontSize={"21"}
                color={colorMode === "light" ? "gray.700" : "gray.200"}
              >
                Dashboard
              </Text>
            </Box>
            <Spacer />

          </SimpleGrid>



        </>
      </Box>
    </Flex>
  );
};

const data = {
  competitors: [
    {
      name: "RealEstateCo1",
      averageRating: 4.5,
      monthlyRatings: {
        2023: {
          January: 4.4,
          February: 4.5,
          March: 4.5,
          April: 4.6,
          May: 4.4,
          June: 4.5,
          July: 4.5,
          August: 4.4,
          September: 4.5,
          October: 4.6,
          November: 4.5,
          December: 4.4,
        },
        2024: {
          January: 4.5,
          February: 4.6,
          March: 4.5,
          April: 4.6,
        },
      },
    },
    {
      name: "RealEstateCo2",
      averageRating: 3.2,
      monthlyRatings: {
        2023: {
          January: 3.1,
          February: 3.2,
          March: 3.0,
          April: 3.3,
          May: 3.2,
          June: 3.4,
          July: 3.1,
          August: 3.0,
          September: 3.3,
          October: 3.2,
          November: 3.1,
          December: 3.0,
        },
        2024: {
          January: 3.2,
          February: 3.3,
          March: 3.2,
          April: 3.4,
        },
      },
    },
    {
      name: "RealEstateCo3",
      averageRating: 4.7,
      monthlyRatings: {
        2023: {
          January: 4.8,
          February: 4.7,
          March: 4.6,
          April: 4.8,
          May: 4.7,
          June: 4.8,
          July: 4.7,
          August: 4.8,
          September: 4.7,
          October: 4.8,
          November: 4.7,
          December: 4.8,
        },
        2024: {
          January: 4.8,
          February: 4.7,
          March: 4.8,
          April: 4.7,
        },
      },
    },
    {
      name: "RealEstateCo4",
      averageRating: 2.9,
      monthlyRatings: {
        2023: {
          January: 2.8,
          February: 2.9,
          March: 3.0,
          April: 2.9,
          May: 2.8,
          June: 2.7,
          July: 3.0,
          August: 2.9,
          September: 2.8,
          October: 3.0,
          November: 2.9,
          December: 2.8,
        },
        2024: {
          January: 3.0,
          February: 2.9,
          March: 2.8,
          April: 2.7,
        },
      },
    },
    {
      name: "RealEstateCo5",
      averageRating: 4.0,
      monthlyRatings: {
        2023: {
          January: 4.0,
          February: 4.1,
          March: 4.0,
          April: 4.2,
          May: 4.1,
          June: 4.0,
          July: 4.1,
          August: 4.0,
          September: 4.2,
          October: 4.0,
          November: 4.1,
          December: 4.0,
        },
        2024: {
          January: 4.2,
          February: 4.1,
          March: 4.0,
          April: 4.1,
        },
      },
    },
  ],
};

// Function to transform data for Recharts
const transformData = (competitor) => {
  const months2023 = Object.keys(competitor.monthlyRatings["2023"]).map(
    (month) => ({
      month: `${month} 2023`,
      rating: competitor.monthlyRatings["2023"][month],
    })
  );
  const months2024 = Object.keys(competitor.monthlyRatings["2024"]).map(
    (month) => ({
      month: `${month} 2024`,
      rating: competitor.monthlyRatings["2024"][month],
    })
  );
  return months2023.concat(months2024);
};

const Chart = () => {
  return (
    <Flex direction="row" h="100vh" p={5}>
      <VStack spacing={4} w="20%" p={5} boxShadow="md" display={"none"}>
        {data.competitors.map((company, index) => (
          <HStack key={index} w="full" justify="space-between">
            <Text>{company.name}</Text>
            <Text>{company.averageRating}</Text>
          </HStack>
        ))}
      </VStack>

      <Box w="100%" p={0}>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={data.competitors.reduce((acc, company) => {
              const transformed = transformData(company);
              transformed.forEach((d, i) => {
                if (!acc[i]) acc[i] = { month: d.month };
                acc[i][company.name] = d.rating;
              });
              return acc;
            }, [])}
            margin={{
              top: 2,
              right: 0,
              left: 0,
              bottom: 2,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="month" />
            <YAxis domain={[0, 5]} />
            <Tooltip />
            {/* <Legend /> */}

            {/* Thresholds */}
            <ReferenceArea
              y1={4}
              y2={5}
              fill="green"
              fillOpacity={0.3}
              label="Good"
            />
            <ReferenceArea
              y1={3}
              y2={4}
              fill="yellow"
              fillOpacity={0.3}
              label="Average"
            />
            <ReferenceArea
              y1={0}
              y2={3}
              fill="red"
              fillOpacity={0.3}
              label="Poor"
            />

            {data.competitors.map((company, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={company.name}
                name={company.name}
                stroke={`hsl(${index * 50}, 70%, 50%)`}
                activeDot={{ r: 0 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Flex>
  );
};

const FullWidthDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box>
      <IconButton
        ref={btnRef}
        onClick={onOpen}
        icon={<GiHamburgerMenu />}
        aria-label="Open Drawer"
        variant="ghost"
      />

      <Drawer
        isOpen={isOpen}
        placement="top"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <HStack>
              <Avatar name="Ryan Florence" src="/Birdeyebird.png" size="sm" />
              <Text
                fontWeight={"light"}
                fontSize={"21"}
                color="gray.500"
                display={1}
                lineHeight={1}
                p="4"
              >
                Stone Creek Communities
              </Text>
            </HStack>
          </DrawerHeader>

          <DrawerBody>
            <IconButton
              aria-label="Toggle Color Mode"
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              onClick={toggleColorMode}
            />
            {/* Add your content here */}
            <Box transition="flex 0.3s" p={2} border="0px">
              <HStack mt="5" textAlign={"center"}>
                <Text
                  fontSize={"21"}
                  fontWeight={"light"}
                  color="gray.500"
                  as="Button"
                  textAlign={"left"}
                  p="4"
                >
                  {" "}
                  Jeanne T. Farris, Front Office Assistant{" "}
                </Text>{" "}
              </HStack>

              <Divider
                mt="2"
                color={colorMode === "light" ? "gray.100" : "gray.700"}
              />
              <Box
                mt="5"
                //   backgroundColor={colorMode === 'light' ? 'gray.200' : 'gray.700'}
                // height="100vh"
                p={0}
              >
                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  <Icon as={GrHomeRounded} boxSize={4} />
                  <Text>Dashboard</Text>
                </HStack>

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  <Icon as={GrCalendar} boxSize={4} />
                  <Text>Appointments</Text>
                </HStack>

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  <Icon as={GrVolume} boxSize={4} />
                  <Text>Campaigns</Text>
                </HStack>

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  <Icon as={FiMessageSquare} boxSize={4} />
                  <Text>Inbox</Text>
                </HStack>

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  {" "}
                  <Icon as={FiDollarSign} boxSize={4} />
                  <Text>Payments</Text>
                </HStack>

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  {" "}
                  <Icon as={GrShareOption} boxSize={4} />
                  <Text>Refferals</Text>
                </HStack>

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  {" "}
                  <Icon as={FiStar} boxSize={4} />
                  <Text>Reviews</Text>
                </HStack>

                <Divider
                  mt="4"
                  w="100%"
                  bg={colorMode === "light" ? "white" : "blue.900"}
                />

                <HStack
                  spacing={4}
                  alignItems="center"
                  mb={0}
                  p="2"
                  bg={colorMode === "light" ? "white" : ""}
                  as="Button"
                  textAlign={"left"}
                  w="100%"
                >
                  {" "}
                  <Icon as={FiSettings} boxSize={4} />
                  <Text>Settings</Text>
                </HStack>
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Manish;
