import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  Icon, Text, 
} from '@chakra-ui/react';
import { VscSymbolString } from 'react-icons/vsc';
import { GoNumber } from 'react-icons/go';
import Papa from 'papaparse';

const attributeOptions = {
  Name: "Name",
  Stage: "Stage",
  group: "Company Name",
  // Add more attributes as needed
};

const ImportCSVButton = ({ setNewData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [bubbleColorHeader, setBubbleColorHeader] = useState('');
  const [bubbleSizeHeader, setBubbleSizeHeader] = useState('');
  const [newData, setNewDataLocal] = useState([]);

  const MAX_STEP = 3;

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setCurrentStep(1);
  };

  const handleNext = () => {
    if (currentStep < MAX_STEP) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const setDataWithHeaders = (newData, newHeaders) => {
    console.log(newData, newHeaders);
    setHeaders(newHeaders);
    setNewDataLocal(newData);
    setSelectedTitles(Object.fromEntries(newHeaders.map((header) => [header.header, header.header])));
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = () => {
    const initialData = newData;
    
    console.log("initialData:", initialData);
    setNewData(initialData);
    handleCloseModal();
  };

  return (
    <>
      <Button variant="outline" onClick={handleOpenModal}>
        Import CSV
      </Button>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="3xl">
        <ModalOverlay />
        <ModalContent minH={"80vh"}>
          <ModalHeader>Import CSV Wizard</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Wizard
              setDataWithHeaders={setDataWithHeaders}
              headers={headers}
              selectedTitles={selectedTitles}
              setSelectedTitles={setSelectedTitles}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              currentStep={currentStep}
            />
          </ModalBody>
          <ModalFooter>
            <SimpleGrid columns={2} spacing={4} w="100%">
              {currentStep !== 1 && (
                <Button onClick={handlePrevious} variant="link">
                  Previous
                </Button>
              )}
              {currentStep !== MAX_STEP && (
                <Button onClick={handleNext} colorScheme="blue" ml={4}>
                  Next
                </Button>
              )}
              {currentStep === MAX_STEP && (
                <Button onClick={handleSubmit} colorScheme="blue" ml={4}>
                  Submit
                </Button>
              )}
            </SimpleGrid>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const ImportCsv = ({ setDataWithHeaders }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const headers = results.meta.fields;
          const data = results.data;

          const headerAttributes = headers.map(header => {
            const firstRecordValue = data.length > 0 ? data[0][header] : null;
            const isNumeric = !isNaN(firstRecordValue);
            return {
              header,
              attribute: isNumeric ? 'numeric' : 'text'
            };
          });

          console.log("Headers with Attributes:", headerAttributes);
          console.log("Data:", data);

          setDataWithHeaders(data, headerAttributes);
        },
      });
    }
  };

  return (
    <Box p={4}>
      <FormControl mb={4}>
        <FormLabel>Select CSV file</FormLabel>
      </FormControl>
      <Input type="file" accept=".csv" onChange={handleFileUpload} mb={4} />
    </Box>
  );
};

const Wizard = ({ currentStep, handleNext, handlePrevious, setDataWithHeaders, headers, selectedTitles, setSelectedTitles }) => {
  const [bubbleSizeHeader, setBubbleSizeHeader] = useState('');
  const [bubbleColorHeader, setBubbleColorHeader] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const MAX_STEP = 3;

  const handleNextStep = () => {
    if (currentStep < MAX_STEP) {
      handleNext();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      handlePrevious();
    }
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleTitleSelect = (header) => (event) => {
    const newSelectedTitles = { ...selectedTitles };
    newSelectedTitles[header] = event.target.value;
    setSelectedTitles(newSelectedTitles);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentHeaders = headers.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(headers.length / itemsPerPage);

  return (
    <Box>
      {currentStep === 1 && (
        <Box>
          <p>Step 1: Upload CSV file</p>
          <ImportCsv setDataWithHeaders={setDataWithHeaders} />
        </Box>
      )}

      {currentStep === 2 && (
        <Box>
          <p>Step 2: Map CSV columns</p>
          <Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Imported Fields</Th>
                  <Th>Mapping</Th>
                  <Th>Data Type</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentHeaders.map((header, index) => (
                  <Tr key={index}>
                    <Td>{header.header}</Td>
                    <Td>
                      <Select
                        onChange={handleTitleSelect(header.header)}
                        value={selectedTitles[header.header] || ""}
                      >
                        <option value="">None</option>
                        {Object.keys(attributeOptions).map((attributeKey) => (
                          <option
                            key={attributeKey}
                            value={attributeKey}
                            disabled={Object.values(selectedTitles).includes(attributeKey)}
                          >
                            {attributeOptions[attributeKey]}
                          </option>
                        ))}
                        <option value="ignore">Ignore</option>
                        <option value="new">New Attribute</option>
                      </Select>
                    </Td>
                    <Td>
                      {header.attribute === 'text' && (
                        <Icon as={VscSymbolString} color="yellow" />
                      )}
                      {header.attribute === 'numeric' && (
                        <Icon as={GoNumber} color="green" />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Button onClick={handlePreviousPage}>Previous</Button>
            <Button onClick={handleNextPage}>Next</Button>
          </Box>
        </Box>
      )}

      {currentStep === 3 && (
        <Box>
          <p>Step 3: Visualization settings</p>
          <Box>
            <Text> Select the status column in your CSV, it is missing you can leave not enabled</Text>

            <FormControl mt={4}>
              <FormLabel>Select Bubble Color</FormLabel>
              <Select value={bubbleColorHeader} onChange={(e) => setBubbleColorHeader(e.target.value)}>
                <option value="">Select Color Header</option>
                {headers.map((header, index) => (
                  <option key={index} value={header.header} disabled={header.attribute !== 'text'}>
                    {header.header}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Text> when the user selects status we can have list of existing statuses, and then we can help users add additional statuses </Text>
            <Text> Query code and get me unqiue values for the status  </Text>
            <Text> If not define status, and add values for the status </Text>

            <Text> then make it clusters for that! </Text>

          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ImportCSVButton;
