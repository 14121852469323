import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Box,
  ChakraProvider,
  Button,
  useColorMode,
  Switch,
  Flex,
  Text,
  extendTheme,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure,
  useToast,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  SimpleGrid,
  IconButton,
  ButtonGroup,
  useColorModeValue,
  Image,
  TableContainer,
  Stack,
  Avatar,
  Badge,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  CheckboxGroup,
  VStack,
  Center,
  Spacer,
} from "@chakra-ui/react";
import useMeasure from "react-use-measure";
import { forceSimulation, forceCenter, forceCollide } from "d3-force";
import { motion, AnimatePresence } from "framer-motion";
import { LuImport } from "react-icons/lu";
import { BiExport, BiImport } from "react-icons/bi";
import { LuCreditCard } from "react-icons/lu";
import Papa from "papaparse";
import { supabase } from "./supabaseClient";
import ReactSelect from "react-select";
import { FiColumns, FiFilter, FiSearch, FiTable } from "react-icons/fi";
import { BsBoundingBoxCircles, BsFileBarGraph } from "react-icons/bs";
import { BiCircle } from "react-icons/bi";
import { FaMoon, FaSun } from "react-icons/fa6";
import { AiOutlineDotChart } from "react-icons/ai";
import Board from "react-ui-kanban";
import { LuGroup } from "react-icons/lu";
import ImportCSVButton from "./ImportCSVButton";
import AddClusterDialog from "./AddClusterDialog";
import TaskManager from "./taskManager";
import DynamicFormDrawer from "./DynamicFormDrawer";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import * as d3 from "d3";
import EditDrawer from "./EditDrawer";
import { FiType } from "react-icons/fi";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { LuList } from "react-icons/lu";
import ClusterGrid from "./Graphs/BubbleChartMain";
import MainComponent from "./Graphs/BubbleChartMain";

const customTheme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});


let initialData = [];



// Step 1: Declare filterValues outside the function
let filterValues = [];
let attributeMapping = {};

const fetchInitialData = async () => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) {
      throw new Error("No active session found");
    }

    const response = await fetch(
      "https://www.tryatakian.com/api/getContacts?object=contact&id=3",
      {
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
      }
    );

    const data = await response.json(); // Parse the response as JSON

    // Check if data and filterValues exist
    if (!data?.transformedData || !data?.filterValues) {
      throw new Error("Invalid data structure");
    }

    // Step 2: Update filterValues
    filterValues = data.filterValues;

    // Step 3: Create mapping from attribute titles to lowercase with underscores
    attributeMapping = filterValues.reduce((acc, filter) => {
      const key = filter.attribute.title.toLowerCase().replace(/ /g, '_');
      acc[key] = {
        id: filter.attribute.id,
        values: filter.attribute.attributes_values.map((value) => value.title),
      };
      return acc;
    }, {});

    // Step 4: Iterate over transformedData and set the appropriate attributes dynamically
    const transformedData = data.transformedData.map((item) => {
      const transformedItem = { ...item };

      Object.keys(attributeMapping).forEach((attributeTitle) => {
        const attributeGroup = Object.keys(item.groups || {}).find((group) => 
          Object.keys(item.groups[group].fields || {}).includes(attributeTitle)
        );

        const attributeValue = attributeGroup ? 
          item.groups[attributeGroup].fields[attributeTitle][0] : 
          "Undefined";

        transformedItem[attributeTitle] = attributeValue;
      });

      // Adding additional properties
      transformedItem.percentage = 50;
      transformedItem.value = 20;
      transformedItem.group = "A";
      transformedItem.color = "blue";
      transformedItem.customer_type = "New";

      return transformedItem;
    });

    // Step 5: Return transformed data
    return transformedData;
  } catch (error) {
    console.error("Failed to fetch initial data:", error);
    return [];
  }
};

// Step 6: Access filterValues elsewhere in your code
console.log(filterValues); // Example access



// Run the function on load
fetchInitialData();

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const Cluster = styled.div`
  border: 5px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  height: 500px;
  overflow: none;
  background-color: ${({ isHovered }) => (isHovered ? "lightyellow" : "white")};
  display: flex;
  align-items: center;
`;

const Circle = styled(motion.div)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  position: absolute;
  cursor: grab;
`;

const Rectangle = styled(motion.div)`
  height: 30px;
  border-radius: 15px;
  background-color: ${(props) => props.color};
  position: relative;
  cursor: grab;
`;

const getInitialPosition = () => ({
  x: Math.random() * 240,
  y: Math.random() * 240,
});

const generateRandomDates = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - Math.floor(Math.random() * 100));
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + Math.floor(Math.random() * 30));
  return { startDate, endDate };
};

const generateStateDates = (states) => {
  return states.reduce((acc, state) => {
    const dates = generateRandomDates();
    acc[state] = dates;
    return acc;
  }, {});
};

const generateDataWithDates = (data) => {
  return data.map((item) => ({
    ...item,
    stateDates: generateStateDates(["Active", "Lost", "Won", "Undefined"]),
  }));
};
const MotionBox = motion(Box);

const calculateCirclePacking = (items, width, height) => {
  const nodes = items.map((item) => ({
    ...item,
    radius: 25,
    x: Math.random() * width,
    y: Math.random() * height,
  }));

  const simulation = forceSimulation(nodes)
    .force("charge", d3.forceManyBody().strength(5))
    .force("center", d3.forceCenter(width / 2, height / 2))
    .force(
      "collide",
      forceCollide()
        .radius((d) => d.radius + 1)
        .iterations(2)
    )
    .force(
      "collision",
      forceCollide().radius((d) => d.value + 5)
    )
    .alphaDecay(0.05)
    .stop();

  for (let i = 0; i < 120; i++) {
    simulation.tick();
  }

  return nodes;
};



const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const colorOptions = [
  "red",
  "green",
  "blue",
  "yellow",
  "magenta",
  "cyan",
  "orange",
  "purple",
  "pink",
  "brown",
  "black",
  "gray",
];



const mapIncomingData = (incomingData) => {
  return incomingData.map((item) => ({
    id: item.index,
    name: item["Name"],
    percentage: parseFloat(item["MRR"]),
    lead_status: item["lead_status"],
    value: 20,
    group: item["Company Name"],
    color: "gray", // Assign a default color or map based on some attribute
  }));
};

const ClusteredBubbleChart = ({
  data,
  clusterByAttribute,
  onCircleClick,
  onCircleDoubleClick,
  onUpdateAttribute,
  setDraggingInfo,
  highlightedCircles,
}) => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [highlightedCluster, setHighlightedCluster] = useState(null);
  const [draggedNode, setDraggedNode] = useState(null);
  const [validClusters, setValidClusters] = useState([]);
  const [showNames, setShowNames] = useState(true);

  console.log("bubble chart", data);

  useEffect(() => {
    console.log("Received data:", data);
  }, [data]);

  const updateCircles = () => {
    if (!data || !Array.isArray(data)) return;

    const svg = d3.select(svgRef.current);
    svg
      .selectAll("circle")
      .each((d, i, nodes) => {
        if (!d) {
          console.error("Data item is undefined at index", i);
          return;
        }
        if (!d.id) {
          console.error("Data item is missing 'id' property:", d);
          return;
        }
      })
      .attr("stroke", (d) => {
        if (!d || !d.id) return;
        return highlightedCircles.includes(d.id) ? "black" : "none";
      })
      .attr("stroke-width", (d) => {
        if (!d || !d.id) return;
        return highlightedCircles.includes(d.id) ? 2 : 0;
      })
      .style("opacity", (d) => {
        if (!d || !d.id) return;
        return highlightedCircles.length > 0
          ? highlightedCircles.includes(d.id)
            ? 1
            : 0.2
          : 1;
      });
    addFloatingNames();
  };

  const addFloatingNames = () => {
    if (!data || !Array.isArray(data)) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll(".circle-name").remove();
    if (showNames) {
      svg
        .selectAll(".circle-name")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "circle-name")
        .attr("x", (d) => d.x)
        .attr("y", (d) => d.y - d.value - 5)
        .attr("text-anchor", "middle")
        .text((d) => d.name || 'Unnamed'); // Handle null or undefined names
    }
  };

  const packCircles = (data, width, height) => {
    const root = d3
      .hierarchy({ children: data })
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);

    return d3.pack().size([width, height]).padding(2)(root).leaves();
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const container = d3.select(containerRef.current);

    const updateDimensions = () => {
      if (!data || !Array.isArray(data)) return;

      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      svg
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("width", width)
        .attr("height", height);

      const attributeGroups = {};
      const uniqueValues = Array.from(
        new Set(data.map((d) => d[clusterByAttribute] || 'Unknown')) // Handle undefined clusterByAttribute
      );

      const numColumns = Math.ceil(Math.sqrt(uniqueValues.length));
      const numRows = Math.ceil(uniqueValues.length / numColumns);

      uniqueValues.forEach((value, index) => {
        const row = Math.floor(index / numColumns);
        const col = index % numColumns;
        attributeGroups[value] = {
          x: (col + 0.5) * (width / numColumns),
          y: (row + 0.5) * (height / numRows),
          width: width / numColumns,
          height: height / numRows,
        };
      });

      setValidClusters(uniqueValues);

      // Pack circles within each cluster
      Object.keys(attributeGroups).forEach((attribute) => {
        const group = attributeGroups[attribute];
        const packedData = packCircles(
          data.filter((d) => (d[clusterByAttribute] || 'Unknown') === attribute),
          group.width,
          group.height
        );
        packedData.forEach((node, i) => {
          const item = data.filter((d) => (d[clusterByAttribute] || 'Unknown') === attribute)[
            i
          ];
          item.x = node.x + group.x - group.width / 2;
          item.y = node.y + group.y - group.height / 2;
          item.fx = item.x;
          item.fy = item.y;
        });
      });

      const simulation = d3
        .forceSimulation(data)
        .force("charge", d3.forceManyBody().strength(5))
        .force("center", d3.forceCenter(width / 2, height / 2))
        .force(
          "collision",
          d3.forceCollide().radius((d) => d.value + 5)
        )
        .alphaDecay(0.05)
        .on("tick", ticked);

      if (clusterByAttribute && clusterByAttribute !== "None") {
        simulation
          .force(
            "x",
            d3
              .forceX((d) => attributeGroups[d[clusterByAttribute] || 'Unknown'].x)
              .strength(0.8)
          )
          .force(
            "y",
            d3
              .forceY((d) => attributeGroups[d[clusterByAttribute] || 'Unknown'].y)
              .strength(0.8)
          )
          .alpha(1)
          .restart();

        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();
        svg.selectAll(".debug-circle").remove();

        Object.keys(attributeGroups).forEach((attribute) => {
          const group = attributeGroups[attribute];

          // Draw bounding box
          svg
            .append("rect")
            .attr("class", `cluster-box ${attribute}`)
            .attr("x", group.x - group.width / 2)
            .attr("y", group.y - group.height / 2)
            .attr("width", group.width)
            .attr("height", group.height)
            .attr("fill", "none")
            .attr("stroke", "gray")
            .attr("stroke-dasharray", "4")
            .lower();

          // Draw center point
          svg
            .append("circle")
            .attr("class", "debug-circle")
            .attr("cx", group.x)
            .attr("cy", group.y)
            .attr("r", 5)
            .attr("fill", "blue");

          svg
            .append("text")
            .attr("class", "label")
            .attr("x", group.x)
            .attr("y", group.y - group.height / 2 - 10)
            .attr("text-anchor", "middle")
            .text(attribute);
        });
      } else {
        simulation
          .force("x", d3.forceX(width / 2).strength(0.1))
          .force("y", d3.forceY(height / 2).strength(0.1))
          .alpha(1)
          .restart();

        svg.selectAll(".cluster-box").remove();
        svg.selectAll(".label").remove();
        svg.selectAll(".debug-circle").remove();
      }

      const node = svg
        .selectAll("g")
        .data(data, (d) => {
          if (!d || !d.id) {
            console.error("Invalid data item:", d);
            return d && d.id;
          }
          return d.id;
        })
        .join(
          (enter) => {
            const g = enter
              .append("g")
              .call(
                d3
                  .drag()
                  .on("start", dragstarted)
                  .on("drag", dragged)
                  .on("end", dragended)
              );

            g.append("circle")
              .attr("r", (d) => d.value)
              .attr("fill", (d) => d.color) // Apply default fill color
              .attr("stroke", (d) =>
                highlightedCircles.includes(d.id) ? "blue" : "none"
              )
              .attr("stroke-width", (d) =>
                highlightedCircles.includes(d.id) ? 2 : 0
              )
              .style("opacity", (d) =>
                highlightedCircles.length > 0
                  ? highlightedCircles.includes(d.id)
                    ? 1
                    : 0.2
                  : 1
              )
              .on("mouseenter", function (event, d) {
                d3.select(this).attr("stroke", "black");
                showTooltip(event, d);
              })
              .on("mouseleave", function (event, d) {
                d3.select(this).attr(
                  "stroke",
                  highlightedCircles.includes(d.id) ? "black" : "none"
                );
                hideTooltip();
              })
              .on("click", function (event, d) {
                onCircleClick(d);
              })
              .on("dblclick", function (event, d) {
                onCircleDoubleClick(d);
              });

            g.append("title").text(
              (d) =>
                `Name: ${d.name || 'Unnamed'}\nPercentage: ${d.percentage}%\nImportance: ${d.importance || 'N/A'}`
            );

            return g;
          },
          (update) => update,
          (exit) => exit.remove()
        );

      function ticked() {
        node.attr("transform", (d) => {
          if (
            clusterByAttribute &&
            clusterByAttribute !== "None" &&
            !draggedNode
          ) {
            const group = attributeGroups[d[clusterByAttribute] || 'Unknown'];
            d.x = Math.max(
              group.x - group.width / 2 + d.value,
              Math.min(group.x + group.width / 2 - d.value, d.x)
            );
            d.y = Math.max(
              group.y - group.height / 2 + d.value,
              Math.min(group.y + group.height / 2 - d.value, d.y)
            );
          }
          return `translate(${d.x}, ${d.y})`;
        });
        addFloatingNames();
      }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;

        setDraggedNode(d);

        data.forEach((node) => {
          if (node.id !== d.id) {
            node.fx = node.x;
            node.fy = node.y;
          }
        });
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;

        if (clusterByAttribute && clusterByAttribute !== "None") {
          const newAttribute = getCluster(event.x, event.y, attributeGroups);
          if (newAttribute !== highlightedCluster) {
            setHighlightedCluster(newAttribute);
            d3.selectAll(".cluster-box").attr("fill", "none");
            d3.select(`.cluster-box.${newAttribute}`).attr(
              "fill",
              "rgba(0, 0, 255, 0.1)"
            );
            setDraggingInfo(
              `Circle ${d.name || 'Unnamed'} is moving to ${newAttribute} cluster`
            );
          }
        }
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
        setDraggedNode(null);

        const newAttribute = getCluster(event.x, event.y, attributeGroups);
        if (newAttribute && d[clusterByAttribute] !== newAttribute) {
          d[clusterByAttribute] = newAttribute;
          onUpdateAttribute(d);
          setDraggingInfo(
            `Circle ${d.name || 'Unnamed'} has been moved to ${newAttribute} cluster`
          );
        } else {
          setDraggingInfo(`Circle ${d.name || 'Unnamed'} was not moved to a valid cluster`);
        }
        setHighlightedCluster(null);
        d3.selectAll(".cluster-box").attr("fill", "none");

        data.forEach((node) => {
          if (node.id !== d.id) {
            node.fx = null;
            node.fy = null;
          }
        });

        // Restart the simulation to update the positions
        simulation.nodes(data).alpha(1).restart();
      }

      function showTooltip(event, d) {
        d3.select("#tooltip")
          .style("opacity", 1)
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`)
          .html(
            `Name: ${d.name || 'Unnamed'}<br>Percentage: ${d.percentage}%<br>Importance: ${d.importance || 'N/A'}`
          );
      }

      function hideTooltip() {
        d3.select("#tooltip").style("opacity", 0);
      }

      function getCluster(x, y, groups) {
        const distances = Object.keys(groups).map((key) => ({
          key,
          distance: Math.sqrt(
            Math.pow(x - groups[key].x, 2) + Math.pow(y - groups[key].y, 2)
          ),
        }));
        const closest = distances.reduce((acc, curr) =>
          curr.distance < acc.distance ? curr : acc
        );
        return closest.key;
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => window.removeEventListener("resize", updateDimensions);
  }, [
    data,
    clusterByAttribute,
    highlightedCircles,
    highlightedCluster,
    onUpdateAttribute,
    draggedNode,
    setDraggingInfo,
  ]);

  useEffect(() => {
    updateCircles();
  }, [highlightedCircles]);

  useEffect(() => {
    addFloatingNames();
  }, [showNames, data]);

  return (
    <Box
      ref={containerRef}
      width="100%"
      height="80vh"
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <Button
        onClick={() => setShowNames(!showNames)}
        position="absolute"
        top="10px"
        left="10px"
      >
        Toggle Names
      </Button>
      <svg ref={svgRef}></svg>
      <div
        id="tooltip"
        style={{
          position: "absolute",
          opacity: 0,
          background: "lightgray",
          padding: "5px",
          borderRadius: "5px",
          pointerEvents: "none",
        }}
      ></div>
    </Box>
  );
};



const DataTable = ({ data, onEdit, defaultColumns }) => {
  const [visibleColumns, setVisibleColumns] = useState(defaultColumns);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = useState({
    name: "",
    percentage: 0,
    importance: "Medium",
    value: 0,
    group: "",
    color: "red",
  });
  const [editCircle, setEditCircle] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = () => {
    // Save logic here
    onClose();
  };

  const handleColumnChange = (e) => {
    const { value, checked } = e.target;
    setVisibleColumns((prev) =>
      checked ? [...prev, value] : prev.filter((col) => col !== value)
    );
  };

  // Extract all unique column names from the data
  const allColumns = useMemo(() => {
    const columns = new Set();
    data.forEach((item) => {
      Object.keys(item).forEach((key) => columns.add(key));
    });
    return Array.from(columns);
  }, [data]);

  const renderColumns = () => {
    if (!data || data.length === 0) return null;

    return visibleColumns.map((col) => <Th key={col}>{col}</Th>);
  };

  const handleEdit = (item) => {
    setFormValues(item);
    setEditCircle(true);
    onOpen();
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} w="100%" rightIcon={<LuCreditCard />}>
          Select Columns
        </MenuButton>
        <MenuList maxHeight="200px" overflowY="auto">
          <VStack align="start">
            <CheckboxGroup colorScheme="teal" defaultValue={defaultColumns}>
              {allColumns.map((col) => (
                <MenuItem key={col} closeOnSelect={false}>
                  <Checkbox
                    value={col}
                    isChecked={visibleColumns.includes(col)}
                    onChange={handleColumnChange}
                  >
                    {col}
                  </Checkbox>
                </MenuItem>
              ))}
            </CheckboxGroup>
          </VStack>
        </MenuList>
      </Menu>
      <TableContainer>
        <Table variant={"striped"}>
          <Thead>
            <Tr>
              {renderColumns()}
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => (
              <Tr key={item.id}>
                {visibleColumns.map((col) => (
                  <Td key={`${item.id}-${col}`}>{item[col]}</Td>
                ))}
                <Td>
                  <Button size="sm" onClick={() => handleEdit(item)}>
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <EditDrawer
        isOpen={isOpen}
        onClose={onClose}
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleSave={handleSave}
        editCircle={editCircle}
      />
    </>
  );
};

const Card = ({ item }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id: item.id, from: item.lead_status },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    console.log("Card component - item:", item);
  }, [item]);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      ref={drag}
      opacity={isDragging ? 0.5 : 1}
      maxW={"320px"}
      w={"full"}
      rounded={"md"}
      p={6}
      mb={2}
      bg={colorMode === 'light' ? 'gray.200' : 'gray.900'}

    >
      <Heading fontSize={"xl"} fontFamily={"body"}>
        {item.name}
      </Heading>
      <Text fontWeight={600} color={"gray.500"} mb={4}>
        {item.email_address}
      </Text>
    </Box>
  );
};


const CardLayout = ({ data, onEdit, clusterByAttribute }) => {
  // Function to group data based on the selected attribute
  const groupData = (data, attribute) => {
    console.log("attribute", attribute)

    const groupedData = {};
    data.forEach((item) => {
      const key = item[attribute];
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);
    });
    return groupedData;
  };

  // Filter and group data based on the selected attribute
  const groupedData =
    clusterByAttribute !== "None"
      ? groupData(data, clusterByAttribute)
      : { All: data };
      const { colorMode, toggleColorMode } = useColorMode();

  return (
    <div>
      {/* Render cards grouped by the selected attribute */}

      {Object.entries(groupedData).map(([group, items]) => (
        <>
          <Heading as="h2" size="lg" p="5" mb={4}>
            {group}
          </Heading>

          <SimpleGrid
            columns={{ base: 2, md: 4, lg: 6 }}
            spacing={4}
            p="5"
            textAlign={"left"}
            key={group}
          >
            {items.map((item) => (
              <Box maxW={"320px"} w={"full"} rounded={"md"} p={6} 
              bg={colorMode === 'light' ? 'gray.200' : 'gray.900'}

              >
                <Heading fontSize={"2xl"} fontFamily={"body"}>
                  {item.name}
                </Heading>
                <Text fontWeight={600} color={"gray.500"} mb={4}>
                  @lindsey_jam3s
                </Text>
                {/* <Card key={item.id} item={item} > </Card> */}

              </Box>
            ))}
          </SimpleGrid>
        </>
      ))}
    </div>
  );
};

const ItemTypes = {
  CARD: "card",
};


const SwimLane = ({ group, items, moveCard }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (draggedItem) => {
      console.log("Dropped item:", draggedItem, "in group:", group);
      moveCard(draggedItem.id, group);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    console.log("SwimLane component - group:", group, "items:", items);
  }, [group, items]);

  return (
    <Box
      ref={drop}
      minW="300px"
      mx={2}
      p={4}
      rounded="md"
      //border="1px solid gray"
      border={colorMode === 'light' ? 'gray.200' : 'gray.900'}

      bg={colorMode === 'light' ? (isOver ? 'blue.100' : 'gray.200') : (isOver ? 'blue.600' : 'gray.700')}

      // bg={isOver ? "blue.100" : "gray.100"}
    >
      <Heading as="h2" size="sm" mb={4}>
        {group}
      </Heading>
      <Stack spacing={4}>
        {items.map((item) => (
          <Card key={item.id} item={item} 
          
          bg={colorMode === 'light' ? 'gray.200' : 'gray.900'}

          />
        ))}
      </Stack>
    </Box>
  );
};

const KanbanLayout_2 = ({ data, onEdit, clusterByAttribute }) => {
  const [boardData, setBoardData] = useState(data);
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    setBoardData(data);
    console.log("KanbanLayout_2 component - data updated:", data);
  }, [data]);

  const groupData = (data, attribute) => {
    console.log("Grouping data by attribute:", attribute);
    const groupedData = {};
    data.forEach((item) => {
      const key = item[attribute] || "Undefined";
      if (!groupedData[key]) {
        groupedData[key] = [];
      }
      groupedData[key].push(item);
    });
    console.log("Grouped data:", groupedData);
    return groupedData;
  };

  const moveCard = (id, toGroup) => {
    console.log("Moving card with id:", id, "to group:", toGroup);
    setBoardData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.id === id ? { ...item, lead_status: toGroup } : item
      );
      console.log("Updated board data:", updatedData);
      return updatedData;
    });
  };

  const groupedData =
    clusterByAttribute !== "None"
      ? groupData(boardData, clusterByAttribute)
      : { All: boardData };

  useEffect(() => {
    console.log("KanbanLayout_2 component - groupedData:", groupedData);
  }, [groupedData]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Flex overflowX="auto" p={5}>
        {Object.entries(groupedData).map(([group, items]) => (
          <SwimLane key={group} group={group} items={items} moveCard={moveCard} />
        ))}
      </Flex>
    </DndProvider>
  );
};

const Import = () => {
  const [isSearchSelected, setIsSearchSelected] = useState(false);
  const [isIconButtonSelected, setIsIconButtonSelected] = useState(false);
  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);
  const [clusterByAttribute, setClusterByAttribute] = useState("lead_status");
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [editCircle, setEditCircle] = useState(null);
  const [formValues, setFormValues] = useState({
    name: "",
    percentage: "",
    importance: "",
    value: "",
    group: "",
    color: "black",
  });
  const [draggingInfo, setDraggingInfo] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [highlightedCircles, setHighlightedCircles] = useState([]);
  const [layout, setLayout] = useState("card");

  /// this is to manage clusters

  const [showDialog, setShowDialog] = useState(false);
  const [clusters, setClusters] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState(null);

  const handleAddCluster = (newCluster) => {
    setClusters([...clusters, newCluster]);
    setSelectedCluster(newCluster.clusterGroup);
  };

  // useEffect to fetch data on initial render
  useEffect(() => {
    const fetchData = async () => {
      const initialData = await fetchInitialData();
      setData(initialData); // Set fetched data
      setFilteredData(initialData); // Set filtered data to the fetched data
    };
    fetchData();
  }, []); // Empty dependency array to run only once on mount

  // useEffect to update filteredData whenever data changes
  useEffect(() => {
    setFilteredData(data);
    console.log(filteredData, "filteredData");
  }, [data]); // Dependency array includes data to update when data changes

  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const handleAddCircle = () => {
    setEditCircle(null);
    setFormValues({
      name: "",
      percentage: "",
      importance: "",
      value: "",
      group: "",
      color: "black",
    });
    onOpen();
  };

  const handleSave = () => {
    if (editCircle) {
      setData(
        data.map((d) =>
          d.id === editCircle.id
            ? {
                ...editCircle,
                ...formValues,
                value: parseInt(formValues.value, 10),
                percentage: parseInt(formValues.percentage, 10),
              }
            : d
        )
      );
    } else {
      const newCircle = {
        id: data.length + 1,
        name: formValues.name,
        percentage: parseInt(formValues.percentage, 10),
        importance: formValues.importance,
        value: parseInt(formValues.value, 10),
        group: formValues.group,
        color: formValues.color,
      };
      setData([...data, newCircle]);
    }
    onClose();
  };

  const handleClusterByAttribute = (e) => {
    //setClusterByAttribute(e.target.value);
    setClusterByAttribute(e.target.value);

  };

  const handleCircleClick = (circle) => {
    const selected = selectedOptions.find(
      (option) => option.value === circle.id
    );

    if (!selected) {
      const newSelectedOptions = [
        ...selectedOptions,
        { value: circle.id, label: circle.name },
      ];
      setSelectedOptions(newSelectedOptions);
      setHighlightedCircles(newSelectedOptions.map((option) => option.value));
      setFilteredData(
        data.filter((d) =>
          newSelectedOptions.map((option) => option.value).includes(d.id)
        )
      );
    }
  };

  const handleCircleDoubleClick = (circle) => {
    setEditCircle(circle);
    setFormValues({
      name: circle.name,
      percentage: circle.percentage.toString(),
      importance: circle.importance,
      value: circle.value.toString(),
      group: circle.group,
      color: circle.color,
    });
    onOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleUpdateAttribute = (updatedCircle) => {
    setData(data.map((d) => (d.id === updatedCircle.id ? updatedCircle : d)));
    toast({
      title: `Attribute updated for ${updatedCircle.name}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setHighlightedCircles(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
    setFilteredData(
      selectedOptions.length > 0
        ? data.filter((d) =>
            selectedOptions.map((option) => option.value).includes(d.id)
          )
        : data
    );
  };

  const options = data.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const [selectedButton, setSelectedButton] = useState(0);

  const bgColor = useColorModeValue("gray.100", "gray.700");

  const handleButtonClick = (index) => {
    setSelectedButton(index);
  };

  const handleSearchClick = () => {
    setIsSearchSelected(!isSearchSelected);
  };

  const handleIconButtonClick = () => {
    setIsIconButtonSelected(!isIconButtonSelected);
  };

  const [newData] = useState([]);
  const setNewData = (newData) => {
    setData((prevData) => [...prevData, ...newData]);
  };

  const handleUpdateStage = (updatedCircle) => {
    setData((prevData) =>
      prevData.map((d) => (d.id === updatedCircle.id ? updatedCircle : d))
    );
  };

  const setNewCircle = (newCircle) => {
    setData((prevData) => [...prevData, newCircle]);
  };

  const mappedData = mapIncomingData(initialData);
  const handleDrop = (id, newCluster) => {
    setData(prevData => prevData.map(item => item.id === id ? { ...item, cluster: newCluster } : item));
};


  return (
    <>
      <Box p="4" border="0px">
        <SimpleGrid columns={{ base: 1, md: 2 }} mb={1} p="0">
          <Box border="0px" p="2" pl="0">
            <Text fontSize={"21"}>Conversion Workbench</Text>
          </Box>
          <SimpleGrid columns="2" spacing="1" border="0px" pt="2">
            <ButtonGroup spacing={0} borderRadius="lg">
              <IconButton
                aria-label="Card Layout"
                icon={<LuCreditCard />}
                isActive={selectedButton === 1}
                bg={selectedButton === 0 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange("card")}
                borderRadius={"sm"}
              />
              <IconButton
                aria-label="Kanban Layout"
                icon={<FiColumns />}
                isActive={selectedButton === 2}
                bg={selectedButton === 0 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange("kanban")}
                borderRadius={"sm"}
              />
              <IconButton
                aria-label="Table Layout"
                icon={<FiTable />}
                borderRadius={"sm"}
                isActive={selectedButton === 3}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange("table")}
              />
              <IconButton
                aria-label="Text Layout"
                icon={<FiType />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange("text")}
              />
              <IconButton
                aria-label="Bubble Layout"
                icon={<AiOutlineDotChart />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange("bubble")}
              />
              <IconButton
                aria-label="List Layout"
                icon={<LuList />}
                borderRadius={"sm"}
                isActive={selectedButton === 1}
                bg={selectedButton === 1 ? bgColor : "transparent"}
                onClick={() => handleLayoutChange("list")}
              />
            </ButtonGroup>

          </SimpleGrid>
   
        </SimpleGrid>
        <SimpleGrid spacing="2" columns="2" w="100%">
          <ReactSelect
              isMulti
              options={options}
              value={selectedOptions}
              onChange={handleSelectChange}
              placeholder="Search..."
              styles={{
                container: (provided) => ({
                  ...provided,
                  marginBottom: "10px",
                  borderRadius: "0px",
                  borderLeft: "none",
                  borderRight: "none",
                  borderTop: "none",
                }),
              }}
            />

<Select
      p="0"
      value={clusterByAttribute}
      onChange={handleClusterByAttribute}
    > 
      {console.log("filter values", filterValues)}
      
      {Object.keys(attributeMapping).map((attribute) => (
        <option key={attribute} value={attribute}>{attribute}</option>
      ))}
    </Select>
          </SimpleGrid>
      </Box>

      <Divider />
      <Box p={0}>
        <Divider mb="0" />
        <Box textAlign={"right"} p="5">
          <ButtonGroup>
            <AddClusterDialog
              isOpen={showDialog}
              onClose={() => setShowDialog(false)}
              onSubmit={handleAddCluster}
            />

            <DynamicFormDrawer />
            <ImportCSVButton
              data={data}
              setNewData={setNewData}
              setData={setData}
            />
            <Button onClick={handleAddCircle}>Add Contact</Button>
          </ButtonGroup>
        </Box>
        <Box>
          {isIconButtonSelected && (
            <>
              {filteredData.length > 0 ? (
                <>
                  <ClusteredBubbleChart
                    data={filteredData}
                    clusterByAttribute={clusterByAttribute}
                    onCircleClick={handleCircleClick}
                    onCircleDoubleClick={handleCircleDoubleClick}
                    onUpdateAttribute={handleUpdateAttribute}
                    setDraggingInfo={setDraggingInfo}
                    highlightedCircles={highlightedCircles}
                  />
                </>
              ) : (
                <Box w="100%" textAlign={"center"} minH={"80vh"}>
                  <Center>
                    <Image src="/onboarding.png" mt="10" />
                  </Center>
                </Box>
              )}
            </>
          )}
        </Box>
        {layout === "card" && (
          <CardLayout
            data={filteredData}
            onEdit={handleCircleDoubleClick}
            clusterByAttribute={clusterByAttribute}
          />
        )}
        {layout === "table" && (
          <DataTable
            data={filteredData}
            onEdit={handleCircleDoubleClick}
            defaultColumns={[
              "name",
              "percentage",
              "importance",
              "value",
              "group",
              "color",
            ]}
          />
        )}
        {layout === "kanban" && (
          <KanbanLayout_2 data={filteredData} onEdit={handleCircleDoubleClick} clusterByAttribute={clusterByAttribute} />
        )}
        {/* {layout === 'text' && <TextManager data={filteredData} onEdit={handleCircleDoubleClick} />} */}
        {layout === "bubble" && (
          <ClusteredBubbleChart
            data={data}
            clusterByAttribute={clusterByAttribute}
            onCircleClick={handleCircleClick}
            onCircleDoubleClick={handleCircleDoubleClick}
            onUpdateAttribute={handleUpdateAttribute}
            setDraggingInfo={setDraggingInfo}
            highlightedCircles={highlightedCircles}
          />
        )}
      </Box>
      <Divider />

      <Box>    <DndProvider backend={HTML5Backend}>
        {console.log("MainComponent", data, attributeMapping, clusterByAttribute )}
      <MainComponent data={data} attributeMapping={attributeMapping} clusterByAttribute={clusterByAttribute} />
      </DndProvider> </Box>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {editCircle ? "Edit Circle" : "Add Circle"}
          </DrawerHeader>
          <DrawerBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Percentage</FormLabel>
              <Input
                name="percentage"
                type="number"
                value={formValues.percentage}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Importance</FormLabel>
              <Select
                name="importance"
                value={formValues.importance}
                onChange={handleInputChange}
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Value</FormLabel>
              <Input
                name="value"
                type="number"
                value={formValues.value}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Group</FormLabel>
              <Input
                name="group"
                value={formValues.group}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Color</FormLabel>
              <Select
                name="color"
                value={formValues.color}
                onChange={handleInputChange}
              >
                {["red", "blue", "green", "yellow", "black"].map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default Import;
