import { Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
  Box,
  Image,
  Button,
  Container,
  Center,
  Divider, 
  useBreakpointValue,
  SimpleGrid, Text, 
} from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import React, { useContext } from "react";
import { motion } from "framer-motion";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import AnimatedBg from "react-animated-bg";
import { PiChalkboardTeacherDuotone } from "react-icons/pi";
import { PiBagDuotone } from "react-icons/pi";
import { PiBellRingingDuotone } from "react-icons/pi";
import { PiButterflyDuotone } from "react-icons/pi";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import Layout from "./birdeye/layout";
import Front from "./birdeye/front";
import Merit from "./homepage/merit";
import Home from "./homepage/home";
// import Hotjar from '@hotjar/browser';
import Orange from "./homepage/newHome";
import Ogee from "./homepage/ogee";
import Revitalash from "./homepage/revitalash";
import Obagi from "./homepage/obagi";
import Grande from "./homepage/grande";
import Nulastin from "./homepage/nulastin";
import Stacy from "./homepage/stacy";
import Lilash from "./homepage/lilash";
import Natura from "./homepage/natura";
import Sisley from "./homepage/sisley";
import Dabalash from "./homepage/dabalash";
import Pat from "./homepage/pat";
import Lamer from "./homepage/lamer";
import KohGenDo from "./homepage/kohgendo";
import Chantecaille from "./homepage/chantecaille";
import Augustinus from "./homepage/augustinus";
import Terry from "./homepage/terry";
import Rodan from "./homepage/rodan";
import LMDB from "./homepage/lmdb";
import Haus from "./homepage/haus";
import Westman from "./homepage/westman";
import Joey from "./homepage/joey";
import Smash from "./homepage/smash";
import Tower from "./homepage/tower";
import Beautifect from "./homepage/beautifect";
import Olga from "./homepage/olga";
import Christina from "./homepage/christina";
import Nutrafol from "./homepage/nutrafol";
import Supergoop from "./homepage/supergoop";
import Tatcha from "./homepage/tatcha";
import Osea from "./homepage/osea";
import Juice from "./homepage/juice";
import Ubeauty from "./homepage/ubeauty";
import Vegamour from "./homepage/vegamour";
import Ouai from "./homepage/ouai";
import Olaplex from "./homepage/olaplex";
import K18 from "./homepage/k18";
import Sold from "./homepage/sold";
import ImageN from "./homepage/imagen";
import Verification from "./homepage/verification";
import Reviews from "./homepage/review";
import Refer from "./homepage/refer";
import Media from "./homepage/media";
import Themes from "./homepage/themes";
import FullDemo from "./fulldemo";
import SearchPackage from "./packaging";
import PackageList from "./packaging/PackageList";
import PackageDetail from "./packaging/PackageDetails";

import CartContext from "./packaging/components/CartContext";
import { CartProvider } from "./packaging/components/CartContext";
import CartPage from "./packaging/components/CartPage";
import Manish from "./manish";
import SalesOrder from "./manish/salesOrder";
import SalesHome from "./manish/saleshome";
import SalesOrderHome from "./manish/salesOrderHome";
// import Experiment from "./concepts/Experiment";
import Welcome from "./homepage/welcome";
import Productivity from "./productivity/productivity";
import Simple from "./productivity/simple";
import Tasks from "./productivity/tasks";
import TasksSimple from "./productivity/tasks_simple";
import TasksNew from "./productivity/tasks_new";
import Multiple from "./productivity/multiple";
import SimpleDragDrop from "./productivity/SimpleDragDrop";
import Drop from "./productivity/Drop";
import Simpler from "./productivity/simpler";
import LuruHome from "./productivity/home";
import Import from "./productivity/import";
import TaskManager from "./productivity/taskManager";
import ProtectedRoute from "./productivity/ProtectedRoute";
import SignIn from "./productivity/SignIn";
import RowColumns from "./productivity/rowsColumns";
import WorkCenterHome from "./productivity/WorkCenterHome";
import GTMCenterHome from "./productivity/GTMCenterHome";
import FunctionalStates from "./functionStates/FunctionalStates";
import States from "./functionStates/FunctionalStates";
import Salte from "./functionStates/Slate";
import ObjectExample from "./functionStates/ObjectExample";
import InsertObjectsForm from "./functionStates/ObjectForm";
import SlayHome from "./functionStates/home";
import ScenarioView from "./functionStates/ScenarioView";
import CompeteHome from "./productivity/compete/home";
import CreateCompetitionEntry from "./productivity/compete/CreateCompetitionEntry";
import CreateCompetitionEntryAdd from "./productivity/compete/CreateCompetitionEntry_Add";

// const siteId = 4987369;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);
export default function App() {
  return (
    <>
        <CartProvider>
      <BrowserRouter>
        <Routes>
        <Route exact path="/fc" element={<States />} />
        <Route exact path="/slate" element={<Salte />} />
        <Route exact path="/object" element={<ObjectExample />} />
        <Route exact path="/objectform" element={<InsertObjectsForm />} />
        <Route exact path="/scenario" element={<ScenarioView />} />

        <Route exact path="/obj1" element={
          <>
          <ProtectedRoute>
          <SlayHome />
          </ProtectedRoute>
          </>
          } />
        {/* <Route exact path="/experiment" element={<Experiment />} /> */}
        <Route exact path="/prod1" element={<Productivity />} />
        <Route exact path="/simples" element={<Simple />} />
        <Route exact path="/multiple" element={<Multiple />} />
        <Route exact path="/rows" element={<RowColumns />} />

        <Route exact path="/drag" element={<SimpleDragDrop />} />
        <Route exact path="/drop" element={<Drop />} />
        {/* <Route exact path="/luru" element={<LuruHome />} /> */}
        <Route exact path="/import" element={<Import />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/luru" element={
          <>
          <ProtectedRoute>
          <LuruHome />
          </ProtectedRoute>
          </>
          } />

<Route exact path="/gtm" element={
          <>
          <ProtectedRoute>
          <GTMCenterHome />
          </ProtectedRoute>
          </>
          } />

<Route exact path="/gtm/compete" element={
          <>
          <ProtectedRoute>
          <CompeteHome />
          </ProtectedRoute>
          </>
          } />

<Route exact path="/gtm/compete/add" element={
          <>
          <ProtectedRoute>
          <CreateCompetitionEntryAdd />
          </ProtectedRoute>
          </>
          } />

<Route exact path="/workcenter" element={
          <>
          <ProtectedRoute>
          <WorkCenterHome />
          </ProtectedRoute>
          </>
          } />

        {/* <Route exact path="/simpler" element={<Simpler />} /> */}


        <Route exact path="/prod/tasks" element={<Tasks />} />
        <Route exact path="/prod/tasksimple" element={<TasksSimple />} />
        <Route exact path="/prod/tasksnew" element={<TasksNew />} />


        <Route exact path="/manish" element={<Manish />} />
        <Route exact path="/manish/salesorder" element={<SalesOrder />} />
        <Route exact path="/manish/sales" element={<SalesHome />} />
        <Route exact path="/manish/sales/salesorder" element={<SalesOrderHome />} />


        <Route exact path="/package" element={<PackageList />} />
        <Route path="/package/:id" element={<PackageDetail />} />
        <Route path="/package/cartpage" element={<CartPage />} />


          
          <Route exact path="/" element={<Home />} />
          <Route exact path="/welcome" element={<Welcome />} />

          <Route exact path="/verfication" element={<Verification />} />
          <Route exact path="/reviews" element={<Reviews />} />
          <Route exact path="/refferals" element={<Refer />} />
          <Route exact path="/media" element={<Media />} />
          <Route exact path="/themes" element={<Themes />} />

          <Route exact path="/new" element={<Orange />} />
          <Route exact path="/demo" element={<FullDemo />} />

          <Route exact path="/social" element={<Layout />} />
          <Route exact path="/front" element={<Front />} />
          {/* <Route exact path="/Merit" element={<Merit />} /> */}

          {/* <Route exact path="/about" element={<About />} /> */}

          {/* <Route exact path="/ogee" element={<Ogee />} />

          <Route exact path="/revitalash" element={<Revitalash />} />
          <Route exact path="/obagi" element={<Obagi />} />
          <Route exact path="/grande" element={<Grande />} />
          <Route exact path="/nulastin" element={<Nulastin />} />
          <Route exact path="/stacy" element={<Stacy />} />
          <Route exact path="/lilash" element={<Lilash />} />
          <Route exact path="/natura" element={<Natura />} />
          <Route exact path="/sisley" element={<Sisley />} />
          <Route exact path="/dabalash" element={<Dabalash />} />
          <Route exact path="/pat" element={<Pat />} />
          <Route exact path="/lamer" element={<Lamer />} />
          <Route exact path="/chantecaille" element={<Chantecaille />} />
          <Route exact path="/terry" element={<Terry />} />
          <Route exact path="/rodan" element={<Rodan />} />
          <Route exact path="/lmdb" element={<LMDB />} />
          <Route exact path="/haus" element={<Haus />} />
          <Route exact path="/westman" element={<Westman />} />
          <Route exact path="/joey" element={<Joey />} />
          <Route exact path="/smash" element={<Smash />} />
          <Route exact path="/merit" element={<Merit />} />
          <Route exact path="/tower" element={<Tower />} />
          <Route exact path="/beautifect" element={<Beautifect />} />
          <Route exact path="/olga" element={<Olga />} />
          <Route exact path="/christina" element={<Christina />} />
          <Route exact path="/nutrafol" element={<Nutrafol />} />
          <Route exact path="/supergoop" element={<Supergoop />} />
          <Route exact path="/tatcha" element={<Tatcha />} />
          <Route exact path="/osea" element={<Osea />} />
          <Route exact path="/juice" element={<Juice />} />
          <Route exact path="/ubeauty" element={<Ubeauty />} />
          <Route exact path="/vegamour" element={<Vegamour />} />
          <Route exact path="/ouai" element={<Ouai />} />
          <Route exact path="/olaplex" element={<Olaplex />} />
          <Route exact path="/k18" element={<K18 />} />
          <Route exact path="/sold" element={<Sold />} />
          <Route exact path="/image" element={<ImageN />} /> */}
{/* 
          <Route exact path="/agustinus" element={<Augustinus />} /> */}

        </Routes>
      </BrowserRouter>
      </CartProvider>
    </>
  );
}

